<template>
    <div v-if="project">
        <table class="table table-responsive" style="font-size:70%" v-if="monthDiff()" >
            <tr>
                <td style="padding: 5px">Denumire</td>
                <td style="padding: 5px" v-for="month in monthDiff()">{{ currentMonthName(month) }}</td>
            </tr>
            <tr v-for="calendar in project.calendars">
                <td style="padding: 5px">
                    <el-popover placement="top-start" width="300" trigger="click" :content="calendar.activity.name">
                        <span  slot="reference" style="white-space: nowrap">A{{ calendar.activity.activity }} {{ calendar.activity.name | truncate(25,'...') }}</span>
                    </el-popover>
                </td>
                <td style="padding: 5px" v-for="month in monthDiff()">
                    <div :class="{ 'bg-tealDeer': calendarActivity(month, calendar) === 'p', 'bg-ufoGreen': calendarActivity(month, calendar) === 'f'}" :title="showDates(calendar)">&nbsp;</div>
                </td>
            </tr>
        </table>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import mixin from './../../mixins/common';
import moment from 'moment';
export default {
    name: "pane-actions-calendar",
    mixins:[mixin],
    data(){
        return {
            fm: false
        }
    },
    props:{
        project: {
            required: true,
            type: Object
        }
    },
    methods:{
        monthDiff(){
            return Number(moment(moment(this.project.implementation_end_date).endOf('month')).diff(moment(this.project.project_start_date).startOf('month'), 'months', true).toFixed());
        },
        currentMonthName(month){
            return moment(new Date(this.project.implementation_start_date)).add(month-1,'months').format('MMM YYYY')
        },
        showDates(calendar){
            let startDate = moment(calendar.start_date);
            let endDate = moment(calendar.end_date);
            return 'Incepe la ' + startDate.format('DD.MM.YYYY') + ', se termina la ' + endDate.format('DD.MM.YYYY')
        },
        calendarActivity(month, calendar){
            if(!calendar.start_date || !calendar.end_date) return false;
            let startDate = moment(new Date(this.project.implementation_start_date)).add(month-1,'months').startOf('month');
            let endDate = moment(new Date(this.project.implementation_start_date)).add(month-1,'months').endOf('month');
            let checkStartDate = moment(new Date(calendar.start_date));
            let checkEndDate = moment(new Date(calendar.end_date));
            if((checkStartDate.isSame(startDate,'day') && checkEndDate.isSame(endDate,'day')) ||
                (checkStartDate.isBefore(startDate,'days') && checkEndDate.isAfter(endDate,'days'))){;
                return 'f'
            }else if((checkStartDate.isBetween(startDate, endDate, 'days', '[]') && checkEndDate.isAfter(endDate,'days')) ||
                (checkStartDate.isBefore(startDate,'days') && checkEndDate.isBetween(startDate, endDate, 'days', '[]')) ||
                (checkStartDate.isBetween(startDate, endDate, 'days', '[]') && checkEndDate.isBetween(startDate, endDate, 'days', '[]'))){
                return 'p'
            }else{
                return null
            }
        },

    }
}
</script>

<style scoped>
.el-popover__reference{
    cursor: help;
}
</style>
