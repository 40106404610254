<template>
    <div>
        <el-drawer title="Validează raportare" :visible.sync="showInformationDrawer.visible" direction="rtl" :wrapperClosable="false" :before-close="handleCloseDrawer" :destroy-on-close="true">
            <div class="px-4">
                <el-form :model="form" ref="" label-position="top" :status-icon="true" :inline-message="true">
                    <el-form-item label="Salariul brut in luna conform CIM pe orgnizatie si actelor aditionale ulterioare (100%)" prop="fa">
                        <el-input v-model="form.manual_salary" placeholder="..." type="float" size="small" show-word-limit
                                  style="text-align:right" @focus="$event.target.select()">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Ore lucratoare in luna conform normei din CIM pe organizatie si actelor aditionale ulterioare(100%)" prop="ca">
                        <el-input v-model="form.manual_hours_worked" placeholder="..." type="float" size="small" show-word-limit
                                  style="text-align:right" @focus="$event.target.select()">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Justificarea depasirii costului orar bugetat" prop="motivate_cost" v-if="form.status !== 1">
                        <el-input v-model="form.justify"  type="textarea"></el-input>
                        <template slot="append">{{form.motivate_cost}}</template>
                    </el-form-item>
                    <hr />
                    <el-button style="margin-left: 10px;" size="small" type="success" @click="storeData(showUploadDrawer.object)">Salveaza</el-button>
                </el-form>
            </div>
        </el-drawer>
    </div>
</template>

<script>
import {mapGetters,mapMutations,mapActions} from "vuex";
import {showInformationDrawer} from "../../store/getters";
export default {
    name: "show-information-drawer",
    data() {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        }
    },
    mounted(){
        console.log('mounted drawer');
    },
    updated() {
        console.log('updated drawer');
    },
    watch:{

    },
    computed:{
        ...mapGetters({
            showInformationDrawer: 'showInformationDrawer',
            validationErrors: 'validationErrors',
        }),
    },
    methods:{
        ...mapActions({
            removeUploadedFile: 'removeUploadedFile',
            saveValidation: 'reporting/saveValidation',
        }),
        ...mapMutations({
            setShowInformationDrawer: 'setShowInformationDrawer',
        }),
    }
}
</script>

<style scoped>

</style>
