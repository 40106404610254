<template>
    <el-form :model="project" :rules="rules" ref="description" label-position="top" :status-icon="true" :inline-message="true">
        <h3 class="mb-4">Descriere</h3>
        <el-form :model="form" id="placesForm" :rules="placeRules" ref="placesForm" label-position="top" :status-icon="true" :inline-message="true">
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <el-form-item label="Selecteaza localitatea" prop="place">
                            <el-select v-model="form.place" filterable clearable remote
                                       placeholder="Introdu numele localitatii" :remote-method="remoteMethod" style="width:100%" value-key="id">
                                <el-option v-for="item in cities"
                                           :key="item.id"
                                           :label="item.name + ' - ' + (item.county.name !== 'Bucuresti' ? 'Judetul ' : ' ') + item.county.name"
                                           :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-2 form-group">
                    <el-form-item class="mt-1" label=" ">
                        <el-button @click.prevent="addPlace" type="secondary">Adauga localitate</el-button>
                    </el-form-item>
                </div>
                <div class="col-sm-6">
                </div>
            </div>
        </el-form>
        <div class="row">
            <div class="col-sm-6">
                <el-form-item label="Localitati de desfasurare" prop="place">
                    <el-table :data="project.locations">
                        <el-table-column prop="name" label="Oras"></el-table-column>
                        <el-table-column prop="county.name" label="Judet"></el-table-column>
                        <el-table-column label="Actiuni" width="80">
                            <template slot-scope="scope">
                                <el-button size="mini" type="danger" icon="el-icon-delete"
                                           @click="handleDeleteRow(scope.$index, scope.row)"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <el-form-item label="Proiectul este preponderent in mediul" prop="medium">
                        <el-select v-model="project.medium" filterable placeholder="Select" style="width:100%" size="medium" value-key="id">
                            <el-option v-for="item in medium.values"
                                    :key="item.id" :label="item.value" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <el-form-item label="Prioritati transversale ale programului" prop="priorities" >
                        <el-select v-model="project.priority" filterable placeholder="Select" style="width:100%" size="medium" multiple value-key="id">
                            <el-option v-for="item in priorities.values" :key="item.id" :value="item" :label="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="form-group">
                    <el-form-item label="Policy markers" prop="policies">
<!--                        <el-select v-model="project.policies" filterable placeholder="Select" style="width:100%"  value-key="id">-->
<!--                            <el-option v-for="item in policies.values"-->
<!--                                       :key="item.id" :label="item.value"  :value="item">-->
<!--                            </el-option>-->
<!--                        </el-select>-->
                    <div class="row">
                        <div class="col-3">
                            Egalitate de gen
                            <el-select v-model="project.policy_1" filterable placeholder="Select" style="width:100%"  value-key="id">
                                <el-option v-for="item in policiesValues"
                                           :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="col-3">
                            Cresterea capacitatii si incluziunea romilor
                            <el-select v-model="project.policy_2" filterable placeholder="Select" style="width:100%"  value-key="id">
                                <el-option v-for="item in policiesValues"
                                           :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="col-3">
                            Incluziunea sociala a altor grupuri sociale decat roma
                            <el-select v-model="project.policy_3" filterable placeholder="Select" style="width:100%"  value-key="id">
                                <el-option v-for="item in policiesValues"
                                           :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="col-3">
                            Anti-discriminare
                            <el-select v-model="project.policy_4" filterable placeholder="Select" style="width:100%"  value-key="id">
                                <el-option v-for="item in policiesValues"
                                           :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                        <div class="col-3">
                            Transparenta si anticoruptie
                            <el-select v-model="project.policy_5" filterable placeholder="Select" style="width:100%"  value-key="id">
                                <el-option v-for="item in policiesValues"
                                           :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </div>
                    </div>










<!--                        <el-table :data="policies" v-loading="false" element-loading-text=""-->
<!--                                  border fit highlight-current-row style="width: 100%">-->
<!--                            <el-table-column min-width="150px" align="center" >-->
<!--                                <template slot-scope="scope">-->
<!--                                    <span>{{scope.row.name}}</span>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                            <el-table-column min-width="150px" align="center" :label="item.name" :key="item.id"-->
<!--                                             v-for="item in policiesHead">-->
<!--                                <template slot-scope="scope">-->
<!--                                    <el-checkbox @change="policyM(scope.row, item.id)" :disabled="scope.row.disabled" :checked="scope.row.roles.includes(item.id)"/>-->
<!--                                </template>-->
<!--                            </el-table-column>-->
<!--                        </el-table>-->
                    </el-form-item>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="form-group">
                    <el-form-item label="Beneficiari" prop="beneficiary">
                        <el-input @change="updateForm" maxlength="2000" show-word-limit v-model="project.beneficiary" :rows="5" placeholder="Introduceti descrierea beneficiarilor" type="textarea"></el-input>
                    </el-form-item>
                </div>
            </div>
        </div>
        <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <el-form-item label="Rezumatul proiectului in limba Romana" prop="description">
                            <el-input @change="updateForm" maxlength="2000" show-word-limit v-model="project.description" :rows="10" placeholder="Introduceti descrierea proiectului" type="textarea"></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <el-form-item label="Rezumatul proiectului in limba Engleza" prop="description_en">
                            <el-input @change="updateForm" maxlength="2000" show-word-limit v-model="project.description_en" :rows="10" placeholder="Introduceti descrierea proiectului" type="textarea"></el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>
    </el-form>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex';
    import moment from "moment";
    export default {
        name: "project-description-form",
        data(){
            return {
                form:{},
                rules: {
                    // locations: [
                    //     { required: true, message: 'Selectati locul de desfasurare', trigger: 'change' },
                    // ],
                    medium: [
                        { required: true, message: 'Selectati mediul de desfasurare', trigger: 'change' },
                    ],
                    framing: [
                        { required: true, message: 'Selectati categoria de incadrare', trigger: 'change' },
                    ],
                    priority: [
                        { required: true, message: 'Selectati prioritatea programului', trigger: 'change' }
                    ],
                    beneficiary: [
                        { required: true, message: 'Introduceti descrierea beneficiarilor', trigger: 'blur' }
                    ],
                    description: [
                        { required: true, message: 'Introduceti descrierea proiectului in limba Romana', trigger: 'blur' }
                    ],
                    description_en: [
                        { required: true, message: 'Introduceti descrierea proiectului in limba Engleza', trigger: 'blur' }
                    ],
                },
                // policiesList: [
                //     {id: 1, name: "Egalitate de gen", roles: [], disabled: false},
                //     {id: 2, name: "Cresterea capacitatii si incluziunea romilor", roles: []},
                //     {id: 3, name: "Incluziunea sociala a altor grupuri sociale decat roma", roles: []},
                //     {id: 4, name: "Anti-discriminare", roles: []},
                //     {id: 5, name: "Transparenta si anticoruptie", roles: []},
                // ],
                policiesValues: [
                    {id: 0, name: "Nu se aplica"},
                    {id: 1, name: "Relevant"},
                    {id: 2, name: "Fundamental"},
                ],
                checked: false,
                placeRules: {
                    place: [
                        {required: true, message: 'Introduceti numele unei localitati', trigger: 'blur'},
                    ],
                },
            }
        },
        computed:{
            ...mapGetters({
                cities: 'cities',
                medium: 'project/medium',
                framing: 'project/framing',
                priorities: 'project/priorities',
                // prioritiesNew: 'project/prioritiesNew',
                policies: 'project/policies',
                project: 'project/project',
                validateDescription: 'project/validateDescription',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validateDescription: function(){
                if(this.validateDescription) {
                    this.setValidateDescription(false);
                    this.submitForm();
                }
            },
        },
        mounted(){
            this.getMedium();
            this.getFraming();
            this.getPriorities();
            this.getPolicies();
            this.clear();
            // this.project.policy.forEach((row2, index2) => {
            //     this.policiesList.forEach((row, index) => {
            //         if (row2.id === row.id) {
            //             row.roles = row2.roles;
            //             row.disabled = true;
            //         }
            //     });
            // });
        },
        methods:{
            ...mapMutations({
                setValidateDescription: 'project/setValidateDescription',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            ...mapActions({
                searchCity: 'searchCity',
                getMedium: 'project/getMedium',
                getFraming: 'project/getFraming',
                getPriorities: 'project/getPriorities',
                saveProject: 'project/saveProject',
                getPolicies: 'project/getPolicies'
            }),
            updateForm() {
                this.saveProject(this.project).then((result) => {
                    if(result.valid === false){
                        this.$notify({ type:'error', title: 'Eroare', message: result.message });
                        reject(false);
                    }
                });
            },
            policyM(row, itemId) {
                if (this.project.policyNew.includes(row)) {
                    this.$notify({ type:'error', title: 'Eroare', message: 'Ai selectat deja o valoare pentru aceasta linie!' });
                } else {
                    row.roles.push(itemId);
                    this.project.policyNew.push(row);
                    row.disabled = true;
                }

            },
            addPlace(){
                this.$refs['placesForm'].validate((valid) => {
                    if (valid) {
                        if (this.form.index > this.project.locations.length) {
                            this.project.locations.push(this.form.place);
                        } else {
                            this.project.locations[this.form.index - 1] = this.form
                        }
                        this.clear();
                        return true;
                    } else {
                        return false;
                    }
                });
            },
            handleDeleteRow(index, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi aceasta inregistrare?')
                    .then(_ => {
                        if (index !== -1) {
                            this.project.locations.splice(index, 1);
                            this.clear();
                        }
                    }).catch(err => {
                        console.log(err)
                    });
            },
            remoteMethod(query) {
                if (query !== '') {
                    setTimeout(() => {
                        this.searchCity(query);
                    }, 200);
                }
            },
            submitForm() {
                this.$refs['description'].validate((valid) => {
                    if (valid) {
                        this.setValidationErrorsExists(false);
                        return true;
                    } else {
                        this.setValidationErrorsExists(true);
                        return false;
                    }
                })
            },
            clear(){
                this.form =  {index: this.project.locations.length + 1};
                this.$refs['placesForm'].clearValidate();
            },
        }
    }
</script>

<style scoped>

</style>
