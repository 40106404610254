<template>
    <div v-if="project" id="employees">
        <h3 class="mb-4 mt-3">
            Echipa
        </h3>
        <div class="row">
            <div class="col-sm-12">
                <el-table :data="notification.employees" stripe fit highlight-current-row style="width:100%">
                    <el-table-column label="Aplicant/Partener">
                        <template slot-scope="scope">
                            <template v-if="scope.row.partner">
                                {{ scope.row.partner.name }}
                            </template>
                            <template v-else>
                                Aplicant
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column label="CNP" prop="cnp" width="140"></el-table-column>
                    <el-table-column label="Pozitie" prop="position"></el-table-column>
                    <el-table-column label="Nume">
                        <template slot-scope="scope">
                            {{ scope.row.first_name }} {{ scope.row.last_name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Data angajare" prop="start_date" width="120" align="right">
                        <template slot-scope="scope" v-if="scope.row.start_date">
                            {{ formatUserDate(scope.row.start_date) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Data finalizare" prop="end_date" width="120" align="right">
                        <template slot-scope="scope" v-if="scope.row.end_date">
                            {{ formatUserDate(scope.row.end_date) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Numar ore" prop="hours" width="100" align="right"></el-table-column>
                    <el-table-column label="Cost ora proiect" prop="project" width="100" align="right"></el-table-column>
                    <el-table-column label="Actiuni" width="130" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="secondary" icon="el-icon-edit"
                                       @click="handleSelectRow(scope.row)"></el-button>
                            <el-button size="mini" type="danger" icon="el-icon-delete"
                                       @click="handleDeleteRow(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-form :model="form" id="employeeForm" :rules="rules" ref="employeeForm" label-position="top" :status-icon="true" :inline-message="true">
            <div class="row">
                <div class="col-sm-12">
                    <h4 class="mb-4 mt-5">Adauga/modifica angajat</h4>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Pozitia din proiect" prop="position">
                            <el-input v-model="form.position" placeholder="Introduceti functia" clearable type="text" maxlength="90" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Partener, daca se aloca pe un partener, altfel se aloca la Aplicant" prop="partner">
                            <el-select v-model="form.partner" clearable placeholder="Selecteaza" style="width:100%" value-key="id">
<!--                                <el-option label="Aplicant" :value="null"></el-option>-->
                                <el-option v-for="item in project.partners"
                                           :key="item.id" :label="item.name"  :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Prenumele">
                        <el-input v-model="form.first_name" placeholder="Introduceti prenumele (ex: Mihai)" type="text" clearable maxlength="30" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Numele">
                        <el-input v-model="form.last_name" placeholder="Introduceti numele de familie (ex: Ionescu)" type="text" maxlength="30" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item>
                        <template slot="label">CNP
                            <el-tooltip effect="light" postion="top">
                                <div slot="content">Daca nu este alocata o persoana inca,<br />pozitiei i se va aloca un numar unic pentru identificare,<br />de tipul 9999xxxxxxxxx</div>
                                <i class="el-icon-info"></i>
                            </el-tooltip>
                        </template>
                        <el-input v-model="form.cnp" placeholder="Introduceti Codul Numeric Personal" type="text" maxlength="13" show-word-limit></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 form-group">
                    <el-form-item label="Numar CIM">
                        <el-input v-model="form.cim" placeholder="Introduceti numarul CIM" type="text" maxlength="10" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3">
                    <el-form-item label="Data CIM" class="form-group">
                        <el-date-picker v-model="form.cim_date" format="dd.MM.yyyy" value-format="yyyy-MM-dd"
                                        type="date" placeholder="Selecteaza data"></el-date-picker>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Data angajare">
                        <el-date-picker v-model="form.start_date" format="dd.MM.yyyy" value-format="yyyy-MM-dd" type="date" placeholder="Selecteaza data" @change="updateStartPeriod"></el-date-picker>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Data finalizare">
                        <el-date-picker v-model="form.end_date" format="dd.MM.yyyy" value-format="yyyy-MM-dd" type="date" placeholder="Selecteaza data" :picker-options="periodEndOptions"></el-date-picker>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Tip norma" prop="type">
                        <el-select v-model="form.workload" placeholder="Select" style="width:100%" value-key="id">
                            <el-option v-for="item in workloads.values"
                                       :key="item.id" :label="item.value"  :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-3">
                    <el-form-item label="Nr. ore norma" class="form-group">
                        <el-input v-model="form.workload_hours" placeholder="Introdu valoarea" clearable type="number" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3">
                    <el-form-item label="Salariu brut" class="form-group">
                        <el-input v-model="form.salary" placeholder="Valodare bruta salariu lunar" clearable type="number" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3">
                    <el-form-item label="Ore bugetate" class="form-group">
                        <el-input v-model="form.hours" placeholder="Numar ore bugetate" clearable type="number" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </div>

                <div class="col-sm-3">
                    <el-form-item label="Cost orar proiect in euro" class="form-group">
                        <el-input v-model="form.project" placeholder="Introdu valoare in euro" clearable type="number" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </div>
<!--                <div class="col-sm-12">-->
<!--                    <el-form-item label="Implicare in proiect" class="form-group">-->
<!--                        <el-input v-model="form.involvement" placeholder="Descrieti implicarea in activitatiile proiectului" clearable type="textarea" rows="4" show-word-limit></el-input>-->
<!--                    </el-form-item>-->
<!--                </div>-->
                <div class="col-sm-12 text-right mb-5">
                    <el-button @click.prevent="clear">Goleste formularul</el-button>
                    <el-button @click.prevent="addRecord" type="secondary">Aplica modificarile</el-button>
                </div>
            </div>
            <div class="input-group">
                <textarea placeholder="Adauga un comentariu" v-on:change="saveComment" v-model="form.comment_sec7" class="form-control custom-control" rows="3" style="resize:none"></textarea>
                <el-button class="input-group-addon btn btn-primary" icon="el-icon-edit" @click="uploadFilesComment(notification)"></br>Vezi/modifică</br>fișierele</br>comentariului</el-button>
            </div>
            <hr />
            <div class="form-group">
                <h5>Lista comentarii:</h5>
                <span style="white-space: pre-line;">{{ form.comment_sec7 }}</span>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from '../../mixins/common';
    import moment from 'moment';
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "notification-team-form",
        mixins: [mixin],
        data(){
            return {
                form:{},
                rules: {
                    position: [
                        {required: true, message: 'Numele pozitiei din proiect este obligatoriu', trigger: 'blur'},
                        { min: 3, max: 90, message: 'Lungimea trebuie sa fie intre 3 si 90 caractere', trigger: 'blur' }
                    ],
                    cnp: [
                        { min: 13, max: 13, message: 'Lungimea trebuie sa fie de 13 caractere', trigger: 'blur'},
                    ],
                },
                minDate: new Date(),
                periodEndOptions: {
                    disabledDate: this.disabledEndDate
                },
            }
        },
        computed:{
            ...mapGetters({
                workloads: 'project/workloads',
                project: 'project/project',
                notification: 'notification/notification',
                validateTeam: 'project/validateTeam',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validateTeam: function(){
                if(this.validateTeam) {
                    this.submitForm();
                }
            },
        },
        mounted(){
            this.getWorkloads();
            this.clear();
        },
        methods:{
            ...mapActions({
                getWorkloads: 'project/getWorkloads',
                // saveProjectFinancial: 'project/saveProjectFinancial', // nu trebuie sa modificam bugetul, daca e ceva de modificat apelam functia respectiva saveTeam
                saveNotificationComment: 'notification/saveNotificationComment'
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
                setValidateTeam: 'project/setValidateTeam',
                setValidationErrorsExists: 'setVfalidationErrorsExists'
            }),
            uploadFilesComment(form)
            {
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Notification',
                    model_id: form.id,
                    category_id: 8,
                    acceptFiles: '.pdf,.docx,.doc,.xlsx,.xls',
                    multiple: true,
                    title: 'Incarcă documente',
                    files: form.commentfiles.filter(p => p.category_id == 8),
                });
            },
            saveComment()
            {
                this.saveNotificationComment({
                    id: this.notification.id,
                    project_id: this.project.id,
                    section_id: 7,
                    comment: this.form.comment_sec7,
                });
            },
            disabledEndDate(date) {
                return date < this.minDate
            },
            updateStartPeriod: function(){
                this.minDate = moment(this.form.start_date)
            },
            addRecord(){
                this.$refs['employeeForm'].validate((valid) => {
                    if (valid) {
                        // this.form.city = this.form.place.name;
                        if(this.form.index > this.notification.employees.length) {
                            if(!this.form.cnp) this.form.cnp = Math.floor(Math.random() * (999999999 - 1 + 1)) + 9999000000000;
                            this.form.start_date = this.form.start_date ? this.formatMysqlDate(this.form.start_date) : null;
                            this.form.end_date = this.form.end_date ? this.formatMysqlDate(this.form.end_date) : null;
                            this.form.cim_date = this.form.cim_date ? this.formatMysqlDate(this.form.cim_date) : null;
                            this.notification.employees.push(this.form);
                        }else{
                            this.notification.employees[this.form.index - 1] = this.form
                        }

                        // this.saveProjectFinancial(this.project).then((result) => { // nu trebuie sa modificam bugetul, daca e ceva de modificat apelam functia respectiva saveTeam
                        //     if(result.valid === false){
                        //         this.$notify({ type:'error', title: 'Eroare', message: result.message });
                        //         reject(false);
                        //     }
                        // });

                        // this.clear();
                        this.saveTeam();
                        this.setValidateTeam(false);
                        VueScrollTo.scrollTo('#employees');
                        return true;
                    } else {
                        VueScrollTo.scrollTo('#employeeForm');
                        return false;
                    }
                });
            },
            saveTeam()
            {
                axios.post('/notifications/notificationEmployee', {project_id: this.project.id, id:this.notification.id, employees: this.notification.employees}).then((result) => { this.$notify({ type: 'success', title: 'Echipa', message: 'Utilizatorul a fost adaugat cu succes!'}); });
            },
            handleSelectRow(row){
                this.clear();
                this.form = row;
                VueScrollTo.scrollTo('#employeeForm');
            },
            clear(){
                this.form =  {index: this.notification.employees.length + 1, partner: {}};
            },
            handleDeleteRow(index, row){
                this.$confirm('Ești sigur(ă) că vrei să ștergi această înregistrare? Acest proces este ireversibil!')
                    .then(_ => {
                        if (index !== -1) {
                            if(row.id > 0) {
                                axios.post('/notifications/notificationRemoveEmployee', {project_id: this.project.id, last_name: row.last_name, first_name: row.first_name});
                                this.notification.employees.splice(index, 1);
                            }else {
                                this.reporting.calendars.splice(index, 1);
                            }
                            this.$notify({ type: 'success', title: 'Echipa', message: 'Înregistrarea a fost ștearsă cu succes!'});
                            this.clear();
                        }
                    }).catch(err => {
                        console.log(err)
                    });
            },
            submitForm() {
                if (this.notification.employees.length > 0) {
                    this.setValidateTeam(false);
                    this.setValidationErrorsExists(false);
                    return true;
                } else {
                    this.setValidationErrorsExists(true);
                    this.$notify({ type: 'warning', title: 'Echipa', message: 'Trebuie sa adaugati cel putin un membru!'});
                    return false;
                }
            },
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
