<template>
    <div>
        <div class="row">
            <div class="col-sm-22">
                <h3>
                    <a href="#" @click.prevent="openUrl('/reportings/exportFinancialReport/'+reporting.id+'/'+project.id, true)" title="Export in Excel" class="btn btn-sm btn-secondary ml-5">
                        <i class="icofont-file-excel"></i> Export in Excel
                    </a>
                </h3>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-sm-12">
                <el-tabs v-model="reportTabs">
                    <el-tab-pane :label="'A'+activity.activity" :name="'A'+activity.activity" v-for="activity in project.activities" :key="activity.id">
                        <template slot="label">
                            <el-tooltip position="top">
                                <div slot="content">{{ activity.name }}</div>
                                <span>A{{ activity.activity }}</span>
                            </el-tooltip>
                        </template>
                        <el-table :data="financialReport[activity.id]" :summary-method="getSummaries" show-summary size="mini">
                            <el-table-column show-overflow-tooltip label="Capitol/Linie buget" width="200">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.category">{{ scope.row.category }}</span>
                                    <span v-if="scope.row.budget" style="margin-left:10px;">{{ scope.row.budget }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="Buget aprobat" align="center">
                                <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                </el-table-column>
                                <el-table-column label="Comp. dezv." align="right" header-align="center" prop="dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="pre_financing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.pre_financing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="pre_cofinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.pre_cofinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="pre_total">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.pre_total) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="pre_dezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.pre_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli aferente perioadei raportate" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="now_financing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.now_financing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="now_cofinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.now_cofinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="now_total">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.now_total) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="now_dezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.now_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Sume necheltuite" align="center">
                                <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                </el-table-column>
                                <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="Indirecte" name="Indirecte">
                        <template slot="label">
                            <el-tooltip position="top">
                                <div slot="content">Cheltuieli indirecte</div>
                                <span>Indirecte</span>
                            </el-tooltip>
                        </template>
                        <el-table :data="financialIndirectReport" :summary-method="getSummaries" show-summary size="mini">
                            <el-table-column show-overflow-tooltip label="Aplicant/Partener" width="200">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.partner">{{ scope.row.partner }}</span>
                                    <!--                                    <span v-if="scope.row.budget" style="margin-left:10px;">{{ scope.row.budget }}</span>-->
                                </template>
                            </el-table-column>
                            <el-table-column label="Buget aprobat" align="center">
                                <el-table-column label="Total" align="right" header-align="center" prop="total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                                </el-table-column>
                                <el-table-column label="Comp. dezv." align="right" header-align="center" prop="dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli acceptate raportari precedente" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="pre_financing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.pre_financing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="pre_cofinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.pre_cofinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="pre_total">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.pre_total) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="pre_dezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.pre_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Cheltuieli aferente perioadei raportate" align="center">
                                <el-table-column label="Din finantare" align="right" header-align="center" prop="now_financing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.now_financing) }}</template>
                                </el-table-column>
                                <el-table-column label="Din cofinantare" align="right" header-align="center" prop="now_cofinancing">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.now_cofinancing) }}</template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" header-align="center" prop="now_total">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.now_total) }}</template>
                                </el-table-column>
                                <el-table-column label="Dezv. org." align="right" header-align="center" prop="now_dezv">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.now_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                            <el-table-column label="Sume necheltuite" align="center">
                                <el-table-column label="Din buget" align="right" header-align="center" prop="diff_total" width="115">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_total) }}</template>
                                </el-table-column>
                                <el-table-column label="Din dezv. org." align="right" header-align="center" prop="diff_dezv" width="100">
                                    <template slot-scope="scope">{{ formatPrice(scope.row.diff_dezv) }}</template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';
    import VueScrollTo from "vue-scrollto";

    export default {
        name: "reporting-report-form",
        mixins:[mixin],
        props:{
            project: {
                required: true,
                type: Object
            },
            reporting: {
                required: true,
                type: Object
            },
        },
        data(){
            return{
                reportTabs: 'A1',
                report: [],
            };
        },
        computed:{
            ...mapGetters({
                financialReport: 'reportings/financialReport',
                financialIndirectReport: 'reportings/financialIndirectReport',
            }),
        },
        watch:{
            reporting: function(bla){
                if(this.project.id && this.reporting.id) {
                    this.getFinancialReport({project_id: this.project.id, reporting_id: this.reporting.id});
                    this.getFinancialIndirectReport({project_id: this.project.id, reporting_id: this.reporting.id});
                }
            },
            validateReport: function(){
                if(this.validateReport) {
                    this.setValidationErrorsExists(true);
                    this.submitForm();
                }
            },
        },
        mounted(){
            if(this.project.id && this.reporting.id) {
                this.getFinancialReport({project_id: this.project.id, reporting_id: this.reporting.id});
                this.getFinancialIndirectReport({project_id: this.project.id, reporting_id: this.reporting.id});
            }
        },
        methods:{
            ...mapActions({
                getFinancialReport: 'reportings/getFinancialReport',
                getFinancialIndirectReport: 'reportings/getFinancialIndirectReport',
            }),
            ...mapMutations({
                setValidationErrorsExists: 'setValidationErrorsExists',
            }),
            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = 'Totaluri';
                        return;
                    }
                    // if(index === 1){
                    //     sums[index] = '';
                    //     return;
                    // }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    } else {
                        sums[index] = ' - ';
                    }
                    if(index !== 0){
                        sums[index] =  this.formatPrice(parseFloat(sums[index]));
                    }
                });
                return sums;
            },
            submitForm() {
                this.setValidationErrorsExists(false);
                return true;
            },
        }
    }
</script>

<style scoped>

</style>
