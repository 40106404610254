<template>
    <div v-if="project">
        <h3 class="mb-4 mt-3">Parteneri</h3>
        <div class="row">
            <div class="col-sm-12">
                <el-table :data="project.partners" id="partners" stripe fit highlight-current-row style="width:100%">
                    <el-table-column label="Denumire" prop="name"></el-table-column>
                    <el-table-column label="Tip" prop="type.value" width="170"></el-table-column>
                    <el-table-column label="CIF" prop="cif" width="120">
                        <template slot-scope="scope">
                            {{ scope.row.fiscal_attribute }}{{ scope.row.cif }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Nr. inreg." prop="register_no" width="140"></el-table-column>
                    <el-table-column label="Localitate" width="250">
                        <template slot-scope="scope">
                            <span v-if="scope.row.country.id === 1 && scope.row.city !== null">
                                {{ scope.row.city.name}} - {{ scope.row.country.name }}
                            </span>
                            <span v-else="scope.row.country.id !== 1">
                                {{ scope.row.address }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Actiuni" width="130px" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="secondary" icon="el-icon-edit"
                                       @click="selectActivity(scope.row)"></el-button>
                            <el-button size="mini" type="danger" icon="el-icon-delete"
                                       @click="handleDelete(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-form :model="form" id="partnerForm" :rules="rules" ref="partnerForm" label-position="top" :status-icon="true" :inline-message="true">
<!--            <div class="row mt-5">-->
<!--                <div class="col-sm-6">-->
<!--                    <el-form-item label="Cauta un partener existent dupa nume sau cod fiscal">-->
<!--                        <el-select v-model="form.company" placeholder="Introduceti numele sau CIF" filterable remote @change="updateCompany"-->
<!--                                   :remoteMethod="checkUnique" style="width:100%" value-key="id">-->
<!--                            <el-option v-for="item in companies" :key="item.id" :label="item.name + '       CIF: ' + item.cif" :value="item">-->
<!--                            </el-option>-->
<!--                        </el-select>-->
<!--                    </el-form-item>-->
<!--                </div>-->
<!--            </div>-->
            <div class="row">
                <div class="col-sm-12">
                    <hr />
                    <h4 class="mb-4 mt-5">Adauga/modifica partener</h4>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Denumirea partenerului (pentru adaugare introduceti si selectati numele)" prop="name">
                            <el-select v-model="form.name" placeholder="Introduceti numele" filterable remote allow-create @change="updateCompany"
                                       :remoteMethod="checkUnique" style="width:100%" value-key="id">
                                <el-option v-for="item in companies" :key="item.id" :label="item.name + '       CIF: ' + item.cif" :value="item"></el-option>
                            </el-select>
<!--                            <el-input @change="updateForm" v-model="form.name" placeholder="Denumirea partenerului in limba romana" clearable type="text" maxlength="90" show-word-limit></el-input>-->
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Denumirea partenerului in limba engleza" prop="name">
                            <el-input @change="updateForm" v-model="form.name_en" placeholder="Denumirea partenerului in limba engleza" clearable type="text" maxlength="90" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="CIF" prop="cif">
                        <el-select v-model="form.cif" placeholder="Introduceti CIF" filterable remote allow-create @change="updateCompany"
                                   :remoteMethod="checkUniquec" style="width:100%" value-key="id">
                            <el-option v-for="item in companies" :key="item.id" :label="item.name + '       CIF: ' + item.cif" :value="item"></el-option>
                        </el-select>
<!--                        <el-input @change="updateForm" v-model="form.cif" placeholder="Introduceti CIF-ul" type="number" maxlength="10" show-word-limit></el-input>-->
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Nr. inregistrare" prop="register_no">
                        <el-input @change="updateForm" v-model="form.register_no" placeholder="Nr. de inregistrare" type="text" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Forma juridica" prop="type">
                        <el-select v-model="form.type" placeholder="Select" style="width:100%" value-key="id">
                            <el-option v-for="item in companyTypes.values"
                                       :key="item.id" :label="item.value"  :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Atribut fiscal" prop="fiscal_attribute">
                        <el-checkbox v-model="form.fiscal_attribute" border true-label="RO">RO</el-checkbox>
                    </el-form-item>
                </div>
                <div class="col-sm-6" v-if="!form.country || form.country.id === 1" >
                    <el-form-item label="Locatie sediu social" prop="city" class="form-group" :rules="{required: true, message: 'Introduceti si selectati localitatea', trigger: 'blur'}">
                        <el-select v-model="form.city" filterable clearable remote
                                   placeholder="Introduceti numele localitatii" :remote-method="remoteMethod" style="width:100%" value-key="id">
                            <el-option v-for="item in cities"
                                       :key="item.id"
                                       :label="item.name + ' - ' + (item.county.name !== 'Bucuresti' ? 'Judetul ' : ' ') + item.county.name"
                                       :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-6" v-if="form.country && form.country.id !== 1">
                    <el-form-item label="Orasul" prop="address" class="form-group" :rules="{required: true, message: 'Introduceti numele localitatii', trigger: 'change'}">
                        <el-input @change="updateForm" v-model="form.address" placeholder="Introduceti numele localitatii" type="text" maxlength="60" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-6">
                    <el-form-item label="Tara" prop="country" class="form-group">
                        <el-select v-model="form.country" placeholder="Selecteaza tara" style="width:100%" value-key="id">
                            <el-option v-for="item in countries"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-3">
                    <el-form-item label="Telefon" prop="phone" class="form-group">
                        <el-input @change="updateForm" v-model="form.phone" placeholder="Numarul de telefon" clearable type="tel" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3">
                    <el-form-item label="Email" prop="email" class="form-group">
                        <el-input @change="updateForm" v-model="form.email" placeholder="Adresa de e-mail" clearable type="email" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-6">
                    <el-form-item label="Website" prop="website" class="form-group">
                        <el-input @change="updateForm" v-model="form.website" placeholder="Adresa URL" clearable type="text" maxlength="60" show-word-limit>
<!--                            <template slot="prepend">https://</template>-->
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-12 text-right mb-5">
                    <el-button @click.prevent="clear">Goleste formularul</el-button>
                    <el-button @click.prevent="addRecord" type="secondary">Aplica modificarile</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "project-partners-form",
        data(){
            return {
                form:{},
                rules: {
                    name: [
                        {required: true, message: 'Numele firmei este obligatoriu', trigger: 'blur'},
                        { min: 3, max: 200, message: 'Lungimea trebuie sa fie intre 3 si 200 caractere', trigger: 'blur' }
                    ],
                    name_en: [
                        {required: true, message: 'Numele firmei este obligatoriu', trigger: 'blur'},
                        { min: 3, max: 200, message: 'Lungimea trebuie sa fie intre 3 si 200 caractere', trigger: 'blur' }
                    ],
                    type: [
                        {required: true, message: 'Selectati forma juridica', trigger: 'blur'},
                    ],
                    cif: [
                        {required: true, message: 'Codul de identificare fiscala este obligatoriu', trigger: 'blur'},
                        { min: 3, max: 15, message: 'Lungimea trebuie sa fie intre 3 si 15 caractere', trigger: '' }
                    ],
                    // city: [
                    //     {required: true, message: '', trigger: 'blur'},
                    // ],
                    country: [
                        {required: true, message: 'Selectati tara', trigger: 'blur'},
                    ],
                    phone: [
                        { required: true, message: 'Telefonul este obligatoriu', trigger: 'blur' },
                        { min: 10, max: 20, message: 'Lungimea trebuie sa fie intre 10 si 20 caractere', trigger: 'blur' }
                    ],
                    email: [
                        { type: 'email', required: true, message: 'Completati adresa de email', trigger: 'blur' }
                    ],
                },
            }
        },
        computed:{
            ...mapGetters({
                cities: 'cities',
                countries: 'countries',
                companies: 'companies',
                project: 'project/project',
                companyTypes: 'project/companyTypes',
                validatePartners: 'project/validatePartners',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validatePartners: function(){
                if(this.validatePartners) {
                    this.submitForm();
                }
            },
        },
        mounted(){
            this.getCompanyTypes();
            this.getCountries();
            this.clear();
        },
        methods:{
            ...mapActions({
                searchCity: 'searchCity',
                searchCompany: 'searchCompany',
                searchCif: 'searchCif',
                getCountries: 'getCountries',
                getCompanyTypes: 'project/getCompanyTypes',
                saveProject: 'project/saveProject',
                removePartnerLine: 'project/removePartnerLine',
            }),
            ...mapMutations({
                setValidatePartners: 'project/setValidatePartners',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            updateForm() {
                this.saveProject(this.project).then((result) => {
                    if(result.valid === false){
                        this.$notify({ type:'error', title: 'Eroare', message: result.message });
                        reject(false);
                    }
                });
            },
            updateCompany(data){
                if(data.cif) {
                    this.form = data;
                    //if (this.form.city) this.searchCity(this.form.city);
                    this.form.city = '';
                    this.form.index = this.project.partners.length + 1;
                }
            },
            checkUnique(query) {
                if (query !== '') {
                    setTimeout(() => {
                        this.searchCompany(query);
                    }, 200);
                }
            },
            checkUniquec(query) {
                if (query !== '') {
                    setTimeout(() => {
                        this.searchCompany(query).then((response) => {if(!response.length) this.$notify({ type: 'error', title: 'Cautare CIF', message: 'CIF-ul introdus de dumneavoastra nu a fost gasit!'});});
                    }, 200);
                }
            },
            remoteMethod(query) {
                if (query !== '') {
                    setTimeout(() => {
                        this.searchCity(query);
                    }, 200);
                }
            },
            // querySearchAsync(queryString, cb) {
            //     let links = this.links;
            //     let results = queryString ? links.filter(this.createFilter(queryString)) : links;
            //     clearTimeout(this.timeout);
            //     this.timeout = setTimeout(() => {
            //         cb(results);
            //     }, 3000 * Math.random());
            // },
            isExist : function(){
                if(this.form.id > 0) return false;
                for(var i=0; i < this.project.partners.length; i++){
                    if( this.project.partners[i].cif == this.form.cif){
                        return true;
                    }
                }
                return false;
            },
            addRecord(){
                this.$refs['partnerForm'].validate((valid) => {
                    if (valid) {
                        if(!this.isExist()) {
                            this.form.type_id = this.form.type.id;
                            if (this.form.index > this.project.partners.length) {
                                this.project.partners.push(this.form);

                            } else {
                                this.project.partners[this.form.index - 1] = this.form
                            }
                            this.savePartner();
                            this.clear();
                            this.setValidatePartners(false);
                            VueScrollTo.scrollTo('#partners');
                            return true;
                        }else{

                            this.$notify({ type: 'warning', title: 'Parteneri', message: 'Acest partener a fost deja adaugat, alege altul!'});
                        }
                    } else {
                        return false;
                    }
                });
            },
            savePartner(){
                this.saveProject(this.project).then((result) => {
                    if(result.valid === false){
                        this.$notify({ type:'error', title: 'Eroare', message: result.message });
                    }
                });
            },
            selectActivity(row){
                this.form = row;
                if(this.form.country.id === 1) {
                    this.remoteMethod(this.form.city.name);
                }
                VueScrollTo.scrollTo('#partnerForm');
            },
            clear(){
                this.form =  {index: this.project.partners.length + 1};
            },
            handleDelete(index, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi aceasta inregistrare?')
                    .then(_ => {
                        if (index !== -1) {
                            if(row.id > 0) {
                                console.log(row);
                                this.removePartnerLine({
                                    pid: this.project.id,
                                    line: row.id});
                                this.project.partners.splice(index, 1);
                            }else {
                                this.project.partners.splice(index, 1);
                            }
                            this.$notify({ type: 'success', title: 'Parteneri', message: 'Inregistrarea a fost stearsa cu succes!'});
                            this.clear();
                        }
                    }).catch(err => {
                        console.log(err)
                    });
            },
            submitForm() {
                if (this.project.partners.length >= 0) {
                    this.setValidatePartners(false);
                    this.setValidationErrorsExists(false);
                    return true;
                } else {
                    this.setValidationErrorsExists(true);
                    this.$notify({ type: 'warning', title: 'Parteneri', message: 'Trebuie sa adaugati cel putin un partener!'});
                    return false;
                }
            },
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
