import axios from 'axios'

/**
 *
 * @param commit
 * @param payload
 * @returns {Promise<T | any>}
 */
export const saveNotification = ({ commit }, payload) => {
    return axios.post('/notifications/notification', payload)
        .then((response) => {
            console.log(response.data.notification);
            commit('setNotification',response.data.notification);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const saveNotificationComment = ({ commit }, payload) => {
    return axios.post('/notifications/saveComment', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const saveNotificationAnswerLimit = ({ commit }, payload) => {
    return axios.post('/notifications/saveAnswerLimit', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const saveNotificationEffectiveDate = ({ commit }, payload) => {
    return axios.post('/notifications/saveEffectiveDate', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const editComment = ({ commit }, payload) => {
    return axios.post('/notifications/editComment', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.resolve(error)
        })
};

export const getIndirectsBudget = ({ commit }, payload) => {
    //return api('/notifications/indirectsBudget', 'setNotificationIndirects', commit, payload);
    return axios.post('/notifications/indirectsBudget', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getIndirectPercent = ({ commit }, payload) => {
    //return api('/notifications/izndirectsBudget', 'setNotificationIndirects', commit, payload);
    return axios.post('/notifications/indirectPercent', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getFinancingBudget = ({ commit }, payload) => {
    //return api('/notifications/financingBudget', 'setNotificationFinancing', commit, payload);
    return axios.post('/notifications/financingBudget', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getTotalBudget = ({ commit }, payload) => {
    //return api('/notifications/totalBudget', 'setNotificationTotals', commit, payload);
    return axios.post('/notifications/totalBudget', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getTotalPartner = ({ commit }, payload) => {
    //return api('/notifications/totalPartner', 'setNotificationTotalsPartner', commit, payload);
    return axios.post('/notifications/totalPartnerBudget', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getSynthetic = ({ commit }, payload) => {
    //return api('/notifications/synthetic', 'setNotificationSynthetic', commit, payload);
    return axios.post('/notifications/synthetic', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getConsolidationActivities = ({ commit }, payload) => {
    //return api('/notifications/consolidationActivities', 'setNotificationConsolidationActivities', commit, payload);
    return axios.post('/notifications/consolidationActivities', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getConsolidationDirectTotals = ({ commit }, payload) => {
    //return api('/notifications/consolidationDirectTotals', 'setNotificationConsolidationDirectTotals', commit, payload);
    return axios.post('/notifications/consolidationDirectTotals', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getConsolidationIndirectTotals = ({ commit }, payload) => {
    //return api('/notifications/consolidationIndirectTotals', 'setNotificationConsolidationIndirectsTotals', commit, payload);
    return axios.post('/notifications/consolidationIndirectTotals', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};

export const getConsolidationEligibleTotals = ({ commit }, payload) => {
    //return api('/notifications/consolidationEligibleTotals', 'setNotificationConsolidationEligibleTotals', commit, payload);
    return axios.post('/notifications/consolidationEligibleTotals', payload)
        .then((response) => {
            return Promise.resolve(response)
        }).catch((error) => {
            return Promise.resolve(error)
        })
};
