<template>
    <div>
        <form-wizard @on-complete="onComplete" :startIndex="section"
                     shape="tab" stepSize="xs" color="#3BB273" title="" subtitle="" layout="vertical"
                     error-color="#E15554" v-loading="loading">
            <tab-content :before-change="validatePromoter" title="Detalii promotor" icon="android">
                <promoter-form :data="promoter"></promoter-form>
            </tab-content>
<!--            <tab-content :before-change="validateAccount" title="Cont acces">-->
<!--                <account-form :data="promoter"></account-form>-->
<!--            </tab-content>-->
            <template slot="footer" slot-scope="props">
                <div class=wizard-footer-left>
                    <a href="/promoters" class="ml-2 btn btn-default" v-if="props.activeTabIndex == 0" >Anuleaza, m-am razgandit</a>
                    <wizard-button  v-if="props.activeTabIndex > 0"  @click.native="props.prevTab()" :style="props.fillButtonStyle">Inapoi</wizard-button>
                </div>
                <div class="wizard-footer-right">
                    <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="btn btn-default" :style="props.fillButtonStyle">Inainte</wizard-button>
                    <wizard-button v-else @click.native="props.nextTab()" class="wizard-footer-right finish-button" :style="props.fillButtonStyle">{{props.isLastStep ? 'Finalizeaza' : 'Inainte'}}</wizard-button>
                </div>
            </template>
        </form-wizard>
    </div>
</template>

<script>
    import {FormWizard, TabContent, WizardButton} from 'vue-form-wizard';
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import PromoterForm from "./form-details";
    import AccountForm from "./form-account";
    import {mapGetters, mapActions, mapMutations} from 'vuex'

    export default {
        name: "promoter-wizard",
        components: {
            AccountForm,
            PromoterForm,
            FormWizard,
            TabContent,
            WizardButton
        },
        props:{
            promoter: {
                required: true,
                type: Object
            },
            section:{
                required: false,
                default: 0,
                type: Number
            }
        },
        data(){
            return {
                loading: false,
                errorMsg: null,
            }
        },
        computed:{
            ...mapGetters({
                getpromoter: 'promoter/promoter',
                promoterValidated: 'promoter/promoterValidated',
                promoterAccountValidated: 'promoter/promoterAccountValidated',
            })
        },
        methods: {
            ...mapMutations({
                setValidatePromoter: 'promoter/setValidatePromoter',
                setValidatePromoterAccount: 'promoter/setValidatePromoterAccount'
            }),
            ...mapActions({
                savePromoter: 'promoter/savePromoter',
            }),
            onComplete: function(){
                // this.setLoading(true);
                // this.savePromoter(this.promoter)
                //     .then(() => {
                this.$notify({ type:'success', title: 'Creare promotor', message: 'Promotorul a fost creat cu succes'});
                setTimeout(function() {
                    window.location.href = '/promoters'
                },1000);
                    // }).catch(function(error){
                    //     this.$notify({ type: 'error', title: 'Creare promotor', message: 'O eroare a aparut la crearea promotorului'});
                    //     this.setLoading(false);
                    //     console.log(error);
                    // });
            },
            setLoading: function(value) {
                this.loading = value
            },
            validatePromoter:function() {
                this.loading = true;
                return new Promise((resolve, reject) => {
                    this.setValidatePromoter(true);
                    setTimeout(() => {
                        if (this.promoterValidated) {
                            this.savePromoter(this.getpromoter).then(success => {
                                if(success.valid === false) {
                                    this.$notify({type: 'error', title: 'Creare promotor', message: success.message});
                                    reject(false);
                                }else if(success.valid === true){
                                    this.$notify({ type:'success', title: 'Creare promotor', message: 'Datele promotorului au fost salvate cu succes!' });
                                    resolve(true);
                                }
                            }, error => {
                                if(error.response.status === 422){
                                    this.$notify({ type:'error', title: 'Creare promotor', message: error.response.data.errors['cif'] ? error.response.data.errors['cif'][0] : error.response.data.errors });
                                }
                                reject(false);
                            });
                            this.loading = false;
                        } else {
                            // this.$notify({ type: 'error', title: 'Creare promotor', message: 'O eroare de validare aparut la crearea promotorului' });
                            this.loading = false;
                            reject(false);
                        }
                    }, 1000)
                })
            },
            // validateAccount:function() {
            //     this.loading = true;
            //     return new Promise((resolve, reject) => {
            //         this.setValidatePromoterAccount(true);
            //         setTimeout(() => {
            //             console.log(this.promoterAccountValidated);
            //             if (this.promoterAccountValidated) {
            //                 console.log('why here');
            //                 this.savePromoter(this.promoter).then(success => {
            //                     if(success.valid === false) {
            //                         this.$notify({type: 'error', title: 'Creare cont promotor', message: success.message});
            //                         reject(false);
            //                     }else if(success.valid === true){
            //                         this.$notify({ type:'success', title: 'Creare cont promotor', message: 'Datele promotorului au fost salvate cu succes!' });
            //                         resolve(true);
            //                     }
            //                 }, error => {
            //                     if(error.response.status === 422){
            //                         this.$notify({ type:'error', title: 'Creare cont promotor', message: error.response.data.errors });
            //                     }
            //                     reject(false);
            //                 });
            //                 this.loading = false;
            //             } else {
            //                 // this.$notify({ type: 'error', title: 'Creare promotor', message: 'O eroare a aparut la crearea promotorului' });
            //                 this.loading = false;
            //                 // reject(false);
            //             }
            //         }, 1000)
            //     })
            //
            // },
        }
    }
</script>

<style>
    .wizard-tab-content{
        padding-top:0 !important;
    }
    .wizard-nav-pills{
        width: 260px !important;
    }
    .wizard-nav-pills li{
        flex: none !important;
        flex-grow: 0 !important;
    }
    .tab_shape{
        display:none !important;
    }
    .wizard-btn{
        padding: 0.5rem 1.3rem !important;
        font-weight: 600 !important;
        font-size: 1.2rem !important;
        line-height: 1.4rem !important;
    }
    .stepTitle {
        border: 1px solid transparent !important;
        margin-bottom: 2px !important;
        padding: 0.75rem 1.25rem !important;
        background-color: #fff !important;
        width:100%;
    }
    .stepTitle.active{
        background-color: #3BB273 !important;
        font-weight: 700 !important;
        color:#fff !important;
    }
    .wizard-header{display:none;}
    .wizard-card-footer{margin-left:220px;}
</style>
