<template>
    <div>
        <el-form :model="form" id="promoter" :rules="rules" ref="promoter" label-position="top" :status-icon="true" :inline-message="true">
            <h3 class="mb-4">Detalii promotor</h3>
            <div class="row mt-4">
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Denumirea promotorului in limba romana" prop="name">
                            <el-input v-model="form.name" placeholder="Denumirea promotorului in limba romana" clearable type="text" maxlength="90" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Denumirea promotorului in limba engleza" prop="name_en">
                            <el-input v-model="form.name_en" placeholder="Denumirea promotorului in limba engleza" clearable type="text" maxlength="90" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="CIF" prop="cif" :error="cif_error_message" :show-message="cif_error">
                        <el-select v-model="form.cif" placeholder="Introduceti CIF" filterable remote allow-create @change="updateCompany"
                                   :remoteMethod="checkUnique" style="width:100%" value-key="id">
                            <el-option v-for="item in companies" :key="item.id" :label="item.name + '       CIF: ' + item.cif" :value="item"></el-option>
                        </el-select>
<!--                        <el-input @change="updateForm" v-model="form.cif" placeholder="Introduceti CIF-ul" type="number" maxlength="10" show-word-limit></el-input>-->
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Nr. inregistrare" prop="register_no">
                        <el-input v-model="form.register_no" placeholder="Nr. de inregistrare" type="text" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Forma juridica" prop="type">
                        <el-select v-model="form.type" placeholder="Select" style="width:100%" value-key="id">
                            <el-option v-for="item in companyTypes.values"
                                       :key="item.id" :label="item.value"  :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Atribut fiscal" prop="fiscal_attribute">
                        <el-checkbox v-model="form.fiscal_attribute" border true-label="RO">RO</el-checkbox>
                    </el-form-item>
                </div>
                <div class="col-sm-6">
                    <el-form-item label="Adresa" prop="address" class="form-group" :rules="{required: true, message: 'Introduceti adresa', trigger: 'change'}">
                        <el-input v-model="form.address" placeholder="Introduceti adresa" type="text" maxlength="90" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-6">
                    <el-form-item label="Localitatea" prop="city" class="form-group" :rules="{required: true, message: 'Introduceti si selectati localitatea', trigger: 'blur'}">
                        <el-select v-model="form.city" filterable clearable remote
                                   placeholder="Introduceti numele localitatii" :remote-method="remoteMethod" style="width:100%" value-key="id">
                            <el-option v-for="item in cities"
                                       :key="item.id"
                                       :label="item.name + ' - ' + (item.county.name !== 'Bucuresti' ? 'Judetul ' : ' ') + item.county.name"
                                       :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Localizare sediu social" prop="grant">
                            <el-select v-model="form.location_type" filterable placeholder="Select" style="width:100%" value-key="id">
                                <el-option v-for="item in locationValues"
                                           :key="item.value" :label="item.value" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
<!--                <div class="col-sm-3">-->
<!--                    <el-form-item label="Tara" prop="country" class="form-group">-->
<!--                        <el-select v-model="form.country" placeholder="Selecteaza tara" style="width:100%" value-key="id" disabled>-->
<!--                            <el-option label="Romania" value="1"></el-option>-->
<!--                        </el-select>-->
<!--                    </el-form-item>-->
<!--                </div>-->
                <div class="col-sm-3">
                    <el-form-item label="Telefon" prop="phone" class="form-group">
                        <el-input v-model="form.phone" placeholder="Numarul de telefon" clearable type="tel" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3">
                    <el-form-item label="Email" prop="email" class="form-group">
                        <el-input v-model="form.email" placeholder="Adresa de e-mail" clearable type="email" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-6">
                    <el-form-item label="Website" prop="website" class="form-group">
                        <el-input v-model="form.website" placeholder="Adresa URL" clearable type="text" maxlength="60" show-word-limit>
                            <!--                            <template slot="prepend">https://</template>-->
                        </el-input>
                    </el-form-item>
                </div>
            </div>
            <hr />
            <h3 class="mb-4 mt-3">Reprezentanti legali
                <a title="Adauga reprezentant legal" @click.prevent="addEmployee" href="#" class="ml-2 btn btn-secondary btn-circle"><i class="icofont-plus"></i></a>
            </h3>
            <div class="row" v-for="(employee, index) in form.employees">
                <div class="col-sm-3">
                    <el-form-item label="Prenume" :prop="'employees.' + index + '.first_name'" class="form-group"
                                  :rules="{required: true, message: 'Prenumele este obligatoriu', trigger: 'blur'}">
                        <el-input v-model="employee.first_name" placeholder="Prenumele" clearable
                                  type="text" maxlength="60" show-word-limit>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3">
                    <el-form-item label="Nume de familie" :prop="'employees.' + index + '.last_name'" class="form-group"
                                  :rules="{required: true, message: 'Numele este obligatoriu', trigger: 'blur'}">
                        <el-input v-model="employee.last_name" placeholder="Numele de familie" clearable
                                  type="text" maxlength="60" show-word-limit>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3">
                    <el-form-item label=" " class="form-group">
                        <el-button @click.prevent="removeEmployee(employee)" icon="el-icon-delete" class="mt-2" v-if="index > 0"></el-button>
                    </el-form-item>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from '../../mixins/common';
    import moment from 'moment';
    export default {
        name: "promoter-form",
        mixins: [mixin],
        props:{
            data:{
                required: true,
                type: Object,
            }
        },
        data(){
            return {
                form:{
                    employees: [],
                },
                cif_error: null,
                cif_error_message: null,
                locationValues: [{
                  value: 'Rural',
                  id: 1
                }, {
                  value: 'Urban mic (<30k)',
                  id: 2
                }, {
                  value: 'Urban',
                  id: 3
                }],
                rules: {
                    name: [
                        { required: true, message: 'Completati denumirea promotorului in romana', trigger: 'blur' },
                        { min: 3, max: 90, message: 'Lungimea trebuie sa fie intre 3 si 90 caractere', trigger: 'blur' }
                    ],
                    name_en: [
                        { required: true, message: 'Completati denumirea promotorului in engleza', trigger: 'blur' },
                        { min: 3, max: 90, message: 'Lungimea trebuie sa fie intre 3 si 90 caractere', trigger: 'blur' }
                    ],
                    cif: [
                        { required: true, message: 'CIF-ul este obligatoriu', trigger: 'blur' },
                        { min: 6, max: 15, message: 'Lungimea trebuie sa fie intre 6 si 15 caractere', trigger: 'blur' }
                    ],
                    type: [
                        { required: true, message: 'Forma juridica este obligatorie', trigger: 'blur' },
                    ],
                    address: [
                        { required: true, message: 'Adresa este obligatorie', trigger: 'blur' },
                    ],
                    city: [
                        { required: true, message: 'Selectati orasul', trigger: 'change' }
                    ],
                    phone: [
                        { required: true, message: 'Telefonul este obligatoriu', trigger: 'blur' },
                        { min: 10, max: 20, message: 'Lungimea trebuie sa fie intre 10 si 20 caractere', trigger: 'blur' }
                    ],
                    email: [
                        { type: 'email', required: true, message: 'Completati adresa de email de contact', trigger: 'blur' }
                    ],
                },
            }
        },
        computed:{
            ...mapGetters({
                cities: 'cities',
                counties: 'counties',
                companies: 'companies',
                companyTypes: 'project/companyTypes',
                validatePromoter: 'promoter/validatePromoter',
                promoter: 'promoter/promoter'
            }),
        },
        watch:{
            validatePromoter: function(){
                if(this.validatePromoter) {
                    this.submitForm();
                }
            }
        },
        mounted(){
            this.getCompanyTypes();
            this.form = this.data;
            if(this.data.city) this.searchCity(this.data.city.name);
            this.links = this.counties;
            if(!this.data.id) this.addEmployee();
        },
        methods:{
            ...mapActions({
                searchCity: 'searchCity',
                searchCompany: 'searchCompany',
                // searchCif: 'searchCif',
                getCompanyTypes: 'project/getCompanyTypes',
            }),
            ...mapMutations({
                setValidatePromoter: 'promoter/setValidatePromoter',
                setPromoterValidated: 'promoter/setPromoterValidated',
                setPromoter: 'promoter/setPromoter',
            }),
            updateCompany(data){
                if(data.cif) {
                    let employees = this.form.employees;
                    this.form = data;
                    this.form.employees = employees;
                    if(this.form.city.name) {
                        this.searchCity(this.form.city.name);
                    }else{
                        this.searchCity(this.form.city);
                    }
                    this.form.city = '';
                    this.form.index = this.project ? this.project.partners.length + 1 : 1;
                }
            },
            checkUnique(query) {
                // if(this.form.cif.length < 6 || this.form.cif.length > 12){
                //     this.cif_error_message = "Lungimea trebuie sa fie intre 6 si 12 caractere";
                //     this.cif_error = true;
                //     return;
                // }
                if(!query) query = this.form.cif;
                if (query !== '') {
                    setTimeout(() => {
                        this.searchCompany(query);
                    }, 200);
                }
            },
            addEmployee(){
                this.form.employees.push({
                    'id' : null,
                    'first_name' : '',
                    'last_name' : '',
                })
            },
            removeEmployee(employee) {
                let index = this.form.employees.indexOf(employee);
                if (index !== -1) {
                    this.form.employees.splice(index, 1);
                }
            },
            remoteMethod(query) {
                if (query !== '') {
                    setTimeout(() => {
                        this.searchCity(query);
                    }, 200);
                }
            },
            submitForm() {
                this.setPromoter(this.form);
                this.clear();
                this.$refs['promoter'].validate((valid) => {
                    if (valid) {

                        this.setPromoterValidated(true);
                    } else {
                        this.$notify({type: 'warning', title: 'Detalii promotor', message: 'Datele introduse nu sunt corecte. Verificati si incercati din nou.'});
                        return false;
                    }
                });
            },
            clear(){
                this.setValidatePromoter(false);
                this.setPromoterValidated(false);
            }
        }
    }
</script>

<style scoped>

</style>
