<template>
    <div>
        <div class="row">
            <div class="col-sm-3">
                <label>Numar apel</label>
                <p v-if="project.appeal">{{project.appeal.value}}</p>
            </div>
            <div class="col-sm-3">
                <label>Runda</label>
                <p v-if="project.round">{{project.round.value}}</p>
            </div>
            <div class="col-sm-6">
                <label>Titlul proiectului</label>
                <p>{{project.name}}</p>
            </div>
            <div class="col-sm-3">
                <label>Numar contract finantare</label>
                <p>{{project.funding_number}}</p>
            </div>
            <div class="col-sm-3">
                <label>Numar GRACE</label>
                <p>{{project.grace_number}}</p>
            </div>
            <div class="col-sm-6">
                <label>Titlul proiectului in limba engleza</label>
                <p>{{project.name_en}}</p>
            </div>
            <div class="col-sm-3">
                <label>Tip grant</label>
                <p v-if="project.grant">{{project.grant.value}}</p>
            </div>
            <div class="col-sm-3">
                <label>Data semnarii contractului</label>
                <p>{{ formatUserDate(project.contract_date) }}</p>
            </div>
            <div class="col-sm-3">
                <label>Perioada de implementare</label>
                <p>{{ formatUserDate(project.implementation_start_date) }} - {{ formatUserDate(project.implementation_end_date) }}</p>
            </div>
            <div class="col-sm-3">
                <label>Durata de implementare</label>
                <p>{{ datesDifference(project.implementation_start_date, project.implementation_end_date) }}</p>
            </div>
            <div class="col-sm-3">
                <label>Bugetul</label>
                <p>{{ formatPrice(project.total_budget) }}</p>
            </div>
            <div class="col-sm-3">
                <label>Din care finantat</label>
                <p>{{ formatPrice(project.funding_budget) }}</p>
            </div>
            <div class="col-sm-3">
                <label>Din care co-finantat</label>
                <p>{{ formatPrice(project.cofunding_budget) }}</p>
            </div>
            <div class="col-sm-3">
                <label>Indirecte</label>
                <p>{{ formatNumber(project.indirect_budget,2) }} %</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label>Proiectul este preponderent in mediul</label>
                <p v-if="project.medium">{{ project.medium.value }}</p>
            </div>
            <div class="col-sm-3">
<!--                <label>Categoria de incadrare</label>-->
<!--                <p v-if="project.framing">{{ project.framing.value }}</p>-->
            </div>
            <div class="col-sm-3">
                <label>Prioritati transversale ale programului</label>
                <ul v-if="project.priority">
                    <li v-for="priority in project.priority">
                        {{ priority.value }}
                    </li>
                </ul>
            </div>
            <div class="col-sm-3">
                <label>Policy markers</label>
                <p v-if="project.policy_1">
                    <label class="ml-2">Egalitate de gen</label> {{ getPolicyValue(project.policy_1) }}
                </p>
                <p v-if="project.policy_2">
                    <label class="ml-2">Cresterea capacitatii si incluziunea romilor</label> {{ getPolicyValue(project.policy_2) }}
                </p>
                <p v-if="project.policy_3">
                    <label class="ml-2">Incluziunea sociala a altor grupuri sociale decat roma</label> {{ getPolicyValue(project.policy_3) }}
                </p>
                <p v-if="project.policy_4">
                    <label class="ml-2">Anti-discriminare</label> {{ getPolicyValue(project.policy_4) }}
                </p>
                <p v-if="project.policy_5">
                    <label class="ml-2">Transparenta si anticoruptie</label> {{ getPolicyValue(project.policy_5) }}
                </p>

            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <el-collapse>
                    <el-collapse-item title="Beneficiari" name="1">
                        <div>{{ project.beneficiary }}</div>
                    </el-collapse-item>
                    <el-collapse-item title="Rezumatul proiectului in limba Romana" name="2">
                        <div>{{ project.description }}</div>
                    </el-collapse-item>
                    <el-collapse-item title="Rezumatul proiectului in limba Engleza" name="3">
                        <div>{{ project.description_en }}</div>
                    </el-collapse-item>
                </el-collapse>
            </div>
            <div class="col-sm-6">
                <label>Localitati de desfasurare</label>
                <p>
                    <el-tag v-for="location in project.locations" :key="location.name" class="mr-1" type="info">
                        {{ location.name }}<span v-if="location.county.name !== 'Bucuresti'">, Judetul {{ location.county.name }}</span>
                    </el-tag>
                </p>
                <label>Monitorizare:</label>
                <p>
                    <el-checkbox v-model="auditV" border @change="updateProject('audit')">Audit</el-checkbox>
                    <el-checkbox v-model="monitorizareV" border @change="updateProject('monitorizare')" >Vizita de monitorizare</el-checkbox>
                </p>
                <el-select v-model="formOperator" @change="updateOperator" placeholder="Selecteaza operatorul de fond" style="width:100%">
                    <el-option v-for="item in operators"
                               :key="item.name" :label="item.name" :value="item.name">
                    </el-option>
                </el-select>
            </div>
            <hr />
            <div class="col-sm-6">

            </div>
            <div class="col-sm-6">

            </div>
        </div>
    </div>
</template>
<script>
    import mixin from './../../mixins/common';
    export default {
        name: "project-details-pane",
        mixins:[mixin],
        props:{
            project: {
                required: true,
                type: Object
            }
        },
        data() {
            return {
                auditV: false,
                monitorizareV: false,
                formOperator: null,
                operators: [
                    {id: 1, name: 'Fundația pentru Dezvoltarea Societății Civile'},
                    {id: 2, name: 'Fundația pentru Parteneriat'},
                    {id: 3, name: 'Centrul de Resurse pentru Comunitățile de Romi'},
                    {id: 4, name: 'Fundația PACT'},
                ]
            }
        },
        mounted() {
            this.project.audit === 1 ? this.auditV = true : this.auditV = false;
            this.project.monitorizare === 1 ? this.monitorizareV = true : this.monitorizareV = false;
            this.formOperator = this.project.fund_operator;
        },
        methods: {
            getPolicyValue(id){
                if(id === 0) return "Nu se aplica";
                if(id === 1) return "Relevant";
                if(id === 2) return "Fundamental";
            },
            updateOperator() {
                axios.post('/projects/updateFundOperator', {project_id: this.project.id, fund_operator: this.formOperator}).then(_ => { this.$notify({ type: 'success', title: 'Operator de fond', message: 'Operatorul de fond a fost salvat cu succes!'}); });
            },
            updateProject(type) {
                if (type === 'audit') {
                    axios.post('/projects/bife', {value: 1, type: 'audit', projectId: this.project.id}).then((result) => {
                        if (result.data.success === true && result.data.type === 1) {
                            this.project.audit = 1;
                            this.$notify({
                                type: 'success',
                                title: 'Audit',
                                message: 'Audit a fost activat pentru proiect!'
                            });
                        } else {
                            this.project.audit = 0;
                            this.$notify({
                                type: 'error',
                                title: 'Audit',
                                message: 'Audit a fost dezactivat pentru proiect!'
                            });
                        }
                    });
                } else if (type === 'monitorizare') {
                    axios.post('/projects/bife', {
                        value: 1,
                        type: 'monitorizare',
                        projectId: this.project.id
                    }).then((result) => {
                        if (result.data.success === true && result.data.type === 1) {
                            this.project.monitorizare = 1;
                            this.$notify({
                                type: 'success',
                                title: 'Monitorizare',
                                message: 'Monitorizarea a fost activata pentru proiect!'
                            });
                        } else {
                            this.project.monitorizare = 0;
                            this.$notify({
                                type: 'error',
                                title: 'Monitorizare',
                                message: 'Monitorizarea a fost dezactivata pentru proiect!'
                            });
                        }
                    });
                }
            },
        }
    }
</script>

<style scoped>

</style>
