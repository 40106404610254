<template>
    <div>
        <form-wizard @on-complete="onComplete" :startIndex="section2"
                     shape="tab" stepSize="xs" color="#3BB273" title="" subtitle="" layout="vertical"
                     error-color="#E15554" v-loading="loading">
            <tab-content title="Echipa" :before-change="validateTeamForm">
                <project-team></project-team>
            </tab-content>
            <tab-content :title="'Buget A' + activity.activity" :key="index" :before-change="() => validateActivityForm(index)" v-for="(activity, index) in project.activities">
                <project-activity :activity-id="index"></project-activity>
            </tab-content>
            <tab-content title="Buget indirecte" :before-change="validateIndirectsForm">
                <project-indirects></project-indirects>
            </tab-content>
            <tab-content  title="Surse de finantare" :before-change="validateFinancingForm">
                <project-financing></project-financing>
            </tab-content>
            <tab-content  title="Validare" :before-change="validateValidation">
                <project-validation-form></project-validation-form>
            </tab-content>

            <template slot="footer" slot-scope="props">
                <div class=wizard-footer-left>
                    <a href="/projects" class="ml-2 btn btn-default" v-if="props.activeTabIndex == 0" >Anuleaza, m-am razgandit</a>
                    <wizard-button  v-if="props.activeTabIndex > 0"  @click.native="props.prevTab()" :style="props.fillButtonStyle">Inapoi</wizard-button>
                </div>
                <div class="wizard-footer-right">
                    <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="btn btn-default" :style="props.fillButtonStyle">Inainte</wizard-button>
                    <wizard-button v-else @click.native="props.nextTab()" class="wizard-footer-right finish-button" :style="props.fillButtonStyle">{{props.isLastStep ? 'Finalizeaza' : 'Inainte'}}</wizard-button>
                </div>
            </template>
        </form-wizard>
    </div>
</template>

<script>
    import {FormWizard, TabContent, WizardButton} from 'vue-form-wizard';
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import ProjectTeam from "./form-team";
    import ProjectActivity from "./form-activity";
    import ProjectIndirects from "./form-indirect";
    import ProjectFinancing from "./form-financing";
    import ProjectValidationForm from "./form-financial-validation";
    import {mapGetters, mapActions, mapMutations} from 'vuex'

    export default {
        name: "project-wizard",
        components: {
            ProjectTeam,
            ProjectActivity,
            ProjectIndirects,
            ProjectFinancing,
            ProjectValidationForm,
            FormWizard,
            TabContent,
            WizardButton
        },
        props:{
            data: {
                required: true,
                type: Object
            },
            section:{
                required: false,
                default: 0,
                type: Number
            }
        },
        data(){
            return {
                loading: false
            }
        },
        computed:{
            ...mapGetters({
                project: 'project/project',
                validationErrorsExists: 'validationErrorsExists',
            }),
            section2: function(){
                if(this.section === 40){
                    return 1 + this.project.activities.length;
                }else if (this.section === 41){
                    return 2 + this.project.activities.length;
                }else{
                    return this.section-10;
                }
            }
        },
        created(){
            this.setProject(this.data);
        },
        methods: {
            ...mapActions({
                saveProject: 'project/saveProjectFinancial',
            }),
            ...mapMutations({
                setProject: 'project/setProject',
                setValidateDetails: 'project/setValidateDetails',
                setValidateActivity: 'project/setValidateActivity',
                setValidateIndirects: 'project/setValidateIndirects',
                setValidateFinancing: 'project/setValidateFinancing',
                setValidateValidation: 'project/setValidateValidation',
            }),
            validateTeamForm: function(){
                return new Promise((resolve, reject) => {
                    this.setValidateDetails(true);
                    setTimeout(() => {
                        if (!this.validationErrorsExists) {
                            this.loading = false;
                            this.$notify({type: 'success', title: 'Salvare date', message: 'Datele proiectului au fost salvate cu succes'});
                            resolve(true);
                        } else {
                            this.$notify({type: 'warning', title: 'Validare date', message: 'Datele introduse nu sunt corecte. Verificati si incercati din nou.'});
                            this.loading = false;
                            reject(false);
                        }
                    }, 1000)
                })
            },
            validateActivityForm: function(index){
                return new Promise((resolve, reject) => {
                    this.setValidateActivity({index : index, value: true});
                    setTimeout(() => {
                        if (!this.validationErrorsExists) {
                            this.loading = false;
                            resolve(true);
                        } else {
                            this.$notify({type: 'warning', title: 'Validare date', message: 'Datele introduse nu sunt corecte. Verificati si incercati din nou.'});
                            this.loading = false;
                            reject(false);
                        }
                    }, 1000)
                })
            },
            validateIndirectsForm: function(){
                return new Promise((resolve, reject) => {
                    this.setValidateIndirects(true);
                    setTimeout(() => {
                        if (!this.validationErrorsExists) {
                            this.loading = false;
                            this.$notify({type: 'success', title: 'Salvare date', message: 'Datele proiectului au fost salvate cu succes'});
                            resolve(true);
                        } else {
                            this.$notify({type: 'warning', title: 'Validare date', message: 'Datele introduse nu sunt corecte. Verificati si incercati din nou.'});
                            this.loading = false;
                            this.setValidateIndirects(false);
                            reject(false);
                        }
                    }, 1000)
                })
            },
            validateFinancingForm: function(){
                return new Promise((resolve, reject) => {
                    this.setValidateFinancing(true);
                    setTimeout(() => {
                        if (!this.validationErrorsExists) {
                            this.loading = false;
                            this.$notify({type: 'success', title: 'Salvare date', message: 'Datele proiectului au fost salvate cu succes'});
                            resolve(true);
                        } else {
                            this.$notify({type: 'warning', title: 'Validare date', message: 'Datele introduse nu sunt corecte. Verificati si incercati din nou.'});
                            this.loading = false;
                            this.setValidateIndirects(false);
                            reject(false);
                        }
                    }, 1000)
                })
            },
            validateValidation: function(){
                return new Promise((resolve, reject) => {
                    this.setValidateValidation(true);
                    setTimeout(() => {
                        if (!this.validationErrorsExists) {
                            this.loading = false;
                            this.project.final = true;
                            this.saveProject(this.project).then((result) => {
                                if(result.valid === false){
                                    this.$notify({ type:'error', title: 'Eroare', message: result.message });
                                    reject(false);
                                }else{
                                    this.$notify({type: 'success', title: 'Salvare date', message: 'Datele proiectului au fost salvate cu succes'});
                                    resolve(true);
                                }
                            });
                        } else {
                            this.$notify({type: 'warning', title: 'Validare date', message: 'Datele introduse nu sunt corecte. Verificati si incercati din nou.'});
                            this.loading = false;
                            reject(false);
                        }
                    }, 1000)
                })
            },
            onComplete: function(){
                this.$notify({type: 'success', title: 'Proiect',  message: 'Proiectul a fost salvat cu succes!'});
                setTimeout(function() {
                    window.location.href = '/projects'
                },1000);
            },
        }
    }
</script>

<style>
    .wizard-tab-content{
        padding-top:0 !important;
    }
    .wizard-nav-pills{
        width: 260px !important;
    }
    .wizard-nav-pills li{
        flex: none !important;
        flex-grow: 0 !important;
    }
    .tab_shape{
        display:none !important;
    }
    .wizard-btn{
        padding: 0.5rem 1.3rem !important;
        font-weight: 600 !important;
        font-size: 1.2rem !important;
        line-height: 1.4rem !important;
    }
    .stepTitle {
        border: 1px solid transparent !important;
        margin-bottom: 2px !important;
        padding: 0.75rem 1.25rem !important;
        background-color: #fff !important;
        width:100%;
    }
    .stepTitle.active{
        background-color: #3BB273 !important;
        font-weight: 700 !important;
        color:#fff !important;
    }
    .wizard-header{display:none;}
    .wizard-card-footer{margin-left:220px;}
</style>
