export default {
    getCatalogItems({ commit, state }) {
        return new Promise((resolve, reject) => {
            axios.get('/api/catalog/' + state.catalog + '/get').then((result) => {
                resolve(result.data.data);
            }).catch((error) => {
                reject(error);
            });
        });
    },

    saveCatalogItem (item) {
        setTimeout(() => {
            console.log('set');
        }, 100)
    }
}
