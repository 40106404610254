<template>
    <el-form :model="reporting.questions" v-if="questions" :rules="rules" ref="questions" label-position="top" :status-icon="true" :inline-message="true">
        <div class="row">
            <div class="col-sm-12">
                <h3 class="text-muted mb-0">Gradul de risc</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <h5 class="text-muted mb-0" v-if="questions">Punctaj companie: {{questions.q1 }} + {{ questions.q2 }} + {{ questions.q3 }} + {{ questions.q4 }} + {{ questions.q5 }} + {{ questions.q6 }} = {{questions.q1  +  questions.q2  +  questions.q3  +  questions.q4  +  questions.q5 + questions.q6 }} => {{questions.company_risk}}%</h5>
                <h5 class="text-muted mb-0" v-if="questions">Punctaj proiect:  {{ questions.q7 }} + {{ questions.q8 }} + {{questions.q9}} + {{questions.q10}} + {{questions.q11}} + {{questions.q12}} + {{questions.q13}} + {{ questions.q14 }} = {{ questions.q7 + questions.q8 + questions.q9 + questions.q10 + questions.q11 + questions.q12 + questions.q13 + questions.q14 }} => {{questions.project_risk}}%</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <h5 class="text-muted mb-0" v-if="questions">Procent risc general: {{questions.risk}}%</h5>
                <h5 class="text-muted mb-0" v-if="questions">Procent risc companie: {{questions.company_risk}}%</h5>
                <h5 class="text-muted mb-0" v-if="questions">Procent risc proiect: {{questions.project_risk}}%</h5>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <h5 class="text-muted mb-0" v-if="questions">Formula calcul:</h5>
                <p v-if="questions">Pentru raspunsurile de la 1 la 6 inclusiv, se aduna punctajul pentru fiecare intrebare in parte - in categoria companie</p>
                <p v-if="questions">Pentru raspunsurile de la 6 la 14 inclusiv, se aduna punctajul pentru fiecare intrebare in parte - in categoria proiect</p>
                <br/>
                <ul>Pentru companie - interval punctaj:
                    <li>Intre 6 si 12 - grad de risc: 30%</li>
                    <li>Intre 13 si 18 - grad de risc: 50%</li>
                    <li>Intre 19 si 24 - grad de risc: 80%</li>
                </ul>
                <ul>Pentru proiect - interval punctaj:
                    <li>Intre 8 si 13 - grad de risc: 30%</li>
                    <li>Intre 14 si 22 - grad de risc: 50%</li>
                    <li>Intre 23 si 32 - grad de risc: 80%</li>
                </ul>
                <p><b>Calculul final se obtine astfel</b>: </p>
                    <p>Daca riscul proiectului este mai mic decat riscul companiei -> riscul final = riscul companiei, altfel, daca riscul proiectului este mai mare decat riscul companiei -> riscul final = riscul proiectului</p>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-8 form-group">
                <el-form-item prop="q1" label="1. Care este media bugetului anual administrat de organizație în ultimii 3 ani în raport cu finanțarea solicitată?">
                    <el-radio-group v-model="questions.q1" >
                        <el-radio-button :label="1" v-if="questions.q1 === 1" >peste dublul finanțării solicitate</el-radio-button>
                        <el-radio-button :label="2" v-if="questions.q1 === 2" border>mai mult decât finanțarea solicitată până la dublul acesteia</el-radio-button>
                        <el-radio-button :label="3" v-if="questions.q1 === 3" border>mai puțin sau egal cu finanțarea solicitată</el-radio-button>
                        <el-radio-button :label="4" v-if="questions.q1 === 4" border>nu a fost administrat un buget până în prezent (organizația este nou înființată)</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="col-4">
                Raspunsuri posibile:
                    <ul>
                        <li>1. peste dublul finanțării solicitate = 1p </li>
                        <li>2. mai mult decât finanțarea solicitată până la dublul acesteia = 2p</li>
                        <li>3. mai puțin sau egal cu finanțarea solicitată =3p</li>
                        <li>4. nu a fost administrat un buget până în prezent (organizația este nou înființată) = 4p</li>
                    </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-8 form-group">
                <el-form-item prop="q2" label="2. In acest moment, care este numărul de proiecte finanţate din fonduri nerambursabile derulate in calitate de aplicant sau partener la acest moment (inclusiv proiectul finanțat prin ACF)?">
                    <el-radio-group v-model="questions.q2" >
                        <el-radio-button :label="1" v-if="questions.q2 === 1" border>1 proiect </el-radio-button>
                        <el-radio-button :label="2" v-if="questions.q2 === 2" border>2-3 proiecte</el-radio-button>
                        <el-radio-button :label="3" v-if="questions.q2 === 3" border>4-5 proiecte</el-radio-button>
                        <el-radio-button :label="4" v-if="questions.q2 === 4" border>peste 5 proiecte</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="col-4">
                Raspunsuri posibile:
                <ul>
                    <li>1. 1 proiect = 1p </li>
                    <li>2. 2-3 proiecte = 2p</li>
                    <li>3. 4-5 proiecte = 3p</li>
                    <li>4. peste 5 proiecte = 4p</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-8 form-group">
                <el-form-item prop="q3" label="3. In acest moment, care este valoarea cumulată a proiectelor finanţate din fonduri nerambursabile derulate de organizaţie în calitate de aplicant sau partener (inclusiv proiectul finanțat prin ACF)?">
                    <el-radio-group v-model="questions.q3" >
                        <el-radio-button :label="1" v-if="questions.q3 === 1" border>sub 50 000€</el-radio-button>
                        <el-radio-button :label="2" v-if="questions.q3 === 2" border>între 50.000€ și 150.000€</el-radio-button>
                        <el-radio-button :label="3" v-if="questions.q3 === 3" border>între 150.001€ și 250.000€ </el-radio-button>
                        <el-radio-button :label="4" v-if="questions.q3 === 4" border>peste  250.000€ </el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="col-4">
                Raspunsuri posibile:
                <ul>
                    <li>1. sub 50 000€ = 1p </li>
                    <li>2. între 50.000€ și 150.000€ = 2p</li>
                    <li>3. între 150.001€ și 250.000€ = 3p</li>
                    <li>4. peste  250.000€ = 4p</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-8 form-group">
                <el-form-item prop="q4" label="4. Care este numărul mediu de salariați (cu contract individual de muncă) și colaboratori permanenți (cu vechime de peste 1 an) ai organizației din ultimii 3 ani?">
                    <el-radio-group v-model="questions.q4" >
                        <el-radio-button :label="1" v-if="questions.q4 === 1" border>1-3 angajați/colaboratori permanenți</el-radio-button>
                        <el-radio-button :label="2" v-if="questions.q4 === 2" border>4-6 angajați/colaboratori permanenți</el-radio-button>
                        <el-radio-button :label="3" v-if="questions.q4 === 3" border>7-10 angajați/colaboratori permanenți</el-radio-button>
                        <el-radio-button :label="4" v-if="questions.q4 === 4" border>peste 10 angajați/colaboratori permanenți</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="col-4">
                Raspunsuri posibile:
                <ul>
                    <li>1. 1-3 angajați/colaboratori permanenți = 1p </li>
                    <li>2. 4-6 angajați/colaboratori permanenț = 2p</li>
                    <li>3. 7-10 angajați/colaboratori permanenți = 3p</li>
                    <li>4. peste 10 angajați/colaboratori permanenți = 4p</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-8 form-group">
                <el-form-item prop="q5" label="5. Care este fluctuația de personal a organizației din ultimii 3 ani? (numărul angajaților care au plecat / totalul efectivului de angajați x 100) pe an calendaristic">
                    <el-radio-group v-model="questions.q5" >
                        <el-radio-button :label="1" v-if="questions.q5 === 1">0 - 10%</el-radio-button>
                        <el-radio-button :label="2" v-if="questions.q5 === 2">11 - 15%</el-radio-button>
                        <el-radio-button :label="3" v-if="questions.q5 === 3">16 - 20%</el-radio-button>
                        <el-radio-button :label="4" v-if="questions.q5 === 4">peste 20%</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="col-4">
                Raspunsuri posibile:
                <ul>
                    <li>1. 0 - 10% = 1p </li>
                    <li>2. 11 - 15% = 2p</li>
                    <li>3. 16 - 20% = 3p</li>
                    <li>4. peste 20% = 4p</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-8 form-group">
                <el-form-item prop="q6" label="6. Care este situația organizației în ceea ce privește activitatea de contabilitate?">
                    <el-radio-group v-model="questions.q6" >
                        <el-radio-button :label="1" v-if="questions.q6 === 1" border>organizația are angajat permanent (cu contract individual de muncă) un contabil cu studii superioare economice</el-radio-button>
                        <el-radio-button :label="2" v-if="questions.q6 === 2" border>organizația colaborează cu un birou contabil autorizat CECCAR</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="col-4">
                Raspunsuri posibile:
                <ul>
                    <li>1. organizația are angajat permanent (cu contract individual de muncă) un contabil cu studii superioare economice = 1p </li>
                    <li>2. organizația colaborează cu un birou contabil autorizat CECCAR = 2p</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-8 form-group">
                <el-form-item prop="q7" label="7. În echipa proiectului există prevăzută poziția de responsabil financiar pe toată perioada de implementare a proiectului">
                    <el-radio-group v-model="questions.q7" >
                        <el-radio-button :label="1" v-if="questions.q7 === 1" border>da, există un responsabil financiar prevăzut în echipa de proiect</el-radio-button>
                        <el-radio-button :label="2" v-if="questions.q7 === 2" border>nu, nu există poziția de responsabil financiar prevăzut în echipa de proiect</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="col-4">
                Raspunsuri posibile:
                <ul>
                    <li>1. da, există un responsabil financiar prevăzut în echipa de proiect = 1p </li>
                    <li>2. nu, nu există poziția de responsabil financiar prevăzut în echipa de proiect = 2p</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-8 form-group">
                <el-form-item prop="q8" label="8. Care este situația organizației privind transmiterea raportului aferent proiectului aflat în implementare?">
                    <el-radio-group v-model="questions.q8" >
                        <el-radio-button :label="1" v-if="questions.q8 === 1" border>a fost depus la timp</el-radio-button>
                        <el-radio-button :label="2" v-if="questions.q8 === 2" border>intre 1 si 15 zile</el-radio-button>
                        <el-radio-button :label="3" v-if="questions.q8 === 3" border>16-30 zile</el-radio-button>
                        <el-radio-button :label="4" v-if="questions.q8 === 4" border>peste 30 zile</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="col-4">
                Raspunsuri posibile:
                <ul>
                    <li>1. a fost depus la timp = 1p </li>
                    <li>2. intre 1 si 15 zile = 2p</li>
                    <li>3. 16-30 zile = 3p</li>
                    <li>4. peste 30 zile = 4p</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-8 form-group">
                <el-form-item prop="q9" label="9. Care este fluctuația de personalului cheie (Manager de proiect, asistent de proiect, responsabil financiar, specialisti cu CIM) in cadrul proiectului pe parcursul perioadei de raportare?">
                    <el-radio-group v-model="questions.q9" >
                        <el-radio-button :label="1" v-if="questions.q9 === 1" border>nicio schimbare - 1 schimbare</el-radio-button>
                        <el-radio-button :label="2" v-if="questions.q9 === 2" border>2-3 schimbări</el-radio-button>
                        <el-radio-button :label="3" v-if="questions.q9 === 3" border>3-4 schimbări</el-radio-button>
                        <el-radio-button :label="4" v-if="questions.q9 === 4" border>de la 5 schimbări</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="col-4">
                Raspunsuri posibile:
                <ul>
                    <li>1. nicio schimbare - 1 schimbare = 1p </li>
                    <li>2. 2-3 schimbări = 2p</li>
                    <li>3. 3-4 schimbări = 3p</li>
                    <li>4. de la 5 schimbări = 4p</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-8 form-group">
                <el-form-item prop="q10" label="10. Care este valoarea subcontractării raportata din totalul costurilor directe eligibile ale proiectului (din buget)?">
                    <el-radio-group v-model="questions.q10" >
                        <el-radio-button :label="0" v-if="questions.q10 === 0" border>nu exista inca un raspuns</el-radio-button>
                        <el-radio-button :label="1" v-if="questions.q10 === 1" border>sub 5% din valoarea costurilor directe</el-radio-button>
                        <el-radio-button :label="2" v-if="questions.q10 === 2" border>5-10% = din valoarea costurilor directe</el-radio-button>
                        <el-radio-button :label="3" v-if="questions.q10 === 3" border>11-20%  = din valoarea costurilor directe</el-radio-button>
                        <el-radio-button :label="4" v-if="questions.q10 === 4" border>peste 20%  din valoarea costurilor directe</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="col-4">
                Raspunsuri posibile:
                <ul>
                    <li>1. nu exista inca un raspun = 0p </li>
                    <li>2. sub 5% din valoarea costurilor directe = 1p</li>
                    <li>3. 5-10% = din valoarea costurilor directe = 2p</li>
                    <li>4. 11-20%  = din valoarea costurilor directe = 3p</li>
                    <li>5. peste 20%  din valoarea costurilor directe = 4p</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-8 form-group">
                <el-form-item prop="q11" label="11. Exista in buget costuri cu constructia/reconstructia?">
                    <el-radio-group v-model="questions.q11" >
                        <el-radio-button :label="0" v-if="questions.q11 === 0" border>nu exista inca un raspuns</el-radio-button>
                        <el-radio-button :label="1" v-if="questions.q11 === 1" border>da, exista</el-radio-button>
                        <el-radio-button :label="2" v-if="questions.q11 === 2" border>nu, nu exista</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="col-4">
                Raspunsuri posibile:
                <ul>
                    <li>1. da, exista = 1p </li>
                    <li>2. nu, nu exista = 2p</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-8 form-group">
                <el-form-item prop="q12" label="12. Care este numărul partenerilor cu buget alocat în proiect?">
                    <el-radio-group v-model="questions.q12" >
                        <el-radio-button :label="0" v-if="questions.q12 === 0" border>nu exista inca un raspuns</el-radio-button>
                        <el-radio-button :label="1" v-if="questions.q12 === 1" border>fără parteneri</el-radio-button>
                        <el-radio-button :label="2" v-if="questions.q12 === 2" border>maximum 2 parteneri</el-radio-button>
                        <el-radio-button :label="3" v-if="questions.q12 === 3" border>3-4 parteneri</el-radio-button>
                        <el-radio-button :label="questions.q12" v-if="questions.q12 >= 4" border>peste 4 parteneri</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="col-4">
                Raspunsuri posibile:
                <ul>
                    <li>1. fără parteneri = 1p </li>
                    <li>2. maximum 2 parteneri = 2p</li>
                    <li>3. 3-4 parteneri= 3p</li>
                    <li>4. peste 4 parteneri = 4p</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-8 form-group">
                <el-form-item prop="q13" label="13. Care este valoarea cofinanțării in bani asumate în proiect?">
                    <el-radio-group v-model="questions.q13" >
                        <el-radio-button :label="0" v-if="questions.q13 === 0" border>nu exista inca un raspuns</el-radio-button>
                        <el-radio-button :label="1" v-if="questions.q13 === 1" border>0-2.000 euro</el-radio-button>
                        <el-radio-button :label="2" v-if="questions.q13 === 2" border>2.001-6.000 euro</el-radio-button>
                        <el-radio-button :label="3" v-if="questions.q13 === 3" border>6.001-10.000 euro</el-radio-button>
                        <el-radio-button :label="4" v-if="questions.q13 === 4" border>peste 10.000 euro</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="col-4">
                Raspunsuri posibile:
                <ul>
                    <li>1. 0-2.000 euro = 1p </li>
                    <li>2. 2.001-6.000 euro = 2p</li>
                    <li>3. 6.001-10.000 euro = 3p</li>
                    <li>4. peste 10.000 euro = 4p</li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-8 form-group">
                <el-form-item prop="q14" label="14. După analiza rapoartelor  financiare anterioare, care este totalul cheltuielilor considerate a fi neeligibile? (se  cumuleaza sumele din fiecare raport)">
                    <el-radio-group v-model="questions.q14" >
                        <el-radio-button :label="0" v-if="questions.q14 === 0" border>nu exista inca un raspuns</el-radio-button>
                        <el-radio-button :label="1" v-if="questions.q14 === 1" border>0 euro - 500 euro </el-radio-button>
                        <el-radio-button :label="2" v-if="questions.q14 === 2" border>501 -1000 euro</el-radio-button>
                        <el-radio-button :label="3" v-if="questions.q14 === 3" border>1001-2000 euro</el-radio-button>
                        <el-radio-button :label="4" v-if="questions.q14 === 4" border>peste 2000 euro</el-radio-button>
                    </el-radio-group>
                </el-form-item>
            </div>
            <div class="col-4">
                Raspunsuri posibile:
                <ul>
                    <li>1. 0 euro - 500 euro = 1p </li>
                    <li>2. 501 -1000 euro = 2p</li>
                    <li>3. 1001-2000 euro = 3p</li>
                    <li>4. peste 2000 euro = 4p</li>
                </ul>
            </div>
        </div>
    </el-form>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';
    import moment from 'moment';
    import numeral from 'numeral';
    export default {
        name: "reporting-risk-form",
        mixins:[mixin],
        props:{
            reporting: {
                required: true,
                type: Object
            },
        },
        data(){
            return {
                questions:{},
                rules: {
                    q1: [
                        { required: true, message: 'Selectați raspunsul la aceasta intrebare', trigger: 'change' }
                    ],
                    q2: [
                        { required: true, message: 'Selectați raspunsul la aceasta intrebare', trigger: 'change' }
                    ],
                    q3: [
                        { required: true, message: 'Selectați raspunsul la aceasta intrebare', trigger: 'change' }
                    ],
                    q4: [
                        { required: true, message: 'Selectați raspunsul la aceasta intrebare', trigger: 'change' }
                    ],
                    q5: [
                        { required: true, message: 'Selectați raspunsul la aceasta intrebare', trigger: 'change' }
                    ],
                    q6: [
                        { required: true, message: 'Selectați raspunsul la aceasta intrebare', trigger: 'change' }
                    ],
                    q7: [
                        { required: true, message: 'Selectați raspunsul la aceasta intrebare', trigger: 'change' }
                    ],
                    q8: [
                        { required: true, message: 'Selectați raspunsul la aceasta intrebare', trigger: 'change' }
                    ],
                    q9: [
                        { required: true, message: 'Selectați raspunsul la aceasta intrebare', trigger: 'change' }
                    ],
                },
            }
        },
        computed:{

        },
        watch:{

        },
        methods:{

        },
        mounted(){
            this.questions = this.reporting.questions;
        },
    }
</script>

<style scoped>

</style>
