<template>
    <div>
        <div class="row" v-if="promoter.accounts" v-for="user in promoter.accounts">
            <div class="col-sm-3">
                <label>Prenume</label>
                <p>{{ user.first_name }}</p>
            </div>
            <div class="col-sm-3">
                <label>Nume</label>
                <p>{{ user.last_name }}</p>
            </div>
            <div class="col-sm-3">
                <label>Email</label>
                <p>{{ user.email }}</p>
            </div>
            <div class="col-sm-3">
                <a href="#" class="btn btn-secondary mt-1" @click.prevent="resetPassword(user)">Reseteaza parola</a>
            </div>
        </div>
        <div v-else class="alert alert-info">
            Nu exista conturi de utilizator pentru aceasta firma
        </div>
    </div>
</template>
<script>
    export default {
        name: "promoter-accounts-pane",
        props:{
            promoter: {
                required: true,
                type: Object
            }
        },
        methods:{
            resetPassword(user){
                console.log(user);
            }
        }
    }
</script>

<style scoped>

</style>
