<template>
    <div v-if="project">
        <h3 class="mb-4 mt-3">Indicatori</h3>
        <div class="row">
            <div class="col-sm-12">
                <el-table :data="notification.indicators" id="indicatorsList" stripe fit highlight-current-row style="width:100%">
                    <el-table-column label="ProjectIndicator" width="100px" align="center">
                        <template slot-scope="scope">
                            I{{ scope.row.indicator }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Denumire" prop="name"></el-table-column>
                    <el-table-column label="Outcome" prop="outcome.value"></el-table-column>
                    <el-table-column label="Output" prop="output.value"></el-table-column>
                    <el-table-column label="ProjectIndicator"></el-table-column>
                    <el-table-column label="Target" prop="target">
                        <template slot-scope="scope">
                            <span  slot="reference">{{ scope.row.target }} {{ scope.row.target_unit }} </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Actiuni" align="center" width="130px">
                        <template slot-scope="scope">
                            <el-button size="mini" type="secondary" icon="el-icon-edit"
                                       @click="handleRowSelect(scope.row)"></el-button>
                            <el-button size="mini" type="danger" icon="el-icon-delete"
                                       @click="handleRowDelete(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-form :model="form" id="indicatorsForm" :rules="rules" ref="indicators" label-position="top" :status-icon="true" :inline-message="true">
            <div class="row">
                <div class="col-sm-12">
                    <h4 class="mb-4 mt-5">Adauga/modifica indicator</h4>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Denumire" prop="name">
                            <el-input v-model="form.name" placeholder="Denumirea indicatorului" clearable type="text" maxlength="200" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Outcome Proiect" prop="outcome">
                            <el-select v-model="form.outcome" filterable placeholder="Select" value-key="id" style="width:100%" @change="updateOutput">
                                <el-option v-for="item in outcomes.values"
                                           :key="item.id" :label="item.value"  :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Output Proiect" prop="output">
                            <el-select v-model="form.output" filterable placeholder="Select" value-key="id" style="width:100%">
                                <el-option v-for="item in outputs.values"
                                           :key="item.id" :label="item.value"  :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Indicatori" prop="indicators">
                            !!! Lipsa informatii !!!
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Target" prop="target">
                        <el-input v-model="form.target" placeholder="Valoare tinta" clearable type="number" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <el-form-item label="Unitate masura" prop="target_unit">
                            <el-select v-model="form.target_unit" filterable placeholder="Select" value-key="id" style="width:100%">
                                <el-option value="buc" label="buc"></el-option>
                                <el-option value="valoare" label="valoare"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 text-right mb-5">
                    <el-button @click.prevent="clear">Goleste formularul</el-button>
                    <el-button @click.prevent="addRecord" type="secondary">Aplica modificarile</el-button>
                </div>
            </div>
            <div class="input-group">
                <textarea placeholder="Adauga un comentariu" v-on:change="saveComment" v-model="form.comment_sec3" class="form-control custom-control" rows="3" style="resize:none"></textarea>
                <el-button class="input-group-addon btn btn-primary" icon="el-icon-edit" @click="uploadFilesComment(notification)"></br>Vezi/modifică</br>fișierele</br>comentariului</el-button>
            </div>
            <hr />
            <div class="form-group">
                <h5>Lista comentarii:</h5>
                <span style="white-space: pre-line;">{{ form.comment_sec3 }}</span>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex'
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "notification-indicators-form",
        data(){
            return {
                form:{},
                rules: {
                    name: [
                        {required: true, message: 'Denumirea este obligatorie', trigger: 'blur'},
                        { min: 3, max: 200, message: 'Lungimea trebuie sa fie intre 3 si 200 caractere', trigger: 'blur' }
                    ],
                    outcome: [
                        {required: true, message: 'Outcome-ul este obligatoriu', trigger: 'blur'},
                    ],
                    output: [
                        {required: true, message: 'Output-ul este obligatoriu', trigger: 'blur'},
                    ],
                    target: [
                        {required: true, message: 'Target-ul este obligatoriu', trigger: 'blur'},
                    ],
                    // description: [
                    //     {required: true, message: 'Descrierea este obligatorie', trigger: 'blur'},
                    //     { min: 20, max: 1500, message: 'Lungimea trebuie sa fie intre 20 si 1500 caractere', trigger: 'blur' }
                    // ],
                },
            }
        },
        computed:{
            ...mapGetters({
                project: 'project/project',
                outcomes: 'project/outcomes',
                outputs: 'project/outputs',
                notification: 'notification/notification',
                validateIndicators: 'project/validateIndicators',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validateIndicators: function(){
                if(this.validateIndicators) {
                    this.submitForm();
                }
            },
        },
        mounted(){
            this.getOutcomes();
            this.getOutputs();
            this.clear();
        },
        methods:{
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
                setValidateIndicators: 'project/setValidateIndicators',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            ...mapActions({
                getOutcomes: 'project/getOutcomes',
                getOutputs: 'project/getOutputs',
                saveNotificationComment: 'notification/saveNotificationComment'
            }),
            uploadFilesComment(form)
            {
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Notification',
                    model_id: form.id,
                    category_id: 4,
                    acceptFiles: '.pdf,.docx,.doc,.xlsx,.xls',
                    multiple: true,
                    title: 'Incarcă documente',
                    files: form.commentfiles.filter(p => p.category_id == 4),
                });
            },
            updateOutput(){
                this.getOutputs(this.form.outcome.id);
            },
            saveComment()
            {
                this.saveNotificationComment({
                    id: this.notification.id,
                    project_id: this.project.id,
                    section_id: 3,
                    comment: this.form.comment_sec3,
                });
            },
            addRecord(){
                this.$refs['indicators'].validate((valid) => {
                    if (valid) {
                        if(this.form.indicator > this.notification.indicators.length) {
                            this.notification.indicators.push(this.form);
                        }else{
                            this.notification.indicators[this.form.indicator - 1] = this.form
                        }
                        this.saveIndicator();
                        this.clear();
                        this.setValidateIndicators(false);
                        VueScrollTo.scrollTo('#indicatorsList');
                        return true;
                    } else {
                        return false;
                    }
                });
            },
            saveIndicator()
            {
                axios.post('/notifications/notificationIndicator', {project_id: this.project.id, id:this.notification.id, indicators: this.notification.indicators}).then((result) => { this.$notify({ type: 'success', title: 'Indicatori', message: 'Indicatorul a fost adaugat cu succes!'}); });
            },
            handleRowSelect(row){
                this.updateOutput();
                this.form = row;
                VueScrollTo.scrollTo('#indicatorsForm');
            },
            clear(){
                this.form = {indicator: this.notification.indicators.length + 1}
            },
            handleRowDelete(index, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi aceasta inregistrare?')
                    .then(_ => {
                        if (index !== -1) {
                            axios.post('/notifications/notificationRemoveIndicator', {project_id: this.project.id, id: this.notification.id, name: row.name});
                            this.$notify({ type: 'success', title: 'Indicatori', message: 'Inregistrarea a fost stearsa cu succes!'});
                            this.notification.indicators.splice(index, 1);
                            this.clear();
                        }
                    }).catch(err => {
                        console.log(err)
                    });
            },
            submitForm() {
                if (this.notification.indicators.length > 0) {
                    this.setValidateIndicators(false);
                    this.setValidationErrorsExists(false);
                    return true;
                } else {
                    this.setValidationErrorsExists(true);
                    this.$notify({ type: 'warning', title: 'Indicatori proiect', message: 'Trebuie sa adaugati cel putin un indicator!'});
                    return false;
                }
            },
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
