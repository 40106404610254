<template>
    <div v-loading="loading">
        <h5>1. Date generale</h5>
        <el-tabs v-model="tabGeneral">
            <el-tab-pane label="Date generale" name="first">
                <div class="row">
                    <div class="col-sm-2">
                        <label>Numar grafic raport</label>
                        <p v-if="reporting.graph">{{ reporting.graph.reporting_id }}</p>
                    </div>
                    <div class="col-sm-2">
                        <label>Tip raport</label>
                        <p v-if="reporting.type_report === 1 || reporting.type_report === 11">Intermediar</p><p v-else>Final</p>
                    </div>
                    <div class="col-sm-4">
                        <label>Perioadă raportată</label>
                        <p>{{formatUserDate(reporting.start_date)}} - {{formatUserDate(reporting.end_date)}}</p>
                    </div>
                    <div class="col-sm-3">
                        <label>Data transmiterii</label>
                        <p>{{formatUserDateTime(reporting.updated_at)}}</p>
                    </div>
                    <div class="col-sm-2">
                        <label>Contract nr.</label>
                        <p>{{project.funding_number}}</p>
                    </div>
                    <div class="col-sm-3">
                        <label>Perioada de implementare a proiectului</label>
                        <p>{{ formatUserDate(project.implementation_start_date) }} - {{ formatUserDate(project.implementation_end_date) }}</p>
                    </div>
                    <div class="col-sm-3">
                        <label>Durata de implementare a proiectului</label>
                        <p>{{ datesDifference(project.implementation_start_date, project.implementation_end_date) }}</p>
                    </div>
                    <div class="col-sm-4">
                        <label>Promotor</label>
                        <p>{{project.promoter.name}}</p>
                    </div>
                    <div class="col-sm-3">
                        <label>Partener(i)</label>
                        <p>
                            <span v-for="p in project.partners">{{ p.name }}<br/></span>
                        </p>
                    </div>
                    <div class="col-sm-4">
                        <label>Documente semnate de la promotor</label>
                        <p>
                            <el-button type="secondary" size="small" icon="el-icon-view" @click="viewDocument()">
                              <span>Vezi fisierele raportarii</span>
                            </el-button>
                        </p>
                    </div>
                    <div class="col-sm-4">
                        <label>VRT</label>
                        <p>
                            <el-button type="secondary" size="small" icon="el-icon-view" @click="viewDocumentVRT()">
                              <span>Vezi fisierul incarcat</span>
                            </el-button>
                        </p>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Istoric modificari" name="second">
                <el-timeline>
                    <el-timeline-item
                        v-for="(row, index) in reporting.logs"
                        :key="index"
                        :timestamp="formatUserDate(row.created_at)">
                        {{ row.message }}
                    </el-timeline-item>
                </el-timeline>
            </el-tab-pane>
        </el-tabs>


        <h5>2. Rezumatul raportului</h5>
        <div class="row">
            <div class="col-sm-6">
                <label>2.1. Rezumat in limba romana</label>
                <p>{{ reporting.summary_ro }}</p>
            </div>
            <div class="col-sm-6">
                <label>2.2. Rezumat in limba engleza</label>
                <p>{{ reporting.summary_en }}</p>
            </div>
        </div>
        <h5>3. Managementul riscurilor</h5>
        <div class="row">
            <div class="col-sm-6">
                <p>{{ reporting.risk_management }}</p>
            </div>
        </div>
        <h5>4. Cooperarea cu partenerii de proiect</h5>
        <div class="row">
            <div class="col-sm-6">
                <label>4.1. Cooperarea cu parteneri din Statele Donatoare (Norvegia, Islanda, Liechtenstein) daca este cazul</label>
                <p>{{ reporting.coop_donor_partners }}</p>
            </div>
        </div>
        <div class="row" v-if="reporting.type_report === 2 || reporting.type_report === 22">
            <div class="col-sm-6">
                <label>4.1.a. Cum a contribuit proiectul la consolidarea relatiilor bilaterale?</label>
                <p>
                    <span v-for="q in JSON.parse(reporting.q1a)">
                        <font style="checkbox">✔</font> {{ q }}<br/>
                    </span>
                </p>
            </div>
            <div class="col-sm-6">
                <label>4.1.b. Ce nivel de implicare a avut partenerul (partenerii) din Statele Donatoare in proiect?</label>
                <p>
                    <span v-for="q in JSON.parse(reporting.q1b)">
                        <font style="checkbox">✔</font> {{ q }}<br/>
                    </span>
                </p>
            </div>
            <div class="col-sm-6">
                <label>4.1.c. Va continua cooperarea cu partenerul/ partenerii din Statele Donatoare dupa finalizarea proiectului?</label>
                <p>
                    <span v-for="q in JSON.parse(reporting.q1c)">
                        <font style="checkbox">✔</font> {{ q }}<br/>
                    </span>
                </p>
            </div>
        </div>
        <div class="col-sm-6">
            <label>4.2. Cooperarea cu alti Parteneri (daca este cazul)</label>
            <p>{{ reporting.coop_partners }}</p>
        </div>
        <hr>
        <h5>5. Realizari privind activitatile, rezultatele si indicatorii proiectului</h5>
        <el-tabs v-model="planTabs" class="mt-5">
            <el-tab-pane label="5.1. Implementarea activitatilor" name="activities" class="active">
                <el-table :data="reporting.technical_activities">
                    <el-table-column label="Activitate" width="70" align="center">
                        <template slot-scope="scope">{{ 'A'+scope.row.activity }}</template>
                    </el-table-column>
                    <el-table-column label="Denumire">
                        <template slot-scope="scope">{{ project.activities[scope.row.activity-1].name }}</template>
                    </el-table-column>
                    <el-table-column label="Descrierea activității" prop="description"></el-table-column>
                    <el-table-column label="Rezultate obținute" prop="results"></el-table-column>
                    <el-table-column label="Modificări" prop="changes"></el-table-column>
                    <el-table-column label="Anexe" width="130px" align="center">
                        <template slot-scope="scope" v-if="scope.row.activity === 2">
                            <el-button size="mini" type="warning" icon="el-icon-files" @click="viewDocumentActivity2(scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>

            <el-tab-pane label="5.2. Indicatorii de program" name="indicators">
                <el-table :data="reporting.indicators" id="indicatorsList" stripe fit highlight-current-row style="width:100%">
                    <el-table-column label="ID" align="center" width="80">
                        <template slot-scope="scope">
                            I{{ scope.row.index }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Indicator" prop="indicator.name"></el-table-column>

                    <el-table-column label="Valoare initiala" prop="indicator.target" align="right" width="150" header-align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.indicator.indicator_id == 152 || scope.row.indicator.indicator_id == 142 || scope.row.indicator.indicator_id == 169 || scope.row.indicator.indicator_id == 198 || scope.row.indicator.indicator_id == 199 || scope.row.indicator.indicator_id == 200 || scope.row.indicator.indicator_id == 201">
                                <span>{{ formatNumberNew(scope.row.value_up_notification,0) }} / {{ formatNumberNew(scope.row.value_down_notification,0) }} = {{ formatNumber(scope.row.value_up_notification/scope.row.value_down_notification*100,2) }}%</span>
<!--                                <span v-if="scope.row.value_notification > 0">{{ formatNumberNew(scope.row.value_notification,0) }}</span>-->
<!--                                <span v-if="scope.row.value_down_notification < 1 && scope.row.value_notification < 1">0/{{ formatNumberNew(scope.row.value_up_notification,0) }} = 0%</span>-->
                            </span>
                            <span v-else>
                                {{ formatNumberNew(scope.row.value_notification,0) }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column label="Valoare realizata pana la sfarsitul perioadei anterioare de raportare" prop="indicator.target" align="right" width="150" header-align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.indicator.indicator_id == 152 || scope.row.indicator.indicator_id == 142 || scope.row.indicator.indicator_id == 169 || scope.row.indicator.indicator_id == 198 || scope.row.indicator.indicator_id == 199 || scope.row.indicator.indicator_id == 200 || scope.row.indicator.indicator_id == 201">
                                <span>{{ formatNumberNew(scope.row.value_up_reporting,0) }} / {{ formatNumberNew(scope.row.value_down_reporting,0) }} = {{ formatNumber(scope.row.value_up_reporting/scope.row.value_down_reporting*100,2) }}%</span>
<!--                                <span v-if="scope.row.value_reporting > 0">{{ formatNumberNew(scope.row.value_reporting,0) }}</span>-->
<!--                                <span v-if="scope.row.value_down_reporting < 1 && scope.row.value_reporting < 1">0/{{ formatNumberNew(scope.row.value_up_reporting,0) }} = 0%</span>-->
                            </span>
                            <span v-else>
                                {{ formatNumberNew(scope.row.value_reporting,0) }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column label="Valoare realizata pana la sfarsitul perioadei acoperite de raportul curent (cumulativ)" prop="indicator.target" align="right" width="150" header-align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.indicator.indicator_id == 152 || scope.row.indicator.indicator_id == 142 || scope.row.indicator.indicator_id == 169 || scope.row.indicator.indicator_id == 198 || scope.row.indicator.indicator_id == 199 || scope.row.indicator.indicator_id == 200 || scope.row.indicator.indicator_id == 201">
                                <span>{{ formatNumberNew(scope.row.value_up,0) }} / {{ formatNumberNew(scope.row.value_down,0) }} = {{ formatNumber(scope.row.value_up/scope.row.value_down*100,2) }}%</span>
<!--                                <span v-if="scope.row.value > 0">{{ formatNumberNew(scope.row.value,0) }}</span>-->
<!--                                <span v-if="scope.row.value_down < 1 && scope.row.value < 1">0/{{ formatNumberNew(scope.row.value_up,0) }} = 0%</span>-->
                            </span>
                            <span v-else>
                                {{ formatNumberNew(scope.row.value,0) }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column label="Valoare propusa (target)" prop="indicator.target" align="right" width="150" header-align="center">
                        <template slot-scope="scope">
                            {{ formatNumberNew(scope.row.value_admin, 0) }}
                        </template>
                    </el-table-column>

                    <el-table-column label="Varsta" width="230" header-align="center">
                        <template slot-scope="scope" v-if="showAgesFor.includes(scope.row.indicator.indicator_id)">
                            <div v-for="age in scope.row.ages" style="display:block">
                                {{age.name}}: {{formatNumberNew(age.value,0)}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Gen" width="140" header-align="center">
                        <template slot-scope="scope" v-if="showGenderFor.includes(scope.row.indicator.indicator_id)">
                            <div v-for="gender in scope.row.genders" style="display:block">
                                {{gender.name}}: {{formatNumberNew(gender.value,0)}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Etnia" width="140" header-align="center">
                        <template slot-scope="scope" v-if="showEthFor.includes(scope.row.indicator.indicator_id)">
                            <div v-for="ethnicity in scope.row.ethnicities" style="display:block">
                                {{ethnicity.name}}: {{formatNumberNew(ethnicity.value,0)}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Servicii" width="140" header-align="center">
                        <template slot-scope="scope" v-if="showServFor.includes(scope.row.indicator.indicator_id)">
                            <div v-for="service in scope.row.services" style="display:block">
                                {{service.name}}: {{formatNumberNew(service.value,0)}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Tematici" width="140" header-align="center">
                        <template slot-scope="scope" v-if="showTechFor.includes(scope.row.indicator.indicator_id)">
                            <div v-for="technique in scope.row.techniques" style="display:block">
                                {{technique.name}}: {{formatNumberNew(technique.value,0)}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Anexe" width="130px" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="warning" icon="el-icon-files" @click="viewDocumentIndicator(scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="5.3. Graficul activitatilor actualizat" name="calendar">
                <div class="row">
                    <div class="col-sm-12">
                        <h4>Calendar activitati proiect</h4>
                        <pane-calendar v-if="reporting.status !== 3" :calendars="project.calendars" :project="project"></pane-calendar>
                        <pane-calendar v-else :calendars="reporting.calendars_old" :project="project"></pane-calendar>
                    </div>
                    <div class="col-sm-12">
                        <h4>Calendar activitati modificate</h4>
                        <pane-calendar :calendars="reporting.calendars" :project="project"></pane-calendar>
                    </div>
                </div>
                <el-table :data="reporting.calendars">
                    <el-table-column label="ID" prop="activity.activity" width="60"></el-table-column>
                    <el-table-column label="Denumire" prop="activity.name"></el-table-column>
                    <el-table-column label="Data inceput" prop="start_date" width="120">
                        <template slot-scope="scope">
                            {{ formatUserDate(scope.row.start_date) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Data sfarsit" prop="end_date" width="120">
                        <template slot-scope="scope">
                            {{ formatUserDate(scope.row.end_date) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Perioada" width="200">
                        <template slot-scope="scope">
                            {{ datesDifference(scope.row.start_date,scope.row.end_date) }}
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <hr>
        <div class="row">
            <div class="col-sm-6" v-if="reporting.type_report === 2 || reporting.type_report === 22">
                <label>5.4. Atingerea/realizarea obiectivelor</label>
                <p>{{ reporting.reaching_goals }}</p>
            </div>
            <div class="col-sm-6" v-if="reporting.type_report === 1 || reporting.type_report === 11">
                <label>5.4. Progresul in realizarea obiectivelor</label>
                <p>{{ reporting.reaching_goals }}</p>
            </div>
        </div>
        <h5>6. Sustenabilitatea proiectului</h5>
        <div class="row">
            <div class="col-sm-6">
                <p>{{ reporting.sustainability }}</p>
            </div>
        </div>
        <div class="col-sm" v-if="reporting.type_report === 2 || reporting.type_report === 22">
            <label>6.1. Cat de probabil este ca efectele pozitive ale proiectului sa continue dupa incheierea perioadei de finantare?</label>
            <p>
                <span v-for="q in JSON.parse(reporting.q6)">
                    <font style="checkbox">✔</font> {{ q }}<br/>
                </span>
            </p>
        </div>
        <h5>7. Lecții învățate</h5>
        <div class="row">
            <div class="col-sm-6">
                <p>{{ reporting.lessons }}</p>
            </div>
        </div>
        <h5>8. Alte comentarii</h5>
        <div class="row">
            <div class="col-sm-6">
                <p>{{ reporting.comment }}</p>
            </div>
        </div>
        <hr>
        <div class="form-group">
            <el-button type="secondary" v-if="typeof reporting.comments !== 'undefined'" class="float-right" size="mini" @click="exportComments()"><i class="icofont-file-pdf"></i> Exporta clarificarile</el-button>
            <h5>Lista comentarii:</h5>
            <div class="feed-element" v-for="(row, index) in reporting.comments" :key="index">
                <div class="media-body" v-if="row.reply_to_id > -1">
                    <i class="icofont-girl" style="font-size:100%"></i> <b v-if="row.user && typeof row.user.promoter[0] !== 'undefined'">Promotor</b><b v-else>Operator de Fond</b>: {{ row.comment }}<br>
                    <small class="text-muted green"><i class="icofont-clock-time"></i> {{ formatUserDate(row.created_at) }}</small>

                    <p v-if="row.files.length" class="text-right">
                        <small>
                            Fisiere incarcate:
                            <span v-for="doc in row.files">
                                <br/><a :href="'/getFile/' + doc.id" target="_blank">{{ doc.file_name }}</a>
                            </span>
                        </small>
                    </p>
                </div>
            </div>
        </div>
        <upload-drawer :roles="roles"></upload-drawer>
        <!--<hr>
        <el-button type="secondary" icon="el-icon-view" @click="viewDocument()">
          <span>Vezi fisierul raportarii</span>
        </el-button>-->
        <hr>
        <div class="form-group">
            <h5>
                Se asteapta raspuns pana la data de: <span v-if="reporting.answer_limit">{{ formatUserDateTime(reporting.answer_limit) }}</span>
                <small v-if="form.answer_limit"><br/>Timp ramas: {{ datesDifferenceWithoutDayPlus(contractMinDate, form.answer_limit) }}</small>
            </h5>
            <label>
                <el-date-picker v-model="form.answer_limit" placeholder="Pana la" type="date" value-format="yyyy-MM-dd" size="medium"
                                format="dd.MM.yyyy" style="width:100%" :picker-options="startPeriodOptions" @change="UPDB"></el-date-picker>

            </label>
        </div>
    </div>
</template>
<script>
    import {mapMutations,mapActions} from "vuex";
    import mixin from './../../mixins/common';
    import moment from 'moment';
    import PaneCalendar from "./pane-actions-technical-calendar";
    import UploadDrawer from "../common/view-reporting-technical-drawer";
    export default {
        name: "reporting-verify-form",
        mixins:[mixin],
        components:{
            PaneCalendar,
            UploadDrawer
        },
        data(){
            return{
                tabGeneral: 'first',
                planTabs:'activities',
                loading: false,
                form: {
                    answer_limit: this.reporting.answer_limit,
                },
                contractMinDate: new Date(),
                startPeriodOptions: {
                    disabledDate: this.disabledContractDate
                },
                showAgesFor: [151,152,153,154,158,161,162,169,170,177,178,195,196,204,142,169],
                showGenderFor: [151,152,153,154,158,161,162,169,170,177,178,195,196,204,142,169],
                showEthFor: [151,152,153,158,161,169,170,177,178,195,196,142,169],
                showServFor: [170],
                showTechFor: [202],
                showUpDownFor:[152,169,198,199,200,201],
            }
        },
        props:{
            project: {
                required: true,
                type: Object
            },
            reporting: {
                required: true,
                type: Object
            },
            roles:{
                type: Array,
                required: false
            }
        },
        methods:{
            ...mapActions({
                saveReportingAnswerLimit: 'reportings/saveReportingAnswerLimit',
            }),
            disabledContractDate(date) {
                return date < this.contractMinDate
            },
            exportComments() {
                window.open('/reportings/export-comments/'+this.reporting.id, '_blank');
            },
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
            }),
            UPDB()
            {
                this.loading = true;
                this.saveReportingAnswerLimit({
                    id: this.reporting.id,
                    date: moment(this.form.answer_limit).utc(true).endOf('day'),
                }).then(_ => {
                        this.loading = false;
                        this.$notify({
                        type: 'success',
                        title: 'Data limita raspuns',
                        message: 'Data limita pentru a primi un raspuns a fost salvata cu succes!'
                    });
                });
            },
            viewDocument()
            {
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Reporting',
                    model_id: this.reporting.id,
                    category_id: this.reporting.type,
                    acceptFiles: '.pdf',
                    multiple: false,
                    title: 'Vizualizare documente',
                    files: this.reporting.documents,
                });
            },
            viewDocumentVRT()
            {
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Reporting',
                    model_id: this.reporting.id,
                    category_id: this.reporting.type,
                    acceptFiles: '.pdf',
                    multiple: false,
                    title: 'Vizualizare documente',
                    files: this.reporting.documents_vrt,
                });
            },
            viewDocumentIndicator(object){
                this.setShowUploadDrawer({
                    visible: true,
                    updateAfterClose: true,
                    model: 'App\\Models\\ReportingIndicator',
                    model_id: object.id,
                    category_id: object.index,
                    acceptFiles: '.pdf,.docx,.doc,.jpg,.xls', //.docx,.doc,
                    multiple: true,
                    title: 'Vizualizare documente',
                    files: object.documents_indicators,
                    object: object,
                });
            },
            viewDocumentActivity2(object){
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\ReportingTechnicalActivity',
                    model_id: object.id,
                    category_id: 1,
                    acceptFiles: '.pdf,.docx,.doc', //.docx,.doc,
                    multiple: true,
                    title: 'Vizualizare documente',
                    files: object.documentsactivity,
                    object: object,
                });
            },
        }
    }
</script>
<style scoped>
.checkbox { border: 1px solid #aaa;color:green; }
</style>
