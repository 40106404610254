<template>
    <el-form :model="project" :rules="rules" ref="description" label-position="top" :status-icon="true" :inline-message="true">
        <h3 class="mb-4">Descriere</h3>
        <el-form :model="form" id="placesForm" :rules="placeRules" ref="placesForm" label-position="top" :status-icon="true" :inline-message="true">
            <div class="row">
                <div class="col-sm-4">
                    <div class="form-group">
                        <el-form-item label="Selecteaza localitatea" prop="place">
                            <el-select v-model="form.place" filterable clearable remote
                                       placeholder="Introdu numele localitatii" :remote-method="remoteMethod" style="width:100%" value-key="id">
                                <el-option v-for="item in cities"
                                           :key="item.id"
                                           :label="item.name + ' - ' + (item.county.name !== 'Bucuresti' ? 'Judetul ' : ' ') + item.county.name"
                                           :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-2 form-group">
                    <el-form-item class="mt-1" label=" ">
                        <el-button @click.prevent="addPlace" type="secondary">Adauga localitate</el-button>
                    </el-form-item>
                </div>
                <div class="col-sm-6">
                </div>
            </div>
        </el-form>
        <div class="row">
            <div class="col-sm-6">
                <el-form-item label="Localitati de desfasurare" prop="place">
                    <el-table :data="notification.locations">
                        <el-table-column prop="name" label="Oras"></el-table-column>
                        <el-table-column prop="county" label="Judet"></el-table-column>
                        <el-table-column label="Actiuni" width="80">
                            <template slot-scope="scope">
                                <el-button size="mini" type="danger" icon="el-icon-delete"
                                           @click="handleDeleteRow(scope.$index, scope.row)"></el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form-item>
            </div>
        </div>
        <hr />
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <el-form-item label="Proiectul este preponderent in mediul" prop="medium">
                        <el-select v-model="notification.medium" filterable placeholder="Select" style="width:100%"  value-key="id" @change="updateOutput">
                            <el-option v-for="item in medium.values"
                                    :key="item.id" :label="item.value" :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <el-form-item label="Categoria de incadrare" prop="framing">
                        <el-select v-model="notification.framing" filterable placeholder="Select" style="width:100%"  value-key="id" @change="updateOutput">
                            <el-option v-for="item in framing.values"
                                       :key="item.id" :label="item.value"  :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <el-form-item label="Prioritati transversale ale programului" prop="priorities">
                        <el-select v-model="notification.priorities" filterable placeholder="Select" style="width:100%"  value-key="id" @change="updateOutput">
                            <el-option v-for="item in priorities.values"
                                       :key="item.id" :label="item.value"  :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <el-form-item label="Policy markers" prop="policies">
                        <el-select v-model="notification.policies" filterable placeholder="Select" style="width:100%"  value-key="id" @change="updateOutput">
                            <el-option v-for="item in policies.values"
                                       :key="item.id" :label="item.value"  :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
            </div>
        </div>
        <div class="input-group">
            <textarea placeholder="Adauga un comentariu" v-on:change="saveComment" v-model="form.comment_sec9" class="form-control custom-control" rows="3" style="resize:none"></textarea>
            <el-button class="input-group-addon btn btn-primary" icon="el-icon-edit" @click="uploadFilesComment(notification)"></br>Vezi/modifică</br>fișierele</br>comentariului</el-button>
        </div>
        <hr />
        <div class="form-group">
            <h5>Lista comentarii:</h5>
            <span style="white-space: pre-line;">{{ form.comment_sec9 }}</span>
        </div>
    </el-form>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex';
    export default {
        name: "notification-city-form",
        data(){
            return {
                form:{},
                rules: {
                    // places: [
                    //     { required: true, message: 'Selectati locul de desfasurare', trigger: 'change' },
                    // ],
                    medium: [
                        { required: true, message: 'Selectati mediul de desfasurare', trigger: 'change' },
                    ],
                    framing: [
                        { required: true, message: 'Selectati categoria de incadrare', trigger: 'change' },
                    ],
                    priorities: [
                        { required: true, message: 'Selectati prioritatea programului', trigger: 'change' }
                    ],
                    policies: [
                        { required: true, message: 'Selectati Policy Makers', trigger: 'change' }
                    ],
                    beneficiary: [
                        { required: true, message: 'Introduceti descrierea beneficiarilor', trigger: 'blur' }
                    ],
                    description: [
                        { required: true, message: 'Introduceti descrierea proiectului in limba Romana', trigger: 'blur' }
                    ],
                    description_en: [
                        { required: true, message: 'Introduceti descrierea proiectului in limba Engleza', trigger: 'blur' }
                    ],
                },
                placeRules: {
                    place: [
                        {required: true, message: 'Introduceti numele unei localitati', trigger: 'blur'},
                    ],
                }
            }
        },
        computed:{
            ...mapGetters({
                cities: 'cities',
                medium: 'project/medium',
                framing: 'project/framing',
                priorities: 'project/priorities',
                policies: 'project/policies',
                project: 'project/project',
                notification: 'notification/notification',
                validateDescription: 'project/validateDescription',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validateDescription: function(){
                if(this.validateDescription) {
                    this.setValidateDescription(false);
                    this.submitForm();
                }
            },
        },
        mounted(){
            this.getMedium();
            this.getFraming();
            this.getPriorities();
            this.getPolicies();
            this.clear();
        },
        methods:{
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
                setValidateDescription: 'project/setValidateDescription',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            ...mapActions({
                searchCity: 'searchCity',
                getMedium: 'project/getMedium',
                getFraming: 'project/getFraming',
                getPriorities: 'project/getPriorities',
                saveProject: 'project/saveProject',
                getPolicies: 'project/getPolicies',
                saveNotificationComment: 'notification/saveNotificationComment'
            }),
            uploadFilesComment(form)
            {
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Notification',
                    model_id: form.id,
                    category_id: 10,
                    acceptFiles: '.pdf,.docx,.doc,.xlsx,.xls',
                    multiple: true,
                    title: 'Incarcă documente',
                    files: form.commentfiles.filter(p => p.category_id == 10),
                });
            },
            saveComment()
            {
                this.saveNotificationComment({
                    id: this.notification.id,
                    project_id: this.project.id,
                    section_id: 9,
                    comment: this.form.comment_sec9,
                });
            },
            updateOutput(){
                axios.post('/notifications/mediumPlace', {project_id: this.project.id, id:this.notification.id, medium_id: this.notification.medium.id, framing_id: this.notification.framing.id, priority_id: this.notification.priorities.id, policy_id: this.notification.policies.id}).then((result) => { this.$notify({ type: 'success', title: 'Modificare localitati si mediul desfasurare ', message: 'Modificarea a fost salvata cu succes!'}); });
            },
            updateForm() {
                this.saveProject(this.project).then((result) => {
                    if(result.valid === false){
                        this.$notify({ type:'error', title: 'Eroare', message: result.message });
                        reject(false);
                    }
                });
            },
            addPlace(){
                this.$refs['placesForm'].validate((valid) => {
                    if (valid) {
                        if (this.form.index > this.notification.places.length) {
                            this.notification.places.push(this.form.place);
                        } else {
                            this.notification.places[this.form.index - 1] = this.form
                        }
                        this.saveCity();
                        this.clear();
                        return true;
                    } else {
                        return false;
                    }
                });
            },
            saveCity()
            {
                axios.post('/notifications/notificationPlace', {project_id: this.project.id, id:this.notification.id, places: this.notification.places}).then((result) => { this.$notify({ type: 'success', title: 'Localitati', message: 'Inregistrarea a fost adaugata cu succes!'}); });
            },
            handleDeleteRow(index, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi aceasta inregistrare?')
                    .then(_ => {
                        if (index !== -1) {
                            axios.post('/notifications/notificationRemovePlace', {project_id: this.project.id, name: row.name, county: row.county});
                            this.$notify({ type: 'success', title: 'Localitati', message: 'Inregistrarea a fost stearsa cu succes!'});
                            this.notification.places.splice(index, 1);
                            this.clear();
                        }
                    }).catch(err => {
                        console.log(err)
                    });
            },
            remoteMethod(query) {
                if (query !== '') {
                    setTimeout(() => {
                        this.searchCity(query);
                    }, 200);
                }
            },
            submitForm() {
                this.$refs['description'].validate((valid) => {
                    if (valid) {
                        this.setValidationErrorsExists(false);
                        return true;
                    } else {
                        this.setValidationErrorsExists(true);
                        return false;
                    }
                })
            },
            clear(){
                this.form =  {index: this.notification.places.length + 1};
                this.$refs['placesForm'].clearValidate();
            },
        }
    }
</script>

<style scoped>

</style>
