
export const setConstructionWorks = (state, payload) => { state.constructionWorks = payload };
export const setIndirectCosts = (state, payload) => { state.indirectCosts = payload };
export const setOrganizationalDevelopment = (state, payload) => { state.organizationalDevelopment = payload };
export const setBudgetFinancing = (state, payload) => { state.budgetFinancing = payload };
export const setGrantFraming = (state, payload) => { state.grantFraming = payload };
export const setBudgetCoFinancing = (state, payload) => { state.budgetCoFinancing = payload };
export const setBudgetNature = (state, payload) => { state.budgetNature = payload };
export const setFramingTimeGrant = (state, payload) => { state.framingTimeGrant = payload };
export const setAppealNumber = (state, payload) => { state.appealNumber = payload };
export const setBudgetMaxGrantPromoter = (state, payload) => { state.budgetMaxGrantPromoter = payload };
export const setBudgetMaxGrantPartner = (state, payload) => { state.budgetMaxGrantPartner = payload };
export const setkeepNerambursabil = (state, payload) => { state.keepNerambursabil = payload };
export const setmaintainBudget = (state, payload) => { state.maintainBudget = payload };
export const setrealocation = (state, payload) => { state.realocation = payload };
