<template>
    <div v-if="project" v-loading="loading">
        <h3 class="mb-4 mt-3">Indicatori de program</h3>
        <div class="row">
            <div class="col-sm-12">
                <el-table :data="project.indicators" id="indicatorsList" stripe fit highlight-current-row style="width:100%">
                    <el-table-column label="Indicator" width="100px" align="center">
                        <template slot-scope="scope">
                            I{{ scope.$index+1 }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Outcome" prop="outcome.value"></el-table-column>
                    <el-table-column label="Output" prop="output.value"></el-table-column>
                    <el-table-column label="Indicator">
                        <template slot-scope="scope">
                            <span v-if="scope.row.outcome_indicators">{{scope.row.outcome_indicators.value}}</span>
                            <span v-if="scope.row.output_indicators">{{scope.row.output_indicators.value}}</span>
                            <span v-if="scope.row.customIndicator">{{scope.row.customIndicator}}</span>
                            <span v-if="!scope.row.customIndicator && scope.row.indicator_id === 0">{{scope.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Target" prop="target">
                        <template slot-scope="scope">
                            <span  slot="reference">{{ scope.row.target }} {{ scope.row.target_unit }} </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Actiuni" align="center" width="130px">
                        <template slot-scope="scope">
                            <el-button size="mini" type="secondary" icon="el-icon-edit"
                                       @click="handleRowSelect(scope.row)"></el-button>
                            <el-button size="mini" type="danger" icon="el-icon-delete"
                                       @click="handleRowDelete(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-form :model="form" id="indicatorsForm" :rules="rules" ref="indicators" label-position="top" :status-icon="true" :inline-message="true">
            <div class="row">
                <div class="col-sm-12">
                    <h4 class="mb-4 mt-5">Adauga/modifica indicator</h4>
                </div>
                <!--<div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Denumire" prop="name">
                            <el-input v-model="form.name" placeholder="Denumirea indicatorului" clearable type="text" maxlength="200" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>-->
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <el-form-item label="Outcome Proiect" prop="outcome">
                            <el-select v-model="form.outcome" filterable clearable placeholder="Selecteaza" value-key="id" style="width:100%" @change="updateOutput">
                                <el-option v-for="item in outcomes.values"
                                           :key="item.id" :label="item.value"  :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
<!--                <div class="col-sm-12">-->
<!--                    <div class="form-group">-->
<!--                        <el-form-item label="Indicatori Outcome" prop="indicators">-->
<!--                            <el-select v-model="form.outcome_indicators" filterable clearable placeholder="Selecteaza" value-key="id"-->
<!--                                       style="width:100%" @change="updateDisable()" @clear="updateDisable()" :disabled="disableOutcome">-->
<!--                                <el-option v-for="item in outcome_indicators.values"-->
<!--                                           :key="item.id" :label="item.value"  :value="item">-->
<!--                                </el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="col-sm-12">
                    <div class="form-group">
                        <el-form-item label="Output Proiect" prop="output">
                            <el-select v-model="form.output" filterable clearable placeholder="Selecteaza" value-key="id" style="width:100%"
                                       @change="updateDisable()" @clear="updateDisable()" :disabled="disableOutput">
                                <el-option v-for="item in outputs.values"
                                           :key="item.id" :label="item.value"  :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <el-form-item label="Indicatori Output" prop="indicators">
                            <el-select v-model="form.output_indicators" filterable clearable placeholder="Selecteaza" value-key="id" style="width:100%"
                                       @change="updateDisable()" :disabled="disableOutput || form.customIndicator ? true : false">
                                <el-option style="max-width: 900px;" v-for="item in output_indicators.values"
                                           :key="item.id" :label="item.value" :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <el-form-item label="Indicator personalizat" prop="indicators">
                            <el-input v-model="form.customIndicator" clearable placeholder="Adauga un indicator personalizat"
                                      @change="updateDisable()" :disabled="disableCustom2"></el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 form-group">
                    <el-form-item label="Target" prop="target">
                        <el-input v-model="form.target" placeholder="Valoare tinta" clearable type="number" show-word-limit></el-input>
                    </el-form-item>
                </div>
<!--                <div class="col-sm-3">-->
<!--                    <div class="form-group">-->
<!--                        <el-form-item label="Unitate masura" prop="target_unit">-->
<!--                            <el-select v-model="form.target_unit" filterable placeholder="Select" value-key="id" style="width:100%">-->
<!--                                <el-option value="buc" label="buc"></el-option>-->
<!--                                <el-option value="valoare" label="valoare"></el-option>-->
<!--                            </el-select>-->
<!--                        </el-form-item>-->
<!--                    </div>-->
<!--                </div>-->
            </div>
            <div class="row">
                <div class="col-sm-12 text-right mb-5">
                    <el-button @click.prevent="clear">Goleste formularul</el-button>
                    <el-button @click.prevent="addRecord" type="secondary">Aplica modificarile</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex'
    import VueScrollTo from 'vue-scrollto';
    import moment from "moment";
    export default {
        name: "project-indicators-form",
        data(){
            return {
                disableOutcome: false,
                disableOutput: false,
                disableCustom: false,
                disableCustom2: false,
                loading: true,
                count: 0,
                form:
                    {
                        customIndicator:'',
                        count:0
                    },
                rules: {
                    /*name: [
                        {required: true, message: 'Denumirea este obligatorie', trigger: 'blur'},
                        { min: 3, max: 200, message: 'Lungimea trebuie sa fie intre 3 si 200 caractere', trigger: 'blur' }
                    ],
                    outcome: [
                        {required: true, message: 'Outcome-ul este obligatoriu', trigger: 'blur'},
                    ],
                     */
                    output_indicators: [
                        {required: true, message: 'Output-ul este obligatoriu', trigger: 'blur'},
                    ],
                    target: [
                        {required: true, message: 'Target-ul este obligatoriu', trigger: 'blur'},
                    ],
                    // target_unit: [
                    //     {required: true, message: 'Unitatea de masura este obligatorie', trigger: 'blur'},
                    // ],
                    // description: [
                    //     {required: true, message: 'Descrierea este obligatorie', trigger: 'blur'},
                    //     { min: 20, max: 1500, message: 'Lungimea trebuie sa fie intre 20 si 1500 caractere', trigger: 'blur' }
                    // ],
                },
            }
        },
        computed:{
            ...mapGetters({
                project: 'project/project',
                outcomes: 'project/outcomes',
                outputs: 'project/outputs',
                outcome_indicators: 'project/outcomeIndicators',
                output_indicators: 'project/outputIndicators',
                validateIndicators: 'project/validateIndicators',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validateIndicators: function(){
                if(this.validateIndicators) {
                    this.submitForm();
                }
            },
        },
        mounted(){
            this.project.indicators.forEach((row, index) => {
                if (row.output_id === 0) {
                    this.count++;
                }
            });
            this.getOutcomes();
            this.getOutputs();
            this.getOutputIndicators().then(_ => { this.loading = false; });
            this.clear();
        },
        methods:{
            ...mapMutations({
                setValidateIndicators: 'project/setValidateIndicators',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            ...mapActions({
                getOutcomes: 'project/getOutcomes',
                getOutputs: 'project/getOutputs',
                getOutcomeIndicators: 'project/getOutcomeIndicators',
                getOutputIndicators: 'project/getOutputIndicators',
                removeIndicator: 'project/removeIndicator',
                saveProject: 'project/saveProject'
            }),
            updateForm() {
                this.saveProject(this.project).then((result) => {
                    if(result.valid === false){
                        this.$notify({ type:'error', title: 'Eroare', message: result.message });
                        reject(false);
                    }
                });
            },
            updateDisable(){
                this.disableOutput = !!(this.form.outcome_indicators && this.form.outcome_indicators.value !== '');
                this.disableOutcome = !!(this.form.output && this.form.output.value !== '');
                this.disableCustom = this.disableOutput || this.disableOutcome;
                if (this.form.output_indicators !== undefined) {
                    if (this.form.output_indicators && this.form.output_indicators.value !== undefined && this.form.output_indicators.value.length > 1) {
                        this.disableCustom2 = true;
                    } else {
                        this.disableCustom2 = false;
                    }
                }
                if(this.form.customIndicator && this.form.customIndicator.length > 1){
                    this.disableOutput = this.disableOutcome = true;
                    // this.form.outcomeIndicators = null;
                    // this.form.output = null;
                    this.form.output_indicators = null;
                }
            },
            updateOutput(){
                if(this.form.outcome) this.getOutputs(this.form.outcome.id);
                this.getOutcomeIndicators();
            },
            addRecord(){
                this.$refs['indicators'].validate((valid) => {
                    if (valid) {
                        let counter = 0; // numaram indicatorii personalizati
                        this.project.indicators.forEach((indicator) => {
                            if(indicator.indicator_id === 0){
                                counter++;
                            }
                        });
                        if(!this.form.output_indicators){
                            counter++;
                        }
                        // if (this.form.customIndicator.length > 1) {
                        //    this.count++;
                        // }
                        if (this.form.output_indicators === undefined) {
                            this.$notify({ type: 'error', title: 'Indicatori proiect', message: 'Indicator output obligatoriu!'});
                            return false;
                        }
                        if (counter > 2) {
                            this.$notify({ type: 'error', title: 'Indicatori proiect', message: 'Ai adaugat deja 2 indicatori personalizati'});
                            return false;
                        }
                        if(this.form.indicator > this.project.indicators.length) {
                            this.project.indicators.push(this.form);
                        }else{
                            this.project.indicators[this.form.indicator - 1] = this.form
                        }
                        this.saveProject(this.project);
                        this.clear();
                        this.setValidateIndicators(false);
                        VueScrollTo.scrollTo('#indicatorsList');
                        return true;
                    } else {
                        return false;
                    }
                });
            },
            handleRowSelect(row){
                this.form = row;
                this.updateOutput();
                this.updateDisable();
                VueScrollTo.scrollTo('#indicatorsForm');
            },
            clear(){
                this.form = {
                    indicator: this.project.indicators.length + 1,
                    customIndicator: ''
                };
                this.disableOutcome = false;
                this.disableOutput =  false;
                this.disableCustom = false;
                this.disableCustom2 = false;
            },
            handleRowDelete(index, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi aceasta inregistrare? Acest proces este ireversibil!')
                    .then(_ => {
                        if (index !== -1) {
                            this.loading = true;
                            this.removeIndicator({id : this.project.id, line: row.id}).then((response) => {
                                this.loading = false;
                                this.project.indicators = response.data.indicators;
                                this.$notify({ type: 'success', title: 'Indicatori', message: 'Inregistrarea a fost stearsa cu succes!'});
                                this.clear();
                            });
                        }
                    }).catch(err => {
                        console.log(err)
                    });
            },
            submitForm() {
                if (this.project.indicators.length > 0) {
                    this.setValidateIndicators(false);
                    this.setValidationErrorsExists(false);
                    return true;
                } else {
                    this.setValidationErrorsExists(true);
                    this.$notify({ type: 'warning', title: 'Indicatori proiect', message: 'Trebuie sa adaugati cel putin un indicator!'});
                    return false;
                }
            },
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
