<template>
    <div>
        <div class="row">
            <div class="col-sm-22">
                <h3>
                    Centralizator Resurse Umane
                </h3>
            </div>
        </div>
        <!--        <div class="row">-->
        <!--            <div class="col-sm-2">-->
        <!--                <label>Grafic</label>-->
        <!--                <p v-if="reporting.graph.reporting_id">{{reporting.graph.reporting_id}}</p>-->
        <!--            </div>-->
        <!--            <div class="col-sm-2">-->
        <!--                <label>Tip raport</label>-->
        <!--                <p v-if="reporting.type_report === 1">Intermediar</p><p v-else>Final</p>-->
        <!--            </div>-->
        <!--            <div class="col-sm-2">-->
        <!--                <label>Perioadă raportată</label>-->
        <!--                <p>{{formatUserDate(reporting.start_date)}} - {{formatUserDate(reporting.end_date)}}</p>-->
        <!--            </div>-->
        <!--            <div class="col-sm-2">-->
        <!--                <label>Sume încasate</label>-->
        <!--                <p>{{reporting.collected}}</p>-->
        <!--            </div>-->
        <!--            <div class="col-sm-2">-->
        <!--                <label>Data încasării</label>-->
        <!--                <p>{{reporting.collected_date}}</p>-->
        <!--            </div>-->
        <!--        </div>-->
        <div class="row mt-3" v-for="activity in reporting.hr">
            <div class="col-sm-12">
                <h6>Activitatea {{ activity.id}}: {{ activity.name }}</h6>
                <!--                {{ activity.reporting }}-->
                <el-table size="mini"
                          :data="activity.data"
                          style="width: 100%">
                    <el-table-column show-overflow-tooltip prop="category.value" label="Luna" width="80">
                        <template slot-scope="scope">
                            {{ formatMonthFromNumber(parseInt(scope.row.month)-1) }}
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="Cod Promotor / Partener" width="200">
                        <template slot-scope="scope">
                            <span v-if="scope.row.position !== null">{{ scope.row.position.position }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="Activitatea" width="200">
                        <template slot-scope="scope">
                            <span v-if="scope.row.position !== null">{{ scope.row.position.position }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="Pozitia" width="200">
                        <template slot-scope="scope">
                            <span v-if="scope.row.position !== null">{{ scope.row.position.position }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Nume si prenume" align="left">
                        <template slot-scope="scope">
                            <span v-if="scope.row.position !== null">{{ scope.row.position.first_name }} {{ scope.row.position.last_name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="CNP" align="left" width="120">
                        <template slot-scope="scope" v-if="scope.row.position !== null">
                            {{ scope.row.position.cnp }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Nr. si data CIM / Nr. si data ctr. voluntariat" align="left" width="120">
                        <template slot-scope="scope" v-if="scope.row.position !== null">
                            {{ scope.row.position.cnp }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Salariu brut in luna conform CIM pe organizatie si actelor aditionale ulterioare" align="left"  header-align="center" width="90">
                        <template slot-scope="scope" v-if="scope.row.position !== null">
                            <span v-if="scope.row.position.workload">{{ scope.row.position.workload.value }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Ore lucratoare in luna conform contractului individual de munca (CIM)" align="center" header-align="center">
                        <template slot-scope="scope" v-if="scope.row.position !== null">
                            {{ scope.row.position.hours }}
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip align="center" label="Ore efectiv lucrate">
                        <template slot-scope="scope">
                            {{ scope.row.month_hours }}
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip align="center" label="Costul total raportat in luna aferent orelor lucrate">
                        <template slot-scope="scope">
                            {{ scope.row.worked_hours }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Cost orar raportat in luna / activitate" align="center" header-align="center">

                    </el-table-column>
                    <el-table-column label="Cost orar unitar bugetat" align="center" header-align="center">
                        <template slot-scope="scope">
                            {{ scope.row.worked_hours - scope.row.month_hours }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Abatere de la costul orar bugetat" align="right"  header-align="center">
                        <template slot-scope="scope" v-if="scope.row.position !== null">
                            {{ formatPrice(scope.row.position.hourly) }}
                        </template>
                    </el-table-column>

                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';

    export default {
        name: "reporting-hr-form",
        mixins:[mixin],
        props:{
            project: {
                required: true,
                type: Object
            },
            reporting: {
                required: true,
                type: Object
            },
        },
        computed:{
            ...mapGetters({

            }),
        },
        mounted(){

        },
        methods:{

        }
    }
</script>

<style scoped>

</style>
