<template>
    <div>
        <div class="row">
            <div class="col-sm-6">
                <label>Denumire promotor</label>
                <p>{{promoter.name}}</p>
            </div>
            <div class="col-sm-6">
                <label>Denumire promotor in limba engleza</label>
                <p>{{promoter.name_en}}</p>
            </div>
            <div class="col-sm-6"></div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label>CIF</label>
                <p>{{promoter.fiscal_attribute}} {{promoter.cif}}</p>
            </div>
            <div class="col-sm-3">
                <label>Nr. inregistrare</label>
                <p>{{promoter.register_no}}</p>
            </div>
            <div class="col-sm-3">
                <label>Forma juridica</label>
                <p v-if="promoter.type">{{promoter.type.value}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <label>Adresa</label>
                <p>{{promoter.address}}</p>
            </div>
            <div class="col-sm-3">
                <label>Oras</label>
                <p v-if="promoter.city">{{promoter.city.name}}</p>
            </div>
            <div class="col-sm-3">
                <label>Judet</label>
                <p v-if="promoter.city">{{promoter.city.county.name}}</p>
            </div>
            <div class="col-sm-3">
                <label>Localizare sediu social</label>
                <p v-if="promoter.location_type">{{promoter.location_type}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label>Telefon</label>
                <p><a :href="urlPhone(promoter.phone)">{{promoter.phone}}</a></p>
            </div>
            <div class="col-sm-3">
                <label>Email</label>
                <p><a :href="urlEmail(promoter.email)">{{promoter.email}}</a></p>
            </div>
            <div class="col-sm-3">
                <label>Website</label>
                <p><a :href="promoter.website" target="_blank">{{promoter.website}}</a></p>
            </div>
        </div>
    </div>
</template>
<script>
    import mixin from './../../mixins/common';
    export default {
        name: "promoter-details-pane",
        mixins:[mixin],
        props:{
            promoter: {
                required: true,
                type: Object
            }
        },
        methods:{
            tel(phone){
                return "tel:" + phone
            },
            email(email){
                return "mailto:" + email
            }
        }
    }
</script>

<style scoped>

</style>
