<template>
    <div>
        <div class="row mb-5">
            <div class="col-sm-6">
                <h4>Template</h4>
                <el-select v-model="allTemp" placeholder="Selecteaza" clearable style="width:100%"
                           @change="updateTable" value-key="id">
                    <el-option v-for="item in templates"
                               :key="item.id" :label="'ID: ' + item.id + ' / Subject: ' + item.subject" :value="item">
                        <span style="float: left">ID: {{ item.id }} / Titlu: {{ item.subject }}</span>
                    </el-option>
                </el-select>
            </div>
        </div>
        <hr>
        <h3 class="mb-4">Trimite catre</h3>
        <div class="row mb-5">
            <div class="col-sm-6">
                <h4>Grupuri</h4>
                <el-select v-model="allGroups" placeholder="Selecteaza" multiple clearable style="width:100%"
                           value-key="id">
                    <el-option v-for="item in groups"
                               :key="item.id" :label="item.name" :value="item">
                        <span style="float: left">{{ item.name }}</span>
                    </el-option>
                </el-select>
            </div>
            <div class="col-sm-6 form-group">
                <h4>Utilizatori</h4>
                <el-select v-model="allUsers" placeholder="Introduceti numele" multiple filterable remote
                           style="width:100%" value-key="id">
                    <el-option v-for="item in users" :key="item.id" :label="item.first_name + ' ' + item.last_name" :value="item">
                        <span style="float: left">{{ item.first_name }} {{ item.last_name }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px" v-for="(role, i) in item.all_roles">{{ role.display_name }}<span v-if="i">/</span></span>
                    </el-option>
                </el-select>
            </div>
        </div>
        <hr>
        <el-form :model="form" :rules="rules" ref="account" label-position="top" :status-icon="true" :inline-message="true">
            <h3 class="mb-4">Redactare</h3>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Subiect" prop="subject" :error="validationErrors.get('subject')">
                            <el-input v-model="form.subject" placeholder="Subiectul mailului" clearable
                                  type="text" show-word-limit disabled></el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div id="app">
                <vue-editor v-model="form.html_template" disabled></vue-editor>
            </div>
        </el-form>
        <div class="row mt-4">
            <div class="col-lg-6">
                <button class="btn btn-primary" @click="send()" :disabled="isDisabled">Trimite!</button>
            </div>
        </div>
        <hr>
        <small>
            <b>INFO!</b></br>
            {{ name }} - Se inlocuieste automat cu numele fiecarui utilizator ce primeste mesajul.</br>
            {{ email }} - Se inlocuieste automat cu mail-ul fiecarui utilizator ce primeste mesajul.</br>
            {{ phonenr }} - Se inlocuieste automat cu numarul de telefon al fiecarui utilizator ce primeste mesajul.</br>
            EX: "Buna ziua dl. {{ name }}, cum va simtiti astazi?" -> REZULTAT: "Buna ziua dl. Stere Cristian, cum va simtiti astazi?"
        </small>
    </div>
</template>

<script>
    import { VueEditor, Quill } from "vue2-editor";
    import { mapGetters, mapMutations, mapActions } from 'vuex'
    import mixin from './../../mixins/common';

    export default {
        name: "account-form",
        mixins:[mixin],
        props:{
            users:{
                required: true,
                type: Array,
            },
            role:{
                required: false,
                type: String,
            },
            templates:{
                required: true,
                type: Array,
            },
            groups:{
                required: true,
                type: Array,
            }
        },
        components: {
            VueEditor
        },
        data(){
            return {
                form: {},
                allTemp: '',
                name: '{{ name }}',
                email: '{{ email }}',
                phonenr: '{{ phonenr }}',
                allGroups: null,
                isDisabled: false,
                allUsers: null,
                notification_icon: 'icofont-ui-password',
                rules: {
                    subject: [
                        { required: true, message: 'Completati subiectul mailului', trigger: 'blur' },
                        { min: 3, message: 'Lungimea trebuie sa fie de cel putin 3 caractere', trigger: 'blur' }
                    ],
                    html_template: [
                        //Pus pe pauza temporar, pana implementez editorul HTML
                        { required: true, message: 'Completati mesajul mailului', trigger: 'blur' },
                        { min: 3, message: 'Lungimea trebuie sa fie de cel putin 3 caractere', trigger: 'blur' }
                    ]
                },
            }
        },
        computed:{
            ...mapGetters({
                validationErrors: 'validationErrors',
                validationErrorsExists: 'validationErrorsExists'
            }),
        },
        methods: {
            ...mapMutations({
                setValidationErrors: 'setValidationErrors',
            }),
            send()
            {
                if(!this.allTemp.id) return this.$notify({type: 'error', title: 'Mail', message: 'Nu ai ales niciun template pentru acest mail!'});
                if(!this.allUsers.length && !this.allGroups.length) return this.$notify({type: 'error', title: 'Mail', message: 'Nu ai ales niciun destinatar pentru acest mail!'});
                // this.isDisabled = true;
                axios.post('/send', {users: this.allUsers, groups: this.allGroups, tid: this.allTemp.id }).then((result) => {
                    this.$notify({
                        type: 'success',
                        title: 'Mail',
                        message: 'Mail-ul a fost trimis cu succes catre '+this.allGroups.length+(this.allGroups.length == 1 ? (' grup') : (' grupuri'))+' si '+this.allUsers.length+(this.allUsers.length == 1 ? (' utilizator!') : (' utilizatori!'))
                    });
                    // window.location.href = '/sendemail';
                });
            },
            updateTable(){
                if(this.allTemp) this.form = this.allTemp, this.$notify({ type:'success', title: 'Template mail', message: 'Template-ul a fost selectat cu succes!' });
                else this.form = {};
            }
        }
    }
</script>

<style scoped>

</style>
