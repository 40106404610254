<template>
    <div>
        <el-drawer v-if="typeof this.showUploadDrawer2.auxDocuments !== 'undefined'" title="Fișiere" :visible.sync="showUploadDrawer2.visible" direction="rtl" :wrapperClosable="false" :before-close="handleCloseDrawer" :destroy-on-close="true">
            <div class="px-4">
                <el-form :model="form" ref="" v-if="formchanged" label-position="top" :status-icon="true" :inline-message="true">
                    <p class="alert alert-info" v-if="typeof this.showUploadDrawer2.canUpload === 'undefined'">{{ infoMessage }}</p>
                    <h5>{{ showUploadDrawer2.title ? showUploadDrawer2.title : title }}</h5>
                    <el-upload ref="upload" action="/api/upload" :data="showUploadDrawer2"
                               :with-credentials="true" :auto-upload="true"
                               :headers="{ 'X-CSRF-TOKEN': csrf}"
                               :multiple="!showUploadDrawer2.multiple ? false : true"
                               :accept="showUploadDrawer2.acceptFiles ? showUploadDrawer2.acceptFiles : acceptFiles"
                               :on-remove="handleRemove" :before-remove="beforeRemove" :on-preview="handlePreview"
                               :on-error="handleUploadError" :on-success="handleUploadSuccess" :on-exceed="handleOnExceed"
                               :limit="showUploadDrawer2.multiple === false ? 1 : 10"
                               :file-list="showUploadDrawer2.files">
                        <el-button v-if="typeof this.showUploadDrawer2.canUpload === 'undefined'" slot="trigger" size="small" type="secondary">{{ btnSelectText }}</el-button>
    <!--                    <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">{{ btnImportText }}</el-button>-->
                        <div class="el-upload__tip" slot="tip">{{ infoTip }}</div>
                    </el-upload>
                    <hr />
                    <small>
                    <div class="form-group">
                        <h5>Lista comentarii:</h5>
                        <div class="feed-element" v-for="(row, index) in comments" :key="index"> <!-- v-if="!(row.user_id !== user_data && row.reply_to_id === -1)" -->
                            <div class="media-body">
                                <i class="icofont-girl" style="font-size:100%"></i> <b v-if="row.user && typeof row.user.promoter[0] !== 'undefined'">Promotor</b><b v-else>Operator de Fond</b>: {{ row.comment }}<br>
                                <small class="text-muted green"><i class="icofont-clock-time"></i> {{ formatUserDate(row.created_at) }}</small>

                                <el-button v-if="row.user_id === user_data && row.reply_to_id === -1" class="float-right ml-2" size="mini" type="primary" icon="el-icon-edit"
                                    @click="innitEditComment(row)">Editeaza</el-button>
                                <el-button v-if="row.user_id === user_data && row.reply_to_id === -1" class="float-right ml-2" size="mini" type="danger" icon="el-icon-delete"
                                    @click="removeComment(index, row)">Sterge</el-button>

                                <p v-if="row.files !== undefined && row.files.length && row.reply_to_id !== -1" class="text-right">
                                    <small>
                                        Fisiere incarcate:
                                        <span v-for="doc in row.files">
                                            <br/><a :href="'/getFile/' + doc.id" target="_blank">{{ doc.file_name }}</a>
                                        </span>
                                    </small>
                                </p>
                            </div>
                            <hr>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Lista comentarii:</label>
                        <el-input v-model="form.comentariu"  placeholder="Adauga comentariul tau referitor la validare" type="textarea"></el-input>
                    </div>
                    <el-button size="mini" type="secondary" icon="el-icon-plus"
                       @click="addComment()" :disabled="form.comentariu && !isOFFButton ? false : true">Adauga comentariu</el-button>
                    </small>
                </el-form>
            </div>
        </el-drawer>
        <el-drawer v-else title="Fișiere" :visible.sync="showUploadDrawer2.visible" direction="rtl" :wrapperClosable="false" :before-close="handleCloseDrawer" :destroy-on-close="true">
            <div class="px-4">
                <p v-if="typeof this.showUploadDrawer2.canUpload === 'undefined'" class="alert alert-info">{{ infoMessage }}</p>
                <h5>{{ showUploadDrawer2.title ? showUploadDrawer2.title : title }}</h5>
                <el-upload ref="upload" action="/api/upload" :data="showUploadDrawer2"
                           :with-credentials="true" :auto-upload="true"
                           :headers="{ 'X-CSRF-TOKEN': csrf}"
                           :multiple="!showUploadDrawer2.multiple ? false : true"
                           :accept="showUploadDrawer2.acceptFiles ? showUploadDrawer2.acceptFiles : acceptFiles"
                           :on-remove="handleRemove" :before-remove="beforeRemove" :on-preview="handlePreview"
                           :on-error="handleUploadError" :on-success="handleUploadSuccess" :on-exceed="handleOnExceed"
                           :limit="showUploadDrawer2.multiple === false ? 1 : 10"
                           :file-list="showUploadDrawer2.files">
                    <el-button v-if="typeof this.showUploadDrawer2.canUpload === 'undefined'" slot="trigger" size="small" type="secondary">{{ btnSelectText }}</el-button>
                    <!--<el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">{{ btnImportText }}</el-button>-->
                    <div class="el-upload__tip" slot="tip">{{ infoTip }}</div>
                </el-upload>
            </div>
        </el-drawer>

        <!-- Editare comentariu -->
        <el-dialog title="Editare comentariu" :visible.sync="dialogEditComment">
          <div class="form-group">
                <label>Scrie mai jos noul comentariu:</label>
                <el-input v-model="form.new_comment" placeholder="Scrie aici..." type="textarea"></el-input>
            </div>
            <el-button size="mini" type="primary"
               @click="editCommentAction()" :disabled="form.new_comment ? false : true"><i class="icofont-edit"></i> Editeaza comentariul</el-button>
        </el-dialog>
    </div>
</template>

<script>
    import {showUploadDrawer2} from "../../store/getters";
    import {mapGetters,mapMutations,mapActions} from "vuex";
    import mixin from './../../mixins/common';
    export default {
        name: "upload-drawer-simple",
        mixins:[mixin],
        data() {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                title: 'Import fișier',
                infoMessage: 'Încărcați numai fișiere de tip .pdf, cu dimensiunea maximă de 2MB',
                infoTip: 'Sunt permise doar fișiere pdf cu dimensiunea mai mică de 2MB',
                btnImportText: 'Importă',
                btnSelectText: 'Selectează fișier(e)',
                acceptFiles: '.pdf,.docx,.doc',
                model: '',
                model_id: 0,
                fileList: [],
                showComments: true,
                showDrawer: false,
                formchanged: false,
                form: {
                    new_comment: null
                },
                dialogEditComment: false,
                updateComment: {},
                isOFFButton: false,
                comments: [],
            }
        },
        props:{
            user_data: {
                required: false,
            }
        },
        watch:{
            showUploadDrawer2:function(){
                if(this.showUploadDrawer2.visible === true && typeof this.showUploadDrawer2.auxDocuments !== 'undefined'){
                    this.placeData(this.showUploadDrawer2.object);
                }
            },
        },
        computed:{
            ...mapGetters({
                showUploadDrawer2: 'showUploadDrawer2',
                reporting: 'reportings/reporting',
            }),
        },
        methods:{
            ...mapActions({
                removeUploadedFile: 'removeUploadedFile',
                saveCommentDOC: 'reportings/saveCommentDOC',
                saveCommentOnDOC2: 'reportings/saveCommentOnDOC2',
                editComment: 'reportings/editComment',
                deleteComment: 'reportings/deleteComment',
            }),
            ...mapMutations({
                setShowUploadDrawer2: 'setShowUploadDrawer2',
            }),
            placeData: function(e) {
                if (e !== null || e !== undefined) {
                    this.comments = e.comments;
                    this.form.id = e.id;
                }
                this.formchanged = true;
            },
            removeComment(line, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi acest comentariu?')
                    .then(_ => {
                        this.loading = true;
                        this.comments.splice(line, 1);
                        this.deleteComment({
                            id: row.id,
                        }).then(_ => {
                                this.loading = false;
                                this.$notify({
                                type: 'success',
                                title: 'Stergere comentariu',
                                message: 'Comentariul a fost sters cu succes din lista!'
                            });
                        });
                    }).catch(err => {
                    console.log(err)
                });
            },
            innitEditComment(row){
                this.dialogEditComment = true;
                this.form.new_comment = row.comment;
                this.form.id_comment = row.id;
                this.updateComment = row;
            },
            editCommentAction(){
                this.loading = true;
                this.dialogEditComment = false;
                this.editComment({
                    id: this.form.id_comment,
                    comment: this.form.new_comment,
                }).then(_ => {
                        this.updateComment.comment = this.form.new_comment;
                        this.loading = false;
                        this.$notify({
                        type: 'success',
                        title: 'Editare comentariu',
                        message: 'Comentariul a fost editat cu succes!'
                    });
                });
            },
            addComment()
            {
                this.isOFFButton = true;
                this.loading = true;
                if(typeof this.showUploadDrawer2.canUpload !== 'undefined' && this.showUploadDrawer2.canUpload === 2)
                {
                    this.saveCommentOnDOC2({
                        comentariu: this.form.comentariu,
                        id: this.form.id
                    }).then((result) => {
                        this.form.comentariu = "";
                        this.isOFFButton = false;
                        this.loading = false;
                        this.comments.push(result.comm);
                        this.$notify({ type: 'success', title: 'Ai adaugat un comentariu', message: 'Comentariul a fost adaugat cu succes!'});
                    });
                }
                else {
                    this.saveCommentDOC({
                        comentariu: this.form.comentariu,
                        id: this.form.id
                    }).then((result) => {
                        this.form.comentariu = "";
                        this.isOFFButton = false;
                        this.loading = false;
                        this.comments.push(result.comm);
                        this.$notify({ type: 'success', title: 'Ai adaugat un comentariu', message: 'Comentariul a fost adaugat cu succes!'});
                    });
                }
            },
            beforeRemove(file, fileList) {
                if(typeof this.showUploadDrawer2.canDelete !== 'undefined') {
                    //this.handleCloseDrawer();
                    this.$notify({ type: 'warning', title: 'Atentie', message: "Nu ai permisiunea sa stergi acest fisier! (Fisierele incarcate nu mai pot fi sterse din aceasta sectiune)"});
                    return 1;
                }
                return this.$confirm(`Ești sigur(ă) că vrei să ștergi fișierul ${ file.name } din listă ?`);
            },
            handleRemove(file, fileList) {
                if(typeof this.showUploadDrawer2.canDelete !== 'undefined') {
                    this.handleCloseDrawer();
                    return 1;
                }
                if (this.showUploadDrawer2.canDelete === 0) {
                    this.handleCloseDrawer();
                    return 1;
                }
                // console.log(file);
                if(typeof this.showUploadDrawer2.updateCommentFiles !== 'undefined') this.showUploadDrawer2.comments.files.splice(file, 1);
                if(typeof this.showUploadDrawer2.updateDOCFiles !== 'undefined') this.showUploadDrawer2.reporting.documents_admin.splice(file, 1);
                if(typeof this.showUploadDrawer2.updateAfterCloseSignedDocument !== 'undefined') this.showUploadDrawer2.notification.signed_document_admin.splice(file, 1);
                if(typeof this.showUploadDrawer2.VRF !== 'undefined') this.showUploadDrawer2.reporting.documents_vrf.splice(file, 1);
                if(typeof this.showUploadDrawer2.VRT !== 'undefined') this.showUploadDrawer2.reporting.documents_vrt.splice(file, 1);
                if(typeof this.showUploadDrawer2.InfoAddress !== 'undefined') this.showUploadDrawer2.reporting.documents_info_address.splice(file, 1);
                if(file.response) {
                    this.removeUploadedFile(file.response.file_id);
                }else if(file.id){
                    this.removeUploadedFile(file.id);
                }
            },
            handlePreview(file, fileList) {
                window.open('/getFile/'+file.id, '_blank');
            },
            handleUploadError(file){
                console.log(file);
            },
            handleOnExceed(){
                this.$notify({ type: 'warning', title: 'Import', message: 'Este permisa incarcarea unui singur fisier!'});
            },
            handleUploadSuccess(response, file, fileList){
                if(response.errors){
                    let messages = Object.values(response.errors).flat();
                    this.$notify({ type: 'warning', title: 'Import', message: messages.toString()});
                }else if(response.success === true){
                    if(typeof this.showUploadDrawer2.updateCommentFiles !== 'undefined') this.showUploadDrawer2.comments.files.push(file);
                    if(typeof this.showUploadDrawer2.updateDOCFiles !== 'undefined') this.showUploadDrawer2.reporting.documents_admin.push(file);
                    if(typeof this.showUploadDrawer2.updateAfterCloseSignedDocument !== 'undefined') this.showUploadDrawer2.notification.signed_document_admin.push(file);
                    if(typeof this.showUploadDrawer2.VRF !== 'undefined') this.showUploadDrawer2.reporting.documents_vrf.push(file);
                    if(typeof this.showUploadDrawer2.VRT !== 'undefined') this.showUploadDrawer2.reporting.documents_vrt.push(file);
                    if(typeof this.showUploadDrawer2.InfoAddress !== 'undefined') this.showUploadDrawer2.reporting.documents_info_address.push(file);
                    file.id = file.response.file_id;
                    this.$notify({ type: 'success', title: 'Import', message: "Fisierul a fost importat cu success!"});
                    // this.$refs.upload.clearFiles();
                }
            },
            handleCloseDrawer(done){
                this.setShowUploadDrawer2({
                    visible: false,
                });
            },
            submitUpload(){
                this.$refs.upload.submit();
            }
        }
    }
</script>

<style scoped>

</style>
