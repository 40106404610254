<template>
    <div v-if="project">
        <h3 class="mb-4 mt-3">Obiective</h3>
        <div class="row">
            <div class="col-sm-12">
                <el-table :data="project.objectives" id="objectivesList" stripe fit highlight-current-row style="width:100%">
                    <el-table-column label="Obiectiv" width="100px" align="center">
                        <template slot-scope="scope">
                            O{{ scope.$index+1 }}
                        </template>
                    </el-table-column>
<!--                    <el-table-column label="Denumire" prop="name"></el-table-column>-->
                    <el-table-column label="Denumire" prop="description">
                        <template slot-scope="scope">
                            <el-popover placement="top-start" title="Descriere" width="300" trigger="click" :content="scope.row.description">
                                <span  slot="reference">{{ scope.row.description | truncate(100, '...') }}</span>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="Actiuni" align="center" width="130px">
                        <template slot-scope="scope">
                            <el-button size="mini" type="secondary" icon="el-icon-edit"
                                       @click="handleRowSelect(scope.row)"></el-button>
                            <el-button size="mini" type="danger" icon="el-icon-delete"
                                       @click="handleRowDelete(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-form :model="form" id="objectivesForm" :rules="rules" ref="objectives" label-position="top" :status-icon="true" :inline-message="true">
            <div class="row">
                <div class="col-sm-12">
                    <h4 class="mb-4 mt-5">Adauga/modifica obiectiv</h4>
                </div>
<!--                <div class="col-sm-6">-->
<!--                    <div class="form-group">-->
<!--                        <el-form-item label="Denumire" prop="name">-->
<!--                            <el-input v-model="form.name" placeholder="Denumirea obiectivului" clearable type="textarea" maxlength="200" show-word-limit></el-input>-->
<!--                        </el-form-item>-->
<!--                    </div>-->
<!--                </div>-->
                <div class="col-sm-6 form-group">
                    <el-form-item label="Denumirea obiectivului" prop="description">
                        <el-input v-model="form.description" placeholder="Denumirea obiectivului" clearable
                                  type="textarea" :rows="6" maxlength="1500" show-word-limit>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-12 text-right mb-5">
                    <el-button @click.prevent="clear">Goleste formularul</el-button>
                    <el-button @click.prevent="addRecord" type="secondary">Aplica modificarile</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "project-objectives-form",
        data(){
            return {
                form:{},
                rules: {
                    // name: [
                    //     {required: true, message: 'Denumirea este obligatorie', trigger: 'blur'},
                    //     { min: 3, max: 200, message: 'Lungimea trebuie sa fie intre 3 si 200 caractere', trigger: 'blur' }
                    // ],
                    description: [
                        {required: true, message: 'Descrierea este obligatorie', trigger: 'blur'},
                        { min: 20, max: 1500, message: 'Lungimea trebuie sa fie intre 20 si 1500 caractere', trigger: 'blur' }
                    ],
                },
            }
        },
        computed:{
            ...mapGetters({
                project: 'project/project',
                validateObjectives: 'project/validateObjectives',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validateObjectives: function(){
                if(this.validateObjectives) {
                    this.submitForm();
                }
            },
        },
        mounted(){
            this.clear();
        },
        methods:{
            ...mapMutations({
                setValidateObjectives: 'project/setValidateObjectives',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            ...mapActions({
                saveProject: 'project/saveProject',
                removeObjective: 'project/removeObjective'
            }),
            addRecord(){
                this.$refs['objectives'].validate((valid) => {
                    if (valid) {
                        this.form.name = this.form.description.substring(1,200); //
                        if(this.form.objective > this.project.objectives.length) {
                            this.project.objectives.push(this.form);
                        }else{
                            this.project.objectives[this.form.objective - 1] = this.form
                        }
                        this.clear();
                        this.setValidateObjectives(false);
                        this.saveProject(this.project);
                        VueScrollTo.scrollTo('#objectivesList');
                        return true;
                    } else {
                        return false;
                    }
                });
            },
            handleRowSelect(row){
                this.form = row;
                VueScrollTo.scrollTo('#objectivesForm');
            },
            clear(){
                this.form = {objective: this.project.objectives.length + 1}
            },
            handleRowDelete(index, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi aceasta inregistrare?')
                    .then(_ => {
                        if (index !== -1) {
                            if(row.id > 0) {
                                this.removeObjective({id : this.project.id, objective: row.id});
                                this.project.objectives.splice(index, 1);
                            }else {
                                this.project.objectives.splice(index, 1);
                            }
                            this.clear();
                        }
                    }).catch(err => {
                        console.log(err)
                    });
            },
            submitForm() {
                if (this.project.objectives.length > 0) {
                    this.setValidateObjectives(false);
                    this.setValidationErrorsExists(false);
                    return true;
                } else {
                    this.setValidationErrorsExists(true);
                    this.$notify({ type: 'warning', title: 'Obiective proiect', message: 'Trebuie sa adaugati cel putin un obiectiv!'});
                    return false;
                }
            },
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
