<template>
    <div>
        <form-wizard @on-complete="onComplete" @on-loading="setLoading" :startIndex="section"
                     shape="tab" stepSize="xs" color="#3BB273" title="" subtitle="" layout="vertical"
                     error-color="#E15554" v-loading="loading">
            <tab-content  title="Date identificare" :before-change="validateDetails">
                <project-details-form :data="project"></project-details-form>
            </tab-content>
            <tab-content title="Descriere" :before-change="validateDescription">
                <project-description-form></project-description-form>
            </tab-content>
            <tab-content  title="Activitati" :before-change="validateActivities">
                <project-activities-form></project-activities-form>
            </tab-content>
            <tab-content  title="Documente" :before-change="validateDocuments">
                <project-documents-form></project-documents-form>
            </tab-content>
            <tab-content  title="Parteneri" :before-change="validatePartners">
                <project-partners-form></project-partners-form>
            </tab-content>
            <tab-content  title="Obiective" :before-change="validateObjectives">
                <project-objectives-form></project-objectives-form>
            </tab-content>
            <tab-content  title="Indicatori de program" :before-change="validateIndicators">
                <project-indicators-form></project-indicators-form>
            </tab-content>
            <tab-content  title="Calendar activitati" :before-change="validateCalendar">
                <project-calendar-form></project-calendar-form>
            </tab-content>
            <tab-content  title="Validare" :before-change="validateValidation">
                <project-validation-form></project-validation-form>
            </tab-content>

            <template slot="footer" slot-scope="props">
                <div class=wizard-footer-left>
                    <a href="/projects" class="ml-2 btn btn-default" v-if="props.activeTabIndex == 0" >Anuleaza, m-am razgandit</a>
                    <wizard-button  v-if="props.activeTabIndex > 0"  @click.native="props.prevTab()"  :style="props.fillButtonStyle">Inapoi</wizard-button>
                </div>
                <div class="wizard-footer-right">
                    <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="btn btn-default" :style="props.fillButtonStyle">Inainte</wizard-button>
                    <wizard-button v-else @click.native="props.nextTab()" class="wizard-footer-right finish-button" :style="props.fillButtonStyle" :disabled="project.status_technical != 9 && props.isLastStep">{{props.isLastStep ? 'Finalizeaza' : 'Inainte'}}</wizard-button>
                </div>
            </template>
        </form-wizard>
        <upload-drawer></upload-drawer>
    </div>
</template>

<script>
    import {FormWizard, TabContent, WizardButton} from 'vue-form-wizard';
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import ProjectDetailsForm from "./form-details";
    import ProjectDescriptionForm from "./form-description";
    import ProjectActivitiesForm from "./form-activities";
    import ProjectDocumentsForm from "./form-documents";
    import ProjectPartnersForm from "./form-partners";
    import ProjectObjectivesForm from "./form-objectives";
    import ProjectIndicatorsForm from "./form-indicators";
    import ProjectCalendarForm from "./form-calendar";
    import ProjectValidationForm from "./form-technical-validation";
    import UploadDrawer from "../common/upload-drawer";
    import {mapGetters, mapActions, mapMutations} from 'vuex'

    export default {
        name: "project-wizard",
        components: {
            ProjectDetailsForm,
            ProjectDescriptionForm,
            ProjectActivitiesForm,
            ProjectDocumentsForm,
            ProjectPartnersForm,
            ProjectObjectivesForm,
            ProjectIndicatorsForm,
            ProjectCalendarForm,
            ProjectValidationForm,
            UploadDrawer,
            FormWizard,
            TabContent,
            WizardButton
        },
        props:{
            data: {
                required: true,
                type: Object
            },
            section:{
                required: false,
                default: 0,
                type: Number
            }
        },
        data(){
            return {
                loading: false,
            }
        },
        computed:{
            ...mapGetters({
                project: 'project/project',
                validationErrorsExists: 'validationErrorsExists',
            })
        },
        created(){
            // this.startIndex = this.section ? this.section : 0;
            this.setProject(this.data);
        },
        methods: {
            ...mapActions({
                saveProject: 'project/saveProject',
            }),
            ...mapMutations({
                setProject: 'project/setProject',
                setValidateDetails: 'project/setValidateDetails',
                setValidateDescription: 'project/setValidateDescription',
                setValidateActivities: 'project/setValidateActivities',
                setValidateDocuments: 'project/setValidateDocuments',
                setValidatePartners: 'project/setValidatePartners',
                setValidateObjectives: 'project/setValidateObjectives',
                setValidateIndicators: 'project/setValidateIndicators',
                setValidateCalendar: 'project/setValidateCalendar',
                setValidateValidation: 'project/setValidateValidation',
            }),
            validateDetails: function(){
                return new Promise((resolve, reject) => {
                    this.setValidateDetails(true);
                    setTimeout(() => {
                        if (!this.validationErrorsExists) {
                            this.loading = false;
                            this.saveProject(this.project).then((result) => {
                                if(result.valid === false){
                                    this.$notify({ type:'error', title: 'Eroare', message: result.message });
                                    reject(false);
                                }else{
                                    this.flashMessage('success', 'Salvare date', 'Datele proiectului au fost salvate cu succes!');
                                    resolve(true);
                                }
                            });
                        } else {
                            this.flashMessage('warning', 'Validare date', 'Datele introduse nu sunt corecte. Verificati si incercati din nou.');
                            this.loading = false;
                            reject(false);
                        }
                    }, 1000)
                })
            },
            validateDescription: function(){
                return new Promise((resolve, reject) => {
                    this.setValidateDescription(true);
                    setTimeout(() => {
                        if (!this.validationErrorsExists) {
                            this.loading = false;
                            this.saveProject(this.project).then((result) => {
                                if(result.valid === false){
                                    this.$notify({ type:'error', title: 'Eroare', message: result.message });
                                    reject(false);
                                }else{
                                    this.flashMessage('success', 'Salvare date', 'Datele proiectului au fost salvate cu succes!');
                                    resolve(true);
                                }
                            });
                        } else {
                            this.flashMessage('warning', 'Validare date', 'Datele introduse nu sunt corecte. Verificati si incercati din nou.');
                            this.loading = false;
                            reject(false);
                        }
                    }, 1000)
                })
            },
            validateActivities: function(){
                return new Promise((resolve, reject) => {
                    this.setValidateActivities(true);
                    setTimeout(() => {
                        if (!this.validationErrorsExists) {
                            this.loading = false;
                            this.saveProject(this.project).then((result) => {
                                if(result.valid === false){
                                    this.$notify({ type:'error', title: 'Eroare', message: result.message });
                                    reject(false);
                                }else{
                                    this.$notify({ type: 'success', title: 'Salvare date', message: 'Datele proiectului au fost salvate cu succes!'});
                                    resolve(true);
                                }
                            });
                        } else {
                            this.$notify({ type:'error', title: 'Eroare', message: 'Datele introduse nu sunt corecte. Verificati si incercati din nou.'});
                            this.loading = false;
                            reject(false);
                        }
                    }, 1000)
                })
            },
            validateDocuments: function(){
                return new Promise((resolve, reject) => {
                    this.setValidateDocuments(true);
                    setTimeout(() => {
                        this.loading = false;
                        this.saveProject(this.project).then((result) => {
                            if(result.valid === false){
                                this.$notify({ type:'error', title: 'Eroare', message: result.message });
                                reject(false);
                            }else{
                                this.flashMessage('success', 'Salvare date', 'Datele proiectului au fost salvate cu succes!');
                                resolve(true);
                            }
                        });
                    }, 1000)
                })
            },
            validatePartners: function(){
                return new Promise((resolve, reject) => {
                    this.setValidatePartners(true);
                    setTimeout(() => {
                        if (!this.validationErrorsExists) {
                            this.loading = false;
                            this.saveProject(this.project).then((result) => {
                                if(result.valid === false){
                                    this.$notify({ type:'error', title: 'Eroare', message: result.message });
                                    reject(false);
                                }else{
                                    this.flashMessage('success', 'Salvare date', 'Datele proiectului au fost salvate cu succes!');
                                    resolve(true);
                                }
                            });
                        } else {
                            this.flashMessage('warning', 'Validare date', 'Datele introduse nu sunt corecte. Verificati si incercati din nou.');
                            this.loading = false;
                            reject(false);
                        }
                    }, 1000)
                })
            },
            validateObjectives: function(){
                return new Promise((resolve, reject) => {
                    this.setValidateObjectives(true);
                    setTimeout(() => {
                        if (!this.validationErrorsExists) {
                            this.loading = false;
                            this.saveProject(this.project).then((result) => {
                                if(result.valid === false){
                                    this.$notify({ type:'error', title: 'Eroare', message: result.message });
                                    reject(false);
                                }else{
                                    this.flashMessage('success', 'Salvare date', 'Datele proiectului au fost salvate cu succes!');
                                    resolve(true);
                                }
                            });
                        } else {
                            this.flashMessage('warning', 'Validare date', 'Datele introduse nu sunt corecte. Verificati si incercati din nou.');
                            this.loading = false;
                            reject(false);
                        }
                    }, 1000)
                })
            },
            validateIndicators: function(){
                return new Promise((resolve, reject) => {
                    this.setValidateIndicators(true);
                    setTimeout(() => {
                        if (!this.validationErrorsExists) {
                            this.loading = false;
                            this.saveProject(this.project).then((result) => {
                                if(result.valid === false){
                                    this.$notify({ type:'error', title: 'Eroare', message: result.message });
                                    reject(false);
                                }else{
                                    this.flashMessage('success', 'Salvare date', 'Datele proiectului au fost salvate cu succes!');
                                    resolve(true);
                                }
                            });
                        } else {
                            this.flashMessage('warning', 'Validare date', 'Datele introduse nu sunt corecte. Verificati si incercati din nou.');
                            this.loading = false;
                            reject(false);
                        }
                    }, 1000)
                })
            },
            validateCalendar: function(){
                return new Promise((resolve, reject) => {
                    this.setValidateCalendar(true);
                    setTimeout(() => {
                        if (!this.validationErrorsExists) {
                            this.loading = false;
                            this.saveProject(this.project).then((result) => {
                                if(result.valid === false){
                                    this.$notify({ type:'error', title: 'Eroare', message: result.message });
                                    reject(false);
                                }else{
                                    this.flashMessage('success', 'Salvare date', 'Datele proiectului au fost salvate cu succes!');
                                    resolve(true);
                                }
                            });
                        } else {
                            this.flashMessage('warning', 'Validare date', 'Datele introduse nu sunt corecte. Verificati si incercati din nou.');
                            this.loading = false;
                            reject(false);
                        }
                    }, 1000)
                })
            },
            validateValidation: function(){
                return new Promise((resolve, reject) => {
                    this.setValidateValidation(true);
                    setTimeout(() => {
                        if (!this.validationErrorsExists) {
                            this.loading = false;
                            this.project.final = true;
                            this.saveProject(this.project).then((result) => {
                                if(result.valid === false){
                                    this.$notify({ type:'error', title: 'Eroare', message: result.message });
                                    reject(false);
                                }else{
                                    this.flashMessage('success', 'Salvare date', 'Datele proiectului au fost salvate cu succes!');
                                    resolve(true);
                                }
                            });
                        } else {
                            this.flashMessage('warning', 'Validare date', 'Datele introduse nu sunt corecte. Verificati si incercati din nou.');
                            this.loading = false;
                            reject(false);
                        }
                    }, 1000)
                })
            },
            onComplete: function(){
                // this.setLoading(true);
                // this.createPromoter(this.promoter)
                //     .then(() => {
                //         this.flashMessage('success','Creare promotor','Promotorul a fost creat cu succes');
                this.flashMessage('success', 'Proiect', 'Proiectul a fost salvat cu succes!');
                setTimeout(function() {
                    window.location.href = '/projects'
                },1000);
                //     }).catch(function(error){
                //         this.flashMessage('error','Creare promotor','O eroare a aparut la crearea promotorului');
                //         this.setLoading(false);
                //         console.log(error);
                //     });
            },
            setLoading: function(value) {
                this.loading = value
            },
            flashMessage: function (type, title, text) {
                this.$notify({
                    type: type,
                    title: title,
                    message: text,
                });
            }
        }
    }
</script>

<style>
    .wizard-tab-content{
        padding-top:0 !important;
    }
    .wizard-nav-pills{
        width: 260px !important;
    }
    .wizard-nav-pills li{
        flex: none !important;
        flex-grow: 0 !important;
    }
    .tab_shape{
        display:none !important;
    }
    .wizard-btn{
        padding: 0.5rem 1.3rem !important;
        font-weight: 600 !important;
        font-size: 1.2rem !important;
        line-height: 1.4rem !important;
    }
    .stepTitle {
        border: 1px solid transparent !important;
        margin-bottom: 2px !important;
        padding: 0.75rem 1.25rem !important;
        background-color: #fff !important;
        width:100%;
    }
    .stepTitle.active{
        background-color: #3BB273 !important;
        font-weight: 700 !important;
        color:#fff !important;
    }
    .wizard-header{display:none;}
    .wizard-card-footer{margin-left:220px;}
</style>
