export default{
    promoters: [],
    promoter: {
        employees:[]
    },
    validatePromoter: false,
    promoterValidated: false,
    validatePromoterAccount: false,
    promoterAccountValidated: false,
    errors: false,
}
