<template>
    <div>
        <div class="row" v-if="notifications" v-for="user in notifications">
            <div class="col-sm-4">
                <label>Nume / Prenume</label>
                <p>{{ user.first_name }} {{ user.last_name }}</p>
            </div>
            <div class="col-sm-4">
                <label>Adresa e-mail</label>
                <p>{{ user.email }}</p>
            </div>
<!--            <div class="col-sm-3">-->
<!--                <label>Notificari trimise</label>-->
<!--                <p v-if="user.received > 0">{{ user.received }}</p> <p v-else>0</p>-->
<!--            </div>-->
        </div>
        <div v-else class="alert alert-info">
            Nu exista e-mailuri adaugate de acest promotor!
        </div>
    </div>
</template>
<script>
    export default {
        name: "promoter-notifications-pane",
        props:{
            notifications: {
                required: true,
                type: Array
            }
        },
    }
</script>

<style scoped>

</style>
