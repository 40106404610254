<template>
    <div v-if="project">
        <h3 class="mb-4 mt-3">IN LUCRU!</h3>
        
        <div class="input-group">
            <textarea placeholder="Adauga un comentariu" v-on:change="saveComment" v-model="form.comment_sec1" class="form-control custom-control" rows="3" style="resize:none"></textarea>     
            <el-button class="input-group-addon btn btn-primary" icon="el-icon-edit" @click="uploadFilesComment(notification)"></br>Vezi/modifică</br>fișierele</br>comentariului</el-button>
        </div>
        <hr />
        <div class="form-group">
            <h5>Lista comentarii:</h5>
            <span style="white-space: pre-line;">{{ form.comment_sec1 }}</span>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex'
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "notification-representatives-form",
        data(){
            return {
                form:{},
            }
        },
        computed:{
            ...mapGetters({
                project: 'project/project',
                notification: 'notification/notification',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        methods:{
            ...mapActions({
                saveNotificationComment: 'notification/saveNotificationComment'
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            uploadFilesComment(form)
            {
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Notification',
                    model_id: form.id,
                    category_id: 2,
                    acceptFiles: '.pdf,.docx,.doc,.xlsx,.xls',
                    multiple: true,
                    title: 'Incarcă documente',
                    files: form.commentfiles.filter(p => p.category_id == 2),
                });
            },
            saveComment()
            {
                this.saveNotificationComment({
                    id: this.project.id,
                    project_id: this.notification.id,
                    section_id: 1,
                    comment: this.form.comment_sec1,
                });
            }
        }
    }

</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>

//Save Comment ID: 6