<template>
    <div>
        <form-wizard @on-complete="onComplete" :startIndex="section2"
                     shape="tab" stepSize="xs" color="#3BB273" title="" subtitle="" layout="vertical"
                     error-color="#E15554" v-loading="loading">
            <tab-content title="General" :before-change="validateNotificationForm">
                <notification-form :selected-project="data.selectedProject"
                   :selected-notification="data.selectedNotification"></notification-form>
            </tab-content>
            <tab-content v-if="data.selectedNotification.type == 1" :title="actName[data.selectedNotification.changes[index]]" :key="index" v-for="(notification, index) in notification.changes">
                <date-form v-if="notification===1"></date-form>
                <partners-form v-if="notification===2"></partners-form>
                <indicators-form v-if="notification===3"></indicators-form>
                <calendar-form v-if="notification===4"></calendar-form>
                <activities-form v-if="notification===5"></activities-form>
                <budget-form v-if="notification===6"></budget-form>
                <team-form v-if="notification===7"></team-form>
                <beneficiaries-form v-if="notification===8"></beneficiaries-form>
                <city-form v-if="notification===9"></city-form>
            </tab-content>
            <tab-content v-if="data.selectedNotification.type == 2" :title="notifName[data.selectedNotification.changes[index]]" :key="index" v-for="(notification, index) in notification.changes">
                <team-form v-if="notification===1"></team-form>
                <budget-form v-if="notification===2"></budget-form>
                <detail-promotor-form v-if="notification===3"></detail-promotor-form>
                <plan-form v-if="notification===4"></plan-form>
                <calendar-form v-if="notification===5"></calendar-form>
                <beneficiaries-form v-if="notification===6"></beneficiaries-form>
                <city-form v-if="notification===7"></city-form>
                <representatives-promotor-form v-if="notification===8"></representatives-promotor-form>
                Reprezentanti legali Promotor
            </tab-content>
<!--            <template slot="footer" slot-scope="props">-->
<!--                <div class=wizard-footer-left>-->
<!--                    <a href="/projects" class="ml-2 btn btn-default" v-if="props.activeTabIndex == 0" >Anuleaza, m-am razgandit</a>-->
<!--                    <wizard-button  v-if="props.activeTabIndex > 0"  @click.native="props.prevTab()" :style="props.fillButtonStyle">Inapoi</wizard-button>-->
<!--                </div>-->
<!--                <div class="wizard-footer-right">-->
<!--                    <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="btn btn-default" :style="props.fillButtonStyle">Inainte</wizard-button>-->
<!--                    <wizard-button v-else @click.native="props.nextTab()" class="wizard-footer-right finish-button" :style="props.fillButtonStyle">{{props.isLastStep ? 'Finalizeaza' : 'Inainte'}}</wizard-button>-->
<!--                </div>-->
<!--            </template>-->
        </form-wizard>
        <upload-drawer></upload-drawer>
    </div>
</template>

<script>
    import {FormWizard, TabContent, WizardButton} from 'vue-form-wizard';
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'
    import NotificationForm from "../notification/form-notification";
    import PartnersForm from "../notification/form-partners";
    import UploadDrawer from "../common/upload-drawer";
    import DateForm from "../notification/form-date";
    import IndicatorsForm from "../notification/form-indicators";
    import CalendarForm from "../notification/form-calendar";
    import ActivitiesForm from "../notification/form-activities";
    import BudgetForm from "../notification/form-budget";
    import PlanForm from "../notification/form-plan";
    import DetailPromotorForm from "../notification/form-detail-promotor";
    import BeneficiariesForm from "../notification/form-beneficiaries";
    import RepresentativesPromotorForm from "../notification/form-representatives-promotor";
    import CityForm from "../notification/form-city";
    import TeamForm from "../notification/form-team";
    import ProjectIndirects from "../project/form-indirect";
    import ProjectFinancing from "../project/form-financing";
    import ProjectValidationForm from "../project/form-financial-validation";
    import {mapGetters, mapActions, mapMutations} from 'vuex'

    export default {
        name: "notification-wizard",
        components: {
            NotificationForm,
            PartnersForm,
            DateForm,
            IndicatorsForm,
            CalendarForm,
            UploadDrawer,
            ActivitiesForm,
            BudgetForm,
            PlanForm,
            DetailPromotorForm,
            BeneficiariesForm,
            RepresentativesPromotorForm,
            CityForm,
            TeamForm,
            FormWizard,
            TabContent,
            WizardButton
        },
        props:{
            data: {
                required: true,
                type: Object
            },
            section:{
                required: false,
                default: 0,
                type: Number
            }
        },
        data(){
            return {
                loading: false,
                actName:
                [
                    '',
                    'Data finala a perioadei de implementare',
                    'Adaugare/inlocuire parteneri',
                    'Adaugare indicatori de program/modificare indicatori proprii',
                    'Calendar activitati',
                    'Adaugare activitate sau modificare texte activitati',
                    'Modificari de buget, cu valoare peste 20%',
                    'Modificare echipa proiect',
                    'Modificare beneficiari',
                    'Modificare localitati si mediul desfasurare'
                ],
                notifName:
                [
                    '',
                    'Modificare echipa proiect',
                    'Modificari de buget, cu valoare mai mica de 20%',
                    'Modificare detalii promotor',
                    'Plan comunicare (Incarcare document  cu acest nume)',
                    'Calendar activitati',
                    'Modificare beneficiari',
                    'Modificare localitati si mediul desfasurare',
                    'Reprezentanti legali Promotor'
                ]
            }
        },
        computed:{
            ...mapGetters({
                project: 'project/project',
                notification: 'notification/notification',
                validationErrorsExists: 'validationErrorsExists',
            }),
            section2: function(){
                if(this.section === 40){
                    return 40 - 39 + this.project.activities.length;
                }else if (this.section === 41){
                    return 40 - 38 + this.project.activities.length;
                }else{
                    return this.section;
                }
            }
        },
        created(){
            // this.setProject(this.data);
        },
        mounted(){
            if(this.data.selectedNotification){
                this.setNotification(this.data.selectedNotification);
            }
            if(this.data.selectedProject){
                this.setProject(this.data.selectedProject);
            }
        },
        methods: {
            ...mapActions({
                // saveProject: 'project/saveProjectFinancial',
            }),
            ...mapMutations({
                setProject: 'project/setProject',
                setNotification: 'notification/setNotification',
                setValidateNotification: 'notification/setValidateNotification',
                // setValidateActivity: 'project/setValidateActivity',
                // setValidateIndirects: 'project/setValidateIndirects',
                // setValidateFinancing: 'project/setValidateFinancing',
                // setValidateValidation: 'project/setValidateValidation',
            }),
            validateNotificationForm: function(){
                return new Promise((resolve, reject) => {
                    this.setValidateNotification(true);
                    setTimeout(() => {
                        if (!this.validationErrorsExists) {
                            this.loading = false;
                            this.$notify({type: 'success', title: 'Salvare date', message: 'Datele proiectului au fost salvate cu succes!'});
                            resolve(true);
                        } else {
                            this.$notify({type: 'warning', title: 'Validare date', message: 'Datele introduse nu sunt corecte. Verificati si incercati din nou.'});
                            this.loading = false;
                            reject(false);
                        }
                    }, 1000)
                })
            },
            onComplete: function(){
                this.loading = true;
                axios.post('/notifications/readyForReView', {project_id: this.project.id, id:this.notification.id}).then((result) => {
                    this.$notify({type: 'success', title: 'Modificare Contractuala',  message: 'Proiectul a fost trimis pentru revizuire!'});
                    setTimeout(function() {
                        window.location.href = '/notifications'
                    },1000);
                });
            },
        }
    }
</script>

<style>
    .wizard-tab-content{
        padding-top:0 !important;
    }
    .wizard-nav-pills{
        width: 260px !important;
    }
    .wizard-nav-pills li{
        flex: none !important;
        flex-grow: 0 !important;
    }
    .tab_shape{
        display:none !important;
    }
    .wizard-btn{
        padding: 0.5rem 1.3rem !important;
        font-weight: 600 !important;
        font-size: 1.2rem !important;
        line-height: 1.4rem !important;
    }
    .stepTitle {
        border: 1px solid transparent !important;
        margin-bottom: 2px !important;
        padding: 0.75rem 1.25rem !important;
        background-color: #fff !important;
        width:100%;
    }
    .stepTitle.active{
        background-color: #3BB273 !important;
        font-weight: 700 !important;
        color:#fff !important;
    }
    .wizard-header{display:none;}
    .wizard-card-footer{margin-left:220px;}
</style>
