import axios from 'axios'
import {setErrors} from "./mutations";

// get promoters
export const getPromoters = ({ commit, dispatch }, [page, limit]) => {
    return axios.get('/api/promoters?page='+page+'&limit='+limit)
        .then((response) => {
            commit('setPromoters',response.data.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};


// get promoters
export const getPromotersList = ({ commit, dispatch }, [page, limit]) => {
    return axios.get('/api/listPromoters?page='+page+'&limit='+limit)
        .then((response) => {
            commit('setPromoters',response.data.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

export const findSor = ({ commit, dispatch }, searchString) => {
    return axios.post('/api/promoter/findSor', {search: searchString})
        .then((response) => {
            if(response.data.data.length > 0) commit('setPromoters',response.data.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

export const setPromoterValidated = ({ commit, dispatch }, value) => { commit('setPromoterValidated',value) };
export const setPromoterAccountValidated = ({ commit }, value) => { commit('setPromoterAccountValidated',value) };
// get promoter details

// save promoter
export const savePromoter = ({ commit, dispatch}, promoter) => {
    return axios.post('/promoters/promoter', promoter)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.reject(error)
        })
};

export const savePromoterUsers = ({ commit }, payload) => {
    return axios.post('/api/promoter/users', payload)
        .then((response) => {
            // commit('setProject', response.data.project);
            return Promise.resolve(response.data)
        })
};

// remove promoter
export const removePromoter = ({ commit, dispatch}, promoter_id) => {
    return axios.post('/api/promoter/remove', {id: promoter_id})
        .catch((error) => {
            console.log(error);
            return Promise.reject(error)
        })
};

// assign promoter
export const assignPromoter = ({ commit, dispatch}, payload) => {
    return axios.post('/api/promoter/assign', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.reject(error)
        })
};

export const findCompany = ({ commit, dispatch }, searchString) => {
    if(searchString.length >= 3) {
        return axios.post('/api/promoter/findCompany', {company: searchString})
            .then((response) => {
                return Promise.resolve(response.data)
            })
            .catch((error) => {
                return Promise.reject(error);
            })
    }
};

// save promoter
export const sendAccountNotification = ({ commit, dispatch}, promoter) => {
    return axios.post('/promoters/notification', promoter)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.reject(error)
        })
};
