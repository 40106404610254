<template>
    <div v-loading="loading">
        <!--  Header Info -->
        <el-tabs v-model="tabGeneral">
            <el-tab-pane label="Date generale" name="first">
                <div class="row">
                    <div class="col-sm-2">
                        <label>Numar grafic raport</label>
                        <p v-if="reporting.graph">{{ reporting.graph.reporting_id }}</p>
                    </div>
                    <div class="col-sm-2">
                        <label>Tip raport</label>
                        <p v-if="reporting.type_report === 1 || reporting.type_report === 11">Intermediar</p><p v-else>Final</p>
                    </div>
                    <div class="col-sm-4">
                        <label>Perioadă raportată</label>
                        <p>{{formatUserDate(reporting.start_date)}} - {{formatUserDate(reporting.end_date)}}</p>
                    </div>
                    <div class="col-sm-3">
                        <label>Data transmiterii</label>
                        <p>{{formatUserDateTime(reporting.updated_at)}}</p>
                    </div>
                    <div class="col-sm-2">
                        <label>Contract nr.</label>
                        <p>{{project.funding_number}}</p>
                    </div>
                    <div class="col-sm-3">
                        <label>Perioada de implementare a proiectului</label>
                        <p>{{ formatUserDate(project.implementation_start_date) }} - {{ formatUserDate(project.implementation_end_date) }}</p>
                    </div>
                    <div class="col-sm-3">
                        <label>Durata de implementare a proiectului</label>
                        <p>{{ datesDifference(project.implementation_start_date, project.implementation_end_date) }}</p>
                    </div>
                    <div class="col-sm-4">
                        <label>Promotor</label>
                        <p>{{project.promoter.name}}</p>
                    </div>
                    <div class="col-sm-6">
                        <label>Partener(i)</label>
                        <p>
                            <span v-for="p in project.partners">{{ p.name }}<br/></span>
                        </p>
                    </div>
                    <div class="col-sm-6">
                        <label>Documente semnate de la promotor</label>
                        <p>
                            <el-button type="secondary" size="small" icon="el-icon-view" @click="viewDocument()">
                              <span>Vezi fisierele raportarii</span>
                            </el-button>
                        </p>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Istoric modificari" name="second">
                <el-timeline>
                    <el-timeline-item
                        v-for="(row, index) in reporting.logs"
                        :key="index"
                        :timestamp="formatUserDate(row.created_at)">
                        {{ row.message }}
                    </el-timeline-item>
                </el-timeline>
            </el-tab-pane>
        </el-tabs>


        <h5>2. Rezumatul raportului</h5>
        <div class="row">
            <div class="col-sm-6">
                <label>2.1. Rezumat in limba romana</label>
                <p>{{ reporting.summary_ro }}</p>
            </div>
            <div class="col-sm-6">
                <label>2.2. Rezumat in limba engleza</label>
                <p>{{ reporting.summary_en }}</p>
            </div>
        </div>
        <h5>3. Managementul riscurilor</h5>
        <div class="row">
            <div class="col-sm-6">
                <p>{{ reporting.risk_management }}</p>
            </div>
        </div>
        <h5>4. Cooperarea cu partenerii de proiect</h5>
        <div class="row">
            <div class="col-sm-6">
                <label>4.1. Cooperarea cu parteneri din Statele Donatoare (Norvegia, Islanda, Liechtenstein) daca este cazul</label>
                <p>{{ reporting.coop_donor_partners }}</p>
            </div>
        </div>
        <div class="row" v-if="reporting.type_report === 2 || reporting.type_report === 22">
            <div class="col-sm-6">
                <label>4.1.a. Cum a contribuit proiectul la consolidarea relatiilor bilaterale?</label>
                <p>
                    <span v-for="q in JSON.parse(reporting.q1a)">
                        <font style="checkbox">✔</font> {{ q }}<br/>
                    </span>
                </p>
            </div>
            <div class="col-sm-6">
                <label>4.1.b. Ce nivel de implicare a avut partenerul (partenerii) din Statele Donatoare in proiect?</label>
                <p>
                    <span v-for="q in JSON.parse(reporting.q1b)">
                        <font style="checkbox">✔</font> {{ q }}<br/>
                    </span>
                </p>
            </div>
            <div class="col-sm-6">
                <label>4.1.c. Va continua cooperarea cu partenerul/ partenerii din Statele Donatoare dupa finalizarea proiectului?</label>
                <p>
                    <span v-for="q in JSON.parse(reporting.q1c)">
                        <font style="checkbox">✔</font> {{ q }}<br/>
                    </span>
                </p>
            </div>
        </div>
        <div class="col-sm-6">
            <label>4.2. Cooperarea cu alti Parteneri (daca este cazul)</label>
            <p>{{ reporting.coop_partners }}</p>
        </div>
        <hr>
        <h5>5. Realizari privind activitatile, rezultatele si indicatorii proiectului</h5>
        <el-tabs v-model="planTabs" class="mt-5">
            <el-tab-pane label="5.1. Implementarea activitatilor" name="activities" class="active">
                <el-table :data="reporting.technical_activities">
                    <el-table-column label="Activitate" width="70" align="center">
                        <template slot-scope="scope">{{ 'A'+scope.row.activity }}</template>
                    </el-table-column>
                    <el-table-column label="Denumire">
                        <template slot-scope="scope">{{ project.activities[scope.row.activity-1].name }}</template>
                    </el-table-column>
                    <el-table-column label="Descrierea activității" prop="description"></el-table-column>
                    <el-table-column label="Rezultate obținute" prop="results"></el-table-column>
                    <el-table-column label="Modificări" prop="changes"></el-table-column>
                    <el-table-column label="Anexe" width="130px" align="center">
                        <template slot-scope="scope" v-if="scope.row.activity === 2">
                            <el-button size="mini" type="warning" icon="el-icon-files" @click="viewDocumentActivity2(scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>

            <el-tab-pane label="5.2. Indicatorii de program" name="indicators">
                <el-table :data="reporting.indicators" id="indicatorsList" stripe fit highlight-current-row style="width:100%">
                    <el-table-column label="ID" align="center" width="80">
                        <template slot-scope="scope">
                            I{{ scope.row.index }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Indicator" prop="indicator.name"></el-table-column>

                    <el-table-column label="Valoare initiala" prop="indicator.target" align="right" width="150" header-align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.indicator !== null && (scope.row.indicator.indicator_id == 152 || scope.row.indicator.indicator_id == 142 || scope.row.indicator.indicator_id == 169 || scope.row.indicator.indicator_id == 198 || scope.row.indicator.indicator_id == 199 || scope.row.indicator.indicator_id == 200 || scope.row.indicator.indicator_id == 201)">
                                <span>{{ formatNumberNew(scope.row.value_up_notification,0) }} / {{ formatNumberNew(scope.row.value_down_notification,0) }} = {{ formatNumber(scope.row.value_up_notification/scope.row.value_down_notification*100,2) }}%</span>
<!--                                <span v-if="scope.row.value_notification > 0">{{ formatNumberNew(scope.row.value_notification,0) }}</span>-->
<!--                                <span v-if="scope.row.value_down_notification < 1 && scope.row.value_notification < 1">0/{{ formatNumberNew(scope.row.value_up_notification,0) }} = 0%</span>-->
                            </span>
                            <span v-else>
                                {{ formatNumberNew(scope.row.value_notification,0) }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column label="Valoare realizata pana la sfarsitul perioadei anterioare de raportare" prop="indicator.target" align="right" width="150" header-align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.indicator !== null && (scope.row.indicator.indicator_id == 152 || scope.row.indicator.indicator_id == 142 || scope.row.indicator.indicator_id == 169 || scope.row.indicator.indicator_id == 198 || scope.row.indicator.indicator_id == 199 || scope.row.indicator.indicator_id == 200 || scope.row.indicator.indicator_id == 201)">
                                <span>{{ formatNumberNew(scope.row.value_up_reporting,0) }} / {{ formatNumberNew(scope.row.value_down_reporting,0) }} = {{ formatNumber(scope.row.value_up_reporting/scope.row.value_down_reporting*100,2) }}%</span>
<!--                                <span v-if="scope.row.value_reporting > 0">{{ formatNumberNew(scope.row.value_reporting,0) }}</span>-->
<!--                                <span v-if="scope.row.value_down_reporting < 1 && scope.row.value_reporting < 1">0/{{ formatNumberNew(scope.row.value_up_reporting,0) }} = 0%</span>-->
                            </span>
                            <span v-else>
                                {{ formatNumberNew(scope.row.value_reporting,0) }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column label="Valoare realizata pana la sfarsitul perioadei acoperite de raportul curent (cumulativ)" prop="indicator.target" align="right" width="150" header-align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.indicator !== null && (scope.row.indicator.indicator_id == 152 || scope.row.indicator.indicator_id == 142 || scope.row.indicator.indicator_id == 169 || scope.row.indicator.indicator_id == 198 || scope.row.indicator.indicator_id == 199 || scope.row.indicator.indicator_id == 200 || scope.row.indicator.indicator_id == 201)">
                                <span>{{ formatNumberNew(scope.row.value_up,0) }} / {{ formatNumberNew(scope.row.value_down,0) }} = {{ formatNumber(scope.row.value_up/scope.row.value_down*100,2) }}%</span>
<!--                                <span v-if="scope.row.value > 0">{{ formatNumberNew(scope.row.value,0) }}</span>-->
<!--                                <span v-if="scope.row.value_down < 1 && scope.row.value < 1">0/{{ formatNumberNew(scope.row.value_up,0) }} = 0%</span>-->
                            </span>
                            <span v-else>
                                {{ formatNumberNew(scope.row.value,0) }}
                            </span>
                        </template>
                    </el-table-column>

                    <el-table-column label="Valoare propusa (target)" prop="indicator.target" align="right" width="150" header-align="center">
                        <template slot-scope="scope">
                            {{ formatNumberNew(scope.row.value_admin, 0) }}
                        </template>
                    </el-table-column>

                    <el-table-column label="Varsta" width="230" header-align="center">
                        <template slot-scope="scope" v-if="scope.row.indicator !== null && showAgesFor.includes(scope.row.indicator.indicator_id)">
                            <div v-for="age in scope.row.ages" style="display:block">
                                {{age.name}}: {{formatNumberNew(age.value,0)}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Gen" width="140" header-align="center">
                        <template slot-scope="scope" v-if="scope.row.indicator !== null && showGenderFor.includes(scope.row.indicator.indicator_id)">
                            <div v-for="gender in scope.row.genders" style="display:block">
                                {{gender.name}}: {{formatNumberNew(gender.value,0)}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Etnia" width="140" header-align="center">
                        <template slot-scope="scope" v-if="scope.row.indicator !== null && showEthFor.includes(scope.row.indicator.indicator_id)">
                            <div v-for="ethnicity in scope.row.ethnicities" style="display:block">
                                {{ethnicity.name}}: {{formatNumberNew(ethnicity.value,0)}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Servicii" width="140" header-align="center">
                        <template slot-scope="scope" v-if="scope.row.indicator !== null && showServFor.includes(scope.row.indicator.indicator_id)">
                            <div v-for="service in scope.row.services" style="display:block">
                                {{service.name}}: {{formatNumberNew(service.value,0)}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Tematici" width="140" header-align="center">
                        <template slot-scope="scope" v-if="scope.row.indicator !== null && showTechFor.includes(scope.row.indicator.indicator_id)">
                            <div v-for="technique in scope.row.techniques" style="display:block">
                                {{technique.name}}: {{formatNumberNew(technique.value,0)}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Anexe" width="130px" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="warning" icon="el-icon-files" @click="viewDocumentIndicator(scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="5.3. Graficul activitatilor actualizat" name="calendar">
                <div class="row">
                    <div class="col-sm-12">
                        <h4>Calendar activitati proiect</h4>
                        <pane-calendar :calendars="project.calendars" :project="project"></pane-calendar>
                    </div>
                    <div class="col-sm-12">
                        <h4>Calendar activitati modificate</h4>
                        <pane-calendar :calendars="reporting.calendars" :project="project"></pane-calendar>
                    </div>
                </div>
                <el-table :data="reporting.calendars">
                    <el-table-column label="ID" prop="activity.activity" width="60"></el-table-column>
                    <el-table-column label="Denumire" prop="activity.name"></el-table-column>
                    <el-table-column label="Data inceput" prop="start_date" width="120">
                        <template slot-scope="scope">
                            {{ formatUserDate(scope.row.start_date) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Data sfarsit" prop="end_date" width="120">
                        <template slot-scope="scope">
                            {{ formatUserDate(scope.row.end_date) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Perioada" width="200">
                        <template slot-scope="scope">
                            {{ datesDifference(scope.row.start_date,scope.row.end_date) }}
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <hr>
        <div class="row">
            <div class="col-sm-6" v-if="reporting.type_report === 2 || reporting.type_report === 22">
                <label>5.4. Atingerea/realizarea obiectivelor</label>
                <p>{{ reporting.reaching_goals }}</p>
            </div>
            <div class="col-sm-6" v-if="reporting.type_report === 1 || reporting.type_report === 11">
                <label>5.4. Progresul in realizarea obiectivelor</label>
                <p>{{ reporting.reaching_goals }}</p>
            </div>
        </div>
        <h5>6. Sustenabilitatea proiectului</h5>
        <div class="row">
            <div class="col-sm-6">
                <p>{{ reporting.sustainability }}</p>
            </div>
        </div>
        <div class="col-sm" v-if="reporting.type_report === 2 || reporting.type_report === 22">
            <label>6.1. Cat de probabil este ca efectele pozitive ale proiectului sa continue dupa incheierea perioadei de finantare?</label>
            <p>
                <span v-for="q in JSON.parse(reporting.q6)">
                    <font style="checkbox">✔</font> {{ q }}<br/>
                </span>
            </p>
        </div>
        <h5>7. Lecții învățate</h5>
        <div class="row">
            <div class="col-sm-6">
                <p>{{ reporting.lessons }}</p>
            </div>
        </div>
        <h5>8. Alte comentarii</h5>
        <div class="row">
            <div class="col-sm-6">
                <p>{{ reporting.comment }}</p>
            </div>
        </div>
        <hr>
        <div class="form-group">
            <el-button type="secondary" v-if="typeof reporting.comments !== 'undefined'" class="float-right" size="mini" @click="exportComments()"><i class="icofont-file-pdf"></i> Exporta clarificarile</el-button>
            <h5>Lista comentarii:</h5>
            <div class="feed-element" v-for="(row, index) in reporting.comments" :key="index">
                <div class="media-body">
                    <i class="icofont-girl" style="font-size:100%"></i> <b v-if="row.user && typeof row.user.promoter[0] !== 'undefined'">Promotor</b><b v-else>Operator de Fond</b>: {{ row.comment }}<br>
                    <small class="text-muted green"><i class="icofont-clock-time"></i> {{ formatUserDate(row.created_at) }}</small>

                    <el-button v-if="row.user_id === user_id && row.reply_to_id === -1" class="float-right ml-2" size="mini" type="primary" icon="el-icon-edit"
                        @click="innitEditComment(row)">Editeaza</el-button>
                    <el-button v-if="row.user_id === user_id && row.reply_to_id === -1" class="float-right ml-2" size="mini" type="danger" icon="el-icon-delete"
                        @click="removeComment(index, row)">Sterge</el-button>
                    <el-button v-if="row.user_id === user_id && row.reply_to_id === -1" class="float-right ml-2" size="mini" type="warning" icon="el-icon-files"
                        @click="openDrawerComment(row)">Incarca fisiere</el-button>

                    <p v-if="row.files !== undefined && row.files.length" class="text-right">
                        <small>
                            Fisiere incarcate:
                            <span v-for="doc in row.files">
                                <br/><a :href="'/getFile/' + doc.id" target="_blank">{{ doc.file_name }}</a>
                            </span>
                        </small>
                    </p>
                </div>
                <hr>
            </div>
        </div>
        <div class="form-group">
            <label>Adauga solicitari de clarificare</label>
            <el-input v-model="form.comment" type="textarea" autosize rows="10"  placeholder="Adauga observatiile referitoare la verificarea raportului"></el-input>
        </div>
        <el-button size="mini" type="secondary" icon="el-icon-plus"
           @click="addComment()" :disabled="form.comment && !isOFFButton ? false : true">Adauga comentariu</el-button>
        <hr>
       <div class="form-group">
            <h5>
                Se asteapta raspuns pana la data de: <span v-if="reporting.answer_limit">{{ formatUserDateTime(reporting.answer_limit) }}</span>
                <small v-if="form.answer_limit"><br/>Timp ramas: {{ datesDifferenceWithoutDayPlus(contractMinDate, form.answer_limit) }}</small>
            </h5>
            <label>
                <el-date-picker v-model="form.answer_limit" placeholder="Pana la" type="date" value-format="yyyy-MM-dd" size="medium"
                                format="dd.MM.yyyy" style="width:100%" :picker-options="startPeriodOptions" @change="UPDB"></el-date-picker>
            </label>
        </div>
        <hr>
        <upload-drawer :roles="roles"></upload-drawer>
        <upload-drawer-simple></upload-drawer-simple>
        <div class="row" v-if="user_type === 1">
            <el-checkbox v-model="checkbox" @change="checkTerms" border label="Confirmare validare"></el-checkbox>
        </div>
        <div class="row my-3">
            <div class="col-sm text-left">
                <el-button @click="sendNotification()" class="btn btn-warning" type="secondary"><i class="icofont-exclamation-tringle"></i> Trimite spre revizuire</el-button>
                <el-button @click="sendNotificationClarification()" class="btn btn-warning" type="secondary"><i class="icofont-circled-left"></i> Trimite spre clarificare cu editare</el-button>
                <el-button @click="sendNotificationClarification2()" class="btn btn-warning" type="secondary"><i class="icofont-circled-left"></i> Trimite spre clarificare</el-button>
            </div>
        </div>
        <div class="row my-3">
            <div class="col-sm text-right">
                <el-button :disabled="sendValidation" class="btn btn-success btn-sm" @click="saveValidation()" type="primary"><i class="icofont-verification-check"></i>Salvare validare</el-button>
            </div>
        </div>

        <h5>VRT</h5>
        <div class="col-sm">
            <el-collapse>
                <el-collapse-item title="VRT - Apasă aici pentru a deschide VRT-ul!" name="1">
                    <h5>Raport de verificare</h5>
                    <h5 v-if="reporting.type_report === 1 || reporting.type_report === 11">Raport tehnic intermediar nr. {{ reporting.graph.reporting_id }}</h5>
                    <h5 v-else>Raport tehnic final nr. {{ reporting.graph.reporting_id }}</h5>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="alert alert-success">Raspunde la intrebarile de mai jos si verifica cu atentie informatiile</div>
                        </div>
                    </div>
                    <el-form :model="vrtForm" :rules="rules" ref="vrtForm" id="vrtForm" class="mt-3">
                        <h5>2. Aspecte analizate</h5>
                        <el-form-item label="2.1. Activitatile au fost derulate conform Graficului activitatilor?"
                                      prop="vrt_1_1" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrtForm.vrt_1_1" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item prop="vrt_1_1_comments" class="mb-0">
                            <el-input placeholder="2.1. Comentarii (referire la schimbarile intervenite, cu menționarea activităților în întârziere)" type="textarea" v-model="vrtForm.vrt_1_1_comments" size="mini" rows="4"></el-input>
                        </el-form-item>

                        <el-form-item label="2.2. Rezultatele activitatilor au fost obtinute asa cum a fost prevazut?" prop="vrt_1_2" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrtForm.vrt_1_2" size="mini">
                                <el-radio-button label="Partial">Partial</el-radio-button>
                                <el-radio-button label="Total">Total</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item prop="vrt_1_2_comments" class="mb-0">
                            <el-input placeholder="2.2. Comentarii" type="textarea" v-model="vrtForm.vrt_1_2_comments" size="mini" rows="4"></el-input>
                        </el-form-item>

                        <el-form-item label="2.3. Există dificultati intampinate in implementarea proiectului? (managementul riscurilor)" prop="vrt_1_3" class="mb-0"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrtForm.vrt_1_3" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item prop="vrt_1_3_comments" class="mb-0">
                            <el-input placeholder="2.3. Comentarii (prezentare a dificultăților, efectelor asupra proiectului și măsurilor luate de promotori)" type="textarea" v-model="vrtForm.vrt_1_3_comments" size="mini" rows="4"></el-input>
                        </el-form-item>

                        <el-form-item label="2.4. Cooperarea cu partenerii din Statele Donatoare"
                                      prop="vrt_1_4"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrtForm.vrt_1_4" size="mini">
                                <el-radio-button label="Foarte buna">Foarte buna</el-radio-button>
                                <el-radio-button label="Buna">Buna</el-radio-button>
                                <el-radio-button label="Relatia ar putea fi imbunatatita">Relatia ar putea fi imbunatatita</el-radio-button>
                                <el-radio-button label="Dificultati importante in cooperare">Dificultati importante in cooperare</el-radio-button>
                                <el-radio-button label="Nu exista parteneri din State Donatoare">Nu exista parteneri din State Donatoare</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item prop="vrt_1_4_comments" class="mb-0">
                            <el-input placeholder="2.4. Comentarii" type="textarea" v-model="vrtForm.vrt_1_4_comments" size="mini" rows="4"></el-input>
                        </el-form-item>

                        <el-form-item label="2.5. Cooperarea cu alti parteneri de proiect"
                                      prop="vrt_1_5"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrtForm.vrt_1_5" size="mini">
                                <el-radio-button label="Foarte buna">Foarte buna</el-radio-button>
                                <el-radio-button label="Buna">Buna</el-radio-button>
                                <el-radio-button label="Relatia ar putea fi imbunatatita">Relatia ar putea fi imbunatatita</el-radio-button>
                                <el-radio-button label="Dificultati importante in cooperare">Dificultati importante in cooperare</el-radio-button>
                                <el-radio-button label="Nu exista parteneri">Nu exista parteneri</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item prop="vrt_1_5_comments" class="mb-0">
                            <el-input placeholder="2.5. Comentarii" type="textarea" v-model="vrtForm.vrt_1_5_comments" size="mini" rows="4"></el-input>
                        </el-form-item>

                        <el-form-item label="2.6. Activitatea de informare si comunicare a fost realizata conform planului de promovare?"
                                      prop="vrt_1_6"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrtForm.vrt_1_6" size="mini">
                                <el-radio-button label="Partial">Partial</el-radio-button>
                                <el-radio-button label="Total">Total</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item prop="vrt_1_6_comments" class="mb-0">
                            <el-input placeholder="2.6. Comentarii (Se vor introduce informatii referitoare la actiuni,  produse, rezultate - exemple)" type="textarea" v-model="vrtForm.vrt_1_6_comments" size="mini" rows="4"></el-input>
                        </el-form-item>

                        <el-form-item label="2.7. Proiectul poate fi considerat “buna practica” - Se va urmari: - Daca a contribuit la schimbari semnificative pentru beneficiarii finali / - Daca a utilizat metode inovatoare / - Daca a obtinut vreun premiu"
                                      prop="vrt_1_7"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrtForm.vrt_1_7" size="mini">
                                <el-radio-button label="Da, fara recomandari suplimentare">Da, fara recomandari suplimentare</el-radio-button>
                                <el-radio-button label="Da, se recomanda documentare suplimentara">Da, se recomanda documentare suplimentara</el-radio-button>
                                <el-radio-button label="Da, se recomanda includerea in comunicarea Programului">Da, se recomanda includerea in comunicarea Programului</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item prop="vrt_1_7_comments" class="mb-0">
                            <el-input placeholder="2.7. Comentarii (In cazul in care a fost bifat “da”, se vor include comentarii cu detalii. De asemenea se vor face recomandari cu privire la: realizare vizita on-the-spot pentru documentare suplimentara, includere in comunicarea rezultatelor programului)" type="textarea" v-model="vrtForm.vrt_1_7_comments" size="mini" rows="4"></el-input>
                        </el-form-item>

                        <el-form-item label="2.8. Exista premisele ca efectele pozitive ale proiectului sa continue dupa finalizare?"
                                      prop="vrt_1_8"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrtForm.vrt_1_8" size="mini">
                                <el-radio-button label="Putin probabil sa continue dupa finalizare">Putin probabil sa continue dupa finalizare</el-radio-button>
                                <el-radio-button label="Probabil va continua dupa finalizare">Probabil va continua dupa finalizare</el-radio-button>
                                <el-radio-button label="Sunt sustenabile si vor continua dupa finalizare">Sunt sustenabile si vor continua dupa finalizare</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item prop="vrt_1_8_comments" class="mb-0">
                            <el-input placeholder="2.8. Comentarii" type="textarea" v-model="vrtForm.vrt_1_8_comments" size="mini" rows="4"></el-input>
                        </el-form-item>

                        <el-form-item label="2.9. Indicatorii de Program asumati prin proiect au fost indepliniti?"
                                      prop="vrt_1_9"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrtForm.vrt_1_9" size="mini">
                                <el-radio-button label="Partial">Partial</el-radio-button>
                                <el-radio-button label="Total">Total</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item prop="vrt_1_9_comments" class="mb-0">
                            <el-input placeholder="2.9. Comentarii (pentru rapoartele intermediare se va introduce % cu gradul de realizare sau valoarea indicatorilor (dacă este cazul). La raportul final se vor introduce comentarii daca nu au fost indepliniti 100% sau au existat depășiri importante)" type="textarea" v-model="vrtForm.vrt_1_9_comments" size="mini" rows="4"></el-input>
                        </el-form-item>

                        <el-form-item label="2.10. Indicatorii de proiect suplimentari, care nu se regasesc in Indicatorii de Program"
                                      prop="vrt_1_10">
                            <el-radio-group v-model="vrtForm.vrt_1_10" size="mini">
                                <el-radio-button label="Partial">Partial</el-radio-button>
                                <el-radio-button label="Total">Total</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item prop="vrt_1_10_comments" class="mb-0">
                            <el-input placeholder="2.10. Comentarii (pentru rapoartele intermediare se va introduce % cu gradul de realizare sau valoarea indicatorilor (dacă este cazul). La raportul final se vor introduce comentarii daca nu au fost indepliniti 100% au au existat depășiri importante)" type="textarea" v-model="vrtForm.vrt_1_10_comments" size="mini" rows="4"></el-input>
                        </el-form-item>

                        <el-form-item v-if="reporting.type_report === 2 || reporting.type_report === 22" label="2.11. Rezumatul rezultatelor proiectului in Engleza" prop="vrt_1_11_comments" class="mb-0">
                            <el-input type="textarea" v-model="vrtForm.vrt_1_11_comments" size="mini" placeholder="Va fi preluat din raportul final al Promotorului, cu mici modificari. Acesta va cuprinde: First, explain why the project was needed (describe the challenges the project set out to address). Second, give a short summary of what you did in the project (which activities were implemented, products were delivered, services were established, etc.). Why were these activities, products and/or services, etc. important? Third, describe the main results of the project, including any unintended results. What difference has the project made for its end beneficiaries? How has the situation improved as a result of the project? Use facts and figures to support your explanation. Finally, explain what the importance of the project has been (why does it matter that it achieved its results/was successful), making sure to consider the expected long-term impact." rows="5"></el-input>
                        </el-form-item>

                        <h5 class="mt-3">3. Concluziile raportului de verificare</h5>
                        <el-form-item prop="vrt_3" class="mb-0">
                            <el-input type="textarea" placeholder="Menționați, în maximum două fraze, concluziile analizei." v-model="vrtForm.vrt_3" size="mini" rows="4"></el-input>
                        </el-form-item>
                        <el-form-item label="3.1. Raportul este aprobat?"
                                      prop="vrt_3_1"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrtForm.vrt_3_1" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>

                        <h5 class="mt-3"><font style="color:red;">*</font> 4. Recomandări pentru monitorizarea proiectului în perioada următoare:</h5>
                        <el-form-item
                                      prop="vrt_4"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrtForm.vrt_4" size="mini">
                                <el-radio-button label="Nu sunt necesare măsuri suplimentare de monitorizare">Nu sunt necesare măsuri suplimentare de monitorizare</el-radio-button>
                                <el-radio-button label="Este necesara comunicarea mai frecventa cu promotorul si oferirea de consultanta">Este necesara comunicarea mai frecventa cu promotorul si oferirea de consultanta</el-radio-button>
                                <el-radio-button label="Este necesara initierea unor modificari ale contractului (notificari/acte aditionale)">Este necesara initierea unor modificari ale contractului (notificari/acte aditionale)</el-radio-button>
                                <el-radio-button label="Este necesara solicitarea de rapoarte tehnice suplimentare">Este necesara solicitarea de rapoarte tehnice suplimentare</el-radio-button>
                                <el-radio-button label="Este necesara realizarea unor vizite de monitorizare suplimentare">Este necesara realizarea unor vizite de monitorizare suplimentare</el-radio-button>
                                <el-radio-button label="Este necesara analizarea optiunii de suspendare a proiectului">Este necesara analizarea optiunii de suspendare a proiectului</el-radio-button>
                                <el-radio-button label="Este necesara analizarea optiunii de incheiere a contractului">Este necesara analizarea optiunii de incheiere a contractului</el-radio-button>
                                <el-radio-button label="Alta masura">Alta masura</el-radio-button>
                            </el-radio-group>
                        </el-form-item>

                        <h5 class="mt-3"><font style="color:red;">*</font> 5. Conditii de aprobare</h5>
                        <el-form-item
                                      prop="vrt_5"
                                      :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                            <el-radio-group v-model="vrtForm.vrt_5" size="mini">
                                <el-radio-button label="Da">Da</el-radio-button>
                                <el-radio-button label="Nu">Nu</el-radio-button>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item prop="vrt_5_comments" class="mb-0">
                            <el-input placeholder="Conditii de aprobare - Comentarii suplimentare" type="textarea" v-model="vrtForm.vrt_5_comments" size="mini" rows="4"></el-input>
                        </el-form-item>

                        <h5 class="mt-2"><small>6. Informatii suplimentare de completat</small></h5>
                        <div class="row">
                            <div class="col-4 el-form-item__label">Data aprobarii:</div>
                            <div class="col-3">
                                <el-form-item prop="vrt_app_date" class="mb-0"
                                              :rules="[{ required: true, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                                    <el-date-picker v-model="vrtForm.vrt_app_date" type="date" size="medium" value-format="yyyy-MM-dd" format="dd.MM.yyyy" placeholder="Alege o data"></el-date-picker>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 el-form-item__label">Data primirii raportului in original:</div>
                            <div class="col-3">
                                <el-form-item prop="vrt_app_date_original" class="mb-0"
                                              :rules="[{ required: false, message: 'Selecteaza o valoare', trigger: ['blur', 'change'] }]">
                                    <el-date-picker v-model="vrtForm.vrt_app_date_original" type="date" size="medium" value-format="yyyy-MM-dd" format="dd.MM.yyyy" placeholder="Alege o data"></el-date-picker>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 el-form-item__label">Intocmit de:</div>
                            <div class="col-3">
                                <el-form-item prop="vrt_made_by" class="mb-0"
                                              :rules="[{ required: true, message: 'Introdu o valoare', trigger: 'blur' }]">
                                    <el-input v-model="vrtForm.vrt_made_by" type="text" size="medium"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 el-form-item__label">A luat la cunostinta (nume monitor financiar):</div>
                            <div class="col-3">
                                <el-form-item prop="vrt_took_note" class="mb-0"
                                              :rules="[{ required: true, message: 'Introdu o valoare', trigger: 'blur' }]">
                                    <el-input v-model="vrtForm.vrt_took_note"  type="text" size="medium"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 el-form-item__label">Verificat de:</div>
                            <div class="col-3">
                                <el-form-item prop="vrt_verify_by" class="mb-0"
                                              :rules="[{ required: true, message: 'Introdu o valoare', trigger: 'blur' }]">
                                    <el-input v-model="vrtForm.vrt_verify_by"  type="text" size="medium"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 el-form-item__label">A luat la cunostinta (nume coordonator financiar):</div>
                            <div class="col-3">
                                <el-form-item prop="vrt_made_by2" class="mb-0"
                                              :rules="[{ required: true, message: 'Introdu o valoare', trigger: 'blur' }]">
                                    <el-input v-model="vrtForm.vrt_made_by2"  type="text" size="medium"></el-input>
                                </el-form-item>
                            </div>
                        </div>
                        <div class="row mt-6">
                            <div class="col-4"></div>
                            <div class="col-6">
                                <el-button type="primary" size="medium" @click.prevent="saveVrt">Salveaza VRT</el-button>
                                <el-button type="secondary" round size="medium" :disabled="vrtDisabled" @click.prevent="exportVrt"><i class="icofont-file-pdf"></i> Export VRT</el-button>
                                <el-button type="secondary" size="medium" @click="requestInfoAddress()">Solicita verificare</el-button>
                            </div>
                        </div>
                        <hr>
                        <div class="col-sm-11">
                            <el-checkbox v-model="reporting.app_technical_report" :checked="reporting.app_technical_report ? true : false" @change="checkApp(2)">
                                Aproba raport tehnic -
                                <el-button :disabled="!reporting.app_technical_report"
                                           @click="openDrawerVRT()" size="medium" type="secondary">
                                    Incarca VRT
                                </el-button>
                            </el-checkbox>
                        </div>
                    </el-form>
                </el-collapse-item>
            </el-collapse>
        </div>
        <!-- Editare comentariu -->
        <el-dialog title="Editare comentariu" :visible.sync="dialogEditComment">
          <div class="form-group">
                <label>Scrie mai jos noul comentariu:</label>
                <el-input v-model="form.new_comment" placeholder="Scrie aici..." type="textarea"></el-input>
            </div>
            <el-button size="mini" type="primary"
               @click="editCommentAction()" :disabled="form.new_comment ? false : true"><i class="icofont-edit"></i> Editeaza comentariul</el-button>
        </el-dialog>
    </div>
</template>
<script>
    import mixin from './../../mixins/common';
    import {mapActions, mapGetters, mapMutations} from "vuex";
    import UploadDrawer from "../common/view-reporting-technical-drawer";
    import UploadDrawer2 from './../common/upload-drawer-simple';
    import PaneCalendar from "./pane-actions-technical-calendar";
    import VueScrollTo from 'vue-scrollto';
    import moment from 'moment';
    export default {
        name: "reporting-verify-technical-form",
        mixins:[mixin],
        components: {
            UploadDrawer,
            UploadDrawer2,
            PaneCalendar,
        },
        props:{
            project: {
                required: true,
                type: Object
            },
            reporting: {
                required: true,
                type: Object
            },
            notification: {
                required: true,
                type: Array
            },
            user_type: {
                required: true,
                type: Number
            },
            user_id: {
                required: true,
            },
            roles: {
                type: Array,
                required: false
            }
        },
        mounted(){
            if(this.user_type === 0) {
                this.sendValidation = false;
            }
            this.vrtForm.reporting_id = this.reporting.id;
            this.vrtForm.project_id = this.reporting.project_id;

            if (this.reporting.vrt && this.reporting.vrt.id > 0) {
                this.vrtForm = this.reporting.vrt;
                if(this.vrtForm.id) this.vrtDisabled = false;
            }
        },
        data(){
            return{
                tabGeneral: 'first',
                planTabs:'activities',
                contractMinDate: new Date(),
                startPeriodOptions: {
                    disabledDate: this.disabledContractDate
                },
                currentDate: new Date(),
                vrtForm: {
                    reporting_id: null,
                    project_id: null,
                    vrt_1_1: '',
                    vrt_1_2: '',
                    vrt_1_3: '',
                    vrt_1_4: '',
                    vrt_1_5: '',
                    vrt_1_6: '',
                    vrt_1_7: '',
                    vrt_1_8: '',
                    vrt_1_9: '',
                    vrt_1_10: '',
                    vrt_1_11: '',
                    vrt_1_1_comments: '',
                    vrt_1_2_comments: '',
                    vrt_1_3_comments: '',
                    vrt_1_4_comments: '',
                    vrt_1_5_comments: '',
                    vrt_1_6_comments: '',
                    vrt_1_7_comments: '',
                    vrt_1_8_comments: '',
                    vrt_1_9_comments: '',
                    vrt_1_10_comments: '',
                    vrt_1_11_comments: '',
                    vrt_3: '',
                    vrt_3_1: '',
                    vrt_4: '',
                    vrt_5: '',
                    vrt_5_comments: '',
                },
                vrtDisabled: true,
                showAgesFor: [151,152,153,154,158,161,162,169,170,177,178,195,196,204,142,169],
                showGenderFor: [151,152,153,154,158,161,162,169,170,177,178,195,196,204,142,169],
                showEthFor: [151,152,153,158,161,169,170,177,178,195,196,142,169],
                showServFor: [170],
                showTechFor: [202],
                showUpDownFor:[152,169,198,199,200,201],
                form: {
                    new_comment: null
                },
                loading:false,
                isOFFButton:false,
                stateOf: ['Valid', 'Invalid'],
                disabled: 0,
                redrawElem: true,
                checkbox: false,
                sendValidation: true,
                dialogEditComment: false,
                updateComment: {},
                rules: {
                    category: [
                        {required: true, message: 'Capitolul este obligatoriu', trigger: 'blur'},
                    ],
                    budget: [
                        {required: true, message: 'Linia de buget este obligatorie', trigger: 'blur'},
                    ],
                    month: [
                        {required: true, message: 'Luna curenta este obligatorie', trigger: 'blur'},
                    ],
                    month_hours: [
                        {required: true, message: 'Orele lucrate lunar sunt obligatorii', trigger: 'blur'},
                    ],
                    worked_hours: [
                        {required: true, message: 'Orele efectiv lucrate sunt obligatorii', trigger: 'blur'},
                    ],
                    supplier: [
                        {required: true, message: 'Furnizorul este obligatoriu', trigger: 'blur'},
                    ],
                    supplier_name: [
                        {required: true,  message: 'Campul este obligatoriu', trigger: 'blur'},
                    ],
                    description: [
                        {required: true, message: 'Descrierea este obligatorie', trigger: 'blur'},
                    ],
                    invoice_no: [
                        {required: true, message: 'Numarul documentului este obligatoriu', trigger: 'blur'},
                    ],
                    invoice_date: [
                        {required: true, message: 'Data documentului este obligatoriu', trigger: 'blur'},
                    ],
                    receipt_no: [
                        {required: true, message: 'Numarul documentului este obligatoriu', trigger: 'blur'},
                    ],
                    receipt_date: [
                        {required: true, message: 'Data documentului este obligatoriu', trigger: 'blur'},
                    ],
                    amount_cost: [
                        {required: true, message: 'Valoarea este obligatorie', trigger: 'blur'},
                    ],
                    amount_financing: [
                        {required: true, message: 'Valoarea este obligatorie', trigger: 'blur'},
                    ],
                    amount_cofinancing: [
                        {required: true, message: 'Valoarea este obligatorie', trigger: 'blur'},
                    ],
                },
            }
        },

        computed:{
            ...mapGetters({
                showUploadDrawer: 'showUploadDrawer',
                showUploadDrawer2: 'showUploadDrawer2',
            }),
        },
        watch:{
            validateDocuments: function(){
                if(this.validateDocuments) {
                    this.submitForm();
                }
            }
        },
        methods:{
            ...mapGetters({
                validation: 'validation/project',
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
                setShowUploadDrawer2: 'setShowUploadDrawer2',
            }),
            ...mapActions({
                saveReportingAnswerLimit: 'reportings/saveReportingAnswerLimit',
                // saveValidation: 'reportings/saveValidation',
                editComment: 'reportings/editComment',
                deleteComment: 'reportings/deleteComment',
                saveAppStatus: 'reportings/saveAppStatus',
                setVrtReporting: 'reportings/setVrtReporting',
                getVrtExport: 'reportings/getVrtExport',
            }),
            disabledContractDate(date) {
                return date < this.contractMinDate
            },
            checkApp(type){
                this.loading = true;
                if(type === 1) {
                    this.saveAppStatus({id: this.reporting.id, financial: this.reporting.app_financial_report})
                        .then(_ => {
                             this.loading = false;
                        });
                }
                else {
                     this.saveAppStatus({id: this.reporting.id, technical: this.reporting.app_technical_report})
                        .then(_ => {
                             this.loading = false;
                        });
                }
            },
            requestInfoAddress() {
                this.loading = true;
                axios.get('/reportings/info-address/'+this.reporting.id).then((result) => {
                    this.loading = false;
                    this.$notify({ type: 'success', title: 'Adresa de informare', message: 'Solicitarea verificarii a fost trimisa succes!'});
                });
            },
            sendInfoAddress() {
                this.loading = true;
                axios.get('/reportings/sendInfoAddress/'+this.reporting.id).then((result) => {
                    this.loading = false;
                    this.$notify({ type: 'success', title: 'Adresa de informare', message: 'Adresa de informare a fost trimisa cu succes!'});
                });
            },
            openDrawerVRT(object){
                this.setShowUploadDrawer2({
                    visible: true,
                    model: 'App\\Models\\Reporting',
                    model_id: this.reporting.id,
                    updateDOCFiles: 1,
                    category_id: 557,
                    VRT: 1,
                    acceptFiles: '.pdf,.docx,.doc,.jpg,.xls', //.docx,.doc,
                    multiple: true,
                    reporting: this.reporting,
                    title: 'Vizualizare documente',
                    files: this.reporting.documents_vrt,
                });
            },
            openDrawerInfoAddress(object){
                this.setShowUploadDrawer2({
                    visible: true,
                    model: 'App\\Models\\Reporting',
                    model_id: this.reporting.id,
                    updateDOCFiles: 1,
                    category_id: 558,
                    InfoAddress: 1,
                    acceptFiles: '.pdf,.docx,.doc,.jpg,.xls', //.docx,.doc,
                    multiple: true,
                    reporting: this.reporting,
                    title: 'Vizualizare documente',
                    files: this.reporting.documents_info_address,
                });
            },
            UPDB() {
                this.loading = true;
                this.saveReportingAnswerLimit({
                    id: this.reporting.id,
                    date: moment(this.form.answer_limit).utc(true).endOf('day'),
                }).then(_ => {
                        this.reporting.answer_limit = this.form.answer_limit;
                        this.$notify({
                            type: 'success',
                            title: 'Data limita raspuns',
                            message: 'Data limita pentru a primi un raspuns a fost salvata cu succes!'
                        });
                        window.location.href = '/reportings/verify/'+this.project.id+'/'+this.reporting.id;
                });
            },
            saveVrt(){
                this.loading = true;
                this.setVrtReporting(this.vrtForm)
                    .then(_ => {
                        this.vrtDisabled = false;
                        this.loading = false;
                        this.$notify({
                            type: 'success',
                            title: 'VRT',
                            message: 'Datele au fost salvate cu succes!'
                        });
                    });
            },
            exportVrt(){
                this.$refs['vrtForm'].validate((valid) => {
                    if (valid) {
                        this.setVrtReporting(this.vrtForm)
                            .then(_ => {
                                this.getVrtExport({project_id: this.vrtForm.project_id, reporting_id: this.vrtForm.reporting_id});
                                // this.openUrl('/reportings/export-vrf/' + this.vrfForm.project_id + '/' + this.vrfForm.reporting_id, true);
                            })
                    }else{
                        VueScrollTo.scrollTo('#vrtForm');
                    }
                })
            },
            exportComments() {
                window.open('/reportings/export-comments/'+this.reporting.id, '_blank');
            },
            removeComment(index, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi acest comentariu?')
                    .then(_ => {
                        this.loading = true;
                        this.reporting.comments.splice(index, 1);
                        this.deleteComment({
                            id: row.id,
                        }).then(_ => {
                                this.loading = false;
                                this.$notify({
                                type: 'success',
                                title: 'Stergere comentariu',
                                message: 'Comentariul a fost sters cu succes din lista!'
                            });
                        });
                    }).catch(err => {
                    console.log(err)
                });
            },
            innitEditComment(row){
                this.dialogEditComment = true;
                this.form.new_comment = row.comment;
                this.form.id_comment = row.id;
                this.updateComment = row;
            },
            editCommentAction() {
                this.loading = true;
                this.dialogEditComment = false;
                this.editComment({
                    id: this.form.id_comment,
                    comment: this.form.new_comment,
                }).then(_ => {
                        this.updateComment.comment = this.form.new_comment;
                        this.loading = false;
                        this.$notify({
                        type: 'success',
                        title: 'Editare comentariu',
                        message: 'Comentariul a fost editat cu succes!'
                    });
                });
            },
            addComment() {
                this.isOFFButton = true;
                this.loading = true;
                axios.post('/reportings/saveCommentTechnical',
                {
                    comment: this.form.comment,
                    id:this.reporting.id
                }).then((result) => {
                    this.form.comment = "";
                    this.isOFFButton = false;
                    this.loading = false;
                    this.reporting.comments.push(result.data.comm);
                    this.$notify({ type: 'success', title: 'Ai adaugat un comentariu', message: 'Comentariul a fost adaugat cu succes!'});
                });
            },
            openDrawerComment(object){
                if(typeof object.files === 'undefined') object.files = [];
                this.setShowUploadDrawer2({
                    visible: true,
                    model: 'App\\Models\\Comment',
                    model_id: object.id,
                    acceptFiles: '.pdf,.docx,.doc,.jpg,.xls', //.docx,.doc,
                    multiple: true,
                    updateCommentFiles: 1,
                    comments: object,
                    title: '',
                    files: object.files,
                });
            },
            viewDocument()
            {
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Reporting',
                    model_id: this.reporting.id,
                    category_id: this.reporting.type,
                    acceptFiles: '.pdf',
                    multiple: false,
                    title: 'Vizualizare documente',
                    files: this.reporting.documents,
                });
            },
            viewDocumentIndicator(object){
                this.setShowUploadDrawer({
                    visible: true,
                    updateAfterClose: true,
                    model: 'App\\Models\\ReportingIndicator',
                    model_id: object.id,
                    category_id: object.index,
                    acceptFiles: '.pdf,.docx,.doc,.jpg,.xls', //.docx,.doc,
                    multiple: true,
                    title: 'Vizualizare documente',
                    files: object.documents_indicators,
                    object: object,
                });
            },
            viewDocumentActivity2(object){
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\ReportingTechnicalActivity',
                    model_id: object.id,
                    category_id: 1,
                    acceptFiles: '.pdf,.docx,.doc', //.docx,.doc,
                    multiple: true,
                    title: 'Vizualizare documente',
                    files: object.documentsactivity,
                    object: object,
                });
            },
            saveValidation() {
                if(!this.reporting.app_technical_report || !this.reporting.documents_vrt.length) {
                    this.$notify({
                        type: 'error',
                        title: 'VRT',
                        message: 'VRT-ul nu a fost inca incarcat & finalizat, nu poti aproba raportul!'
                    });
                    return 0;
                }
                this.$confirm('Doresti sa actualizezi calendarul proiectului cu datele din aceasta raportare tehnica?')
                    .then(_ => {
                        let exit = false;
                        let exit_message = null;
                        // verifica daca perioada adaugata se suprapune cu perioada aceleisi activitati

                        this.notification.forEach((row3, index) => {
                            row3.calendars.forEach((row, index) => {
                                this.reporting.calendars.forEach((row2, index) => {
                                    if(row.ID_Initial == row2.calendar_id && row.index !== row2.index){
                                        if(this.checkDateInInterval(row.start_date, row.end_date, row2.start_date, row2.end_date)){
                                            exit_message = moment.utc(row2.start_date).format('DD.MM.YYYY')+' - '+moment.utc(row2.end_date).format('DD.MM.YYYY');
                                            exit = true;
                                        }
                                    }
                                });
                            });
                        });
                        /*if(exit) {
                            this.$notify({ type: 'error', title: 'Calendar', message: 'Perioada ' + exit_message + ' se suprapune cu una din perioadele existente! Corectati si incercati din nou.'});
                            return false;
                        }
                        else {*/
                            this.$confirm('Esti sigur(a) ca vrei sa validezi aceasta raportare?' +
                                ' Dupa validare nu vei mai putea edita raportul!' +
                                ' Inainte de a merge mai departe verifica daca sunt incarcate VRT, VRF si Adresa de informare! ')
                                .then(_ => {
                                    this.loading = true;
                                    axios.post('/reportings/save',
                                        {
                                            reporting_id:this.reporting.id,
                                            project_id:this.project.id,
                                            saveCalendar: 1,
                                        }).then((result) => {
                                            window.location.href = '/reportings'
                                        });
                                }).catch(err => {
                                console.log(err)
                            });
                        //}
                    }).catch(err => {
                        let exit = false;
                        let exit_message = null;
                        // verifica daca perioada adaugata se suprapune cu perioada aceleisi activitati

                        this.notification.forEach((row3, index) => {
                            row3.calendars.forEach((row, index) => {
                                this.reporting.calendars.forEach((row2, index) => {
                                    if(row.ID_Initial == row2.calendar_id && row.index !== row2.index){
                                        if(this.checkDateInInterval(row.start_date, row.end_date, row2.start_date, row2.end_date)){
                                            exit_message = moment.utc(row2.start_date).format('DD.MM.YYYY')+' - '+moment.utc(row2.end_date).format('DD.MM.YYYY');
                                            exit = true;
                                        }
                                    }
                                });
                            });
                        });
                        /*if(exit) {
                            this.$notify({ type: 'error', title: 'Calendar', message: 'Perioada ' + exit_message + ' se suprapune cu una din perioadele existente! Corectati si incercati din nou.'});
                            return false;
                        }
                        else {*/
                            this.$confirm('Esti sigur(a) ca vrei sa validezi aceasta raportare?' +
                                ' Dupa validare nu vei mai putea edita raportul!' +
                                ' Inainte de a merge mai departe verifica daca sunt incarcate VRT, VRF si Adresa de informare! ')
                                .then(_ => {
                                    this.loading = true;
                                    axios.post('/reportings/save',
                                        {
                                            reporting_id:this.reporting.id,
                                            project_id:this.project.id,
                                        }).then((result) => {
                                            window.location.href = '/reportings'
                                        });
                                }).catch(err => {
                                console.log(err)
                            });
                        //}
                });
            },
            sendNotification() {
                this.$confirm('Esti sigur(a) ca vrei sa trimiti spre revizuire aceasta raportare? In cazul solicitarii de revizuire, promotorul va avea drept de editare completa a acestei raportari.')
                    .then(_ => {
                        this.loading = true;
                        axios.post('/reportings/reviewTechnical',
                            {
                                reporting_id: this.reporting.id,
                                project_id: this.project.id,
                            }).then((result) => {
                                window.location.href = '/reportings'
                            });
                    }).catch(err => {
                    console.log(err)
                });
            },
            sendNotificationClarification() {
                this.$confirm('Esti sigur(a) ca vrei sa trimiti spre revizuire aceasta raportare? In cazul acestei solicitari de clarificare, promotorul va avea si drept de editare pentru sectiunile "Indicatori", "Calendar" si intrebarile "4.1.a", "4.1.b", "4.1.c" si "6.1".')
                    .then(_ => {
                        this.loading = true;
                        axios.post('/reportings/reviewTechnical2',
                            {
                                reporting_id: this.reporting.id,
                                project_id: this.project.id,
                            }).then((result) => {
                                window.location.href = '/reportings'
                            });
                    }).catch(err => {
                    console.log(err)
                });
            },
            sendNotificationClarification2() {
                this.$confirm('Esti sigur(a) ca vrei sa trimiti spre clarificare aceasta raportare? In cazul acestei solicitari de clarificare, promotorul va putea transmite doar raspunsuri si documente suplimentare.')
                    .then(_ => {
                        this.loading = true;
                        axios.post('/reportings/reviewTechnical3',
                            {
                                reporting_id: this.reporting.id,
                                project_id: this.project.id,
                            }).then((result) => {
                                window.location.href = '/reportings'
                            });
                    }).catch(err => {
                    console.log(err)
                });
            },
            checkTerms() {
                if(this.checkbox === true){
                    this.sendValidation = false;
                }else{
                    this.sendValidation = true;
                }
            },
        }
    }
</script>
<style scoped>
/deep/ .el-form-item__error{
    margin-top:-5px;
    position: relative;
}
</style>
