export default{
    constructionWorks: [],
    indirectCosts: [],
    organizationalDevelopment: [],
    budgetFinancing: [],
    grantFraming: [],
    budgetCoFinancing: [],
    budgetNature: [],
    framingTimeGrant: [],
    appealNumber: [],
    budgetMaxGrantPromoter: [],
    budgetMaxGrantPartner: [],
    keepNerambursabil: [],
    maintainBudget: [],
    realocation: [],
}
