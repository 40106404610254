<template>
    <div>
        <div class="row mb-3">
            <div class="col-xs-12 col-sm-6">
                <small style="font-size:12px;">Apasa <i class="icofont-keyboard"></i> ENTER pentru a efectua o cautare in baza de date!</small>
                <el-input v-model="search" placeholder="Cauta promotor dupa denumire sau CIF" prefix-icon="el-icon-search" @change="getInfo()" clearable />
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12"> <!-- .filter(data => !search || data.name.toLowerCase().includes(search.toLowerCase()) || data.cif.toLowerCase().includes(search.toLowerCase()))-->
                <el-table :data="promoters" size="medium"
                         style="width: 100%" fit highlight-current-row v-loading="loading">
                    <!-- <el-table-column type="expand">
                        <template slot-scope="props">
                            <div class="row">
                                <div class="col-sm-4">
                                    <p>
                                        Denumire: <label>{{ props.row.name }}</label><br />
                                        CIF: <label>{{ props.row.cif ? props.row.cif : '' }}</label><br />
                                        An infiintare: <label>{{ props.row.year ? props.row.year : '' }}</label><br />
                                        Adresa: <label>{{ props.row.address ? props.row.address : '' }}</label><br />
                                        Oras, Judet: <label>{{ props.row.city ? props.row.city.name : '' }}{{ props.row.county ? (', '.props.row.county) : '' }}</label><br />
                                    </p>
                                </div>
                                <div class="col-sm-4">
                                    <p>
                                        Telefon: <label>{{ props.row.phone ? props.row.phone : '' }}</label><br />
                                        Email: <label>{{ props.row.email ? props.row.email : '' }}</label><br />
                                        Website: <label>{{ props.row.website ? props.row.website : '' }}</label><br />
                                    </p>
                                </div>
                                <div class="col-sm-4">
                                    <p>
                                        Creat la : <label>{{ props.row.created_at ? props.row.created_at : '' }}</label>
                                    </p>
                                </div>
                            </div>
                        </template>
                    </el-table-column>-->
                    <el-table-column prop="id" label="#" sortable width="50" align="right"></el-table-column>
                    <el-table-column prop="name" show-overflow-tooltip name="name" label="Denumire" sortable>
                        <template slot-scope="scope">
                            <el-tooltip effect="light" placement="top-start">
                                <div slot="content">{{ scope.row.name }}</div>
                                <a href="#" @click.prevent="openUrl('/promoters/show/' + scope.row.id)">{{ scope.row.name }}</a>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cif" name="cif" label="CIF" sortable width="120"></el-table-column>
                    <el-table-column prop="city.name" name="city" label="Localitate" sortable width="200"></el-table-column>
                    <el-table-column prop="projects" name="projects" label="Proiecte" sortable align="center" header-align="center" width="100">
                        <template slot-scope="scope">
                            <el-popover placement="right-start" width="500" trigger="click" v-if="scope.row.projects && scope.row.projects.length > 0">

                                <el-table :data="daPA" v-if="daPA.length > 0" style="width:100%" size="small">
                                    <el-table-column width="240" property="name" label="Proiect" show-overflow-tooltip>
                                        <template slot-scope="prscope">
                                            <a href="#" @click.prevent="openUrl('/projects/show/' + prscope.row.id, true)">{{ prscope.row.name }}</a>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="70" property="round.value" label="Runda"></el-table-column>
                                    <el-table-column width="60" property="appeal.value" label="Apel"></el-table-column>
                                    <el-table-column width="100" label="Buget" align="right" header-align="center">
                                        <template slot-scope="prscope">
                                            {{ formatPrice(prscope.row.total_budget) }}
                                        </template>
                                    </el-table-column>
                                </el-table>

                                <span slot="reference"><el-button @click="swapComponent(scope.row.projects)" round size="small">{{ scope.row.projects.length }}</el-button></span>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="Buget" header-align="center">
                        <el-table-column prop="updated_at" sortable label="Total" align="right" header-align="center" width="120">
                            <template slot-scope="scope">
                                <span v-if="scope.row.projects">{{ total(scope.row.projects,'total_budget',scope.row)  }}</span><br />
                            </template>
                        </el-table-column>
                        <el-table-column prop="created_at" sortable label="Finantat" align="right" header-align="center" width="120">
                            <template slot-scope="scope">
                                <span v-if="scope.row.projects">{{ total(scope.row.projects,'funding_budget',scope.row)  }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="deleted_at" sortable label="Co-finantat" align="right" header-align="center" width="120">
                            <template slot-scope="scope">
                                <span v-if="scope.row.projects">{{ total(scope.row.projects,'cofunding_budget',scope.row)  }}</span>
                            </template>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="Cont" align="center" header-align="center" width="60">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="light" placement="top-start" v-if="scope.row.accounts && scope.row.accounts.length > 0">
                                <div slot="content">
                                    <span>{{ scope.row.accounts[0].first_name}} {{ scope.row.accounts[0].last_name}}</span> <br/>
                                    <span><i class="el-icon-message"></i>{{ scope.row.accounts[0].email}}</span>
                                    <span v-if="scope.row.accounts[0].phone"><i class="el-icon-phone"></i>{{ scope.row.accounts[0].phone}}</span>
                                </div>
                                <i class="el-icon-user"></i>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column align="right" width="70" v-if="checkPermission(['promoter-write'])">
                        <template slot-scope="scope">
                            <el-dropdown @command="handleCommand" trigger="click">
                                <span class="el-dropdown-link">
                                    <i class="el-icon-more-outline"></i> <i class="el-icon-arrow-down el-icon--right"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item :command="[scope.row.id, 'edit']" v-if="checkPermission(['promoter-write'])">Date promotor</el-dropdown-item>
                                    <el-dropdown-item v-if="checkPermission(['promoter-user-write'])" :command="[scope.row.id, 'account']">Cont acces</el-dropdown-item>
                                    <el-dropdown-item :command="[scope.row.id, 'delete']" v-if="scope.row.projects.length === 0" divided>Sterge</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="col-sm-12 text-right">
                <el-pagination layout="prev, pager, next, total" class="bg-white" :hide-on-single-page="true" :page-size="pag_page"
                   :total="pag_total" :current-page="pag_current"  @current-change="handleCurrentChange"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>

    import { mapGetters, mapActions } from 'vuex'
    import mixin from '../../mixins/common';
    import {promoters} from "../../store/modules/promoter/getters";
    export default {
        name: "list",
        mixins:[mixin],
        props:{
            permissions:{
                required: true,
            },
        },
        data() {
            return {
                loading: false,
                search: '',
                daPA: '',
                pag_total: 0,
                pag_current: 1,
                pag_page: 20
            }
        },
        computed:{
            ...mapGetters({
                promoters: 'promoter/promoters',
            }),
        },
        methods:{
            ...mapActions({
                getPromoters: 'promoter/getPromoters',
                findSor: 'promoter/findSor',
                removePromoter: 'promoter/removePromoter'
            }),
            swapComponent: function(component) {
                this.daPA = component;
            },
            getInfo() {
                if(this.search.length > 0)
                {
                    this.loading = true;
                    this.pag_total = 0;
                    this.findSor(this.search).then((response) => { this.pag_total = response.total; this.loading = false; });
                }
                else this.getData(this.pag_current);
            },
            handleCommand(command){
                if(command[1] === 'delete'){
                    this.loading = true;
                    this.$confirm('Esti sigur(a) ca vrei sa stergi aceast promotor?')
                        .then(_ => {
                            this.removePromoter(command[0]).then(()=>{
                                this.getPromoters([this.pag_current,this.pag_page]).then(() => {
                                    this.loading = false
                                });
                                this.$notify({ type: 'success', title: 'Stergere date', message: 'Promotorul a fost sters cu succes!'});
                            });
                        }).catch(err => {
                            this.loading = false;
                            console.log(err)
                        });
                }else if(command[1] === 'edit'){
                    this.openUrl('/promoters/edit/'+command[0]+'/1');
                }else if(command[1] === 'account'){
                    this.openUrl('/promoters/account/'+command[0]);
                }
            },
            handleCurrentChange(val){
                this.pag_current = val;
               this.getData(val);
            },
            total(projects, column, row){
                let total = 0;
                projects.forEach((val) => {
                    if(column === 'total_budget') {
                        total += parseFloat(val.total_budget);
                    }else if(column === 'funding_budget'){
                        total += parseFloat(val.funding_budget);
                    }else if(column === 'cofunding_budget') {
                        total += parseFloat(val.cofunding_budget);
                    }else if(column === 'indirect_budget') {
                        total += parseFloat(val.indirect_budget);
                    }
                });

                if(column === 'total_budget') {
                    row.updated_at = total;
                }else if(column === 'funding_budget'){
                    row.created_at = total;
                }else if(column === 'cofunding_budget') {
                    row.deleted_at = total;
                }

                return this.formatPrice(total);
            },
            getData(page){
                this.loading = true;
                this.getPromoters([page,this.pag_page]).then((response) => {
                    this.pag_total = parseInt(response.total);
                    this.pag_current = parseInt(response.current_page);
                    this.pag_page = parseInt(response.per_page);
                    this.loading = false;
                });
            },
            checkPermission(permission){
                let perm = this.permissions;
                return _.filter(permission, function (item){
                    return _.includes(perm, item);
                }).length;
            }
        },
        mounted(){
            this.getData(this.pag_current);
        }
    }
</script>

<style scoped>
    .el-dropdown-link{
        cursor:pointer;
    }
</style>
