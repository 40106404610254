<template>
    <div v-if="project" id="validation">
        <h3 class="mb-4 mt-3">
            Validare proiect financiar
            <a href="#" @click.prevent="openUrl('/projects/export-budget/'+project.id, true)" title="Export in Excel" class="btn btn-sm btn-secondary ml-5">
                <i class="icofont-file-excel"></i> Export în Excel
            </a>
        </h3>
        <div class="row">
            <div class="col-sm-12">
                <el-collapse accordion>
                    <el-collapse-item name="1">
                        <template slot="title">
                            1. Lucrari de reabilitare
                            <div v-if="constructionWorks.message">
                                <div class="badge badge-success" v-if="constructionWorks.valid" style="margin-left:10px;"><i class="el-icon-success"></i> {{ constructionWorks.message }}</div>
                                <div class="badge badge-danger" v-if="!constructionWorks.valid" style="margin-left:10px;"><i class="el-icon-error"></i> {{ constructionWorks.message }}</div>
                            </div>
                        </template>
                        <div style="font-weight:bold;">Costurile aferente constructiei, reconstructiei, renovarii sau modernizarii unui imobil pot fi de maxim 50% din total costuri directe eligibile.</div>
                    </el-collapse-item>

                    <el-collapse-item name="2">
                        <template slot="title">
                            2. Costuri indirecte
                            <div v-if="indirectCosts.message">
                                <div class="badge badge-success" v-if="indirectCosts.valid" style="margin-left:10px;"><i class="el-icon-success"></i> {{ indirectCosts.message }}</div>
                                <div class="badge badge-danger" v-if="!indirectCosts.valid" style="margin-left:10px;"><i class="el-icon-error"></i> {{ indirectCosts.message }}</div>
                            </div>
                        </template>
                        <div style="font-weight:bold;">Suma bugetata pentru costurile indirecte nu trebuie sa depaseasca 15% din totalul costurilor cu personalul angajat in proiect (costurile aferente liniei bugetare 1.1.1. Resurse umane).</div>
                    </el-collapse-item>

                    <el-collapse-item name="3">
                        <template slot="title">
                            3. Costuri dezvoltare organizationala
                            <div v-if="organizationalDevelopment.message">
                                <div class="badge badge-success" v-if="organizationalDevelopment.valid" style="margin-left:10px;"><i class="el-icon-success"></i> {{ organizationalDevelopment.message }}</div>
                                <div class="badge badge-danger" v-if="!organizationalDevelopment.valid" style="margin-left:10px;"><i class="el-icon-error"></i> {{ organizationalDevelopment.message }}</div>
                            </div>
                        </template>
                        <div style="font-weight:bold;">Exista posibilitatea de a aloca pana la 20% din totalul finantarii nerambursabile a proiectului pentru activitati care sa contribuie la dezvoltarea organizationala a Solicitantului si / sau a partenerilor ONG din Romania cu exceptia Outcome-ului 4 Capcitate si sustenabilitate crescute ale Societatii Civile.
                            In aceasta situatie, Solicitantii vor trebui sa stabileasca obiective si activitati separate de dezvoltare organizationala si sa le mentioneze ca atare in Cererea de finantare.
                            Nu este acceptata includerea de costuri de dezvoltare organizationala in buget fara a specifica in cuprinsul Cererii de finantare obiectivul / ele de dezvoltare organizationala avut / e in vedere. Costurile cu achizitia de echipamente vor fi acceptate doar daca sustin obiective de dezvoltare organizationala specificate in aplicatie.
                        </div>
                    </el-collapse-item>

                    <!-- <el-collapse-item name="4">
                        <template slot="title">
                            4. Ponderea finantarii solicitata in bugetul total
                            <div v-if="budgetFinancing.message">
                                <div class="badge badge-success" v-if="budgetFinancing.valid" style="margin-left:10px;"><i class="el-icon-success"></i> {{ budgetFinancing.message }}</div>
                                <div class="badge badge-danger" v-if="!budgetFinancing.valid" style="margin-left:10px;"><i class="el-icon-error"></i> {{ budgetFinancing.message }}</div>
                            </div>
                        </template>
                        <div style="font-weight:bold;">Suma maxima ce poate fi acordata drept grant pentru un proiect este de 90% din totalul costurilor eligibile ale proiectului.
                            In functie de componenta/subcomponenta pe care ati aplicat exista limitari nominale in functie de tipul de grant solicitat (mic, mediu sau mare).</div>
                    </el-collapse-item> -->

                    <el-collapse-item name="4">
                        <template slot="title">
                            4. Incadrarea in plafoanele nominale pentru tipul de grant solicitat
                            <div v-if="grantFraming.message">
                                <div class="badge badge-success" v-if="grantFraming.valid" style="margin-left:10px;"><i class="el-icon-success"></i> {{ grantFraming.message }}</div>
                                <div class="badge badge-danger" v-if="!grantFraming.valid" style="margin-left:10px;"><i class="el-icon-error"></i> {{ grantFraming.message }}</div>
                            </div>
                        </template>
                        <div></div>
                    </el-collapse-item>


                    <!-- <el-collapse-item name="6">
                        <template slot="title">
                            6. Ponderea cofinantarii in bugetul total
                            <div v-if="budgetCoFinancing.message">
                                <div class="badge badge-success" v-if="budgetCoFinancing.valid" style="margin-left:10px;"><i class="el-icon-success"></i> {{ budgetCoFinancing.message }}</div>
                                <div class="badge badge-danger" v-if="!budgetCoFinancing.valid" style="margin-left:10px;"><i class="el-icon-error"></i> {{ budgetCoFinancing.message }}</div>
                            </div>
                        </template>
                        <div></div>
                    </el-collapse-item>

                    <el-collapse-item name="7">
                        <template slot="title">
                            7. Ponderea contributiei in natura in cofinantare
                            <div v-if="budgetNature.message">
                                <div class="badge badge-success" v-if="budgetNature.valid" style="margin-left:10px;"><i class="el-icon-success"></i> {{ budgetNature.message }}</div>
                                <div class="badge badge-danger" v-if="!budgetNature.valid" style="margin-left:10px;"><i class="el-icon-error"></i> {{ budgetNature.message }}</div>
                            </div>
                        </template>
                        <div style="font-weight:bold;">Suma bugetata pentru contributia in natura poate sa reprezinte pana la 50% din cofinantare.</div>
                    </el-collapse-item> -->

                    <el-collapse-item name="5">
                        <template slot="title">
                            5. Suma maxima de finantare nerambursabila pentru promotor
                            <div v-if="budgetMaxGrantPromoter.message">
                                <div class="badge badge-success" v-if="budgetMaxGrantPromoter.valid" style="margin-left:10px;"><i class="el-icon-success"></i> {{ budgetMaxGrantPromoter.message }}</div>
                                <div class="badge badge-danger" v-if="!budgetMaxGrantPromoter.valid" style="margin-left:10px;"><i class="el-icon-error"></i> {{ budgetMaxGrantPromoter.message }}</div>
                            </div>
                        </template>
                        <div style="font-weight:bold;">Suma maxima de finantare nerambursabila de care beneficiaza promotorul proiectului din programul ACF,
                            atat ca promotor cat si ca partener, nu depaseste 550.000 euro sau numarul maxim de proiecte pe care
                            le gestioneaza promotorul proiectului prin programul ACF, atat ca promotor cat si ca partener, este de 4 proiecte.</div>
                    </el-collapse-item>

                    <el-collapse-item name="6">
                        <template slot="title">
                            6. Suma maxima de finantare nerambursabila pentru parteneri
                            <div v-if="budgetMaxGrantPartner.message">
                                <div class="badge badge-success" v-if="budgetMaxGrantPartner.valid" style="margin-left:10px;"><i class="el-icon-success"></i> {{ budgetMaxGrantPartner.message }}</div>
                                <div class="badge badge-danger" v-if="!budgetMaxGrantPartner.valid" style="margin-left:10px;"><i class="el-icon-error"></i> {{ budgetMaxGrantPartner.message }}</div>
                            </div>
                        </template>
                        <div style="font-weight:bold;">Suma maxima de finantare nerambursabila de care beneficiaza promotorul proiectului din programul ACF,
                            atat ca promotor cat si ca partener, nu depaseste 550.000 euro sau numarul maxim de proiecte pe care
                            le gestioneaza promotorul proiectului prin programul ACF, atat ca promotor cat si ca partener, este de 4 proiecte.</div>
                    </el-collapse-item>
                </el-collapse>
            </div>
        </div>
        <div class="row">
            <h3 class="mb-4 mt-3">Note</h3>
            <div class="col-sm-12">
                <el-table :data="project.comments" id="commentList" stripe fit highlight-current-row style="width:100%">
                    <el-table-column label="Note" prop="comment"></el-table-column>
                    <el-table-column label="Utilizator" prop="user.name" width="160px">
                        <template slot-scope="scope">
                            {{ scope.row.user.first_name }} {{ scope.row.user.last_name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Date" width="160px" prop="created_at"></el-table-column>
                </el-table>
            </div>
        </div>
        <el-form :model="project" id="validationForm" ref="validation" label-position="top" :status-icon="true" :inline-message="true">
            <div class="row mt-4">
                <div class="col-sm-12 form-group">
                    <el-form-item label="Adauga o nota la proiect" prop="comment">
                        <el-input v-model="project.comment" placeholder="Introduceti eventualele mentiuni legate de proiect" clearable
                                  type="textarea" :rows="3" maxlength="1500" show-word-limit>
                        </el-input>
                    </el-form-item>
                </div>
            </div>
        </el-form>
        <div class="row">
            <div class="col-sm-12 text-right mb-5">
                <el-button type="secondary" @click="validateProject">Valideaza proiectul</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from 'vuex'
    import VueScrollTo from 'vue-scrollto';
    import mixin from './../../mixins/common';
    import {validateConstructionWorks} from "../../store/modules/validation/actions";
    export default {
        name: "project-validation-form",
        mixins:[mixin],
        data(){
            return {
                form:{},
            }
        },
        computed:{
            ...mapGetters({
                project: 'project/project',
                validateValidation: 'project/validateValidation',
                validationErrorsExists: 'validationErrorsExists',
                constructionWorks: 'validation/constructionWorks',
                indirectCosts: 'validation/indirectCosts',
                organizationalDevelopment: 'validation/organizationalDevelopment',
                //budgetFinancing: 'validation/budgetFinancing',
                grantFraming: 'validation/grantFraming',
                //budgetCoFinancing: 'validation/budgetCoFinancing',
                //budgetNature: 'validation/budgetNature',
                budgetMaxGrantPromoter: 'validation/budgetMaxGrantPromoter',
                budgetMaxGrantPartner: 'validation/budgetMaxGrantPartner',
                // framingTimeGrant: 'validation/framingTimeGrant',
                // appealNumber: 'validation/appealNumber',
            }),
        },
        watch:{
            validateValidation: function(){
                if(this.validateValidation) {
                    this.submitForm();
                }
            },
        },
        methods:{
            ...mapActions({
                validateConstructionWorks: 'validation/validateConstructionWorks',
                validateIndirectCosts: 'validation/validateIndirectCosts',
                validateOrganizationalDevelopment: 'validation/validateOrganizationalDevelopment',
                //validateBudgetFinancing: 'validation/validateBudgetFinancing',
                validateGrantFraming: 'validation/validateGrantFraming',
                //validateBudgetCoFinancing: 'validation/validateBudgetCoFinancing',
                //validateBudgetNature: 'validation/validateBudgetNature',
                validateMaxGrantPromoter: 'validation/validateMaxGrantPromoter',
                validateMaxGrantPartner: 'validation/validateMaxGrantPartner',
                // validateFramingTimeGrant: 'validation/validateFramingTimeGrant',
                // validateAppealNumber: 'validation/validateAppealNumber',
            }),
            ...mapMutations({
                setValidateValidation: 'project/setValidateValidation',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            validateProject(){
                this.validateConstructionWorks({id:this.project.id});
                this.validateIndirectCosts({id:this.project.id});
                this.validateOrganizationalDevelopment({id:this.project.id});
                //this.validateBudgetFinancing({id:this.project.id});
                this.validateGrantFraming({id:this.project.id});
                //this.validateBudgetCoFinancing({id:this.project.id});
                //this.validateBudgetNature({id:this.project.id});
                this.validateMaxGrantPromoter({id:this.project.id});
                this.validateMaxGrantPartner({id:this.project.id});
                // this.validateFramingTimeGrant({id:this.project.id});
                // this.validateAppealNumber({id:this.project.id});
                VueScrollTo.scrollTo('#validation');
            },
            submitForm() {
                this.validateProject();
                if(this.constructionWorks.valid && this.indirectCosts.valid && this.organizationalDevelopment.valid && this.grantFraming.valid
                    && this.budgetMaxGrantPromoter.valid && this.budgetMaxGrantPartner.valid) {
                    console.log('valid');
                    // && this.validateMaxGrantPromoter
                    this.setValidateValidation(false);
                    this.setValidationErrorsExists(false);
                    return true;
                }{
                    console.log('invalid');
                    this.setValidateValidation(false);
                    this.setValidationErrorsExists(true);
                    return false;
                }
            },
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
