<template>
    <div>
        <div class="row" v-if="promoter.employees" v-for="repr in promoter.employees">
            <div class="col-sm-3">
                <label>Prenume</label>
                <p>{{ repr.first_name }}</p>
            </div>
            <div class="col-sm-3">
                <label>Nume</label>
                <p>{{ repr.last_name }}</p>
            </div>
            <div class="col-sm-6"></div>
        </div>
        <div v-else class="alert alert-info">
            Nu exista reprezentanti legali pentru aceasta firma
        </div>
    </div>
</template>
<script>
    export default {
        name: "promoter-employees-pane",
        props:{
            promoter: {
                required: true,
                type: Object
            }
        },
    }
</script>

<style scoped>

</style>
