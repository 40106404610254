<template>
<!--    <el-table :data="partner" style="width:100%" size="small" v-loading="loading">-->
<!--        <el-table-column width="200" property="name" label="Partener">-->
<!--            <template slot-scope="scope">-->
<!--                {{ scope.row.name }}-->
<!--            </template>-->
<!--        </el-table-column>-->
<!--        <el-table-column width="130" label="Buget" align="right" header-align="center">-->
<!--            <template slot-scope="scope">-->
<!--                {{ amounts[0] }} {{ formatPrice(amounts[0]) }}-->
<!--            </template>-->
<!--        </el-table-column>-->
<!--    </el-table>-->
    <div class="partner-budget">
        <el-row>
            <el-col :span="12" size="mini">
                <small>Buget total:</small><br />{{ formatPrice(amounts.budget_total) }}<br />
                <small>Finantare:</small><br />{{ formatPrice(amounts.financing_total) }}<br />
                <small>Co-Finantare:</small><br />{{ formatPrice(amounts.cofinancing_total) }}<br />
            </el-col>
            <el-col :span="12" size="mini">
                <small>Indirecte:</small><br /> {{ formatPrice(amounts.indirect_total) }}<br />
                <small>Dezv. Org.:</small><br />{{ formatPrice(amounts.budget_dezv) }}<br />
                <br />
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import { mapActions } from 'vuex'
    import mixin from '../../mixins/common';
    export default {
        name: "comp-partner-budget",
        mixins:[mixin],
        props:{
            amounts:{
                required: true,
                type: Object
            },
        },
        data(){
            return {
                loading: false,
            }
        },
        methods: {
            // ...mapActions({
            //     getPartnerBudget: 'project/getPartnerBudget',
            // }),
            // checkPartnerBudget(project_id, partner_id){
            //     this.getPartnerBudget({project_id: project_id, partner_id: partner_id}).then((result)=>{
            //         this.amounts = result;
            //     });
            // },
        },
        mounted(){
            // this.checkPartnerBudget(this.project_id, this.partner.id);
        },
    }
</script>

<style scoped>
    .partner-budget{
        line-height: 14px;
    }
    small{color:#909399;}
</style>
