<template>
    <div>
        <div class="row mb-5">
            <div class="col-sm-6">
                <h3>Selecteaza utilizator promotor</h3>
                <el-select v-model="allUsers" placeholder="Selecteaza" v-on:input="limiter" filterable multiple clearable style="width:100%"
                           @change="updateUsers" value-key="id">
                    <el-option v-for="item in users"
                               :key="item.index" :label="item.first_name + ' ' + item.last_name" :value="item">
                        <span style="float: left">{{ item.first_name }} {{ item.last_name }} ({{ item.email }})</span>
                        <span style="float: right; color: #8492a6; font-size: 13px" v-for="(role, i) in item.all_roles">{{ role.display_name }}<span v-if="i">/</span></span>
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="row">
            <div class="col"><hr></div>
            <div class="col-auto">sau</div>
            <div class="col"><hr></div>
        </div>
        <el-form :model="form" :rules="rules" ref="account" label-position="top" :status-icon="true" :inline-message="true">
            <h3 class="mb-4">
                <template v-if="form.pivot && form.pivot.user_id">
                    Modifica cont access aplicatie
                    <a title="Trimite notificare configurare parola" href="#" @click.prevent="notify" class="btn btn-secondary btn-circle ml-5"><i :class="notification_icon"></i></a>
                </template>
                <template v-else>
                    Creeaza cont access aplicatie
                </template>
            </h3>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Prenume" prop="first_name" :error="validationErrors.get('first_name')">
                            <el-input v-model="form.first_name" placeholder="Introduceti prenumele utilizatorului" clearable
                                  type="text" maxlength="30" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group required">
                        <el-form-item label="Nume" prop="last_name" :error="validationErrors.get('last_name')">
                            <el-input id="lastName" v-model="form.last_name" placeholder="Introduceti numele utilizatorului" clearable
                                      type="text" maxlength="30" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group required">
                        <el-form-item label="Adresa de e-mail" prop="email" :error="validationErrors.get('email')" :show-message="true" :validate-status="'error'">
                            <el-input id="email" v-model="form.email" placeholder="Introduceti adresa de e-mail" clearable :disabled="disabled"
                                  type="text" maxlength="60" show-word-limit></el-input>
                            <p class="mt-0" v-if="!disabled">ATENTIE: dupa finalizare adresa de email nu mai poate fi modificata</p>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group required">
                        <el-form-item label="Numar de telefon" prop="phone" :error="validationErrors.get('phone')">
                            <el-input id="phone" v-model="form.phone" placeholder="Introduceti numarul de telefon" clearable
                                      type="tel" maxlength="20" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <a href="/promoters" class="ml-2 btn btn-default" >Anuleaza, m-am razgandit</a>
                </div>
                <div class="col-sm-6">
                    <button type="submit" class="btn btn-primary" @click.prevent="saveForm">Salveaza modificari</button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from 'vuex'
    import mixin from './../../mixins/common';

    export default {
        name: "account-form",
        mixins:[mixin],
        props:{
            data:{
                required: true,
                type: Object,
            },
            users:{
                required: true,
                type: Array,
            },
        },
        data(){
            return {
                form: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                },
                allUsers: null,
                notification_icon: 'icofont-ui-password',
                disabled: false,
                rules: {
                    first_name: [
                        { required: true, message: 'Completati prenumele utilizatorului', trigger: 'blur' },
                        { min: 3, max: 30, message: 'Lungimea trebuie sa fie intre 3 si 30 caractere', trigger: 'blur' }
                    ],
                    last_name: [
                        { required: true, message: 'Completati numele utilizatorului', trigger: 'blur' },
                        { min: 3, max: 30, message: 'Lungimea trebuie sa fie intre 3 si 30 caractere', trigger: 'blur' }
                    ],
                    email: [
                        { type: 'email', required: true, message: 'Completati adresa de email', trigger: 'blur' }
                    ],
                    phone: [
                        { min: 8, max: 20, message: 'Telefonul trebuie sa fie intre 8 si 20 caractere', trigger: 'blur' }
                    ]
                },
            }
        },
        computed:{
            ...mapGetters({
                // validatePromoterAccount: 'promoter/validatePromoterAccount',
                validationErrors: 'validationErrors',
                validationErrorsExists: 'validationErrorsExists'
            }),
        },
        watch:{
            // validatePromoterAccount: function(){
            //     if(this.validatePromoterAccount) {
            //         this.submitForm();
            //     }
            // }
        },
        mounted(){
            // if(!this.data.accounts){
            //     this.data.accounts.push({
            //         'id' : null,
            //         'first_name' : '',
            //         'last_name' : '',
            //         'email' : '',
            //         'phone' : '',
            //         'role' : this.role ?? null,
            //         'group' :  this.group ?? null,
            //     })
            // }else {
            if(this.data.accounts.length > 0){
                this.form = this.data.accounts[0];
            }
            this.disabled = !!this.form.id;
            this.allUsers = this.data.accounts;
        },
        methods: {
            ...mapMutations({
                // setValidationErrors: 'setValidationErrors',
                // setValidatePromoter: 'promoter/setValidatePromoter',
                // setPromoterValidated: 'promoter/setPromoterValidated',
                // setValidatePromoterAccount: 'promoter/setValidatePromoterAccount',
                // setPromoterAccountValidated: 'promoter/setPromoterAccountValidated',
            }),
            ...mapActions({
                saveAccount: 'saveAccount',
                assignPromoter: 'promoter/assignPromoter',
                validateNewUser: 'validateNewUser',
                savePromoterUsers: 'promoter/savePromoterUsers',
                sendAccountNotification: 'promoter/sendAccountNotification'
            }),
            limiter(e) {
              if(e.length > 1) {
                this.$notify({type: 'error', title: 'Alocare responsabili', message: 'Nu poti selecta mai mult de o persoana!'});
                e.pop()
              }
            },
            // submitForm() {
            //     this.clear();
            //     if(!this.form.id) {
            //         this.validateNewUser(this.form).then(response => {
            //             if (response.errors) {
            //                 return false;
            //             } else {
            //                 this.validateForm();
            //                 return true;
            //             }
            //         });
            //     }else{
            //         this.validateForm();
            //     }
            // },
            updateUsers(){
                this.loading = true;
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        this.savePromoterUsers({'id': this.data.id, 'accounts': this.allUsers}).then(response => {
                            if(response.valid) {
                                this.$notify({ type:'success', title: 'Alocare responsabili', message: 'Datele au fost salvate cu succes!' });
                                // this.form = this.allUsers[this.allUsers.length-1];
                                if(response.promoter.accounts.length > 0) {
                                    this.form = response.promoter.accounts[0];
                                    this.disabled = true;
                                }else{
                                    this.disabled = false;
                                    this.form = {
                                        first_name: '',
                                        last_name: '',
                                        email: '',
                                        phone: '',
                                    }
                                }
                            }else{
                                this.$notify({type: 'error', title: 'Alocare responsabili', message: response.message});
                            }
                        }, error => {
                            if(error.response.status === 422){
                                this.$notify({ type:'error', title: 'Alocare responsabili', message: error.response.data.errors });
                            }
                        });
                        this.loading = false;
                    }, 1000)
                })
            },
            saveForm() {
                this.$refs['account'].validate((valid) => {
                    this.saveAccount(this.form).then((response) => {
                        this.assignPromoter({
                            promoter_id : this.data.id,
                            user_id : response.id,
                            role : 'promoter',
                            group: 'promoter'
                        }).then(result => {
                            this.$notify({ type:'success', title: 'Creare cont promotor', message: 'Datele promotorului au fost salvate cu succes!' });
                            setTimeout(function() {
                                window.location.href = '/promoters'
                            },1000);
                        })
                    });
                    // if (!this.validationErrorsExists && valid) {
                    //     this.setPromoterAccountValidated(true);
                    //     return true;
                    // } else {
                    //     this.$notify({
                    //         type: 'warning',
                    //         title: 'Validare date',
                    //         message: 'Datele introduse nu sunt corecte. Verificati si incercati din nou.'
                    //     });
                    //     return false;
                    // }
                });
            },
            clear(){
                this.setValidationErrors({});
                this.setValidatePromoterAccount(false);
                this.setPromoterAccountValidated(false);
            },
            notify(){
                this.notification_icon = 'el-icon-loading';
                this.sendAccountNotification(this.form).then(result => {
                    this.$notify({ type:'success', title: 'Notificare promotor', message: 'Promotorul a fost notificat cu succes!'});
                    this.notification_icon = 'icofont-ui-password';
                });
            }
        }
    }
</script>

<style scoped>

</style>
