<template>
    <div v-loading="loading">
        <small class="text-muted mb-0">Numele proiectului</small>
        <h3 class="mb-4">{{ selectedProject.name }}</h3>
        <el-form :model="form" id="notificationForm" :validate-on-rule-change="false" v-loading="loading"
                 :rules="rules" ref="notificationForm" label-position="top" :status-icon="true" :inline-message="true">
            <div class="row">
                <div class="col-sm-12">
                    <el-form-item label="Descrierea notificării" v-if="form.type" prop="description">
                        <el-input v-model="form.description" placeholder="Descrieți cât mai detaliat modificarea dorită" type="textarea" rows="10" clearable maxlength="5000" show-word-limit></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <el-form-item label="Incarca documente justificative (unde este cazul)" v-if="form.type">
                        <el-button type="secondary" @click="uploadFiles(form)">
                            <span v-if="!form.files">Adaugă fișiere</span>
                            <span v-else>Vezi/modifică fișiere</span>
                        </el-button>

<!--                        <el-upload  action="https://jsonplaceholder.typicode.com/posts/" :on-change="handleChange" :file-list="fileList" multiple>-->
<!--                            <el-button size="small" type="secondary">Adaugă fișiere</el-button>-->
<!--                            <div slot="tip" class="el-upload__tip">Sunt permise fișiere pdf, jpg, png, doc, docx, xls, xlsx de maxim 1MB</div>-->
<!--                        </el-upload>-->
                    </el-form-item>
                </div>
            </div>
            <div class="input-group">
                <textarea placeholder="Adauga un comentariu" v-on:change="saveComment" v-model="form.comment_sec0" class="form-control custom-control" rows="3" style="resize:none"></textarea>
                <el-button class="input-group-addon btn btn-primary" icon="el-icon-edit" @click="uploadFilesComment(form)"></br>Vezi/modifică</br>fișierele</br>comentariului</el-button>
            </div>
            <hr />
            <div class="form-group">
                <h5>Lista comentarii:</h5>
                <span style="white-space: pre-line;">{{ form.comment_sec0 }}</span>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';
    import moment from 'moment';
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "notification-form",
        mixins:[mixin],
        props:{
            selectedProject:{
                type: Object,
                required: true,
            },
            selectedNotification:{
                type: Object,
                required: false,
            }
        },
        data(){
            return {
                loading:true,
                form:{},
                minDate: new Date(),
                maxDate: new Date(),
                projectPeriodOptions: {
                    disabledDate: this.disabledPeriodDate
                },
                rules: {
                    type: [
                        {required: true, message: 'Tipul modificării este obligatoriu', trigger: 'blur'},
                    ],
                    changes: [
                        {required: true, message: 'Aplicantul sau partenerul este obligatoriu', trigger: 'blur'},
                    ],
                    description: [
                        {required: true, message: 'Descrierea este obligatorie', trigger: 'blur'},
                    ],
                },
            }
        },
        computed:{
            ...mapGetters({
                notification: 'notification/notification',
                validateNotification: 'notification/validateNotification',
            }),
        },
        watch:{
            notification: function(){
                if(this.notification) {
                    this.form = this.notification;
                }
            },
            showUploadDrawer:function(){
                if(this.notification) {
                    this.form = this.notification;
                }
            },
            validateNotification: function(){
                if(this.validateNotification) {
                    this.setValidateNotification(false);
                    this.submitForm();
                }
            },
        },
        mounted(){
            this.clear();
            this.contractMaxDate = moment(this.selectedProject.implementation_end_date);
            if(this.selectedNotification){
                this.form = this.selectedNotification;
            }
        },
        methods:{
            ...mapActions({
                saveNotification: 'notification/saveNotification',
                saveNotificationComment: 'notification/saveNotificationComment',
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
                setValidateNotification: 'notification/setValidateNotification',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            saveComment()
            {
                this.saveNotificationComment({
                    id: this.form.id ? this.form.id : 0,
                    project_id: this.form.project_id ? this.form.project_id : this.selectedProject.id,
                    section_id: 0,
                    comment: this.form.comment_sec0,
                });
            },
            disabledPeriodDate(date) {
                return !(date >= this.minDate && date <= this.contractMaxDate);
            },
            clear(){
                this.loading = false;
            },
            uploadFiles(form){
                this.saveNotification({
                    id: form.id ? form.id : 0,
                    project_id: form.project_id ? form.project_id : this.selectedProject.id,
                    type: form.type,
                    changes: form.changes,
                    change_date: form.change_date,
                    description: form.description,
                }).then(result => {
                    this.setShowUploadDrawer({
                        visible: true,
                        model: 'App\\Models\\Notification',
                        model_id: form.id,
                        category_id: 0,
                        acceptFiles: '.pdf,.docx,.doc,.xlsx,.xls',
                        multiple: true,
                        title: 'Incarcă documente',
                        files: form.files ? form.files : [],
                    });
                });
            },
            uploadFilesComment(form)
            {
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Notification',
                    model_id: form.id,
                    category_id: 1,
                    acceptFiles: '.pdf,.docx,.doc,.xlsx,.xls',
                    multiple: true,
                    title: 'Incarcă documente',
                    files: form.commentfiles.filter(p => p.category_id == 1),
                });
            },
            submitForm() {
                this.$refs['notificationForm'].validate((valid) => {
                    if (valid) {
                        this.saveNotification({
                            id: this.form.id ? this.form.id : 0,
                            project_id: this.form.project_id ? this.form.project_id : this.selectedProject.id,
                            type: this.form.type,
                            changes: this.form.changes,
                            change_date: this.form.change_date,
                            description: this.form.description,
                        }).then(() => {
                            // this.setValidateNotification(false);
                            this.setValidationErrorsExists(false);
                            return true;
                        })
                    } else {
                        // this.setValidateNotification(true);
                        this.setValidationErrorsExists(true);
                        return false;
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
