<template>
    <div v-if="project" id="topActivities">
        <h3 class="mb-4 mt-3">Activitati proiect</h3>
        <div class="row">
            <div class="col-sm-12">
                <el-table :data="project.activities" stripe fit highlight-current-row style="width:100%">
                    <el-table-column label="Activitate" width="100px" align="center" prop="activity">
                        <template slot-scope="scope" >
<!--                            A{{ scope.$index+1 }}-->
                            A{{ scope.row.activity }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Denumire" prop="name"></el-table-column>
                    <el-table-column label="Descriere" prop="description">
                        <template slot-scope="scope">
                            <el-popover placement="top-start" title="Descriere" width="300" trigger="click" :content="scope.row.description">
                                <span  slot="reference">{{ scope.row.description | truncate(25, '...') }}</span>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="Rezultate" prop="results">
                        <template slot-scope="scope">
                            <el-popover placement="top-start" title="Rezultate" v-if="scope.row.results" width="300" trigger="click" :content="scope.row.results">
                                <span  slot="reference">{{ scope.row.results | truncate(25, '...') }}</span>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="Resurse" prop="resources">
                        <template slot-scope="scope">
                            <el-popover placement="top-start" title="Resurse" v-if="scope.row.resources" width="300" trigger="click" :content="scope.row.resources">
                                <span  slot="reference">{{ scope.row.resources | truncate(25, '...') }}</span>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="Actiuni">
                        <template slot-scope="scope">
                            <el-button size="mini" type="secondary" icon="el-icon-edit"
                                       @click="selectActivity(scope.row)"></el-button>
                            <el-button size="mini" v-if="scope.$index > 1 && (!scope.row.budget || (scope.row.budget && scope.row.budget.length === 0))" type="danger" icon="el-icon-delete"
                                       @click="handleDelete(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-form :model="form" id="form" v-show="this.project.activities && parseInt(this.form.activity) <= 14" :rules="rules" ref="settings" label-position="top" :status-icon="true" :inline-message="true">
            <div class="row">
                <div class="col-sm-12">
                    <h4 class="mb-4 mt-5">Adauga/modifica activitatea  <strong>A{{form.activity}}</strong></h4>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Denumire" prop="name">
                            <el-input v-model="form.name" placeholder="Denumirea activitatii" clearable
                                      type="textarea" :rows="3" maxlength="200" show-word-limit>
                            </el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-6 form-group">
                    <el-form-item label="Descrierea activitatii" prop="description">
                        <el-input v-model="form.description" placeholder="Descrierea activitatii" clearable
                                  type="textarea" :rows="6" maxlength="1500" show-word-limit>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-6">
                    <el-form-item label="Descrierea rezultatelor asteptate" prop="results" class="form-group">
                        <el-input v-model="form.results" placeholder="Descrierea rezultatelor" clearable
                                  type="textarea" :rows="6" maxlength="2000" show-word-limit>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-6">
                    <el-form-item label="Resursele necesare / Justificarea bugetului" prop="resources" class="form-group">
                        <el-input v-model="form.resources" placeholder="Resursele necesare" clearable
                                  type="textarea" :rows="6" maxlength="2000" show-word-limit>
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-12 text-right mb-5">
                    <el-button @click.prevent="clear">Goleste formularul</el-button>
                    <el-button @click.prevent="addActivity" type="secondary">Aplica modificarile</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "project-activities-form",
        data(){
            return {
                form:{},
                rules: {
                    name: [
                        {required: true, message: 'Denumirea este obligatorie', trigger: 'blur'},
                        { min: 3, max: 200, message: 'Lungimea trebuie sa fie intre 3 si 200 caractere', trigger: 'blur' }
                    ],
                    description: [
                        {required: true, message: 'Descrierea activitatii este obligatorie', trigger: 'blur'},
                        { min: 20, max: 1500, message: 'Lungimea trebuie sa fie intre 20 si 1500 caractere', trigger: 'blur' }
                    ],
                    // results: [
                    //     {required: true, message: 'Descrierea rezultatelor este obligatorie', trigger: 'blur'},
                    // ],
                    // resources: [
                    //     {required: true, message: 'Resursele necesare sunt obligatorii', trigger: 'blur'},
                    // ]
                },
            }
        },
        computed:{
            ...mapGetters({
                project: 'project/project',
                validateActivities: 'project/validateActivities',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validateActivities: function(){
                if(this.validateActivities) {
                    this.submitForm();
                }
            },
        },
        mounted(){
            if(this.project.activities && this.project.activities.length === 0){
                this.project.activities = [{
                        activity: '1',
                        name: 'Management de proiect',
                        description: 'Management de proiect',
                        results: 'Management de proiect',
                        resources: 'Management de proiect'
                    }, {
                        activity: '2',
                        name: 'Informare si comunicare',
                        description: 'Informare si comunicare',
                        results: 'Informare si comunicare',
                        resources: 'Informare si comunicare'
                    }
                ];
            }
            this.clear();
        },
        methods:{
            ...mapActions({
                removeActivity: 'project/removeActivity',
                saveProject: 'project/saveProject',
            }),
            ...mapMutations({
                setValidateActivities: 'project/setValidateActivities',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            addActivity(){
                this.$refs['settings'].validate((valid) => {
                    if (valid) {
                        if(this.form.activity > this.project.activities.length) {
                            this.project.activities.push(this.form);
                        }else{
                            this.project.activities[this.form.activity-1] = this.form
                        }
                        this.clear();
                        this.setValidateActivities(false);
                        this.saveProject(this.project);
                        VueScrollTo.scrollTo('#topActivities');
                        return true;
                    } else {
                        return false;
                    }
                });

            },
            selectActivity(row){
                this.form = row;
                setTimeout(() => {  VueScrollTo.scrollTo('#form'); }, 500);
            },
            clear(){
                this.form = {activity: this.project.activities.length + 1, name:'', description:'', results:'', resources:''}
            },
            handleDelete(index, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi aceasta inregistrare? Acest proces este ireversibil!')
                    .then(_ => {
                        if (index !== -1) {
                            if(row.id > 0) {
                                this.removeActivity({id : this.project.id, line: row.id});
                                this.project.activities.splice(index, 1);
                            }else {
                                this.project.activities.splice(index, 1);
                            }
                            this.$notify({ type: 'success', title: 'Activitate', message: 'Inregistrarea a fost stearsa cu succes!'});
                            this.clear();
                        }
                    }).catch(err => {
                        console.log(err)
                    });
                // this.$confirm('Esti sigur(a) ca vrei sa stergi aceasta inregistrare?')
                //     .then(_ => {
                //         if (index !== -1) {
                //             this.project.activities.splice(index, 1);
                //             this.clear();
                //         }
                //     }).catch(err => {
                //         console.log(err)
                //     });
            },
            submitForm() {
                if (this.project.activities && this.project.activities.length > 0) {
                    this.setValidateActivities(false);
                    this.setValidationErrorsExists(false);
                    return true;
                } else {
                    this.setValidationErrorsExists(true);
                    this.$notify({ type: 'warning', title: 'Activitati proiect', message: 'Trebuie sa adaugati cel putin doua activitati!'});
                    return false;
                }
            },
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
