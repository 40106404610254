import Vue from 'vue';
import Vuex from 'vuex';
import { getField, updateField } from 'vuex-map-fields';
Vue.use(Vuex);

import state from './state'
import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from './actions'


// modules
// import conversations from './modules/conversations';
import catalog from './modules/catalog';
import promoter from './modules/promoter';
import project from './modules/project';
import validation from './modules/validation';
import validationNotification from './modules/validationNotification';
import reportings from './modules/reportings';
import notification from './modules/notification'
import report from './modules/report'


const debug = process.env.NODE_ENV !== 'production';
export default new Vuex.Store({
    modules: {
        catalog, promoter, project, validation, validationNotification, reportings, notification, report
    },
    state, getters, mutations, actions,
    string: debug
});
