import catalog from "../../api/catalog";
import { getField, updateField } from 'vuex-map-fields';

const state = {
    catalog: 0,
    catalogItems: {},
    addresses: [
        {
            zip: '',
            town: '',
            street: '',
        },
    ],
};

const getters = {
    getField,
};

const mutations = {
    updateField,
    setCatalogItems(state, items){
       state.catalogItems = items;
    },

    save(state, row){
        console.log(row);
    },
    addAddressRow(state) {
        state.addresses.push({
            zip: '',
            town: '',
            street: '',
        });
    },
};

const actions = {
    getCatalogItems ({ commit }, ) {
        catalog.getCatalogItems(items => {
            commit('setCatalogItems', items)
        })
    }
};

export default{
    state,
    getters,
    mutations,
    actions
}
