import moment from 'moment';
import numeral from 'numeral';
moment.locale('ro');
// define a mixin object
export default{
    methods: {
        formatUserDateNoDay: function(date = null) {
            if(date === null) return '';
            return moment(date).utc(true).format('MM.YYYY');
        },
        formatUserDate: function(date = null) {
            if(date === null) return '';
            return moment(date).utc(true).format('DD.MM.YYYY');
        },
        formatUserDateTime: function(date = null) {
            if(date === null) return '';
            return moment(date).utc(true).format('DD.MM.YYYY HH:mm:ss');
        },
        formatMysqlDate: function(date = null){
            if(date === null) return '';
            return moment(date).utc(true).format('YYYY-MM-DD');
        },
        formatNewDate: function(date = null) {
            if(date === null) return '';
            return moment(date).utc(true).format('MMMM/YYYY');
        },
        formatPrice(value, decimals = 2, currency = '€'){
            let val = (value/1).toFixed(decimals).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + currency;
            // return (value/1).toFixed(decimals).replace('.', ',') + currency;
        },
        formatNumber(value, decimals = 2){
            return numeral(value/1).format('0,0.00');
        },
        formatNumberNew(value, decimals = 2){
            return (value/1).toFixed(decimals).replace('.', ',');
        },
        formatNumberPercent(value, decimals = 2){
            return numeral(value/1).format('0.0');
        },
        formatNumberAsPrice(value, decimals = 2) {
            let val = (value/1).toFixed(decimals).replace('.', ',');
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        },
        formatMonthFromNumber(month, format = 'MMMM'){
            return moment().month(month).format(format);
        },

        formatMonthFromNumber2(month, format = 'MMMM'){
            var x = month.split('-');
            if (x[1] === '01') {
                return 'Ianuarie';
            } else if (x[1] === '02') {
                return 'Feburarie';
            } else if (x[1] === '03') {
                return 'Martie';
            } else if (x[1] === '04') {
                return 'Aprilie';
            } else if (x[1] === '05') {
                return 'Mai';
            } else if (x[1] === '06') {
                return 'Iunie';
            } else if (x[1] === '07') {
                return 'Iulie';
            } else if (x[1] === '08') {
                return 'August';
            } else if (x[1] === '09') {
                return 'Septembrie';
            } else if (x[1] === '10') {
                return 'Octombrie';
            } else if (x[1] === '11') {
                return 'Noiembrie';
            } else if (x[1] === '12') {
                return 'Decembrie';
            }
        },
        formatPercent(value){
            return numeral(value).format('0,0.00%')
        },
        urlPhone(phone){
            return "tel:" + phone
        },
        urlEmail(email){
            return "mailto:" + email
        },
        openUrl(url, newWindow = false){
            // window.location.href = url;
            window.open(url, newWindow ? '_blank': '_self');
        },
        datesDifference(start, end){
            let a = moment(end).utc(true).endOf('day').add(1,'days');
            let b = moment(start).utc(true).startOf('day');
            let str = '';
            let years = 0;
            let months = 0;
            let days = 0;

            years = a.diff(b, 'year');
            b.add(years, 'years');

            months = a.diff(b, 'months');
            b.add(months, 'months');

            days = a.diff(b, 'days');

            if(years > 1) str += years + ' ani ';
            if(years === 1) str += years + ' an ';
            if(months > 1) str += months + ' luni ';
            if(months === 1) str += months + ' luna ';
            if(days > 1) str += days + ' zile ';
            if(days === 1) str += days + ' zi ';
            if(years === 0 && months === 0 && days < 1) str += 'mai putin de 1 zi ';

            return str;
        },
        validAnswerLimit(date) {
            if(date === undefined || date === null) return true;
            let now = moment().utc(true);
            let then = moment(date).utc(true);
            return then.diff(now, 'minutes').toString() > 0;
        },
        datesDifferenceIF(start, end){
            if(end === null) return 1;
            let a = moment.utc(end).endOf('day').add(1,'days');
            let b = moment.utc(start).startOf('day');
            let str = '';
            let years = 0;
            let months = 0;
            let days = 0;

            years = a.diff(b, 'year');
            b.add(years, 'years');

            months = a.diff(b, 'months');
            b.add(months, 'months');

            days = a.diff(b, 'days');

            if(years > 1) str += years + ' ani ';
            if(years === 1) str += years + ' an ';
            if(months > 1) str += months + ' luni ';
            if(months === 1) str += months + ' luna ';
            if(days > 1) str += days + ' zile ';
            if(days === 1) str += days + ' zi ';
            if(years === 0 && months === 0 && days < 1) str += 'mai putin de 1 zi ';

            return str;
        },
        datesDifferenceWithoutDayPlus(start, end){
            let a = moment(end).utc(true).endOf('day').subtract(1,'days');
            let b = moment(start).utc(true).startOf('day');
            let str = '';
            let years = 0;
            let months = 0;
            let days = 1;

            years = a.diff(b, 'year');
            b.add(years, 'years');

            months = a.diff(b, 'months');
            b.add(months, 'months');

            days = Math.round(a.diff(b, 'days',1)); // + 1

            if(years > 1) str += years + ' ani ';
            if(years === 1) str += years + ' an ';
            if(months > 1) str += months + ' luni ';
            if(months === 1) str += months + ' luna ';
            if(days > 1) str += days + ' zile ';
            if(days === 1) str += days + ' zi ';
            if(years === 0 && months === 0 && days < 1) str += 'mai putin de 1 zi ';

            return str;
        },
        checkDateInInterval(originalStartDate, originalEndDate, newStartDate, newEndDate){
            let checkStartDate = moment(newStartDate).utc(true);
            let checkEndDate = moment(newEndDate).utc(true);
            originalStartDate = moment(originalStartDate).utc(true);
            originalEndDate = moment(originalEndDate).utc(true);
            if(checkStartDate.isBetween(originalStartDate, originalEndDate, 'days', '[]') && checkEndDate.isBetween(originalStartDate, originalEndDate, 'days', '[]')) { //dates are = or inside the original range
                console.log('unu');
                return true;
            }else if(checkStartDate.isBetween(originalStartDate, originalEndDate, 'days', '[]') && checkEndDate.isSameOrAfter(originalEndDate,'days')){ // start date is in the original range and end date is after it
                console.log('doi');
                return true;
            }else if(checkStartDate.isSameOrBefore(originalStartDate,'days') && checkEndDate.isBetween(originalStartDate, originalEndDate, 'days', '[]')){ // end date is in the original range and start date is before it
                console.log('trei');
                return true;
            }else if(checkStartDate.isSameOrBefore(originalStartDate,'days') && checkEndDate.isSameOrAfter(originalEndDate,'days')) {   // dates are outside original range
                console.log('patru');
                return true;
            }else{
                return false;
            }
        },
    }
}
