<template>
    <div>
        <div class="row mb-5">
            <div class="col-sm-6">
                <h4>Selecteaza responsabili proiect</h4>
                <el-select v-model="allUsers" placeholder="Selecteaza" multiple filterable clearable style="width:100%"
                           @change="updateUsers" value-key="id">
                    <el-option v-for="item in users"
                               :key="item.index" :label="item.first_name + ' ' + item.last_name" :value="item">
                        <span style="float: left">{{ item.first_name }} {{ item.last_name }} ({{ item.email }})</span>
                        <span style="float: right; color: #8492a6; font-size: 13px" v-for="role in item.all_roles">{{ role.display_name }}</span>
                    </el-option>
                </el-select>
            </div>
        </div>
        <el-link href="/groups/1/users" :underline="false"><h4>Creaza un utilizator nou</h4></el-link>
    </div>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex'
    export default {
        name: "allocate-user-form",
        props:{
            project:{
                required: true,
                type: Object,
            },
            users:{
                required: true,
                type: Array,
            },
        },
        data(){
            return{
                allUsers: null
            }
        },
        mounted() {
            // if(this.project.users.length > 0){
            //     this.project.users.forEach((item) => {
            //         this.allUsers.push(item.user_id);
            //     })
            // }
            this.allUsers = this.project.users;
        },
        computed:{
            ...mapGetters({
                // project: 'project/project',
            }),
        },
        methods: {
            ...mapMutations({
                setValidationErrors: 'setValidationErrors',
                setValidateAccount: 'project/setValidateAccount',
            }),
            ...mapActions({
                validateNewUser: 'validateNewUser',
            }),
            updateUsers(){
                this.project.users = this.allUsers;
            },
            submitForm() {
                this.clear();
                if(!this.form.id) {
                    this.validateNewUser(this.form).then(response => {
                        if (response.errors) {
                            return false;
                        } else {
                            this.validateForm();
                            return true;
                        }
                    });
                }else{
                    this.validateForm();
                }
            },
        }
    }
</script>

<style scoped>

</style>
