<template>
    <div>
        <el-tabs v-model="infoTabs">
            <el-tab-pane label="Detalii promotor" name="details">
                <promoter-details-pane :promoter="promoter"></promoter-details-pane>
            </el-tab-pane>
            <el-tab-pane label="Reprezentanti legali" name="employees">
                <promoter-employees-pane :promoter="promoter"></promoter-employees-pane>
            </el-tab-pane>
        </el-tabs>
        <h3 class="mt-4 mb-3">Proiecte</h3>
        <el-tabs v-model="projectsTabs" v-if="promoter.projects.length > 0">
            <el-tab-pane :key="project.id" :label="project.name" :name="'tab' + project.id" v-for="project in promoter.projects">
                <project-details-pane :project="project"></project-details-pane>
            </el-tab-pane>
        </el-tabs>
        <div v-else class="alert alert-info">
            Nu exista proiecte pentru aceasta firma
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import mixin from './../../mixins/common';
    import promoterDetailsPane from './pane-details';
    import promoterEmployeesPane from './pane-employees';
    import promoterAccountsPane from './pane-accounts';
    import projectDetailsPane from './../project/pane-details';

    export default {
        name: "page-view",
        mixins:[mixin],
        components:{
            promoterDetailsPane,
            promoterEmployeesPane,
            promoterAccountsPane,
            projectDetailsPane
        },
        props:{
            promoter: {
                required: true,
                type: Object
            }
        },
        data() {
            return {
                loading: false,
                search: '',
                infoTabs: 'details',
                projectsTabs: this.promoter.projects.length > 0 ? 'tab' + this.promoter.projects[0].id : ""
            }
        },
        computed:{
            ...mapGetters({
                promoters: 'promoter/promoters',
            }),
        },
        methods:{
            ...mapActions({
                getPromoters: 'promoter/getPromoters'
            }),
            handleClick(tab, event) {
                console.log(tab, event);
            }
        },
        mounted(){
            // this.loading = true;
            // this.getPromoters();
            // this.loading = false;
        }
    }
</script>

<style scoped>

</style>
