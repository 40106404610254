<template>
    <div v-if="project">
        <h3 class="mb-4 mt-3">Validare proiect tehnic</h3>
        <div class="row">
            <div class="col-sm-12">
                <ul>
                    <div class="custom-control custom-checkbox">
                      <input v-if="project.status_technical == 9" type="checkbox" class="custom-control-input" id="defaultChecked" checked disabled>
                      <input v-else type="checkbox" @change="updateDB" class="custom-control-input" id="defaultChecked2">
                      <label class="custom-control-label" for="defaultChecked2">Am verificat cu atentie toate informatiile si sunt corecte la data verificarii!</label>
                    </div>
                </ul>
            </div>
        </div>
        <div class="row">
            <h3 class="mb-4 mt-3">Note</h3>
            <div class="col-sm-12">
                <el-table :data="project.comments" id="commentList" stripe fit highlight-current-row style="width:100%">
                    <el-table-column label="Note" prop="comment"></el-table-column>
                    <el-table-column label="Utilizator" prop="user.name" width="160px">
                        <template slot-scope="scope">
                            {{ scope.row.user.first_name }} {{ scope.row.user.last_name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Date" width="160px" prop="created_at"></el-table-column>
                </el-table>
            </div>
        </div>
        <el-form :model="project" id="validationForm" :rules="rules" ref="validation" label-position="top" :status-icon="true" :inline-message="true">
            <div class="row mt-4">
                <div class="col-sm-6 form-group">
                    <el-form-item label="Adauga o nota la proiect" prop="comment">
                        <el-input v-model="project.comment" placeholder="Introduceti eventualele mentiuni legate de proiect" clearable
                                  type="textarea" :rows="6" maxlength="1500" show-word-limit>
                        </el-input>
                    </el-form-item>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "project-validation-form",
        data(){
            return {
                form:{},
                rules: {
                    // name: [
                    //     {required: true, message: 'Denumirea este obligatorie', trigger: 'blur'},
                    //     { min: 3, max: 200, message: 'Lungimea trebuie sa fie intre 3 si 200 caractere', trigger: 'blur' }
                    // ],
                    // description: [
                    //     {required: true, message: 'Descrierea este obligatorie', trigger: 'blur'},
                    //     { min: 20, max: 1500, message: 'Lungimea trebuie sa fie intre 20 si 1500 caractere', trigger: 'blur' }
                    // ],
                },
            }
        },
        computed:{
            ...mapGetters({
                project: 'project/project',
                validateValidation: 'project/validateValidation',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validateValidation: function(){
                if(this.validateValidation) {
                    this.submitForm();
                }
            },
        },
        mounted(){
            // this.clear();
        },
        methods:{
            ...mapMutations({
                setValidateValidation: 'project/setValidateValidation',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            ...mapActions({
                saveProjectTeh: 'project/saveProjectTeh',
            }),
            // addRecord(){
            //     this.$refs['validation'].validate((valid) => {
            //         if (valid) {
            //             this.setValidateValidation(false);
            //             return true;
            //         } else {
            //             return false;
            //         }
            //     });
            // },
            // clear(){
            //     this.form = {indicator: this.project.indicators.length + 1}
            // },
            // handleRowDelete(index, row){
            //     this.$confirm('Esti sigur(a) ca vrei sa stergi aceasta inregistrare?')
            //         .then(_ => {
            //             if (index !== -1) {
            //                 this.project.indicators.splice(index, 1);
            //                 this.clear();
            //             }
            //         }).catch(err => {
            //             console.log(err)
            //         });
            // },
            updateDB() {
                document.getElementById("defaultChecked2").disabled = true;
                this.saveProjectTeh(this.project).then((result) => {
                    if(result.valid === false){
                        this.$notify({ type:'error', title: 'Eroare', message: result.message });
                        reject(false);
                    }
                });
                return 1;
            },
            submitForm() {
                // if (this.project.indicators.length > 0) {
                this.setValidateValidation(false);
                this.setValidationErrorsExists(false);
                return true;
                // } else {
                //     this.setValidationErrorsExists(true);
                //     this.$notify({ type: 'warning', title: 'Indicatori proiect', message: 'Trebuie sa adaugati cel putin un indicator!'});
                //     return false;
                // }
            },
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
