<template>
    <div>
        <el-drawer title="Încarcă fișiere" :visible.sync="showUploadDrawer.visible" direction="rtl" :wrapperClosable="false" :before-close="handleCloseDrawer" :destroy-on-close="true">
            <div class="px-4">
                <p class="alert alert-info">{{ infoMessage }}</p>
                <h5>{{ showUploadDrawer.title ? showUploadDrawer.title : title }}</h5>
                <el-upload ref="upload" action="/api/upload-xml" :data="showUploadDrawer"
                           :with-credentials="true" :auto-upload="false"
                           :headers="{ 'X-CSRF-TOKEN': csrf}"
                           :multiple="!showUploadDrawer.multiple ? false : true"
                           :accept="showUploadDrawer.acceptFiles ? showUploadDrawer.acceptFiles : acceptFiles"
                           :on-remove="handleRemove" :before-remove="beforeRemove" :on-preview="handlePreview"
                           :on-error="handleUploadError" :on-success="handleUploadSuccess" :on-exceed="handleOnExceed"
                           :limit="showUploadDrawer.multiple === false ? 1 : 10"
                           :file-list="showUploadDrawer.files">
                    <el-button slot="trigger" size="small" type="secondary">{{ btnSelectText }}</el-button>
                    <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">{{ btnImportText }}</el-button>
                    <div class="el-upload__tip" slot="tip">{{ infoTip }}</div>
                </el-upload>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import {mapGetters,mapMutations,mapActions} from "vuex";
    export default {
        name: "upload-drawer-xml",
        data() {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                title: 'Import fișier',
                infoMessage: 'Alege XML-ul proiectului aferent pe care doresti sa-l incarci!',
                infoTip: 'Sunt permise doar fișiere .XML cu dimensiunea mai mică de 2MB.',
                btnImportText: 'Importă',
                btnSelectText: 'Selectează fișier(e)',
                acceptFiles: '.xml',
                model: '',
                model_id: 0,
                fileList: [],
                showComments: true,
                showDrawer: false,
            }
        },
        computed:{
            ...mapGetters({
                showUploadDrawer: 'showUploadDrawer',
            }),
        },
        methods:{
            ...mapActions({
                removeUploadedFile: 'removeUploadedFile',
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
            }),
            beforeRemove(file, fileList) {
                return this.$confirm(`Ești sigur(ă) că vrei să ștergi fișierul ${ file.name } din listă ?`);
            },
            handleRemove(file, fileList) {
                // console.log(file);
                if(file.response) {
                    this.removeUploadedFile(file.response.file_id);
                }else if(file.id){
                    this.removeUploadedFile(file.id);
                }
            },
            handlePreview(file, fileList) {
                window.open('/getFile/'+file.id, '_blank');
            },
            handleUploadError(file){
                console.log(file);
            },
            handleOnExceed(){
                this.$notify({ type: 'warning', title: 'Import', message: 'Este permisa incarcarea unui singur fisier!'});
            },

            handleUploadSuccess(response, file, fileList){
                if(response.errors){
                    let messages = Object.values(response.errors).flat();
                    this.$notify({ type: 'warning', title: 'Import', message: messages.toString()});
                }else if(response.success === true){
                    this.$notify({ type: 'success', title: 'Import', message: "Fisierul a fost importat cu success!"});
                    // this.$refs.upload.clearFiles();
                }
            },

            handleCloseDrawer(done){
                this.setShowUploadDrawer({
                    visible: false
                });
            },
            submitUpload(){
                this.$refs.upload.submit();
            }
        }
    }
</script>

<style scoped>

</style>
