<template>
    <div>
        <div class="row">
            <div class="col-sm-12">
                <el-table :data="notifications">
                    <el-table-column prop="id" label="#" width="50">
                        <template slot-scope="scope">
                            <span v-if="scope.row.number === 0">-</span>
                            <span v-else>{{ scope.row.number }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="Tip">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type === 1" >Act adițional</span>
                            <span v-else>Notificare</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="changes" width="300" label="Modificari">
                        <template slot-scope="scope">
                            <small v-for="type in scope.row.changes">{{ type.value }}<br/></small>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="Stare" width="190" header-align="center" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status === 1 && scope.row.response === 0" class="badge" style="background-color:#F56C6C;">Nefinalizat</span>
                            <span v-else-if="((scope.row.status === 2 && scope.row.response === 3) || (scope.row.status === 1 && scope.row.response === 2)) && !validAnswerLimit(scope.row.answer_limit)" class="badge badge-danger">Transmis fara raspuns la SC</span>
                            <span v-else-if="scope.row.status === 2 && (scope.row.response === 1 || scope.row.response === 0)" class="badge" style="background-color:#2292A4;">Depusă</span>
                            <span v-else-if="scope.row.status === 2 && scope.row.response === 3" class="badge" style="background-color:#2292A4;">Clarificari primite</span>
                            <span v-else-if="scope.row.status === 1 && scope.row.response === 2" class="badge" style="background-color:#2292A4;">Clarificari solicitate</span>
                            <span v-else-if="scope.row.status === 4 && scope.row.response === 2" class="badge" style="background-color:red;">Respins</span>
                            <span v-else-if="scope.row.status === 3" class="badge" style="background-color:#3BB273;">Aprobata</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="updated_at" header-align="center" align="right" label="Data" width="140">
                        <template slot-scope="scope">
                            {{ formatUserDateTime(scope.row.updated_at) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Acțiuni" width="150" header-align="center">
                        <template slot-scope="scope">
                             <span v-if="checkPermission(['notification-read','notification-technical-write','notification-financial-write'])">
                                <el-button size="mini"
                                           type="secondary" icon="el-icon-view" @click="openUrl('/notifications/show/'+scope.row.project_id+'/'+scope.row.id)"></el-button>
                            </span>
                            <span v-if="(scope.row.response === 1 || scope.row.response === 3 || (!(scope.row.status === 3) && !(scope.row.status === 4 && scope.row.response === 2) && scope.row.answer_limit && !validAnswerLimit(scope.row.answer_limit))) && checkPermission(['notification-technical-write','notification-financial-write'])">
                                <el-button  size="mini" type="primary" icon="el-icon-edit"
                                            @click="openUrl('/notifications/'+scope.row.project_id+'/'+scope.row.id)"></el-button>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import mixin from '../../mixins/common';
    export default {
        name: "notification-page-list",
        mixins:[mixin],
        props:{
            permissions:{
                required: true,
            },
            notifications: {
                required: true,
                type: Array
            },
            types: {
                required: true,
                type: Array
            }
        },
        data() {
            return {
                currentDate: new Date(),
                loading: true,
                search: '',
            }
        },
        methods:{
            checkPermission(permission){
                let perm = this.permissions;
                return _.filter(permission, function (item){
                    return _.includes(perm, item);
                }).length;
            }
        },
    }
</script>

<style scoped>
    .badge {
        color:#ffffff;font-weight:normal;
    }
</style>
