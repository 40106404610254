
import axios from 'axios'

/**
 * Validate Construction Works
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validateConstructionWorks = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validation/constructionWorks', payload)
        .then((response) => {
            commit('setConstructionWorks',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

/**
 * Validate Indirect Costs
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validateIndirectCosts = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validation/indirectCosts', payload)
        .then((response) => {
            commit('setIndirectCosts',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

/**
 * Validate Organizational Development
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validateOrganizationalDevelopment = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validation/organizationalDevelopment', payload)
        .then((response) => {
            commit('setOrganizationalDevelopment',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

/**
 * Validate Budget Financing
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validateBudgetFinancing = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validation/budgetFinancing', payload)
        .then((response) => {
            commit('setBudgetFinancing',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

/**
 * Validate Grant Framing
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validateGrantFraming = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validation/grantFraming', payload)
        .then((response) => {
            commit('setGrantFraming',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

/**
 * Validate Budget Co-Financing
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validateBudgetCoFinancing = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validation/budgetCoFinancing', payload)
        .then((response) => {
            commit('setBudgetCoFinancing',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

/**
 * Validate Nature Budget
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validateBudgetNature = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validation/budgetNature', payload)
        .then((response) => {
            commit('setBudgetNature',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

/**
 * Validate Time Framing for Grant
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validateFramingTimeGrant = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validation/framingTimeGrant', payload)
        .then((response) => {
            commit('setFramingTimeGrant',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

/**
 * Validate Correct Appeal Number
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validateAppealNumber = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validation/appealNumber', payload)
        .then((response) => {
            commit('setAppealNumber',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};



/**
 * Validate Correct Appeal Number
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validateMaxGrantPromoter = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validation/maxGrantPromoter', payload)
        .then((response) => {
            commit('setBudgetMaxGrantPromoter',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};



/**
 * Validate Correct Appeal Number
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validateMaxGrantPartner = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validation/maxGrantPartner', payload)
        .then((response) => {
            commit('setBudgetMaxGrantPartner',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

