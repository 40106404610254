<template>
    <div v-if="project" id="indirect">
        <h3 class="mb-4 mt-3">Surse de finantare <small class="text-muted">(valoare maxima {{ this.formatPrice(this.project.funding_budget ) }})</small></h3>
        <div class="row">
            <div class="col-sm-12">
                <h4>Detaliere surse pe aplicant si parteneri</h4>
                <table class="table table-responsive">
                    <thead>
                        <tr>
                            <th style="width:60px">Cod</th>
                            <th>Denumire aplicant/partener</th>
                            <th>Contributie in bani</th>
                            <th>Contributie in natura</th>
                            <th>Finantare</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in project.financing">
                            <td>
<!--                                {{ row }}-->
                                {{ row.partner_id === project.promoter.id ? 'A' : 'P' + row.partner.id }}
                            </td>
                            <td>
                                {{ row.partner_id === project.promoter.id ? project.promoter.name : row.partner.name}}
                            </td>
                            <td class="text-right">
                                <el-input-number v-model="row.totalCash" controls-position="right" :min="0" :step="1" size="medium" @focus="$event.target.select()">
                                    <template slot="append">{{form.currency}}</template>
                                </el-input-number>
                            </td>
                            <td class="text-right">
                                {{ formatPrice(row.totalNature) }}
                            </td>
                            <td class="text-right">
                                {{ formatPrice(parseFloat(row.totalBudget)) }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="alert alert-danger fade show" v-if="!okIndirects()">
                    <i class="icofont-warning"></i>Valoarea surselor de finantare introduse depaseste valorea proiectului! Modifica valorile introduse.
                </div>
            </div>
        </div>
        <div class="row my-4">
            <div class="col-sm-7 text-right">
                <el-button type="secondary" @click="apply">Aplica modificarile</el-button>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <h4>Totaluri</h4>
                <table class="table table-responsive">
                    <thead>
                    <tr>
                        <th style="width:500px">Surse</th>
                        <th>Total</th>
                        <th>Procent din total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            1. Finantare nerambursabila solicitata
                        </td>
                        <td class="text-right">
                            {{ formatPrice(this.project.totalIrredeemable) }}
                        </td>
                        <td class="text-right">
                            {{ formatPercent(this.project.totalIrredeemable/this.project.totalEligible) }}
                        </td>
                    </tr>
                    <tr>
                        <td>2. Cofinantare Aplicant si Parteneri</td>
                        <td class="text-right">
                            {{ formatPrice(this.project.totalCoFunding) }}
                        </td>
                        <td class="text-right">
                            {{ formatPercent(this.project.totalCoFunding/this.project.totalEligible) }}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-muted ml-3">2.1 Contributie in bani</td>
                        <td class="text-right text-muted">
                            {{ formatPrice(this.project.totalCash) }}
                        </td>
                        <td class="text-right text-muted">
                            {{ formatPercent(this.project.totalCash/this.project.totalCoFunding) }}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-muted ml-3">2.2 Contributie in natura</td>
                        <td class="text-right text-muted">
                            {{ formatPrice(this.project.totalNature) }}
                        </td>
                        <td class="text-right text-muted">
                            {{ formatPercent(this.project.totalNature/this.project.totalCoFunding) }}
                        </td>
                    </tr>
                    <tr>
                        <td>TOTAL COSTURI ELIGIBILE ALE PROIECTULUI</td>
                        <td class="text-right">
                            {{ formatPrice(this.project.totalEligible) }}
                        </td>
                        <td class="text-right">
                            {{ formatPercent(this.project.totalEligible/this.project.totalEligible) }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations, mapActions } from 'vuex'
    import mixin from './../../mixins/common';
    export default {
        name: "project-financing-form",
        mixins:[mixin],
        data(){
            return {
                showPosition: false,
                form:{},
                financing: [],
                totalIrredeemable: 0,
                totalCoFunding: 0,
                totalCash: 0,
                totalNature: 0,
                totalEligible: 0,
            }
        },
        computed:{
            ...mapGetters({
                project: 'project/project',
                validateFinancing: 'project/validateFinancing',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validateFinancing: function(){
                if(this.validateFinancing) {
                    this.submitForm();
                }
            },
        },
        mounted(){
            if(this.project.financing.length === 0){
                this.project.financing.push({
                    type: 'A',
                    partner_id: this.project.promoter_id,
                    partner:{
                        id: (this.project.promoter ? this.project.promoter.id : null),
                        name: (this.project.promoter ? this.project.promoter.name : null),
                    },
                    totalCash: 0,
                    totalNature: 0,
                    totalIndirect: 0,
                    totalBudget: 0,
                });
                this.project.partners.forEach((value, index) => {
                    this.project.financing.push({
                        type: 'P' + value.id,
                        partner_id: value.id,
                        partner:{
                            id: value.id,
                            name: value.name,
                        },
                        totalCash: 0,
                        totalNature: 0,
                        totalIndirect: 0,
                        totalBudget: 0,
                    })
                });
                // this.project.financing = this.financing;
            }
        },
        methods:{
            ...mapActions({
                saveProjectFinancing: 'project/saveProjectFinancing',
            }),
            ...mapMutations({
                setValidateFinancing: 'project/setValidateFinancing',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            okIndirects(){
                // let tc = 0;
                // let tn = 0;
                // let te = 0;
                // let ti = 0;
                // this.project.financing.forEach(function(i){
                //     tc += parseFloat(i.totalCash);
                //     tn += parseFloat(i.totalNature);
                //     te += parseFloat(i.totalBudget) + parseFloat(i.totalIndirect);
                //     ti += parseFloat(i.totalIndirect);
                // });
                // // this.project.indirects.forEach(function(j){
                // //     ti += parseFloat(j.price);
                // // });
                // console.log(tc, tn, te, ti);
                // this.totalCash = tc;
                // this.totalNature = tn;
                // this.totalEligible = te;
                // this.totalCoFunding = this.totalCash + this.totalNature;
                // this.totalIrredeemable = this.totalEligible - this.totalCoFunding;
                // return parseFloat(this.project.funding_budget) >= this.totalCash;
                return true;
            },
            // saveFinancing(){
            //     this.saveProjectFinancing({
            //         project_id: this.project.id,
            //         indirects: this.project.indirects,
            //     }).then((result) => {
            //         if (result.valid === false) {
            //             this.$notify({type: 'error', title: 'Eroare', message: result.message});
            //             reject(false);
            //         }
            //     });
            // },
            apply(){
                this.saveProjectFinancing({
                    project_id: this.project.id,
                    financing: this.project.financing,
                    totalIrredeemable: this.totalIrredeemable,
                    totalCoFunding: this.totalCoFunding,
                    totalCash: this.totalCash,
                    totalNature: this.totalNature,
                    totalEligible: this.totalEligible,
                }).then((result) => {
                    if (result.valid === false) {
                        this.$notify({type: 'error', title: 'Eroare', message: result.message});
                        this.setValidationErrorsExists(true);
                        return false;
                    } else {
                        this.project.totalEligible = result.budget.totalEligible;
                        this.project.totalIrredeemable = result.budget.totalIrredeemable;
                        this.project.totalCoFunding = result.budget.totalCoFunding;
                        this.project.totalNature = result.budget.totalNature;
                        this.project.totalCash = result.budget.totalCash;
                    }
                });
            },
            submitForm() {
                if (this.project.financing.length > 0 && this.okIndirects()) {
                    this.saveProjectFinancing({
                        project_id: this.project.id,
                        financing: this.project.financing,
                        totalIrredeemable: this.totalIrredeemable,
                        totalCoFunding: this.totalCoFunding,
                        totalCash: this.totalCash,
                        totalNature: this.totalNature,
                        totalEligible: this.totalEligible,
                    }).then((result) => {
                        if (result.valid === false) {
                            this.$notify({type: 'error', title: 'Eroare', message: result.message});
                            this.setValidationErrorsExists(true);
                            return false;
                        } else {
                            this.project.totalEligible = result.budget.totalEligible;
                            this.project.totalIrredeemable = result.budget.totalIrredeemable;
                            this.project.totalCoFunding = result.budget.totalCoFunding;
                            this.project.totalNature = result.budget.totalNature;
                            this.project.totalCash = result.budget.totalCash;
                            this.setValidateFinancing(false);
                            this.setValidationErrorsExists(false);
                            return true;
                        }
                    });
                    // this.setValidateFinancing(false);
                    // this.setValidationErrorsExists(false);
                    // return true;
                } else {
                    this.setValidationErrorsExists(true);
                    this.$notify({ type: 'warning', title: 'Surse de finantare', message: 'Valorile introduse nu sunt permise! Incercati din nou.'});
                    return false;
                }
            },
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
