<template>
    <div>
        <form-wizard @on-complete="onComplete" shape="tab" stepSize="xs" color="#3BB273" title="" subtitle="" layout="vertical"
                     error-color="#E15554" v-loading="loading">
            <tab-content :before-change="validateAccount" title="Responsabili">
                <account-form :project="project" :users="users" role="project" group="project"></account-form>
            </tab-content>
            <template slot="footer" slot-scope="props">
                <div class=wizard-footer-left>
                    <a href="/projects" class="ml-2 btn btn-default" v-if="props.activeTabIndex == 0" >Anuleaza, m-am razgandit</a>
                    <wizard-button  v-if="props.activeTabIndex > 0"  @click.native="props.prevTab()" :style="props.fillButtonStyle">Inapoi</wizard-button>
                </div>
                <div class="wizard-footer-right">
                    <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="btn btn-default" :style="props.fillButtonStyle">Inainte</wizard-button>
                    <wizard-button v-else @click.native="props.nextTab()" class="wizard-footer-right finish-button" :style="props.fillButtonStyle">{{props.isLastStep ? 'Finalizeaza' : 'Inainte'}}</wizard-button>
                </div>
            </template>
        </form-wizard>
    </div>
</template>

<script>
    import {FormWizard, TabContent, WizardButton} from 'vue-form-wizard';
    import AccountForm from "./form-account";
    import {mapGetters, mapActions, mapMutations} from 'vuex'

    export default {
        name: "add-wizard",
        components: {
            AccountForm,
            FormWizard,
            TabContent,
            WizardButton
        },
        props:{
            project: {
                required: true,
                type: Object
            },
            users: {
                required: true,
                type: Array
            },
        },
        data(){
            return {
                loading: false,
                errorMsg: null,
            }
        },
        computed:{
            ...mapGetters({
                projectValidated: 'project/projectValidated',
                projectAccountValidated: 'project/projectAccountValidated',
            })
        },
        mounted(){

        },
        methods: {
            ...mapMutations({
                setValidateProject: 'project/setValidateProject',
                setValidateProjectAccount: 'project/setValidateProjectAccount'
            }),
            ...mapActions({
                saveProjectUsers: 'project/saveProjectUsers',
            }),
            onComplete: function(){
                this.$notify({ type:'success', title: 'Alocare responsabili', message: 'Proiectul a fost actualizat cu succes'});
                setTimeout(function() {
                    window.location.href = '/projects'
                },1000);
            },
            setLoading: function(value) {
                this.loading = value
            },
            validateAccount:function() {
                this.loading = true;
                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        this.saveProjectUsers({'id': this.project.id, 'users': this.project.users}).then(response => {
                            console.log(response);
                            if(response.valid) {
                                // this.$notify({ type:'success', title: 'Alocare responsabili', message: 'Datele proiectului au fost salvate cu succes!' });
                                resolve(true);
                            }else{
                                console.log(response);
                                this.$notify({type: 'error', title: 'Alocare responsabili', message: response.message});
                                reject(false);
                            }
                        }, error => {
                            if(error.response.status === 422){
                                this.$notify({ type:'error', title: 'Alocare responsabili', message: error.response.data.errors });
                            }
                            reject(false);
                        });
                        this.loading = false;
                    }, 1000)
                })

            },
        }
    }
</script>

<style>
    .wizard-tab-content{
        padding-top:0 !important;
    }
    .wizard-nav-pills{
        width: 260px !important;
    }
    .wizard-nav-pills li{
        flex: none !important;
        flex-grow: 0 !important;
    }
    .tab_shape{
        display:none !important;
    }
    .wizard-btn{
        padding: 0.5rem 1.3rem !important;
        font-weight: 600 !important;
        font-size: 1.2rem !important;
        line-height: 1.4rem !important;
    }
    .stepTitle {
        border: 1px solid transparent !important;
        margin-bottom: 2px !important;
        padding: 0.75rem 1.25rem !important;
        background-color: #fff !important;
        width:100%;
    }
    .stepTitle.active{
        background-color: #3BB273 !important;
        font-weight: 700 !important;
        color:#fff !important;
    }
    .wizard-header{display:none;}
    .wizard-card-footer{margin-left:220px;}
</style>
