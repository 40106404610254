import axios from "axios";

/**
 * Get all counties from DB
 * @param commit
 * @returns {*}
 */
export const getCounties = ({ commit }) => {
    return axios.get('/api/counties')
        .then((response) => {
            commit('setCounties',response.data);
            return Promise.resolve(response.data)
        })
};

export const getCountries = ({ commit }) => {
    return axios.get('/api/countries')
        .then((response) => {
            commit('setCountries',response.data);
            return Promise.resolve(response.data)
        })
};


/**
 * Get all cities from DB
 * @param commit
 * @returns {*}
 */
export const getCities = ({ commit }) => {
    return axios.get('/api/cities')
        .then((response) => {
            commit('setCities',response.data);
            return Promise.resolve(response.data)
        })
};


/**
 * Get searched city with id's and county
 * @param commit
 * @param dispatch
 * @param searchString
 * @returns {*}
 */
export const searchCity = ({ commit, dispatch }, searchString) => {
    if( searchString.length >= 3) {
        return axios.post('/api/cities/search', {search: searchString})
        .then((response) => {
            commit('setCities',response.data);
            return Promise.resolve(response.data)
        })
    }
};

export const searchCompany = ({ commit, dispatch }, searchString) => {
    if(searchString.length >= 3) {
        return axios.post('/api/companies/search', {search: searchString})
            .then((response) => {
                commit('setCompanies',response.data);
                return Promise.resolve(response.data)
            })
    }
};

// export const validateNewUser = ({ commit, dispatch }, user) => {
//     commit('setValidationErrors', {});
//     return axios.post('/api/validateNewUser', user)
//         .then((response) => {
//             return true;
//         })
//         .catch(error => {
//             if(error.response.status === 422) {
//                 commit('setValidationErrors', error.response.data.errors);
//             }
//             return false;
//         });
// }
export const validateNewUser = async ({ commit, dispatch }, user) => {
    return axios.post('/api/validateNewUser', user)
        .then((response) => {
            return Promise.resolve(response.data)
            commit('setValidationErrors', {});
            commit('setValidationErrorsExists', false);
        })
        .catch((error) => {
            if (error.response.status === 422) {
                commit('setValidationErrors', error.response.data.errors);
                commit('setValidationErrorsExists', true);
            }
            return Promise.resolve(error.response.data);
        });
}


// save promoter
export const saveAccount = ({ commit, dispatch}, payload) => {
    return axios.post('/api/account', payload)
        .then((response) => {
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.reject(error)
        })
};


export const removeUploadedFile = ({ commit, dispatch }, file_id) => {
    return axios.post('/api/upload/remove', {file_id: file_id})
        .then((response) => {
            return Promise.resolve(response.data)
        })
};

export const getDateRates = ({ commit, dispatch }, payload) => {
    return axios.post('/api/rates/getByDate', {date: payload[0], currency: payload[1]})
        .then((response) => {
            commit('setDayRate',response.data);
            return Promise.resolve(response.data)
        })
};

export const getRates = ({ commit }) => {
    return axios.get('/api/rates')
        .then((response) => {
            commit('setRates',response.data);
            return Promise.resolve(response.data)
        })
};

export const searchAnafCompany = ({ commit, dispatch }, searchString) => {
    if(searchString && searchString.length >= 3) {
        return axios.post('/api/anaf/search', {search: searchString})
            .then((response) => {
                 console.log(response);
                // commit('setCompanies',response.data);
                return Promise.resolve(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }
};