/**
 *
 * @param state
 * @returns {Array|string}
 */
export const cities = (state) => { return state.cities };
export const counties = (state) => { return state.counties };
export const countries = (state) => { return state.countries };
export const companies = (state) => { return state.companies };
export const showUploadDrawer = (state) => { return state.showUploadDrawer };
export const showUploadDrawer2 = (state) => { return state.showUploadDrawer2 };

export const validationErrors = (state) => { return state.validationErrors };
export const validationErrorsExists = (state) => { return state.validationErrorsExists };
export const rates = (state) => { return state.rates };
export const dayRate = (state) => { return state.dayRate };
