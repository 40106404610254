<template>
    <div v-if="project" id="activity">
        <h3 class="mb-4 mt-3">
            A{{this.project.activities[this.activityId].activity}}: {{ this.project.activities[this.activityId].name }}
<!--            @click="drawer = true"-->
            <el-button @click="handleImport" icon="icofont-file-excel" type="secondary" size="small" class="mt-2" style="margin-left: 16px;float:right;"> Import din Excel</el-button>
        </h3>
        <div class="row">
            <div class="col-sm-12">
                <el-table :data="project.activities[activityId].budget" stripe fit highlight-current-row size="small"
                          :summary-method="getSummaries" show-summary style="width:100%">
<!--                    <el-table-column label="Index" prop="line" width="60" align="center"></el-table-column>-->
                    <el-table-column type="index" label="Index"></el-table-column>
                    <el-table-column label="Capitol buget" width="200">
                        <template slot-scope="scope">
                            <el-popover placement="top-start" title="Capitol buget" width="300" trigger="click" :content="scope.row.category.value">
                                <span  slot="reference" v-if="scope.row.category.value">{{ scope.row.category.value | truncate(40, '...') }}</span>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="Aplicant/Partener" prop="partner">
                        <template slot-scope="scope" v-if="scope.row.partner">
                            {{ scope.row.partner.id !== project.promoter.id ? 'P' + scope.row.partner.id : 'A' }}
                        </template>
                    </el-table-column>
<!--                    <el-table-column label="Cod" prop="code"></el-table-column>-->
                    <el-table-column label="Linie buget" width="150">
                        <template slot-scope="scope">
                            <el-popover placement="top-start" title="Linie buget" width="300" trigger="click" :content="scope.row.budget.value">
                                <span  slot="reference" v-if="scope.row.budget.value">{{ scope.row.budget.value | truncate(30, '...')}}</span>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="Pozitia">
                        <template slot-scope="scope">
                            <el-popover v-if="scope.row.position" placement="top-start" title="Pozitia" width="300" trigger="click" :content="scope.row.position.position">
                                <span  slot="reference" v-if="scope.row.position.position">{{ scope.row.position.position | truncate(30, '...')}}</span>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="Descriere">
                        <template slot-scope="scope">
                            <el-popover placement="top-start" title="Descriere" width="300" trigger="click" :content="scope.row.description">
                                <span  slot="reference" v-if="scope.row.description">{{ scope.row.description | truncate(30, '...')}}</span>
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column label="UM" prop="unit.value"></el-table-column>
                    <el-table-column label="Cantitate" prop="quantity" align="right" width="70"></el-table-column>
                    <el-table-column label="Cost unitar" align="right" width="70">
                        <template slot-scope="scope">
                            {{ formatPrice(scope.row.price) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Total" align="right" prop="total" width="90">
                        <template slot-scope="scope">
                            {{ formatPrice(parseFloat(scope.row.price) * parseInt(scope.row.quantity)) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Dezv. Org." prop="dezv" align="right" width="90">
                        <template slot-scope="scope">
                            {{ formatPrice(scope.row.dezv) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Actiuni" width="130px" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="secondary" icon="el-icon-edit"
                                       @click="handleSelectRow(scope.row)"></el-button>
                            <el-button size="mini" type="danger" icon="el-icon-delete"
                                       @click="handleDeleteRow(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-form :model="form" :id="'activityForm'+activityId" :validate-on-rule-change="false" v-loading="loading"  :rules="rules" ref="activityForm" label-position="top" :status-icon="true" :inline-message="true">
            <div class="row">
                <div class="col-sm-12">
                    <h4 class="mb-4 mt-5">Adauga/modifica linie buget</h4>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <el-form-item label="Capitol buget" prop="category">
                            <el-select v-model="form.category" placeholder="Selecteaza" style="width:100%" value-key="id" @change="updatePosition">
                                <el-option v-for="item in costCategories.values"
                                           :key="item.id" :label="item.value"  :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Partener, daca se aloca pe un partener, altfel se va aloca pe aplicant" prop="partner">
                            <el-select v-model="form.partner" clearable placeholder="Selecteaza" style="width:100%" value-key="id">
                                <el-option v-for="item in project.partners"
                                           :key="item.id" :label="item.name"  :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-6"></div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Linia de buget" prop="budget">
                            <el-select v-model="form.budget" placeholder="Selecteaza" style="width:100%" value-key="id">
                                <el-option v-for="item in budgetLines.values"
                                           :key="item.id" :label="item.value"  :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-6 form-group">
                    <el-form-item label="Pozitia in cadrul proiectului" v-if="showPosition" >
                        <el-select v-model="form.position" placeholder="Selecteaza" style="width:100%" value-key="id">
                            <el-option v-for="item in project.employees"
                                       :key="item.id" :label="item.position"  :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-12 form-group">
                    <el-form-item label="Descriere" prop="description">
                        <el-input v-model="form.description" placeholder="Introduceti numele de familie (ex: Ionescu)" type="text" maxlength="250" show-word-limit></el-input>
                    </el-form-item>
                </div>
<!--                <div class="col-sm-6 form-group">-->
<!--                    <el-form-item label="Aplicant">-->
<!--                        <el-input v-model="form.cnp" placeholder="Introduceti Codul Numeric Personal" type="text" maxlength="13" show-word-limit></el-input>-->
<!--                    </el-form-item>-->
<!--                </div>-->
                <div class="col-sm-3 form-group">
                    <el-form-item label="Unitate masura" prop="unit">
                        <el-select v-model="form.unit" placeholder="Select" style="width:100%" value-key="id">
                            <el-option v-for="item in units.values"
                                       :key="item.id" :label="item.value"  :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Cantitate" prop="quantity">
                        <el-input v-model="form.quantity" placeholder="0" type="number" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Cost unitar" prop="price">
                        <el-input v-model="form.price" type="number" placeholder="0"></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Total">
                        <el-input :value="formatPrice(form.quantity * form.price)" type="text" disabled align="right"></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-6 form-group">
                    <el-form-item label="Cost ce intra pe componenta de dezvoltare organizationala" class="form-group">
                        <el-input v-model="form.dezv" placeholder="0" type="number"></el-input>
                    </el-form-item>
                </div>
<!--                <div class="col-sm-3 form-group">-->
<!--                    <el-form-item label="Procent din total">-->
<!--                        <el-input :value="formatNumber(form.dezv/(form.quantity * form.price)*100) + '%'" type="text" disabled align="right"></el-input>-->
<!--                    </el-form-item>-->
<!--                </div>-->
                <div class="col-sm-12 text-right mb-5">
                    <el-button @click.prevent="clear">Goleste formularul</el-button>
                    <el-button @click.prevent="addRecord" type="secondary">Aplica modificarile</el-button>
                </div>
            </div>
        </el-form>
        <el-drawer title="Import fișier buget" :visible.sync="drawer" direction="rtl" :wrapperClosable="false" :before-close="handleCloseDrawer" :destroy-on-close="true">
            <div class="px-4">
                <p class="alert alert-info">
                    Pentru importul bugetului este necesară utilizarea unui fișier Excel ce conține toate informațiile necesare pentru a face legătura cu baza de date.
                </p>
                <p class="alert alert-info">
                    <a href="/files/buget-import.xlsx" target="_blank">Descărcați de aici</a> un model de fișier,
                    adăugați înregistrările necesare și importați utilizând comenzile de mai jos.
                </p>
                <h5>Import fișier buget</h5>
                <el-upload ref="upload" action="/projects/import-budget" :data="{project: this.project.id, activity: this.project.activities[this.activityId].id }"
                           :with-credentials="true" :auto-upload="false" :multiple="false"  :headers="{ 'X-CSRF-TOKEN': csrf}" accept=".xlsx"
                           :on-error="handleUploadError" :on-success="handleUploadSuccess">
                    <el-button slot="trigger" size="small" type="secondary">Selecteaza fișier XLSX</el-button>
                    <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">Importă</el-button>
                    <div class="el-upload__tip" slot="tip">Sunt permise doar fisiere xlsx cu dimensiunea mai mică de 500kb</div>
                </el-upload>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "project-activity-form",
        mixins:[mixin],
        props:{
            activityId: Number,
        },
        data(){
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                showPosition: false,
                loading:false,
                form:{},
                // activity: {
                //     budget:[]
                // },
                drawer: false,
                rules: {
                    category: [
                        {required: true, message: 'Capitolul este obligatoriu', trigger: 'blur'},
                    ],
                    budget: [
                        {required: true, message: 'Linia de buget este obligatorie', trigger: 'blur'},
                    ],
                    description: [
                        {required: true, message: 'Descrierea este obligatorie', trigger: 'blur'},
                    ],
                    unit: [
                        {required: true, message: 'Unitatea de masura este obligatorie', trigger: 'blur'},
                    ],
                    quantity: [
                        {required: true, message: 'Cantitatea este obligatorie', trigger: 'blur'},
                    ],
                    price: [
                        {required: true, message: 'Pretul unitar este obligatoriu', trigger: 'blur'},
                    ],
                },
            }
        },
        computed:{
            ...mapGetters({
                costCategories: 'project/costCategories',
                budgetLines: 'project/budgetLines',
                units: 'project/units',
                project: 'project/project',
                validateActivity: 'project/validateActivity',
                validationErrorsExists: 'validationErrorsExists',
            }),
            // project(){
            //     return this.getProject;
            // }
        },
        watch:{
            validateActivity: function(){
                if(this.validateActivity[this.activityId]) {
                    this.submitForm();
                }
            },
        },
        mounted(){
            this.getCostCategories();
            // this.activity = this.project.activities[this.activityId];
            this.clear();
        },
        methods:{
            ...mapActions({
                getCostCategories: 'project/getCostCategories',
                getBudgetLines: 'project/getBudgetLines',
                getUnits: 'project/getUnits',
                removeBudgetLine: 'project/removeBudgetLine',
                loadProject: 'project/loadProject',
                saveProjectBudgetLine: 'project/saveProjectBudgetLine'
            }),
            ...mapMutations({
                setValidateActivity: 'project/setValidateActivity',
                setValidationErrorsExists: 'setValidationErrorsExists',
            }),
            updatePosition(){
                this.showPosition = this.form.category.id === 99;
                this.getBudgetLines(this.form.category.id);
                this.getUnits(this.form.category.id === 99 ? 99 : 0);
            },
            addRecord(){
                this.loading = true;
                this.$refs['activityForm'].validate((valid) => {
                    if (valid) {
                        this.saveBudgetLine(this.form);
                        this.setValidateActivity(false);
                        VueScrollTo.scrollTo('#activity');
                        this.clear();
                        return true;
                    } else {
                        this.loading = false;
                        return false;
                    }
                });
            },
            saveBudgetLine(line){
                this.saveProjectBudgetLine({
                    project_id: this.project.id,
                    promoter_id: this.project.promoter_id,
                    activity_id: this.project.activities[this.activityId].id,
                    line: line,
                }).then((result) => {
                    if(result.valid === false){
                        this.$notify({ type:'error', title: 'Eroare', message: result.message });
                        reject(false);
                    }else{
// removed this to reload the project in project action
//                         if(line.line > this.project.activities[this.activityId].budget.length) {
//                             this.project.activities[this.activityId].budget.push(result.line);
//                         }else{
//                             this.project.activities[this.activityId].budget[line.line - 1] = result.line
//                         }
                    }
                    this.loading = false;
                });
            },
            handleSelectRow(row){
                this.form = row;
                this.updatePosition();
                VueScrollTo.scrollTo('#activityForm'+this.activityId);
            },
            clear(){
                this.form =  {
                    line: this.project.activities[this.activityId].budget.length + 1,
                    partner: this.project.promoter,
                };
                this.$refs['activityForm'].clearValidate();
            },
            handleDeleteRow(line, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi aceasta inregistrare? Acest proces este ireversibil!')
                    .then(_ => {
                        if (line !== -1) {
                            if(row.id > 0) {
                                this.removeBudgetLine({id : this.project.id, line: row.id});
                                this.project.activities[this.activityId].budget.splice(line, 1);
                            }else {
                                this.project.activities[this.activityId].budget.splice(line, 1);
                            }
                            this.$notify({ type: 'success', title: 'Linie buget', message: 'Inregistrarea a fost stearsa cu succes!'});
                            this.clear();
                        }
                    }).catch(err => {
                        console.log(err)
                    });
            },
            handleImport(){
                this.drawer = true
            },
            handleUploadError(msg, file){
                console.log(msg);
                console.log(file);
            },
            handleUploadSuccess(response){
                if(response.error){
                    // let messages = Object.values(response.errors).flat();
                    this.$notify({ type: 'error', title: 'Import', message: response.message});
                }else if(response.error === false){
                    this.$notify({ type: 'success', title: 'Import', message: "Fisierul a fost importat cu success!"});
                    this.$refs.upload.clearFiles();
                }

            },
            handleCloseDrawer(done){
                // this.$confirm('Vrei sa inchizi aceasta fereastra?')
                //     .then(_ => {
                this.loading = true;
                setTimeout(() => {
                    this.loadProject(this.project.id);

                    this.loading = false;
                    done();
                }, 1000);
                this.$forceUpdate();
                        // this.activity = this.project.activities[this.activityId];
                        // console.log(this.project.activities[this.activityId].budget);
                    // })
                    // .catch(_ => {});
            },
            submitUpload(){
                this.$refs.upload.submit();
            },
            submitForm() {
                this.setValidateActivity({index: this.activityId, value: false});
                this.setValidationErrorsExists(false);
                VueScrollTo.scrollTo('#activity');
                return true;

                /*if (this.project.activities[this.activityId].budget.length > 0) {
                    // this.project.activities[this.activityId] = this.activity;
                    this.setValidateActivity({index: this.activityId, value: false});
                    this.setValidationErrorsExists(false);
                    VueScrollTo.scrollTo('#activity');
                    return true;
                } else {
                    this.setValidationErrorsExists(true);
                    this.$notify({ type: 'warning', title: 'Buget', message: 'Trebuie sa adaugati cel putin o linie de buget!'});
                    console.log('false');
                    return false;
                }*/
            },
            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 1) {
                        sums[index] = 'Totaluri';
                        return;
                    }
                    if(index < 9){
                        sums[index] = '';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    } else {
                        sums[index] = ' - ';
                    }
                    if(index === 9 || index === 10){
                        sums[index] =  this.formatPrice(sums[index]);
                    }
                });
                return sums;
            }
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
