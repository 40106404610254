//
import axios from "axios";
// a test
export const runReport = ({ commit, dispatch}, data) => {
    return axios.post('/reports/run', data)
        .then((response) => {
            commit('setReportData',response.data);
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.reject(error)
        })
};

export const exportReport = ({ commit, dispatch}, data) => {
    return axios.post('/reports/export', data, {responseType: 'blob'})
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', data.report.name+'.xlsx');
            document.body.appendChild(link);
            link.click();
            return Promise.resolve(response.data)
        }).catch((error) => {
            console.log(error);
            return Promise.reject(error)
        })
};
