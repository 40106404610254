<template>
    <div v-loading="loading">
        <div class="row">
            <div class="col-sm-3">
                <label>Tip inregistrare</label>
                <p v-if="notification.type === 1">Act adițional</p><p v-else>Notificare</p>
            </div>
            <div class="col-sm-3">
                <label>Nr.</label>
                <p>{{ notification.number }}</p>
            </div>
            <div class="col-sm-3">
                <label>Data transmiterii</label>
                <p>{{formatUserDateTime(notification.updated_at)}}</p>
            </div>
            <div class="col-sm-3">
                <label>Stare</label>
                <p>
                    <span v-if="notification.status === 1 && notification.response === 0" class="badge text-white" style="background-color:#F56C6C;">Nefinalizat</span>
                    <span v-else-if="((notification.status === 2 && notification.response === 3) || (notification.status === 1 && notification.response === 2)) && !validAnswerLimit(notification.answer_limit)" class="badge text-white" style="background-color:red;">Transmis fara raspuns la SC</span>
                    <span v-else-if="notification.status === 2 && (notification.response === 1 || notification.response === 0)" class="badge text-white" style="background-color:#2292A4;">Depusă</span>
                    <span v-else-if="notification.status === 2 && notification.response === 3" class="badge text-white" style="background-color:#2292A4;">Clarificari primite</span>
                    <span v-else-if="notification.status === 1 && notification.response === 2" class="badge text-white" style="background-color:#2292A4;">Clarificari solicitate</span>
                    <span v-else-if="notification.status === 4 && notification.response === 2" class="badge text-white" style="background-color:red;">Respins</span>
                    <span v-else-if="notification.status === 3" class="badge text-white" style="background-color:#3BB273;">Aprobata</span>
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-3">
                <label>Sectiuni pentru care se doreste modificari</label>
                <p>
                    <el-tag v-for="tag in notification.changes" :key="tag.value" class="mr-1" type="info">
                        {{ tag.value }}
                    </el-tag>
                </p>
            </div>
            <div class="col-sm-3">
                <label>Alte documente</label>
                <p>
                    <span v-for="doc in notification.files">
                        <a :href="'/getFile/' + doc.id" target="_blank">{{ doc.file_name }}</a><br/>
                    </span>
                </p>
            </div>
            <div class="col-sm-3">
                <label>Documente semnate de promotor</label>
                <p>
                    <span v-for="doc in notification.signed_document">
                        <a :href="'/getFile/' + doc.id" target="_blank">{{ doc.file_name }}</a><br/>
                    </span>
                </p>
            </div>
            <div class="col-sm-3" v-if="notification.type === 1">
                <label>Documente semnate de operator</label>
                <!--<p>
                    <span v-for="doc in notification.signed_document_admin">
                        <a :href="'/getFile/' + doc.id" target="_blank">{{ doc.file_name }}</a><br/>
                    </span>
                </p>-->
                <el-button type="primary" size="small" icon="el-icon-edit" @click="uploadDocument(notification)">
                    <span>Incarca/Modifica documentul semnat</span>
                </el-button>
            </div>

            <div class="col-sm-12">
                <label v-if="notification.type === 1">Descrierea actului adițional</label><label v-else>Descrierea notificarii</label>
                <nl2br tag="p" :text="notification.description" />
            </div>
        </div>
        <h3 class="mt-4 mb-3">Sectiuni</h3>
        <el-tabs v-model="planTabs" class="mt-5">
            <el-tab-pane :label="tag.value" :key="tag.id" v-for="(tag,index) in notification.changes" v-bind:name="getClass(index)" class="active">

                <div class="row" v-if="tag.id === 205">
                    <div class="col-sm-4">
                        <label>Data actuala de finalizare a implementarii</label>
                        <p>{{formatUserDate(project.implementation_end_date)}}</p>
                    </div>
                    <div class="col-sm-4">
                        <label>Data modificata de finalizare a implementarii</label>
                        <p v-if="notification.end_date !== null">{{formatUserDate(notification.end_date.end_date)}}</p>
                    </div>
                </div>

                <div v-if="tag.id === 206 || tag.id === 226">
                    Date initiale
                    <el-table :data="project.partners" size="mini">
                        <el-table-column label="Cod" width="70">
                            <template slot-scope="scope">
                                P{{ scope.row.id }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Denumire" prop="name"></el-table-column>
                        <el-table-column label="Adresa">
                            <template slot-scope="scope">
                                <span v-if="scope.row.address">{{ scope.row.address }}</span>
                                <span v-if="scope.row.city_id">{{ scope.row.city.name }}</span>
                                <span v-if="scope.row.city_id">, {{ scope.row.city.county.name }}</span>
                                <span v-if="scope.row.country">, {{ scope.row.country.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="CIF" width="140">
                            <template slot-scope="scope">
                                {{ scope.row.fiscal_attribute }} {{ scope.row.cif }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Nr. inreg" prop="register_no" width="140"></el-table-column>
                        <el-table-column label="Forma juridica" prop="type.value" width="140"></el-table-column>
                        <el-table-column label="Contact" prop="phone" width="180">
                            <template slot-scope="scope">
                                <a v-if="scope.row.phone" :href="urlPhone(scope.row.phone, true)"><i class="el-icon-phone-outline"></i> {{ scope.row.phone }}</a>
                                <a v-if="scope.row.email" :href="urlEmail(scope.row.email, true)"><br /><i class="el-icon-message"></i> {{ scope.row.email | truncate(10, '...') }}</a>
                            </template>
                        </el-table-column>
                        <el-table-column label="Website" prop="website" width="200">
                            <template slot-scope="scope">
                                <a href="#" v-if="scope.row.website" @click.prevent="openUrl(scope.row.website, true)">{{ scope.row.website.replace('https://','') | truncate(15, '...') }}</a>
                            </template>
                        </el-table-column>
                    </el-table>
                    <hr>
                    Date curente
                    <el-table :data="notification.partners.filter(p => p.deleted_at === null)" size="mini">
                        <el-table-column label="Cod" width="70">
                            <template slot-scope="scope">
                                <span v-if="scope.row.id !== project.promoter.id">
                                    {{ 'P' + (scope.row.ID_Initial ? scope.row.ID_Initial : scope.row.company_id_Initial) }}
                                 </span>
                            <span v-else>
                                    A
                                C</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Denumire" prop="name"></el-table-column>
                        <el-table-column label="Adresa">
                            <template slot-scope="scope">
                                <span v-if="scope.row.address">{{ scope.row.address }}</span>
                                <span v-if="scope.row.city_id">{{ scope.row.city.name }}</span>
                                <span v-if="scope.row.city_id">, {{ scope.row.city.county.name }}</span>
                                <span v-if="scope.row.country">, {{ scope.row.country.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="CIF" width="140">
                            <template slot-scope="scope">
                                {{ scope.row.fiscal_attribute }} {{ scope.row.cif }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Nr. inreg" prop="register_no" width="140"></el-table-column>
                        <el-table-column label="Forma juridica" prop="type.value" width="140"></el-table-column>
                        <el-table-column label="Contact" prop="phone" width="180">
                            <template slot-scope="scope">
                                <a v-if="scope.row.phone" :href="urlPhone(scope.row.phone, true)"><i class="el-icon-phone-outline"></i> {{ scope.row.phone }}</a>
                                <a v-if="scope.row.email" :href="urlEmail(scope.row.email, true)"><br /><i class="el-icon-message"></i> {{ scope.row.email | truncate(10, '...') }}</a>
                            </template>
                        </el-table-column>
                        <el-table-column label="Website" prop="website" width="200">
                            <template slot-scope="scope">
                                <a href="#" v-if="scope.row.website" @click.prevent="openUrl(scope.row.website, true)">{{ scope.row.website.replace('https://','') | truncate(15, '...') }}</a>
                            </template>
                        </el-table-column>
                        <el-table-column label="Actiuni" width="130px" align="center">
                            <template slot-scope="scope">
                                <el-button size="mini" type="warning" icon="el-icon-files" @click="openDrawer(scope.row)">Fisiere</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div v-if="tag.id === 207">
                    Date initiale
                    <el-table :data="project.indicators" size="mini">
                        <el-table-column label="ID" prop="indicator" width="60"></el-table-column>
                        <el-table-column label="Outcome" prop="outcome.value"></el-table-column>
                        <el-table-column label="Output" prop="output.value"></el-table-column>
                        <el-table-column label="Indicator">
                            <template slot-scope="scope">
                                <span v-if="scope.row.outcome_indicators">{{scope.row.outcome_indicators.value}}</span>
                                <span v-if="scope.row.output_indicators">{{scope.row.output_indicators.value}}</span>
                                <span v-if="scope.row.customIndicator">{{scope.row.customIndicator}}</span>
                                <span v-if="!scope.row.customIndicator && scope.row.indicator_id === 0">{{scope.row.name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Target" width="100px">
                            <template slot-scope="scope">{{ formatNumber(scope.row.target,0) }} {{ scope.row.target_unit }}</template>
                        </el-table-column>
                    </el-table>
                    <hr>
                    Date curente
                    <el-table :data="notification.indicators.filter(p => p.deleted_at === null)" size="mini">
                        <el-table-column label="ID" prop="indicator" width="60"></el-table-column>
                        <el-table-column label="Outcome" prop="outcome.value"></el-table-column>
                        <el-table-column label="Output" prop="output.value"></el-table-column>
                        <el-table-column label="Indicator">
                            <template slot-scope="scope">
                                <span v-if="scope.row.outcome_indicators">{{scope.row.outcome_indicators.value}}</span>
                                <span v-if="scope.row.output_indicators">{{scope.row.output_indicators.value}}</span>
                                <span v-if="scope.row.customIndicator">{{scope.row.customIndicator}}</span>
                                <span v-if="!scope.row.customIndicator && scope.row.indicator_id === 0">{{scope.row.name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Target" width="100px">
                            <template slot-scope="scope">{{ formatNumber(scope.row.target,0) }} {{ scope.row.target_unit }}</template>
                        </el-table-column>
                    </el-table>
                </div>

                <div v-if="tag.id === 227">
                    Date curente
                    <el-table :data="notification.indicators.filter(p => p.deleted_at === null)" id="indicatorsList" size="mini" stripe fit highlight-current-row style="width:100%">
                        <el-table-column label="Indicator" align="center" width="80">
                            <template slot-scope="scope">
                                I{{ scope.row.indicator }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Indicator">
                            <template slot-scope="scope">
                                <span>{{scope.row.name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Target" prop="indicator.target" align="right" width="150" header-align="center">
                            <template slot-scope="scope">
                                <span v-if="scope.row.value_down == 0">{{ formatNumber(scope.row.value_up,0) }} / {{ formatNumber(scope.row.value_down,0) }} = Invalid</span>
                                <span v-else>{{ formatNumber(scope.row.value_up,0) }} / {{ formatNumber(scope.row.value_down,0) }} = {{ formatNumber(scope.row.value_up/scope.row.value_down * 100,2) }}%</span>
<!--                                <span v-if="scope.row.value > 0">{{ formatNumber(scope.row.value,2) }}</span>-->
<!--                                <span v-if="scope.row.value_down < 1 && scope.row.value < 1">0/{{ formatNumber(scope.row.value_up,0) }} = 0%</span>-->
                            </template>
                        </el-table-column>
                        <el-table-column label="Varsta" width="230" header-align="center">
                            <template slot-scope="scope" v-if="scope.row.indicator_id === 152 || scope.row.indicator_id === 142 || scope.row.indicator_id === 169">
                                <div v-for="age in scope.row.ages" :key="age" style="display:block">
                                    {{age.name}}: {{formatNumber(age.value,0)}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Gen" width="140" header-align="center">
                            <template slot-scope="scope" v-if="scope.row.indicator_id === 152 || scope.row.indicator_id === 142 || scope.row.indicator_id === 169">
                                <div v-for="gender in scope.row.genders" :key="gender" style="display:block">
                                    {{gender.name}}: {{formatNumber(gender.value,0)}}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Etnia" width="140" header-align="center">
                            <template slot-scope="scope" v-if="scope.row.indicator_id === 152 || scope.row.indicator_id === 142 || scope.row.indicator_id === 169">
                                <div v-for="ethnicity in scope.row.ethnicities" :key="ethnicity" style="display:block">
                                    {{ethnicity.name}}: {{formatNumber(ethnicity.value,0)}}
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div v-if="tag.id === 208 || tag.id === 218">
                    Date initiale
                    <pane-calendar :project="project" :notification="notification"></pane-calendar>
                    <el-table :data="notification.calendarstrashed.filter(p => p.ID_Initial != 0)" size="mini">
                        <el-table-column label="Denumire">
                            <template slot-scope="scope">
                                <div v-if="typeof scope.row.activity !== 'undefined' && scope.row.status !== 2">A{{scope.row.activity.activity}} {{scope.row.activity.name}}</div>
                                <div v-else-if="typeof scope.row.activity_notification !== 'undefined'">A{{scope.row.activity_notification.activity}} {{scope.row.activity_notification.name}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Data inceput" prop="start_date_Initial" width="120">
                            <template slot-scope="scope">
                                {{ formatUserDate(scope.row.start_date_Initial) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Data sfarsit" prop="end_date_Initial" width="120">
                            <template slot-scope="scope">
                                {{ formatUserDate(scope.row.end_date_Initial) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Perioada" width="200">
                            <template slot-scope="scope">
                                {{ datesDifference(scope.row.start_date_Initial, scope.row.end_date_Initial) }}
                            </template>
                        </el-table-column>
                    </el-table>
                    <hr>
                    Date curente
                    <pane-actions-calendar :project="project" :notification="notification"></pane-actions-calendar>
                    <el-table :data="notification.calendars" size="mini">
                        <el-table-column label="Denumire" prop="activity.name">
                            <template slot-scope="scope">
                                <div v-if="scope.row.status !== 2">A{{scope.row.activity.activity}} {{scope.row.activity.name}}</div>
                                <div v-else>A{{scope.row.activity_notification.activity}} {{scope.row.activity_notification.name}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="Data inceput" prop="start_date" width="120">
                            <template slot-scope="scope">
                                {{ formatUserDate(scope.row.start_date) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Data sfarsit" prop="end_date" width="120">
                            <template slot-scope="scope">
                                {{ formatUserDate(scope.row.end_date) }}
                            </template>
                        </el-table-column>
                        <el-table-column label="Perioada" width="200">
                            <template slot-scope="scope">
                                {{ datesDifference(scope.row.start_date,scope.row.end_date) }}
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <el-table v-if="tag.id === 209" :data="notification.activities" size="mini">
                    <el-table-column label="Activitate" width="80" align="center">
                        <template slot-scope="scope">{{ 'A'+scope.row.activity }}</template>
                    </el-table-column>
                    <el-table-column label="Denumire" prop="name"></el-table-column>
                    <el-table-column label="Descriere" prop="description"></el-table-column>
                    <el-table-column label="Rezultate" prop="results"></el-table-column>
                    <el-table-column label="Resurse" prop="resources"></el-table-column>
                </el-table>

                <div v-if="tag.id === 210 || tag.id === 215">

                    <el-tabs  @tab-click="handleClick">
                        <el-tab-pane :label="'A'+activity.activity" :name="activity.activity" v-for="(activity, index) in notification.activities" :key="activity.id">
                            <h4 class="mt-5 mb-5">Activitatea {{ activity.activity }}: {{ activity.name }}</h4>
                            <el-table :data="activity.budget" stripe fit highlight-current-row size="mini"
                                      :summary-method="getSummaries" show-summary style="width:100%">
                                <el-table-column label="Index" type="index" width="60" align="center"></el-table-column>
                                <el-table-column label="Capitol buget" width="200">
                                    <template slot-scope="scope">
                                        <el-popover placement="top-start" title="Capitol buget" width="300" trigger="click" :content="scope.row.category.value">
                                            <span  slot="reference" v-if="scope.row.category.value">{{ scope.row.category.value | truncate(40, '...') }}</span>
                                        </el-popover>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Aplicant/Partener" prop="partner">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.partner_id !== project.promoter.id">
                                           <span v-if="scope.row.partner">
                                                {{ 'P' + (scope.row.partner.ID_Initial ? scope.row.partner.ID_Initial : scope.row.partner.company_id_Initial) }}
                                           </span>
                                           <span v-else>
                                               A
                                           </span>
                                        </span>
                                        <span v-else>
                                            A
                                        </span>
                                    </template>
                                </el-table-column>
                                <!--                    <el-table-column label="Cod" prop="code"></el-table-column>-->
                                <el-table-column label="Linie buget" width="150">
                                    <template slot-scope="scope">
                                        <el-popover placement="top-start" title="Linie buget" width="300" trigger="click">
                                            <span  slot="reference" v-if="scope.row.budget !== null">{{ scope.row.budget !== null ? (scope.row.budget.value) : ''}}</span>
                                        </el-popover>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Pozitia">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.notification_position">{{ scope.row.notification_position.position }}</span>
                                        <span v-else><span v-if="scope.row.position">{{ scope.row.position.position }}</span></span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Descriere">
                                    <template slot-scope="scope">
                                        <el-popover placement="top-start" title="Descriere" width="300" trigger="click" :content="scope.row.description">
                                            <span  slot="reference" v-if="scope.row.description">{{ scope.row.description | truncate(30, '...')}}</span>
                                        </el-popover>
                                    </template>
                                </el-table-column>
                                <el-table-column label="UM" prop="unit.value"></el-table-column>
                                <el-table-column label="Cantitate" prop="quantity" align="right" width="70"></el-table-column>
                                <el-table-column label="Cost unitar" align="right" width="100">
                                    <template slot-scope="scope">
                                        {{ formatPrice(scope.row.price) }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Total" align="right" prop="total" width="90">
                                    <template slot-scope="scope">
                                        {{ formatPrice(parseFloat(scope.row.price) * parseInt(scope.row.quantity)) }}
                                    </template>
                                </el-table-column>
                                <el-table-column label="Dezv. Org." prop="dezv" align="right" width="90">
                                    <template slot-scope="scope">
                                        {{ formatPrice(scope.row.dezv) }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="edited" label="Modificari" align="right" fixed="right"  header-align="center" width="150">
                                    <template slot-scope="scope">
                                        <span v-if="(parseFloat(scope.row.total) === parseFloat(scope.row.total_Initial)) && scope.row.dezv === scope.row.dezv_Initial && scope.row.description === scope.row.description_Initial">   </span><span v-else><i class="icofont-check" style="color:green;font-size:30px;"></i></span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>

                        <el-tab-pane label="Indirecte" name="indirects">

                            <div class="row">
                                <div class="col-sm-12">
                                    <table class="table table-responsive">
                                        <thead>
                                            <tr>
                                                <th style="width:60px">Cod</th>
                                                <th>Denumire aplicant/partener</th>
                                                <th>Cost in €</th>
                                                <th>Cost comp. dezv. org.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="row in indirects">
                                                <td>
                                         <span v-if="row.partner_id !== project.promoter.id">
                                            <span v-if="row.partner">
                                                {{ 'P' + (row.partner.ID_Initial ? row.partner.ID_Initial : row.partner.company_id_Initial) }}
                                            </span>
                                            <span v-else>A</span>
                                         </span>
                                                    <span v-else>
                                            A
                                        </span>
                                                </td>
                                                <td>
                                                    <span v-if="row.partner_id !== project.promoter.id && row.partner">{{ row.partner.name }}</span>
                                                    <span v-else>{{ project.promoter.name }}</span>
                                                </td>
                                                <td>
                                                    <el-input disabled v-model="row.price" type="number" placeholder="0 €" @focus="$event.target.select()"></el-input>
                                                </td>
                                                <td>
                                                    <el-input disabled v-model="row.dezv" type="number" placeholder="0 €" @focus="$event.target.select()"></el-input>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    Valoarea procentului este de: {{ indirectPercent }}%
                                </div>
                            </div>
                        </el-tab-pane>

                        <el-tab-pane label="Surse finantare" name="financing">
                            <small class="text-muted">(valoare maxima {{ maxValue }})</small>
                            <div class="row">
                                <div class="col-sm-12">
                                    <h4>Detaliere surse pe aplicant si parteneri</h4>
                                    <table class="table table-responsive">
                                        <thead>
                                            <tr>
                                                <th style="width:60px">Cod</th>
                                                <th>Denumire aplicant/partener</th>
                                                <th>Contributie in bani</th>
                                                <th>Contributie in natura</th>
                                                <th>Finantare</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="row in financing">
                                            <td>
                                                <span v-if="row.partner_id !== project.promoter.id">
                                                    <span v-if="row.partner">
                                                        {{ 'P' + row.partner_id }}
                                                    </span>
                                                    <span v-else>A</span>
                                                </span>
                                                <span v-else>A</span>
                                            </td>
                                            <td>
                                                {{ row.partner && parseInt(row.partner_id) !== parseInt(project.promoter.id) ? row.partner.name : project.promoter.name}}
                                            </td>
                                            <td class="text-right">
                                                <el-input-number disabled v-model="row.totalCash" controls-position="right" :min="0" :step="1" size="medium" @focus="$event.target.select()">
                                                    <template slot="append">{{form.currency}}</template>
                                                </el-input-number>
                                            </td>
                                            <td class="text-right">
                                                {{ formatPrice(row.totalNature) }}
                                            </td>
                                            <td class="text-right">
                                                {{ formatPrice(row.totalBudget) }}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </el-tab-pane>

                        <el-tab-pane class="mt-4" label="Consolidare realocari" name="realocate">
                            <h3>Consolidare realocari</h3>
                            <el-tabs @tab-click="handleClickConsolidation">
                                <el-tab-pane :label="'A'+activity.activity" :name="activity.activity" v-for="(activity, index) in notification.activities" :key="'cons' + activity.id">
                                    <h5 class="mt-2 mb-1">Activitatea {{ activity.activity }}: {{ activity.name }}</h5>
                                    <div class="row mt-3" >
                                        <div class="col-sm-12">
                                            <el-table :data="consolidationActivities[activity.id]" stripe fit highlight-current-row size="mini" :summary-method="consolidationSummary" show-summary
                                                      style="width:100%;">
                                                <el-table-column label="Ultimul buget aprobat" header-align="center">
                                                    <el-table-column label="Capitol de buget" width="300">
                                                        <template slot-scope="scope">
                                                            <el-popover placement="top-start" title="Categorie de buget" width="350" trigger="click" >
                                                                <span slot="reference" >{{scope.row.value}}</span>
                                                            </el-popover>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column label="Total costuri eligibile" align="right" prop="total_initial" width="90">
                                                        <template slot-scope="scope">
                                                            {{formatNumberAsPrice(scope.row.total_initial)}}
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column label="Din care contributie in natura" align="right" prop="nature_total_initial" width="110">
                                                        <template slot-scope="scope">
                                                            <span v-if="scope.row.nature_total_initial !== undefined && scope.row.nature_total_initial.length > 0">{{formatNumberAsPrice(scope.row.nature_total_initial)}}</span>
                                                            <span v-else> {{formatNumberAsPrice(0)}}</span>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column label="Din care dezvoltare organizationala" prop="dezv_initial" align="right" width="110">
                                                        <template slot-scope="scope">
                                                            {{formatNumberAsPrice(scope.row.dezv_initial)}}
                                                        </template>
                                                    </el-table-column>
                                                </el-table-column>
                                                <el-table-column label="Modificari curente" header-align="center">
                                                    <el-table-column label="Total costuri eligibile" align="right" prop="modified" width="90">
                                                        <template slot-scope="scope">
                                                            {{formatNumberAsPrice(scope.row.modified)}}
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column label="Din care contributie in natura" align="right" prop="nature_diff"  width="110">
                                                        <template slot-scope="scope">
                                                            <span v-if="scope.row.nature_diff !== undefined">{{formatNumberAsPrice(scope.row.nature_diff )}}</span>
                                                            <span v-else> {{formatNumberAsPrice(0)}}</span>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column label="Din care dezvoltare organizationala" prop="modified_dezv"  align="right" width="110">
                                                        <template slot-scope="scope">
                                                            {{formatNumberAsPrice(scope.row.modified_dezv)}}
                                                        </template>
                                                    </el-table-column>
                                                </el-table-column>

                                                <el-table-column label="Buget modificat" header-align="center">
                                                    <el-table-column label="Total costuri eligibile" align="right" prop="total" width="90">
                                                        <template slot-scope="scope">
                                                            {{formatNumberAsPrice(scope.row.total)}}
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column label="Din care contributie in natura" align="right" prop="nature_total" width="110">
                                                        <template slot-scope="scope">
                                                            <span v-if="scope.row.nature_total !== undefined"> {{formatNumberAsPrice(scope.row.nature_total)}}</span>
                                                            <span v-else> {{formatNumberAsPrice(0)}}</span>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column label="Din care dezvoltare organizationala" prop="dezv"  align="right" width="110">
                                                        <template slot-scope="scope">
                                                            {{formatNumberAsPrice(scope.row.dezv)}}
                                                        </template>
                                                    </el-table-column>
                                                </el-table-column>
                                                <el-table-column width="110" label="Pondere realocari activitati fata de ultimul buget aprobat" prop="percentWeight" header-align="center">
                                                </el-table-column>
                                            </el-table>
                                        </div>
                                    </div>
                                </el-tab-pane>
                                <el-tab-pane label="Totaluri directe" name="tabConsTotal1" key="tabConsTotal1">
                                    <h5 class="mt-2 mb-1">Total costuri directe eligibile</h5>
                                    <el-table :data="consolidationDirectTotals" stripe fit highlight-current-row size="mini"
                                              style="width:100%;">
                                        <el-table-column label="Ultimul buget aprobat" header-align="center">
                                            <el-table-column label="Denumire" width="150">
                                                <span>Total costuri directe eligibile</span>
                                            </el-table-column>
                                            <el-table-column label="Total costuri eligibile" align="right" prop="total_Initial" width="90">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.lastBudgetTotal)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care contributie in natura" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.lastBudgetNature)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care dezvoltare organizationala" prop="dezv_Initial" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.lastBudgetDezv)}}
                                                </template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Modificari curente" header-align="center">
                                            <el-table-column label="Total costuri eligibile" align="right" prop="percent_total_eligible" width="90">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.modifiedBudgetTotal)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care contributie in natura" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.modifiedBudgetNature)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care dezvoltare organizationala" prop="percent_dezv" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.modifiedBudgetDezv)}}
                                                </template>
                                            </el-table-column>
                                        </el-table-column>

                                        <el-table-column label="Buget modificat" header-align="center">
                                            <el-table-column label="Total costuri eligibile" align="right" prop="total" width="90">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.currentBudgetTotal)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care contributie in natura" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.currentBudgetNature)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care dezvoltare organizationala" prop="dezv" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.currentBudgetDezv)}}
                                                </template>
                                            </el-table-column>
                                        </el-table-column>
                                    </el-table>
                                </el-tab-pane>
                                <el-tab-pane label="Totaluri indirecte" name="tabConsTotal2" key="tabConsTotal2">
                                    <h5 class="mt-2 mb-1">Total indirecte</h5>
                                    <el-table :data="consolidationIndirectTotal" stripe fit highlight-current-row size="small"
                                              style="width:100%;">
                                        <el-table-column label="Ultimul buget aprobat" header-align="center">
                                            <el-table-column label="Denumire" width="150">
                                                <span>Total costuri indirecte eligibile</span>
                                            </el-table-column>
                                            <el-table-column label="Total costuri eligibile" align="right" prop="total_Initial" width="90">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.lastBudgetTotal)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care contributie in natura" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.lastBudgetNature)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care dezvoltare organizationala" prop="dezv_Initial" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.lastBudgetDezv)}}
                                                </template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Modificari curente" header-align="center">
                                            <el-table-column label="Total costuri eligibile" align="right" prop="percent_total_eligible" width="90">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.modifiedBudgetTotal)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care contributie in natura" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.modifiedBudgetNature)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care dezvoltare organizationala" prop="percent_dezv" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.modifiedBudgetDezv)}}
                                                </template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Buget modificat" header-align="center">
                                            <el-table-column label="Total costuri eligibile" align="right" prop="total" width="90">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.currentBudgetTotal)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care contributie in natura" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.currentBudgetNature)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care dezvoltare organizationala" prop="dezv" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.currentBudgetDezv)}}
                                                </template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Pondere realocari fata de ultimul buget aprobat" prop="percentWeight" header-align="center">
                                            <template slot-scope="scope">
                                                {{formatNumberAsPrice(Math.abs((scope.row.modifiedBudgetTotal / scope.row.lastBudgetTotal) * 100))}} %
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </el-tab-pane>
                                <el-tab-pane label="Totaluri eligibile" name="tabConsTotal3" key="tabConsTotal3">
                                    <h5 class="mt-2 mb-1">Total costuri eligibile ale proiectului</h5>
                                    <el-table :data="consolidationEligibleTotals" stripe fit highlight-current-row size="mini"
                                              style="width:100%;">
                                        <el-table-column label="Ultimul buget aprobat" header-align="center">
                                            <el-table-column label="Denumire" width="150">
                                                <span>Total costuri directe eligibile</span>
                                            </el-table-column>
                                            <el-table-column label="Total costuri eligibile" align="right" prop="total_Initial" width="90">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.lastBudgetTotal)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care contributie in natura" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.lastBudgetNature)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care dezvoltare organizationala" prop="dezv_Initial" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.lastBudgetDezv)}}
                                                </template>
                                            </el-table-column>
                                        </el-table-column>
                                        <el-table-column label="Modificari curente" header-align="center">
                                            <el-table-column label="Total costuri eligibile" align="right" prop="percent_total_eligible" width="90">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.modifiedBudgetTotal)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care contributie in natura" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.modifiedBudgetNature)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care dezvoltare organizationala" prop="percent_dezv" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.modifiedBudgetDezv)}}
                                                </template>
                                            </el-table-column>
                                        </el-table-column>

                                        <el-table-column label="Buget modificat" header-align="center">
                                            <el-table-column label="Total costuri eligibile" align="right" prop="total" width="90">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.currentBudgetTotal)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care contributie in natura" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.currentBudgetNature)}}
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="Din care dezvoltare organizationala" prop="dezv" align="right" width="110">
                                                <template slot-scope="scope">
                                                    {{formatNumberAsPrice(scope.row.currentBudgetDezv)}}
                                                </template>
                                            </el-table-column>
                                        </el-table-column>


                                    </el-table>
                                </el-tab-pane>
                            </el-tabs>
                        </el-tab-pane>

                        <el-tab-pane label="Totaluri" name="totals">
                            <table class="table table-responsive">
                                <thead>
                                <tr>
                                    <th style="width:500px">Surse</th>
                                    <th>Total</th>
                                    <th>Procent din total</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        1. Finantare nerambursabila solicitata
                                    </td>
                                    <td class="text-right">
                                        {{ formatPrice(totalIrredeemable) }}
                                    </td>
                                    <td class="text-right">
                                        {{ formatPercent(totalIrredeemable/totalEligible) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>2. Cofinantare Aplicant si Parteneri</td>
                                    <td class="text-right">
                                        {{ formatPrice(totalCoFunding) }}
                                    </td>
                                    <td class="text-right">
                                        {{ formatPercent(totalCoFunding/totalEligible) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-muted">2.1 Contributie in bani</td>
                                    <td class="text-right text-muted">
                                        {{ formatPrice(totalCash) }}
                                    </td>
                                    <td class="text-right text-muted">
                                        {{ formatPercent(totalCash/totalCoFunding) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-muted">2.2 Contributie in natura</td>
                                    <td class="text-right text-muted">
                                        {{ formatPrice(totalNature) }}
                                    </td>
                                    <td class="text-right text-muted">
                                        {{ formatPercent(totalNature/totalCoFunding) }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>TOTAL COSTURI ELIGIBILE ALE PROIECTULUI</td>
                                    <td class="text-right">
                                        {{ formatPrice(totalEligible) }}
                                    </td>
                                    <td class="text-right">
                                        {{ formatPercent(totalEligible/totalEligible) }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </el-tab-pane>

                        <el-tab-pane label="Total pe parteneri" name="total_partners">
                            <el-table :data="partnerFinancing" fit>
                                <el-table-column label="Cod" width="100">
                                    <template slot-scope="scope">
                                        <span v-if="scope.row.partner_id !== project.promoter.id">
                                            <span v-if="scope.row.partner">
                                                {{ 'P' + scope.row.partner_id }}
                                            </span>
                                            <span v-else>
                                                A
                                            </span>
                                        </span>
                                        <span v-else>
                                            A
                                        </span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Denumire aplicant/partener" prop="price">
                                    <template slot-scope="scope">
                            <span v-if="scope.row.partner_id === project.promoter.id">
                                {{ project.promoter.name  }}
                            </span>
                                        <span v-else-if="scope.row.partner !== null && scope.row.partner !== undefined">
                                {{ scope.row.partner.name  }}
                            </span>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Cost total" prop="price" width="140" align="right" header-align="center">
                                    <template slot-scope="scope">{{ formatPrice(parseFloat(scope.row.totalBudget) + parseFloat(scope.row.totalCash) + parseFloat(scope.row.totalNature)) }}</template>
                                </el-table-column>
                                <el-table-column label="Din care comp. dezv. org." width="200" align="right" header-align="center">
                                    <template slot-scope="scope">{{ scope.row.totalDezv > 0 ? formatPrice(scope.row.totalDezv) : formatPrice(0,2) }}</template>
                                </el-table-column>
                            </el-table>
                        </el-tab-pane>

                        <el-tab-pane label="Buget sintetic" name="synthetic">
                            <el-table :data="synthetic" size="mini">
                                <el-table-column label="Linia de buget" width="300">
                                    <template slot-scope="scope">{{ scope.row.budget }}</template>
                                </el-table-column>
                                <template v-for="i in 14">
                                    <el-table-column :label="'A' + i + ' total'" width="100" header-align="center" align="right">
                                        <template slot-scope="scope">
                                            <span v-if="parseInt(scope.row['totalA'+i]) > 0">{{ formatPrice(scope.row['totalA'+i],2) }}</span>
                                            <span v-else>{{ formatPrice(0,2) }}</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column :label="'A' + i + ' dezv'" width="100" header-align="center" align="right">
                                        <template slot-scope="scope">
                                            <span v-if="parseInt(scope.row['dezvA'+i]) > 0">{{ formatPrice(scope.row['dezvA'+i],2) }}</span>
                                            <span v-else>{{ formatPrice(0,2) }}</span>
                                        </template>
                                    </el-table-column>
                                </template>
                            </el-table>
                        </el-tab-pane>
                        <el-tab-pane label="Export excel" name="export">
                            <a href="#" @click.prevent="openUrl('/notifications/export-document-excel/'+notification.id, true)" title="Export in Excel" class="btn btn-sm btn-secondary ml-5">
                                <i class="icofont-file-excel"></i> Export în Excel
                            </a>
                        </el-tab-pane>
                        <el-tab-pane label="Validari" name="validation">
                            <div v-if="notification" id="validation">
                                <h3 class="mb-4 mt-3">
                                    Validare
                                    <span v-if="notification.type === 1" >act adițional</span>
                                    <span v-else>notificare</span>
                                </h3>
                                <div class="row">
                                    <div class="col-sm-12">
                                        <el-collapse accordion>
                                            <el-collapse-item name="1">
                                                <template slot="title">
                                                    1. Lucrari de reabilitare
                                                    <div v-if="constructionWorks.message">
                                                        <div class="badge badge-success" v-if="constructionWorks.valid" style="margin-left:10px;"><i class="el-icon-success"></i> {{ constructionWorks.message }}</div>
                                                        <div class="badge badge-danger" v-if="!constructionWorks.valid" style="margin-left:10px;"><i class="el-icon-error"></i> {{ constructionWorks.message }}</div>
                                                    </div>
                                                </template>
                                                <div style="font-weight:bold;">Costurile aferente constructiei, reconstructiei, renovarii sau modernizarii unui imobil pot fi de maxim 50% din total costuri directe eligibile.</div>
                                            </el-collapse-item>

                                            <el-collapse-item name="2">
                                                <template slot="title">
                                                    2. Costuri indirecte
                                                    <div v-if="indirectCosts.message">
                                                        <div class="badge badge-success" v-if="indirectCosts.valid" style="margin-left:10px;"><i class="el-icon-success"></i> {{ indirectCosts.message }}</div>
                                                        <div class="badge badge-danger" v-if="!indirectCosts.valid" style="margin-left:10px;"><i class="el-icon-error"></i> {{ indirectCosts.message }}</div>
                                                    </div>
                                                </template>
                                                <div style="font-weight:bold;">Suma bugetata pentru costurile indirecte nu trebuie sa depaseasca valoarea rezultata prin aplicarea procentul initial al consturilor indirecte din contractul de finantare asupra totalului costurilor cu personalul angajat in proiect rezultat in urma modificarii contractuale (costurile aferente liniei bugetare 1.1.1. Resurse umane).</div>
                                            </el-collapse-item>

                                            <el-collapse-item name="3">
                                                <template slot="title">
                                                    3. Costuri dezvoltare organizationala
                                                    <div v-if="organizationalDevelopment.message">
                                                        <div class="badge badge-success" v-if="organizationalDevelopment.valid" style="margin-left:10px;"><i class="el-icon-success"></i> {{ organizationalDevelopment.message }}</div>
                                                        <div class="badge badge-danger" v-if="!organizationalDevelopment.valid" style="margin-left:10px;"><i class="el-icon-error"></i> {{ organizationalDevelopment.message }}</div>
                                                    </div>
                                                </template>
                                                <div style="font-weight:bold;">Exista posibilitatea de a aloca pana la 20% din totalul finantarii nerambursabile a proiectului pentru activitati care sa contribuie la dezvoltarea organizationala a Solicitantului si / sau a partenerilor ONG din Romania cu exceptia Outcome-ului 4 Capcitate si sustenabilitate crescute ale Societatii Civile.
                                                    In aceasta situatie, Solicitantii vor trebui sa stabileasca obiective si activitati separate de dezvoltare organizationala si sa le mentioneze ca atare in Cererea de finantare.
                                                    Nu este acceptata includerea de costuri de dezvoltare organizationala in buget fara a specifica in cuprinsul Cererii de finantare obiectivul / ele de dezvoltare organizationala avut / e in vedere. Costurile cu achizitia de echipamente vor fi acceptate doar daca sustin obiective de dezvoltare organizationala specificate in aplicatie.
                                                </div>
                                            </el-collapse-item>

                                            <el-collapse-item name="4">
                                                <template slot="title">
                                                    4. Verificare mentinere finantare nerambursabila acordata
                                                    <div v-if="keepNerambursabil.message">
                                                        <div class="badge badge-success" v-if="keepNerambursabil.valid" style="margin-left:10px;"><i class="el-icon-success"></i> {{ keepNerambursabil.message }}</div>
                                                        <div class="badge badge-danger" v-if="!keepNerambursabil.valid" style="margin-left:10px;"><i class="el-icon-error"></i> {{ keepNerambursabil.message }}</div>
                                                    </div>
                                                </template>
                                                <div style="font-weight:bold;">
                                                    Valoarea finanţării nerambursabile din bugetul modificat trebuie a fie egala cu valoarea finanţării nerambursabile din bugetul initial (cel de la semnarea contractului de finantare)
                                                </div>
                                            </el-collapse-item>

                                            <el-collapse-item name="5">
                                                <template slot="title">
                                                    5. Suma maxima de finantare nerambursabila pentru promotor
                                                    <div v-if="budgetMaxGrantPromoter.message">
                                                        <div class="badge badge-success" v-if="budgetMaxGrantPromoter.valid" style="margin-left:10px;"><i class="el-icon-success"></i> {{ budgetMaxGrantPromoter.message }}</div>
                                                        <div class="badge badge-danger" v-if="!budgetMaxGrantPromoter.valid" style="margin-left:10px;"><i class="el-icon-error"></i> {{ budgetMaxGrantPromoter.message }}</div>
                                                    </div>
                                                </template>
                                                <div style="font-weight:bold;">Suma maxima de finantare nerambursabila de care beneficiaza promotorul proiectului din programul ACF,
                                                    atat ca promotor cat si ca partener, nu depaseste 550.000 euro sau numarul maxim de proiecte pe care
                                                    le gestioneaza promotorul proiectului prin programul ACF, atat ca promotor cat si ca partener, este de 4 proiecte.</div>
                                            </el-collapse-item>

                                            <el-collapse-item name="6">
                                                <template slot="title">
                                                    6. Suma maxima de finantare nerambursabila pentru parteneri
                                                    <div v-if="budgetMaxGrantPartner.message">
                                                        <div class="badge badge-success" v-if="budgetMaxGrantPartner.valid" style="margin-left:10px;"><i class="el-icon-success"></i> {{ budgetMaxGrantPartner.message }}</div>
                                                        <div class="badge badge-danger" v-if="!budgetMaxGrantPartner.valid" style="margin-left:10px;"><i class="el-icon-error"></i> {{ budgetMaxGrantPartner.message }}</div>
                                                    </div>
                                                </template>
                                                <div style="font-weight:bold;">Suma maxima de finantare nerambursabila de care beneficiaza fiecare partener al  proiectului din programul ACF Romania, atat ca promotor cat si ca partener, nu depaseste 550.000 euro sau numarul maxim de proiecte pe care le gestioneaza fiecare partener al proiectului prin programul ACF Romania, atat ca promotor cat si ca partener, este de 4 proiecte.</div>
                                            </el-collapse-item>


                                            <el-collapse-item name="8">
                                                <template slot="title">
                                                    7. Depasire pondere realocare intre activitati
                                                    <div v-if="realocation.message">
                                                        <div class="badge badge-success" v-if="realocation.valid" style="margin-left:10px;"><i class="el-icon-success"></i> {{ realocation.message }}</div>
                                                        <div class="badge badge-danger" v-if="!realocation.valid" style="margin-left:10px;"><i class="el-icon-error"></i> {{ realocation.message }}</div>
                                                    </div>
                                                </template>
                                                <div style="font-weight:bold;">Ponderea realocarilor intre activitati pentru notificare nu poate sa depaseasca 20%.</div>
                                            </el-collapse-item>

                                            <el-collapse-item name="7">
                                                <template slot="title">
                                                    Atentionare! Verificare mentinere buget total
                                                    <div v-if="maintainBudget.message">
                                                        <div class="badge badge-success" v-if="maintainBudget.valid" style="margin-left:10px;"><i class="el-icon-success"></i> {{ maintainBudget.message }}</div>
                                                        <div class="badge badge-danger" v-if="!maintainBudget.valid" style="margin-left:10px;"><i class="el-icon-error"></i> {{ maintainBudget.message }}</div>
                                                    </div>
                                                </template>
                                                <div style="font-weight:bold;">

                                                </div>
                                            </el-collapse-item>
                                        </el-collapse>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 text-right mb-5">
                                        <el-button type="secondary" @click="validateProject">Valideaza proiectul</el-button>
                                    </div>
                                </div>
                                <!--<div class="row">
                                    <div class="col-sm-12 text-right mb-5">
                                        <el-button type="secondary" @click="validateProject">Valideaza modificare contractuala</el-button>
                                    </div>
                                </div>-->
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>

                <div v-if="tag.id === 212 || tag.id === 219">
                    <div class="col-sm-12">
                        <label>Date initiale</label>
                        <p>{{ notification.beneficiary_Initial }}</p>
                        <label>Modificare</label>
                        <p>{{ notification.beneficiary }}</p>
                    </div>
                </div>

                <div v-if="tag.id === 211 || tag.id === 214">
                    Date initiale
                    <el-table :data="notification.employeestrashed.filter(p => p.cnp_Initial !== null)" size="mini">
                        <el-table-column label="Aplicant/Partener" width="150">
                            <template slot-scope="scope">
                                <span v-if="scope.row.partner_initial && scope.row.partner_id_Initial !== project.promoter_id">
                                    {{ scope.row.partner_initial.name }}
                                </span>
                                <span v-else>
                                    Aplicant
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="CNP" prop="cnp_Initial"></el-table-column>
                        <el-table-column label="Pozitie" prop="position_Initial"></el-table-column>
                        <el-table-column label="Nume">
                            <template slot-scope="scope">
                                {{ scope.row.first_name_Initial }} {{ scope.row.last_name_Initial }}
                            </template>
                        </el-table-column>
                        <el-table-column label="CIM / Data" prop="cim_Initial">
                            <template slot-scope="scope">
                                <span v-if="scope.row.cim_Initial && scope.row.cim_date_Initial">
                                    {{ scope.row.cim_Initial }} / {{ formatUserDate(scope.row.cim_date_Initial) }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Perioada">
                            <template slot-scope="scope">
                                <span v-if="scope.row.start_date_Initial">
                                    {{ formatUserDate(scope.row.start_date_Initial) }}<br />{{ formatUserDate(scope.row.end_date_Initial) }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Ore / Tip norma" prop="hours">
                            <template slot-scope="scope">{{ scope.row.workload_hours_Initial }} <span v-if="scope.row.workload_initial">{{ scope.row.workload_initial.value }}</span></template>
                        </el-table-column>
                        <el-table-column label="Salariu brut">
                            <template slot-scope="scope">{{ formatPrice(scope.row.salary_Initial, 2, ' RON') }}</template>
                        </el-table-column>
                        <el-table-column label="Ore bugetate">
                            <template slot-scope="scope">{{ formatNumber(scope.row.hours_Initial) }}</template>
                        </el-table-column>
                        <el-table-column label="Cost orar proiect">
                            <template slot-scope="scope">{{ formatPrice(scope.row.project_Initial) }}</template>
                        </el-table-column>
                    </el-table>
                    <hr>
                    Date curente
                    <el-table :data="notification.employees" size="mini">
                        <el-table-column label="Aplicant/Partener" width="150">
                            <template slot-scope="scope">
                                <!--<span v-if="scope.row.partner && scope.row.partner['notification_id'] === notification.id">
                                    {{ scope.row.partner.name }}
                                </span>
                                <span v-else-if="scope.row.partner_initial && scope.row.partner_id !== null">
                                    {{ scope.row.partner_initial.name }}
                                </span>
                                <span v-else>
                                    Aplicant
                                </span>-->
                                <span v-if="scope.row.partner_id !== project.promoter.id">
                                    <span v-if="scope.row.partner && scope.row.partner['notification_id'] === notification.id">
                                        {{ scope.row.partner.name }}
                                    </span>
                                    <span v-else-if="scope.row.partner_initial">
                                        {{ scope.row.partner_initial.name }}
                                    </span>
                                    <span v-else-if="scope.row.partner">
                                        {{ scope.row.partner.name }}
                                    </span>
                                    <span v-else>
                                        Aplicant
                                    </span>
                                </span>
                                <span v-else>
                                    Aplicant
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="CNP" prop="cnp"></el-table-column>
                        <el-table-column label="Pozitie" prop="position"></el-table-column>
                        <el-table-column label="Nume">
                            <template slot-scope="scope">
                                {{ scope.row.first_name }} {{ scope.row.last_name }}
                            </template>
                        </el-table-column>
                        <el-table-column label="CIM / Data" prop="cim">
                            <template slot-scope="scope">
                                <span v-if="scope.row.cim && scope.row.cim_date">
                                    {{ scope.row.cim }} / {{ formatUserDate(scope.row.cim_date) }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Perioada">
                            <template slot-scope="scope">
                                <span v-if="scope.row.start_date">
                                    {{ formatUserDate(scope.row.start_date) }}<br />{{ formatUserDate(scope.row.end_date) }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Ore / Tip norma" prop="hours">
                            <template slot-scope="scope">{{ scope.row.workload_hours }} <span v-if="scope.row.workload">{{ scope.row.workload.value }}</span></template>
                        </el-table-column>
                        <el-table-column label="Salariu brut">
                            <template slot-scope="scope">{{ formatPrice(scope.row.salary, 2, ' RON') }}</template>
                        </el-table-column>
                        <el-table-column label="Ore bugetate">
                            <template slot-scope="scope">{{ formatNumber(scope.row.hours) }}</template>
                        </el-table-column>
                        <el-table-column label="Cost orar proiect">
                            <template slot-scope="scope">{{ formatPrice(scope.row.project) }}</template>
                        </el-table-column>
                    </el-table>
                </div>

                <div v-if="tag.id === 216 && notification.promoter !== null">
                    Date initiale
                    <div class="row">
                        <div class="col-sm-6">
                            <label>Denumire promotor</label>
                            <p>{{notification.promoter.name_Initial}}</p>
                        </div>
                        <div class="col-sm-6">
                            <label>Denumire promotor in limba engleza</label>
                            <p>{{notification.promoter.name_en_Initial}}</p>
                        </div>
                        <div class="col-sm-6"></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <label>CIF</label>
                            <p>{{notification.promoter.fiscal_attribute_Initial}} {{notification.promoter.cif_Initial}}</p>
                        </div>
                        <div class="col-sm-3">
                            <label>Nr. inregistrare</label>
                            <p>{{notification.promoter.register_no_Initial}}</p>
                        </div>
                        <div class="col-sm-3">
                            <label>Forma juridica</label>
                            <p v-if="notification.promoter.type_initial">{{notification.promoter.type_initial.value}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label>Adresa</label>
                            <p>{{notification.promoter.address_Initial}}</p>
                        </div>
                        <div class="col-sm-3">
                            <label>Localitate</label>
                            <p v-if="notification.promoter.city_initial">{{notification.promoter.city_initial.name}}</p>
                        </div>
                        <div class="col-sm-3">
                            <label>Judet</label>
                            <p v-if="notification.promoter.city_initial">{{notification.promoter.city_initial.county.name}}</p>
                        </div>
                        <div class="col-sm-3">
                            <label>Localizare sediu social</label>
                            <p v-if="notification.promoter.location_type_Initial">{{notification.promoter.location_type_Initial}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <label>Telefon</label>
                            <p><a :href="urlPhone(notification.promoter.phone_Initial)">{{notification.promoter.phone_Initial}}</a></p>
                        </div>
                        <div class="col-sm-3">
                            <label>Email</label>
                            <p><a :href="urlEmail(notification.promoter.email_Initial)">{{notification.promoter.email_Initial}}</a></p>
                        </div>
                        <div class="col-sm-3">
                            <label>Website</label>
                            <p><a :href="notification.promoter.website_Initial" target="_blank">{{notification.promoter.website_Initial}}</a></p>
                        </div>
                    </div>
                    <hr>
                    Date curente
                    <div class="row">
                        <div class="col-sm-6">
                            <label>Denumire promotor</label>
                            <p>{{notification.promoter.name}}</p>
                        </div>
                        <div class="col-sm-6">
                            <label>Denumire promotor in limba engleza</label>
                            <p>{{notification.promoter.name_en}}</p>
                        </div>
                        <div class="col-sm-6"></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <label>CIF</label>
                            <p>{{notification.promoter.fiscal_attribute}} {{notification.promoter.cif}}</p>
                        </div>
                        <div class="col-sm-3">
                            <label>Nr. inregistrare</label>
                            <p>{{notification.promoter.register_no}}</p>
                        </div>
                        <div class="col-sm-3">
                            <label>Forma juridica</label>
                            <p v-if="project.promoter.type">{{project.promoter.type.value}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">
                            <label>Adresa</label>
                            <p>{{notification.promoter.address}}</p>
                        </div>
                        <div class="col-sm-3">
                            <label>Localitate</label>
                            <p v-if="notification.promoter.city">{{notification.promoter.city.name}}</p>
                        </div>
                        <div class="col-sm-3">
                            <label>Judet</label>
                            <p v-if="notification.promoter.city">{{notification.promoter.city.county.name}}</p>
                        </div>
                        <div class="col-sm-3">
                            <label>Localizare sediu social</label>
                            <p v-if="notification.promoter.location_type">{{notification.promoter.location_type}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-3">
                            <label>Telefon</label>
                            <p><a :href="urlPhone(notification.promoter.phone)">{{notification.promoter.phone}}</a></p>
                        </div>
                        <div class="col-sm-3">
                            <label>Email</label>
                            <p><a :href="urlEmail(notification.promoter.email)">{{notification.promoter.email}}</a></p>
                        </div>
                        <div class="col-sm-3">
                            <label>Website</label>
                            <p><a :href="notification.promoter.website" target="_blank">{{notification.promoter.website}}</a></p>
                        </div>
                    </div>
                    <div class="row" v-if="notification.promoter.documents_promoter">
                        <div class="col-sm-3">
                            <label>Documente justificative</label>
                            <div v-for="doc in notification.promoter.documents_promoter" :key="doc.id">
                                <a :href="'/getFile/' + doc.id" target="_blank">{{ doc.file_name }}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="tag.id === 217">
                    <label>Plan comunicare</label>
                    <div v-for="doc in notification.plan_document" :key="doc.id">
                        <a :href="'/getFile/' + doc.id" target="_blank">{{ doc.file_name }}</a>
                    </div>
                </div>

                <div v-if="tag.id === 221">
                    Date initiale
                    <div class="row" v-if="notification.promoter_representatives2" v-for="repr in notification.promoter_representatives2">
                        <div class="col-sm-3">
                            <label>Prenume</label>
                            <p>{{ repr.first_name }}</p>
                        </div>
                        <div class="col-sm-3">
                            <label>Nume</label>
                            <p>{{ repr.last_name }}</p>
                        </div>
                        <div class="col-sm-6"></div>
                    </div>
                    <div v-else class="alert alert-info">
                        Nu au existat date initiale!
                    </div>
                    <hr>
                    Date curente
                    <div class="row" v-if="notification.promoter_representatives" v-for="repr in notification.promoter_representatives">
                        <div class="col-sm-3">
                            <label>Prenume</label>
                            <p>{{ repr.first_name }}</p>
                        </div>
                        <div class="col-sm-3">
                            <label>Nume</label>
                            <p>{{ repr.last_name }}</p>
                        </div>
                        <div class="col-sm-6"></div>
                    </div>
                    <div v-else class="alert alert-info">
                        Nu exista reprezentanti legali pentru aceasta firma
                    </div>
                </div>

                <div v-if="tag.id === 213 || tag.id === 220" class="row">
                    <div class="col-sm-6">
                        <label>Localitati de desfasurare <b>(Date initiale)</b></label>
                        <p>
                            <el-tag v-for="tag in project.locations" :key="tag.name" class="mr-1" type="info">
                                {{ tag.name }}<span v-if="tag.county.name !== 'Bucuresti'">, Judetul {{ tag.county.name }}</span>
                            </el-tag>
                        </p>

                        <div class="col-sm-6">
                            <label>Proiectul este preponderent in mediul</label>
                            <p v-if="project.medium !== undefined && project.medium !== null">{{ project.medium.value }}</p>
                        </div>
                        <div class="col-sm-6">
                            <!--                <label>Categoria de incadrare</label>-->
                            <!--                <p v-if="project.framing">{{ project.framing.value }}</p>-->
                        </div>
                        <!--<div class="col-sm-6">
                            <label>Prioritati transversale ale programului</label>
                            <p v-if="project.priorities">{{ project.priorities.value }}</p>
                        </div>-->
                        <div class="col-sm-6">
            <!--                <label>Policy markers</label>-->
            <!--                <p v-if="project.policies">{{ project.policies.value }}</p>-->
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label>Localitati de desfasurare <b>(Date curente)</b></label>
                        <p>
                            <el-tag v-for="tag in notification.places" :key="tag.name" class="mr-1" type="info">
                                {{ tag.name }}<span v-if="tag.county !== 'Bucuresti'">, Judetul {{ tag.county }}</span>
                            </el-tag>
                        </p>

                        <div class="col-sm-6">
                            <label>Proiectul este preponderent in mediul</label>
                            <p v-if="notification.medium !== undefined && notification.medium !== null">{{ notification.medium.value }}</p>
                            <p v-else-if="project.medium !== undefined && project.medium !== null">{{ project.medium.value }}</p>
                        </div>
                        <div class="col-sm-6">
            <!--                <label>Categoria de incadrare</label>-->
            <!--                <p v-if="notification.framing">{{ notification.framing.value }}</p>-->
                        </div>
                        <!--<div class="col-sm-6">
                            <label>Prioritati transversale ale programului</label>
                            <p v-if="notification.priorities">{{ notification.priorities.value }}</p>
                        </div>-->
                        <div class="col-sm-6">
            <!--                <label>Policy markers</label>-->
            <!--                <p v-if="notification.policies">{{ notification.policies.value }}</p>-->
                        </div>
                    </div>
                </div>

                <div class="row" v-if="tag.id === 222">
                    <div class="col-sm-12">
                        Date initiale
                        <el-table :data="project.rgraph" stripe fit highlight-current-row size="mini">
                            <el-table-column prop="reporting_id" label="Nr. Raportare" width="200" align="center"></el-table-column>
                            <el-table-column prop="date" label="Ultima zi de depunere"  align="center" width="200">
                                <template slot-scope="scope">
                                    {{ formatUserDate(scope.row.date) }}
                                </template>
                            </el-table-column>
                            <el-table-column label="Suma Estimata" align="right">
                                <template slot-scope="scope">
                                    {{ formatPrice(scope.row.estimated_amount) }}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <hr>
                    <div class="col-sm-12">
                        Date curente
                        <el-table :data="notification.rgraph" stripe fit highlight-current-row size="mini">
                          <el-table-column prop="reporting_id" label="Nr. Raportare" width="200" align="center"></el-table-column>
                          <el-table-column prop="date" label="Ultima zi de depunere"  align="center" width="200">
                              <template slot-scope="scope">
                                {{ formatUserDate(scope.row.date) }}
                              </template>
                          </el-table-column>
                          <el-table-column label="Suma Estimata" align="right">
                                <template slot-scope="scope">
                                    {{ formatPrice(scope.row.estimated_amount) }}
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>

                <div v-if="tag.id === 224 || tag.id === 225">
                    <div class="col-sm">
                        <div>{{ notification.other_changes }}</div>
                    </div>
                </div>

            </el-tab-pane>
        </el-tabs>
        <hr>
        <div class="form-group">
            <h5>Lista comentarii:</h5>
            <div class="feed-element" v-for="(row, index) in notification.comments" :key="index">
                <div class="media-body">
                    <i class="icofont-girl" style="font-size:100%"></i> <b v-if="row.user && typeof row.user.promoter[0] !== 'undefined'">Promotor</b><b v-else>Operator de Fond</b>: {{ row.comment }}<br>
                    <small class="text-muted green"><i class="icofont-clock-time"></i> {{ formatUserDate(row.created_at) }}</small>
                    <el-button v-if="row.user_id === user_id && row.reply_to_id === -1" class="float-right ml-2" size="mini" type="primary" icon="el-icon-edit"
                        @click="innitEditComment(row)">Editeaza</el-button>
                    <el-button v-if="row.user_id === user_id && row.reply_to_id === -1" class="float-right ml-2" size="mini" type="warning" icon="el-icon-files"
                        @click="openDrawerComment(row)">Incarca fisiere</el-button>

                    <p v-if="row.files !== undefined && row.files.length" class="text-right">
                        <small>
                            Fisiere incarcate:
                            <span v-for="doc in row.files">
                                <br/><a :href="'/getFile/' + doc.id" target="_blank">{{ doc.file_name }}</a><br/>
                            </span>
                        </small>
                    </p>
                </div>
                <hr>
            </div>
        </div>
        <div class="form-group">
            <label>Adauga un comentariu</label>
            <el-input v-model="form.comentariu"  placeholder="Adauga comentariul tau referitor la validare" type="textarea"></el-input>
        </div>
        <el-button size="mini" type="secondary" icon="el-icon-plus"
           @click="addComment()" :disabled="form.comentariu && !isOFFButton ? false : true">Adauga comentariu</el-button>
       <hr>
       <div class="form-group">
            <h5>
                Se asteapta raspuns pana la data de: <span v-if="notification.answer_limit">{{ formatUserDateTime(notification.answer_limit) }}</span>
                <small v-if="form.answer_limit"><br/>Timp ramas: {{ datesDifferenceWithoutDayPlus(contractMinDate, form.answer_limit) }}</small>
            </h5>
            <label>
                <el-date-picker v-model="form.answer_limit" placeholder="Pana la" type="date" value-format="yyyy-MM-dd" size="medium"
                                format="dd.MM.yyyy" style="width:100%" :picker-options="startPeriodOptions" @change="UPDB"></el-date-picker>
            </label>
        </div>
        <hr>
        <div class="form-group" v-if="notification.type === 1">
            <h5>
                Data intrarii in vigoare a modificarilor contractuale: <span v-if="notification.change_date">{{ formatUserDate(notification.change_date) }}</span>
            </h5>
            <label>
                <el-date-picker v-model="form.change_date" placeholder="Pana la" type="date" value-format="yyyy-MM-dd"
                format="dd.MM.yyyy" style="width:100%" @change="UPDB2"></el-date-picker>
            </label>
        </div>
        <hr>
        <div class="row my-3">
            <div class="col-sm-6 text-left">
                <el-button @click="sendNotification()" class="btn btn-warning" type="secondary"><i class="icofont-exclamation-tringle"></i> Trimite spre revizuire</el-button>
                <el-button @click="dialogFormVisible = true" class="btn btn-danger" type="danger"><i class="icofont-close"></i> Respinge</el-button>
            </div>
            <div class="col-sm-6 text-right">
                <el-button class="btn btn-success btn-sm" @click="saveValidation()" type="primary"><i class="icofont-verification-check"></i> Aproba</el-button>
            </div>
        </div>

        <!-- Respingere act aditional - introducere motiv -->
        <el-dialog :title="notification.type === 1 ? 'Respingere - Act Aditional' : 'Respingere - Notificare'" :visible.sync="dialogFormVisible">
          <div class="form-group">
                <label>Scrie mai jos motivul pentru care vrei sa respingi aceasta modificare contractuala:</label>
                <el-input v-model="form.reject_reason" placeholder="Scrie aici..." type="textarea"></el-input>
            </div>
            <el-button size="mini" type="danger"
               @click="rejectNotification()" :disabled="form.reject_reason && !isOFFButton ? false : true"><i class="icofont-close"></i> Respinge</el-button>
        </el-dialog>

        <!-- Editare comentariu -->
        <el-dialog :title="notification.type === 1 ? 'Editare comentariu - Act Aditional' : 'Editare comentariu - Notificare'" :visible.sync="dialogEditComment">
          <div class="form-group">
                <label>Scrie mai jos noul comentariu:</label>
                <el-input v-model="form.new_comment" placeholder="Scrie aici..." type="textarea"></el-input>
            </div>
            <el-button size="mini" type="primary"
               @click="editCommentAction()" :disabled="form.new_comment ? false : true"><i class="icofont-edit"></i> Editeaza comentariul</el-button>
        </el-dialog>

        <upload-drawer></upload-drawer>
    </div>
</template>
<script>
import {mapMutations, mapActions, mapGetters} from 'vuex'
    import nl2br from 'vue-nl2br';
    import VueScrollTo from 'vue-scrollto';
    import mixin from './../../mixins/common';
    import moment from 'moment';
    import UploadDrawer from "../common/upload-drawer";
    import PaneActionsCalendar from "./pane-actions-calendar";
    import PaneCalendar from "./pane-actions-calendar-initial";
    import numeral from "numeral";
    import {validateConstructionWorks} from "../../store/modules/validationNotification/actions";
    export default {
        name: "notification-verify",
        mixins:[mixin],
        components:{
            UploadDrawer,
            PaneActionsCalendar,
            PaneCalendar,
            nl2br
        },
        data() {
            return {
                currentDate: new Date(),
                planTabs:'activities',
                form: {
                    answer_limit: this.notification.answer_limit,
                    new_comment: null
                },
                dialogFormVisible: false,
                dialogEditComment: false,
                loading:false,
                isOFFButton:false,
                contractMinDate: new Date(),
                updateComment: {},
                startPeriodOptions: {
                    disabledDate: this.disabledContractDate
                },
                activeName: [],
                indirects: [],
                synthetic: [],
                financing: [],
                partnerFinancing: [],
                consolidationDirectTotals: [],
                consolidationIndirectTotal: [],
                consolidationEligibleTotals: [],
                consolidationActivities: [],
                totalIndirects: 0,
                totalIndirectsInitial: 0,
                indirectPercent: 0,
                totalEligible: 0,
                totalIrredeemable: 0,
                totalCoFunding: 0,
                totalNature: 0,
                totalCash: 0,
                maxValue: 0,
                key:0,
            }
        },
        props:{
            project: {
                required: true,
                type: Object
            },
            notification: {
                required: true,
                type: Object
            },
            user_id: {
                required: true,
            }
        },
        computed: {
            ...mapGetters({
                constructionWorks: 'validationNotification/constructionWorks',
                indirectCosts: 'validationNotification/indirectCosts',
                organizationalDevelopment: 'validationNotification/organizationalDevelopment',
                budgetMaxGrantPromoter: 'validationNotification/budgetMaxGrantPromoter',
                budgetMaxGrantPartner: 'validationNotification/budgetMaxGrantPartner',
                keepNerambursabil: 'validationNotification/keepNerambursabil',
                maintainBudget: 'validationNotification/maintainBudget',
                realocation: 'validationNotification/realocation',
            }),
        },
        methods:{
            ...mapActions({
                saveNotificationAnswerLimit: 'notification/saveNotificationAnswerLimit',
                saveNotificationEffectiveDate: 'notification/saveNotificationEffectiveDate',
                editComment: 'notification/editComment',
                getIndirectsBudget: 'notification/getIndirectsBudget',
                getIndirectPercent: 'notification/getIndirectPercent',
                getFinancingBudget: 'notification/getFinancingBudget',
                getTotalBudget: 'notification/getTotalBudget',
                getTotalPartner: 'notification/getTotalPartner',
                getSynthetic: 'notification/getSynthetic',
                getConsolidationActivities: 'notification/getConsolidationActivities',
                getConsolidationDirectTotals: 'notification/getConsolidationDirectTotals',
                getConsolidationIndirectTotals: 'notification/getConsolidationIndirectTotals',
                getConsolidationEligibleTotals: 'notification/getConsolidationEligibleTotals',
                // validation
                validateConstructionWorks: 'validationNotification/validateConstructionWorks',
                validateIndirectCosts: 'validationNotification/validateIndirectCosts',
                validateOrganizationalDevelopment: 'validationNotification/validateOrganizationalDevelopment',
                validateMaxGrantPromoter: 'validationNotification/validateMaxGrantPromoter',
                validateMaxGrantPartner: 'validationNotification/validateMaxGrantPartner',
                validatekeepNerambursabil: 'validationNotification/validatekeepNerambursabil',
                validatemaintainBudget: 'validationNotification/validatemaintainBudget',
                validaterealocation: 'validationNotification/validaterealocation',
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
            }),
            validateProject(){
                this.maintainBudget.message = null;
                this.indirectCosts.message = null;
                this.organizationalDevelopment.message = null;
                this.budgetMaxGrantPromoter.message = null;
                this.budgetMaxGrantPartner.message = null;
                this.keepNerambursabil.message = null;
                this.realocation.message = null;

                this.validateConstructionWorks({id:this.project.id, notification:this.notification.id});
                this.validateIndirectCosts({id:this.project.id, notification:this.notification.id});
                this.validateOrganizationalDevelopment({id:this.project.id, notification:this.notification.id});
                this.validateMaxGrantPromoter({id:this.project.id, notification:this.notification.id});
                this.validateMaxGrantPartner({id:this.project.id, notification:this.notification.id});
                this.validatekeepNerambursabil({id:this.project.id, notification:this.notification.id});
                this.validatemaintainBudget({id:this.project.id, notification:this.notification.id});
                this.validaterealocation({id:this.project.id, notification:this.notification.id});
                VueScrollTo.scrollTo('#validation');
            },
            disabledContractDate(date) {
                return date < this.contractMinDate
            },
            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 1) {
                        sums[index] = 'Totaluri';
                        return;
                    }
                    if(index < 9){
                        sums[index] = '';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    } else {
                        sums[index] = ' - ';
                    }
                    if(index === 9 || index === 10){
                        sums[index] =  this.formatPrice(sums[index]);
                    }
                });
                return sums;
            },
            okIndirects2(){
                let tc = 0;
                let tn = 0;
                let te = 0;
                let ti = 0;
                if (typeof this.notification.financing !== 'undefined') {
                    this.notification.financing.forEach(function(i){
                        tc += parseFloat(i.totalCash);
                        tn += parseFloat(i.totalNature);
                        te += parseFloat(i.totalBudget) + parseFloat(i.totalIndirect);
                    });
                }

                this.totalCash = tc;
                this.totalNature = tn;
                this.totalEligible = te + ti;
                this.totalCoFunding = this.totalCash + this.totalNature;
                this.totalIrredeemable = this.totalEligible - this.totalCoFunding;
                return parseFloat(this.project.funding_budget) >= this.totalCash;
            },
            getClass(property){
                return property === 0 ? 'activities' : '';
            },
            /*promoterDocument(object){
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\NotificationPromoter',
                    model_id: this.notification.id,
                    acceptFiles: '.pdf,.docx,.doc,.jpg,.xls', //.docx,.doc,
                    multiple: true,
                    title: 'Vizualizare documente',
                    files: object.documents_promoter,
                });
            },*/
            uploadDocument(form)
            {
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Notification',
                    model_id: this.notification.id,
                    category_id: 52, // Category ID 51 = Semnare Document Acte Aditionale - ADMIN
                    acceptFiles: '.pdf',
                    multiple: true,
                    canDelete: 1,
                    title: 'Documentul modificarii contractuale semnat',
                    files: form.signed_document_admin,
                    updateAfterCloseSignedDocument: 1,
                });
            },
            openDrawer(object){
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\NotificationPartner',
                    model_id: object.id,
                    acceptFiles: '.pdf,.docx,.doc,.jpg,.xls', //.docx,.doc,
                    multiple: true,
                    title: 'Vizualizare documente',
                    files: object.documents_partners,
                });
            },
            openDrawerComment(object){
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Comment',
                    model_id: object.id,
                    acceptFiles: '.pdf,.docx,.doc,.jpg,.xls', //.docx,.doc,
                    multiple: true,
                    updateCommentFiles: 1,
                    comments: object,
                    title: 'Documente comentariu',
                    files: object.files,
                });
            },
            handleClick(tab, event) {

                if (tab.name === 'indirects') {
                    // go and bring the indirects
                    this.getIndirectsBudget({
                        project_id: this.project.id, id:this.notification.id,
                    }).then((response) => {
                        this.indirects = response.data;
                        this.loading = false;
                    });
                    this.getIndirectPercent({
                        project_id: this.project.id, id:this.notification.id,
                    }).then((response) => {
                        this.indirectPercent = response.data;
                        this.loading = false;
                    });
                }
                if (tab.name === 'financing') {
                    this.loading = true;
                    // go and bring the financing
                    this.getFinancingBudget({
                        project_id: this.project.id, id:this.notification.id,
                    }).then((response) => {
                        this.financing = response.data;
                        this.maxValue = this.formatPrice(this.project.funding_budget);
                        this.loading = false;
                    });
                }
                if (tab.name === 'totals') {
                    // go and bring the totals
                    this.getTotalBudget({
                        project_id: this.project.id, id:this.notification.id,
                    }).then((response) => {
                        this.totalEligible = response.data.totalEligible;
                        this.totalIrredeemable = response.data.totalIrredeemable;
                        this.totalCoFunding = response.data.totalCoFunding;
                        this.totalNature = response.data.totalNature;
                        this.totalCash = response.data.totalCash;
                        this.loading = false;
                    });
                }
                if (tab.name === 'total_partners') {
                    // go and bring the totals for partners
                    this.getTotalPartner({
                        project_id: this.project.id, id:this.notification.id,
                    }).then((response) => {
                        this.partnerFinancing = response.data;
                        this.loading = false;
                    });
                }
                if (tab.name === 'synthetic') {
                    // go and bring the synthetic
                    this.getSynthetic({
                        project_id: this.project.id, id:this.notification.id,
                    }).then((response) => {
                        this.synthetic = response.data;
                        this.loading = false;
                    });
                }
                if (tab.name === 'realocate') {
                    // totals for activities
                    this.getConsolidationActivities({
                        project_id: this.project.id, id:this.notification.id,
                    }).then((response) => {
                        // console.log(response);
                        this.consolidationActivities = response.data;
                        this.loading = false;
                    });
                }
            },
            handleClickConsolidation(tab, event) {
                this.showMessage = false;
                if (tab.name === 'tabConsTotal1') {
                    // direct totals
                    this.getConsolidationDirectTotals({
                        project_id: this.project.id, id:this.notification.id,
                    }).then((response) => {
                        this.consolidationDirectTotals = response.data;
                        this.loading = false;
                    });
                }
                if (tab.name === 'tabConsTotal2') {
                    // indirect totals
                    this.getConsolidationIndirectTotals({
                        project_id: this.project.id, id:this.notification.id,
                    }).then((response) => {
                        this.consolidationIndirectTotal = response.data;
                        this.loading = false;
                    });
                }
                if (tab.name === 'tabConsTotal3') {
                    // eligible totals
                    this.getConsolidationEligibleTotals({
                        project_id: this.project.id, id:this.notification.id,
                    }).then((response) => {
                        this.consolidationEligibleTotals = response.data;
                        this.loading = false;
                    });
                }
            },

            UPDB()
            {
                this.loading = true;
                this.form.answer_limit = moment(this.form.answer_limit).utc(true).endOf('day').format('YYYY-MM-DD HH:mm:ss');
                this.notification.answer_limit = this.form.answer_limit;
                this.saveNotificationAnswerLimit({
                    id: this.notification.id,
                    date: this.form.answer_limit
                }).then(_ => {
                        this.loading = false;
                        this.$notify({
                        type: 'success',
                        title: 'Data limita raspuns',
                        message: 'Data limita pentru a primi un raspuns a fost salvata cu succes!'
                    });
                });
            },
            UPDB2()
            {
                this.loading = true;
                this.saveNotificationEffectiveDate({
                    id: this.notification.id,
                    date: this.form.change_date,
                }).then(_ => {
                        this.loading = false;
                        this.notification.change_date = this.form.change_date;
                        this.$notify({
                        type: 'success',
                        title: 'Modificare data',
                        message: 'Data intrarii in vigoare a modificarilor contractuale a fost modificata cu succes!'
                    });
                });
            },
            innitEditComment(row){
                this.dialogEditComment = true;
                this.form.new_comment = row.comment;
                this.form.id_comment = row.id;
                this.updateComment = row;
            },
            editCommentAction(){
                this.loading = true;
                this.dialogEditComment = false;
                this.editComment({
                    id: this.form.id_comment,
                    comment: this.form.new_comment,
                }).then(_ => {
                        this.updateComment.comment = this.form.new_comment;
                        this.loading = false;
                        this.$notify({
                        type: 'success',
                        title: 'Editare comentariu',
                        message: 'Comentariul a fost editat cu succes!'
                    });
                });
            },
            addComment()
            {
                this.isOFFButton = true;
                this.loading = true;
                axios.post('/notifications/saveComment',
                {
                    comentariu: this.form.comentariu,
                    id:this.notification.id
                }).then((result) => {
                    this.form.comentariu = "";
                    this.isOFFButton = false;
                    this.loading = false;
                    this.notification.comments.push(result.data.comm);
                    this.$notify({ type: 'success', title: 'Ai adaugat un comentariu', message: 'Comentariul a fost adaugat cu succes!'});
                });
            },
            saveValidation() {
                if(this.notification.type === 1 && this.notification.change_date === null) {
                    return this.$notify({ type: 'error', title: 'Selectare data', message: 'Data intrarii in vigoare a modificarilor contractuale nu a fost selectata!'});
                }

                this.$confirm('Esti sigur(a) ca vrei sa validezi aceasta modificare contractuala?')
                    .then(_ => {
                        this.loading = true;
                        axios.post('/notifications/save',
                            {
                                notification_id:this.notification.id
                            }).then((result) => {
                                this.loading = false;
                            if (result.data.valid) {
                                this.$notify({ type: 'success', title: 'Aprobare notificare', message: 'Modificarea a fost aprobata cu succes!'});
                                setTimeout(() => {
                                    window.location.href = '/notifications';
                                }, 500);
                            } else {
                                this.$notify({ type: 'error', title: 'Aprobare notificare', message: 'A intervenit o eroare - modificarile nu au fost aplicate proiectului!'});
                            }
                            });
                    }).catch(err => {
                    console.log(err)
                });
            },
            rejectNotification() {
                this.$confirm('Esti sigur(a) ca vrei sa respingi aceasta modificare contractuala?')
                    .then(_ => {
                        this.dialogFormVisible = false;
                        this.loading = true;
                        axios.post('/notifications/reject',
                        {
                            notification_id: this.notification.id,
                            reject_reason: this.form.reject_reason,
                        }).then((result) => {
                            this.loading = false;
                            window.location.href = '/notifications'
                        });
                }).catch(err => {
                    this.loading = true;
                    console.log(err)
                });
            },
            sendNotification() {
                this.$confirm('Esti sigur(a) ca vrei sa trimiti spre revizuire aceasta modificare contractuala?')
                    .then(_ => {
                        this.loading = true;
                        axios.post('/notifications/review',
                        {
                            notification_id: this.notification.id
                        }).then((result) => {
                            this.loading = false;
                            window.location.href = '/notifications'
                        });
                }).catch(err => {
                    console.log(err)
                });
            },
            consolidationSummary(param) {
                if (param.data !== null && param.data.length > 0) {
                    const { columns, data } = param;
                    const sums = [];
                    columns.forEach((column, index) => {
                        if (index === 0) {
                            sums[index] = 'Totaluri';
                            return;
                        }
                        const values = data.map(item => Number(item[column.property]));
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        if (index > 0 && index < 10) {
                            sums[index] = this.formatNumberNew(sums[index]);
                        }
                    });
                    let percent = (parseFloat(sums[4])/parseFloat(sums[1])) * 100;
                    sums[10] =  numeral(Math.abs(percent)).format('0.00') + ' %';
                    return sums;
                } else {
                    let sums = 0;
                    return sums;
                }
            },
        }
    }
</script>
<style scoped>
</style>
