<template>
    <div v-if="project">
        <h3 class="mb-4 mt-3">Calendar activitati</h3>
        <div class="row">
            <div class="col-sm-12">
                <pane-actions-calendar :project="notification"></pane-actions-calendar>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <el-table :data="notification.calendars" id="calendarList" stripe fit highlight-current-row style="width:100%">
                    <el-table-column label="ID" width="100px" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.index }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Denumire" prop="name"></el-table-column>
                    <el-table-column label="Data inceput" prop="start_date" width="150px">
                        <template slot-scope="scope">
                            {{ formatUserDate(scope.row.start_date) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Data sfarsit" prop="end_date" width="150px">
                        <template slot-scope="scope">
                            {{ formatUserDate(scope.row.end_date) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Perioada" prop="end_date" width="200px">
                        <template slot-scope="scope">
                            {{ datesDifference(scope.row.start_date,scope.row.end_date) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Actiuni" align="center" width="130px">
                        <template slot-scope="scope">
                            <el-button size="mini" type="secondary" icon="el-icon-edit"
                                       @click="handleRowSelect(scope.row)"></el-button>
                            <el-button size="mini" type="danger" icon="el-icon-delete"
                                       @click="handleRowDelete(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-form :model="form" id="calendarForm" :rules="rules" ref="calendar" label-position="top" :status-icon="true" :inline-message="true">
            <div class="row">
                <div class="col-sm-12">
                    <h4 class="mb-4 mt-5">Adauga/modifica activitate</h4>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Denumire" prop="activity">
                            <!--                            <el-input v-model="form.name" placeholder="Denumire activitate" clearable type="text" maxlength="200" show-word-limit></el-input>-->
                            <el-select v-model="form.activity" filterable placeholder="Select" style="width:100%" value-key="id">
                                <el-option v-for="item in project.activities"
                                           :key="item.id" :label="item.name"  :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <!--                <div class="col-sm-6 form-group">-->
                <!--                    <el-form-item :label="'Perioada de desfasurare: ' + datesDifference(this.form.period[0], this.form.period[1])" prop="period">-->
                <!--                        <el-date-picker format="dd.MM.yyyy"-->
                <!--                                        v-model="form.period"-->
                <!--                                        type="daterange" unlink-panels-->
                <!--                                        range-separator="-"  :picker-options="periodPickerOptions"-->
                <!--                                        start-placeholder="De la"-->
                <!--                                        end-placeholder="pana la">-->
                <!--                        </el-date-picker>-->
                <!--                    </el-form-item>-->
                <!--                </div>-->
                <div class="col-sm-3">
                    <div class="form-group">
                        <el-form-item label="Perioada de implementare:" prop="project_start_date">
                            <el-date-picker v-model="form.start_date" placeholder="De la" type="date" value-format="yyyy-MM-dd"
                                            format="dd.MM.yyyy" style="width:100%" :picker-options="periodOptions"></el-date-picker> <!-- @change="updateStartPeriod"-->
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <el-form-item :label="' ' + datesDifference(this.form.start_date, this.form.end_date)"  prop="project_end_date">
                            <el-date-picker v-model="form.end_date" placeholder="Pana la" type="date" value-format="yyyy-MM-dd"
                                            format="dd.MM.yyyy" style="width:100%"  :picker-options="periodOptions"></el-date-picker>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-12 text-right mb-5">
                    <el-button @click.prevent="clear">Goleste formularul</el-button>
                    <el-button @click.prevent="addRecord" type="secondary">Aplica modificarile</el-button>
                </div>
            </div>
            <div class="input-group">
                <textarea placeholder="Adauga un comentariu" v-on:change="saveComment" v-model="form.comment_sec4" class="form-control custom-control" rows="3" style="resize:none"></textarea>     
                <el-button class="input-group-addon btn btn-primary" icon="el-icon-edit" @click="uploadFilesComment(notification)"></br>Vezi/modifică</br>fișierele</br>comentariului</el-button>
            </div>
            <hr />
            <div class="form-group">
                <h5>Lista comentarii:</h5>
                <span style="white-space: pre-line;">{{ form.comment_sec4 }}</span>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations } from 'vuex'
    import VueScrollTo from 'vue-scrollto';
    import mixin from './../../mixins/common';
    import moment from 'moment';
    import PaneActionsCalendar from "./pane-actions-calendar";
    export default {
        name: "notification-calendar-form",
        mixins:[mixin],
        components:{
            PaneActionsCalendar
        },
        data(){
            return {
                form:{},
                rules: {
                    activity: [
                        {required: true, message: 'Denumirea este obligatorie', trigger: 'blur'},
                    ],
                    start_date: [
                        {required: true, message: 'Data de inceput este obligatorie', trigger: 'change'},
                    ],
                    end_date: [
                        {required: true, message: 'Data de final este obligatorie', trigger: 'change'},
                    ],
                },
                minDate: new Date(),
                maxDate: new Date(),
                periodOptions: {
                    disabledDate: this.disabledDate
                },
            }
        },
        computed:{
            ...mapGetters({
                project: 'project/project',
                validateCalendar: 'project/validateCalendar',
                notification: 'notification/notification',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validateCalendar: function(){
                if(this.validateCalendar) {
                    this.submitForm();
                }
            },
        },
        mounted(){
            this.clear();
        },
        methods:{
            ...mapActions({
                saveNotificationComment: 'notification/saveNotificationComment',
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
                setValidateCalendar: 'project/setValidateCalendar',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            uploadFilesComment(form)
            {
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Notification',
                    model_id: form.id,
                    category_id: 5,
                    acceptFiles: '.pdf,.docx,.doc,.xlsx,.xls',
                    multiple: true,
                    title: 'Incarcă documente',
                    files: form.commentfiles.filter(p => p.category_id == 5),
                });
            },
            saveComment()
            {
                this.saveNotificationComment({
                    id: this.notification.id,
                    project_id: this.project.id,
                    section_id: 4,
                    comment: this.form.comment_sec4,
                });
            },
            disabledDate(date) {
                if(date >= this.minDate && date <= this.maxDate){
                    return false;
                }else{
                    return true;
                }
            },
            updateStartPeriod: function(){
                this.minDate = moment.utc(this.form.start_date)
            },
            addRecord(){
                this.$refs['calendar'].validate((valid) => {
                    if (valid) {
                        let exit = false;
                        let exit_message = null;
                        // verifica daca perioada adaugata se suprapune cu perioada aceleisi activitati
                        this.notification.calendars.forEach((row, index) => {
                            if(row.activity.id === this.form.activity.id && row.index !== this.form.index){
                                if(this.checkDateInInterval(row.start_date, row.end_date, this.form.start_date, this.form.end_date)){
                                    exit_message = moment.utc(this.form.start_date).format('DD.MM.YYYY')+' - '+moment.utc(this.form.end_date).format('DD.MM.YYYY');
                                    exit = true;
                                }
                            }
                        });
                        if(exit) {
                            this.$notify({ type: 'error', title: 'Calendar', message: 'Perioada ' + exit_message + ' se suprapune cu una din perioadele existente! Corectati si incercati din nou.'});
                            return false;
                        }

                        if(this.form.index > this.notification.calendars.length) {
                            this.notification.calendars.push(this.form);
                        }else{
                            this.notification.calendars[this.form.index - 1] = this.form
                        }
                        this.saveCalendar();
                        this.clear();
                        this.setValidateCalendar(false);
                        VueScrollTo.scrollTo('#calendarList');
                        return true;
                    } else {
                        return false;
                    }
                });
            },
            saveCalendar()
            {
                axios.post('/notifications/notificationCalendar', {project_id: this.project.id, id:this.notification.id, calendars: this.notification.calendars}).then((result) => { this.$notify({ type: 'success', title: 'Calendar', message: 'Inregistrarea a fost adaugata cu succes!'}); });
            },
            handleRowSelect(row){
                this.form = row;
                VueScrollTo.scrollTo('#calendarForm');
            },
            clear(){
                this.form = {index: this.notification.calendars.length + 1};
                this.minDate = moment.utc(this.project.project_start_date);
                this.maxDate = moment.utc(this.project.project_end_date);
            },
            handleRowDelete(index, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi aceasta inregistrare? Acest proces este ireversibil!')
                    .then(_ => {
                        if (index !== -1) {
                            if(row.id > 0) {
                                axios.post('/notifications/notificationRemoveCalendar', {project_id: this.project.id, id: this.notification.id, name:row.activity.name, start_date: row.start_date, end_date: row.end_date,});
                                this.notification.calendars.splice(index, 1);
                            }else {
                                this.notification.calendars.splice(index, 1);
                            }
                            this.$notify({ type: 'success', title: 'Calendar', message: 'Inregistrarea a fost stearsa cu succes!'});
                            this.clear();
                        }
                    }).catch(err => {
                    console.log(err)
                });
            },
            submitForm() {
                if (this.notification.calendars.length > 0) {
                    this.setValidateCalendar(false);
                    this.setValidationErrorsExists(false);
                    return true;
                } else {
                    this.setValidationErrorsExists(true);
                    this.$notify({ type: 'warning', title: 'Calendar', message: 'Trebuie sa adaugati cel putin un eveniment in calendar!'});
                    return false;
                }
            },
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
