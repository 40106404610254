<template>
    <div>
        <el-drawer size="50%" v-if="typeof this.showUploadDrawer.updateCommentFiles === 'undefined'"
                   title="Validează raportare" :visible.sync="showUploadDrawer.visible" direction="rtl"
                   :wrapperClosable="false" :before-close="handleCloseDrawer" :destroy-on-close="true">
            <div class="px-4">
<!--                <div v-if="errors.length">-->
<!--                    <strong>Corectati urmatoarele erori:</strong>-->
<!--                    <ul>-->
<!--                        <li v-for="error in errors">{{ error }}</li>-->
<!--                    </ul>-->
<!--                </div>-->
                <el-form :model="form" v-if="formChanged" :rules="rules" ref="uploadValidationForm" :id="'uploadValidationForm'+activity.id"
                         label-position="top" :status-icon="true" :inline-message="false">
<!--                    <div class="form-group">-->
<!--                        <label>Selecteaza starea raportarii</label><br />-->
<!--                        <el-select :disabled="disabled == 1" @change="updateInput" v-model="form.status" clearable placeholder="Selecteaza" value-key="id">-->
<!--                            <el-option v-for="item in stateOf"-->
<!--                                       :key="item.id" :label="item.name"  :value="item">-->
<!--                            </el-option>-->
<!--                        </el-select>-->
                    <el-form-item label="Selecteaza starea raportarii" prop="status">
                        <el-radio-group v-model="form.status" size="small">
                            <el-radio-button :label="1">Valid</el-radio-button>
                            <el-radio-button :label="2">Invalid</el-radio-button>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="Cheltuieli finantare" prop="fa">
                        <el-input v-model="form.amount_financing" placeholder="0" type="float" size="small" show-word-limit
                                  style="text-align:right" @focus="$event.target.select()">
                            <template slot="append">{{form.currency}}</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="Cheltuieli cofinantare" prop="ca">
                        <el-input v-model="form.amount_cofinancing" placeholder="0" type="float" size="small" show-word-limit
                                  style="text-align:right" @focus="$event.target.select()">
                            <template slot="append">{{form.currency}}</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="Cheltuieli dezvoltare" prop="deza">
                        <el-input v-model="form.dezv" placeholder="0" type="float" size="small" show-word-limit
                                  style="text-align:right" @focus="$event.target.select()">
                            <template slot="append">{{form.currency}}</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="Nr ore efectiv lucrate acceptate" v-if="this.form.budget_id === 107 || this.form.budget_id === 108" prop="worked_hours">
                        <el-input :disabled="disabled === 1"  v-model="form.worked_hours"  size="small"
                                type="number" placeholder="0" ></el-input>
                    </el-form-item>

                    <el-form-item label="Motivarea neeligibilitatii costului" prop="motivate_cost">
                        <el-input v-model="form.motivate_cost"  placeholder="Adaugati motivarea neeligibilitatii costului" type="textarea"></el-input>
                    </el-form-item>

                    <hr />

                    <el-form-item label="Adauga un comentariu" prop="comment" v-show="object.validation && object.validation.id > 0">
                        <el-input v-model="form.comment"  placeholder="Adauga comentariul tau referitor la validare" type="textarea"></el-input>
                    </el-form-item>

<!--                    <font v-if="object.validation === null" style="color:red;">Vei putea adauga comentarii dupa ce validezi/invalidezi linia de cheltuiala!<br/></font>-->
                    <!-- :disabled="object.validation !== null && form.comment && !isOFFButton ? false : true" -->
                    <!-- :disabled="object.validation === null ? true : false" -->
                    <el-button size="mini" type="secondary" icon="el-icon-plus" v-show="object.validation && object.validation.id > 0"
                       :disabled="form.comment ? false : true" @click="addComment()" >Adauga comentariu</el-button>

                    <!-- Comentarii -->
                    <div class="form-group mt-3">
                        <h5>Comentarii</h5>
                        <el-timeline reverse class="pl-0">
                            <el-timeline-item v-for="(row, index) in comments" :key="index"
                                :timestamp="formatUserDate(row.created_at)"> <!-- v-if="!(row.user_id !== user_data && row.reply_to_id === -1)"-->
                                <div class="row">
                                    <div class="col-8">
                                        <i class="icofont-girl" style="font-size:100%"></i>
                                        <small v-if="row.user && typeof row.user.promoter[0] !== 'undefined'" class=" font-weight-bold">Promotor</small>
                                        <small v-else class=" font-weight-bold">Operator de Fond</small>
                                        <el-button v-if="row.user_id === user_data && row.reply_to_id === -1" class="float-right ml-2"round size="mini" type="danger" icon="el-icon-delete"
                                                   @click="removeComment(index, row)" style="margin-top:-10px"></el-button>
                                        <el-button v-if="row.user_id === user_data && row.reply_to_id === -1" class="float-right ml-2" round size="mini" type="primary" icon="el-icon-edit"
                                                   @click="innitEditComment(row)" style="margin-top:-10px"></el-button>
                                        <br>
                                        {{ row.comment }}
                                    </div>
                                    <div class="col-4 text-right" v-if="row.files !== undefined && row.files.length && row.reply_to_id !== -1">
                                        <small v-if="row.files && row.files.length > 0" class="font-weight-bold">Fisiere incarcate</small><br />
                                        <template v-for="doc in row.files">
                                            <small><a :href="'/getFile/' + doc.id" target="_blank">{{ doc.file_name }}</a></small><br />
                                        </template>
                                    </div>
                                </div>
                            </el-timeline-item>
                        </el-timeline>
                    </div>

                    <!--  Acte justificative -->
                    <div class="form-group" v-if="typeof form.documentsRisk !== 'undefined' && form.documentsRisk.length > 0">
                        <h5>Acte justificative:</h5>
                        <ul class="list-unstyled">
                            <li v-for="document in form.documentsRisk">
                                <el-link v-if="document.file" @click="loadDoc(document)" class="mr-4">
                                    <small>{{ document.file_name }}</small>
                                </el-link>
                            </li>
                        </ul>
                    </div>

                    <h5>{{ showUploadDrawer.title ? showUploadDrawer.title : title }}</h5>
                    <font v-if="object.validation === null" style="color:red;">Vei putea adauga documente dupa ce validezi/invalidezi linia de cheltuiala!<br/></font>
                    <el-upload ref="upload" action="/api/upload" :data="showUploadDrawer"
                               :with-credentials="true" :auto-upload="false"
                               :headers="{ 'X-CSRF-TOKEN': csrf}"
                               :multiple="!showUploadDrawer.multiple ? false : true"
                               :accept="showUploadDrawer.acceptFiles ? showUploadDrawer.acceptFiles : acceptFiles"
                               :on-remove="handleRemove" :before-remove="beforeRemove" :on-preview="handlePreview"
                               :on-error="handleUploadError" :on-success="handleUploadSuccess" :on-exceed="handleOnExceed"
                               :limit="showUploadDrawer.multiple === false ? 1 : 10"
                               :file-list="showUploadDrawer.files">
                        <el-button :disabled="object.validation === null ? true : false" slot="trigger" size="small" type="secondary">{{ btnSelectText }}</el-button>
                        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">{{ btnImportText }}</el-button>
                        <div class="el-upload__tip" slot="tip">{{ infoTip }}</div>
                    </el-upload>

                    <div class="form-group mt-5">
                        <el-button type="primary" @click="storeData">Salveaza validare</el-button>
                    </div>
                </el-form>
            </div>
        </el-drawer>

        <el-drawer v-else title="Încarcă fișiere" :visible.sync="showUploadDrawer.visible" direction="rtl" :wrapperClosable="false" :before-close="handleCloseDrawer" :destroy-on-close="true">
            <div class="px-4">
                <p class="alert alert-info">{{ infoMessage }}</p>
                <h5>{{ showUploadDrawer.title ? showUploadDrawer.title : title }}</h5>
                <el-upload ref="upload" action="/api/upload" :data="showUploadDrawer"
                           :with-credentials="true" :auto-upload="true"
                           :headers="{ 'X-CSRF-TOKEN': csrf}"
                           :multiple="!showUploadDrawer.multiple ? false : true"
                           :accept="showUploadDrawer.acceptFiles ? showUploadDrawer.acceptFiles : acceptFiles"
                           :on-remove="handleRemove" :before-remove="beforeRemove" :on-preview="handlePreview"
                           :on-error="handleUploadError" :on-success="handleUploadSuccess" :on-exceed="handleOnExceed"
                           :limit="showUploadDrawer.multiple === false ? 1 : 10"
                           :file-list="showUploadDrawer.files">
                    <el-button slot="trigger" size="small" type="secondary">{{ btnSelectText }}</el-button>
<!--                    <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">{{ btnImportText }}</el-button>-->
                    <div class="el-upload__tip" slot="tip">{{ infoTip }}</div>
                </el-upload>
            </div>
        </el-drawer>

        <!-- Editare comentariu -->
        <el-dialog title="Editare comentariu" :visible.sync="dialogEditComment">
          <div class="form-group">
                <label>Scrie mai jos noul comentariu:</label>
                <el-input v-model="new_comment" placeholder="Scrie aici..." type="textarea"></el-input>
            </div>
            <el-button size="mini" type="primary"
               @click="editCommentAction()" :disabled="new_comment ? false : true"><i class="icofont-edit"></i> Editeaza comentariul</el-button>
        </el-dialog>
    </div>
</template>

<script>
    import mixin from './../../mixins/common';
    import {mapGetters,mapMutations,mapActions} from "vuex";
    import {showUploadDrawer} from "../../store/getters";
    import VueScrollTo from "vue-scrollto";
    export default {
        name: "upload-reporting-drawer",
        mixins:[mixin],
        props:{
            user_data: {
                required: false,
            }
        },
        data() {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                title: 'Import fișier',
                infoMessage: 'Încărcați numai fișiere de tip .pdf, .docx, .doc, cu dimensiunea maximă de 2MB',
                infoTip: 'Sunt permise doar fișiere pdf cu dimensiunea mai mică de 2MB',
                btnImportText: 'Importă',
                btnSelectText: 'Selectează fișier(e)',
                acceptFiles: '.pdf,.docx,.doc',
                model: '',
                new_comment: '',
                model_id: 0,
                fileList: [],
                comments: [],
                object: [],
                showComments: true,
                showDrawer: false,
                isOFFButton: false,
                form: {
                    new_comment: null,
                },
                dialogEditComment: false,
                updateComment: {},
                activity: {},
                // stateOf: ['Valid', 'Invalid'],
                disabled: 0,
                formChanged: false,
                errors: [],
                rules: {
                    status: [
                        { type: 'number', required: true, message: 'Selecteaza starea', trigger: 'blur, change' }
                    ],
                    // date: [
                    //     { type: 'string', required: true, message: 'Selecteaza data', trigger: 'blur, change' }
                    // ],
                },
            }
        },
        // updated() {
            // this.form.currency = this.showUploadDrawer.object.currency;
            // this.placeData(this.showUploadDrawer.object);
        // },
        computed:{
            ...mapGetters({
                showUploadDrawer: 'showUploadDrawer',
                validationErrors: 'validationErrors',
            }),
        },
        watch:{
            showUploadDrawer: function() {
                this.clear();
                if (this.showUploadDrawer.object !== undefined && typeof this.showUploadDrawer.updateCommentFiles === 'undefined') {
                    this.object = this.showUploadDrawer.object;
                    this.placeData(this.object);
                }
            }
        },
        methods:{
            ...mapActions({
                removeUploadedFile: 'removeUploadedFile',
                saveValidationActivityLine: 'reportings/saveValidationActivityLine',
                saveCommentActivity: 'reportings/saveCommentActivity',
                editComment: 'reportings/editComment',
                deleteComment: 'reportings/deleteComment',
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
            }),
            removeComment(line, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi acest comentariu?')
                    .then(_ => {
                        this.loading = true;
                        this.comments.splice(line, 1);
                        this.deleteComment({
                            id: row.id,
                        }).then(_ => {
                                this.loading = false;
                                this.$notify({
                                type: 'success',
                                title: 'Stergere comentariu',
                                message: 'Comentariul a fost sters cu succes din lista!'
                            });
                        });
                    }).catch(err => {
                    console.log(err)
                });
            },
            innitEditComment(row){
                this.dialogEditComment = true;
                this.new_comment = row.comment;
                this.form.id_comment = row.id;
                this.updateComment = row;
            },
            editCommentAction(){
                this.loading = true;
                this.dialogEditComment = false;
                this.editComment({
                    id: this.form.id_comment,
                    comment: this.new_comment,
                }).then(_ => {
                        this.updateComment.comment = this.new_comment;
                        this.loading = false;
                        this.$notify({
                        type: 'success',
                        title: 'Editare comentariu',
                        message: 'Comentariul a fost editat cu succes!'
                    });
                });
            },
            addComment() {
                this.isOFFButton = true;
                this.loading = true;
                if (this.object.category_id === 106) {
                    this.saveCommentActivity({
                        comment: this.form.comment,
                        id: this.object.validation.id,
                        indirect: 1,
                    }).then((result) => {
                        this.form.comment = "";
                        this.isOFFButton = false;
                        this.loading = false;
                        this.comments.push(result.comm);
                        this.$notify({ type: 'success', title: 'Ai adaugat un comentariu', message: 'Comentariul a fost adaugat cu succes!'});
                    });
                }
                else {
                    this.saveCommentActivity({
                        comment: this.form.comment,
                        id: this.object.validation.id,
                        indirect: 0,
                    }).then((result) => {
                        this.form.comment = "";
                        this.isOFFButton = false;
                        this.loading = false;
                        this.comments.push(result.comm);
                        this.$notify({ type: 'success', title: 'Ai adaugat un comentariu', message: 'Comentariul a fost adaugat cu succes!'});
                    });
                }
            },
            beforeRemove(file, fileList) {
                if(file.can_delete) {
                    //this.handleCloseDrawer();
                    this.$notify({ type: 'warning', title: 'Atentie', message: "Nu ai permisiunea sa stergi acest fisier!"});
                    return false;
                }
                return this.$confirm(`Ești sigur(ă) că vrei să ștergi fișierul ${ file.name } din listă ?`);
            },
            handleRemove(file, fileList) {
                if(file.can_delete) {
                    // this.handleCloseDrawer();
                    return false;
                }
                // console.log(file);
                // if(typeof this.showUploadDrawer.updateCommentFiles !== 'undefined') this.showUploadDrawer.comments.files.splice(file, 1);
                // if(typeof this.showUploadDrawer.validation !== 'undefined') this.showUploadDrawer.object.validation.documents.splice(file, 1);
                if(file.response) {
                    this.removeUploadedFile(file.response.file_id);
                }else if(file.id){
                    this.removeUploadedFile(file.id);
                }
            },
            handlePreview(file, fileList) {
                window.open('/getFile/'+file.id, '_blank');
            },
            handleUploadError(file){
                // console.log(file);
            },
            handleOnExceed(){
                this.$notify({ type: 'warning', title: 'Import', message: 'Este permisa incarcarea unui singur fisier!'});
            },
            handleUploadSuccess(response, file, fileList){
                if(response.errors){
                    let messages = Object.values(response.errors).flat();
                    this.$notify({ type: 'warning', title: 'Import', message: messages.toString()});
                }else if(response.success === true){
                    if(typeof this.showUploadDrawer.updateCommentFiles !== 'undefined') this.showUploadDrawer.comments.files.push(file);
                    if(typeof this.showUploadDrawer.validation !== 'undefined') this.showUploadDrawer.object.validation.documents.push(file);
                    this.$notify({ type: 'success', title: 'Import', message: "Fisierul a fost importat cu success!"});
                    // this.$refs.upload.clearFiles();
                }
            },
            handleCloseDrawer(done){
                this.setShowUploadDrawer({
                    visible: false
                });
            },
            submitUpload(){
                this.$refs.upload.submit();
            },
            // added extra functions and functionality
            updateInput(data) {
                if (data === 'Invalid') {
                    this.disabled = 1;
                } else {
                    this.disabled = 0;
                }
            },
            addActivity(row) {
                if (row.header_validation !== null) {
                    this.form.id = row.header_validation.id;
                }
                if (row.category_id === 106) {
                    this.form.indirect = 1;
                }
                this.form.project_id = row.project_id,
                this.form.reporting_id = row.reporting_id,
                this.form.activity_id = row.activity_id,
                this.form.reporting_activity_id = row.id,
                this.saveValidationActivityLine(this.form).then(response => {
                    if(response.valid === false) {
                        this.$notify({type: 'error', title: 'Adaugare validare', message: response.message});
                    }else if(response.valid === true){
                        this.$notify({ type:'success', title: 'Adaugare validare', message: 'Validarea a fost salvata cu success!' });
                        this.clear();
                        this.handleCloseDrawer();
                        row.validation = response.lineUpdate;
                        row.validation.validationState = response.validation;
                    }
                }, error => {
                    if(error.response.status === 422){
                        this.$notify({ type:'error', title: 'Adaugare validare', message: error.response.data.errors });
                    }
                });
            },
            storeData(){
                this.$refs['uploadValidationForm'].validate((valid) => {
                    if (valid) {
                        this.addActivity(this.object);
                    } else {
                        this.$notify({ type:'error', title: 'Adaugare validare', message: 'Exista erori la completarea formularului!' });
                        VueScrollTo.scrollTo('#uploadValidationForm'+this.activity.id);
                        return false;
                    }
                });
            },
            // checkForm: function (e) {
            //     if (e.status ) { //&& e.comment
            //         return true;
            //     }
            //
            //     this.errors = [];
            //
            //     if (!e.status) {
            //         this.errors.push('Setati un status!');
            //     }
            //     // if (!e.comment) {
            //     //     this.errors.push('Adaugati un comentariu!');
            //     // }
            // },
            resetErrors() {
                this.errors = [];
            },
            placeData: function(e) {
                if (e !== null) {
                   if (e.validation !== null) {
                       this.form.status = e.validation.status;
                       this.form.worked_hours = e.validation.worked_hours;
                       this.form.amount_cofinancing = e.validation.amount_cofinancing;
                       this.form.amount_financing = e.validation.amount_financing;
                       this.form.dezv = e.validation.dezv;
                       this.comments = e.validation.comments;
                       this.form.currency = e.currency;
                       this.form.documents = e.validation.documents;
                       this.form.documentsRisk = e.documents;
                       this.form.motivate_cost = e.validation.motivate_cost;
                       this.form.budget_id = e.budget_id;
                   } else {
                       this.form.amount_financing = e.amount_financing;
                       this.form.amount_cofinancing = e.amount_cofinancing;
                       this.form.dezv = e.dezv;
                       this.form.documents = e.documents;
                       this.form.budget_id = e.budget_id;
                   }
                }
                this.formChanged = true;
                this.disabled = 0;
                // console.log(this.form.amount_financing);
            },
            loadDoc(file) {
                window.open('/getFile/'+file.id, '_blank');
            },
            clear(){
                this.object = [];
                this.comments = [];
                this.form = {
                    id: 0,
                    amount_financing: 0,
                    amount_cofinancing: 0,
                    dezv: 0,
                    currency: 'RON',
                    comment: '',
                    status: 1,
                    worked_hours: 0,
                    documents: '',
                    motivate_cost: '',
                    indirect: 0
                };
            }
        }
    }
</script>

<style scoped>

</style>
