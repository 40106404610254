
export const constructionWorks = (state) => { return state.constructionWorks };
export const indirectCosts = (state) => { return state.indirectCosts };
export const organizationalDevelopment = (state) => { return state.organizationalDevelopment };
export const budgetFinancing = (state) => { return state.budgetFinancing };
export const grantFraming = (state) => { return state.grantFraming };
export const budgetCoFinancing = (state) => { return state.budgetCoFinancing };
export const budgetNature = (state) => { return state.budgetNature };
export const framingTimeGrant = (state) => { return state.framingTimeGrant };
export const appealNumber = (state) => { return state.appealNumber };
export const budgetMaxGrantPromoter = (state) => { return state.budgetMaxGrantPromoter };
export const budgetMaxGrantPartner = (state) => { return state.budgetMaxGrantPartner };
