<template>
    <div v-if="project" id="employees">
        <h3 class="mb-4 mt-3">
            Echipa
        </h3>
        <div class="row">
            <div class="col-sm-12">
                <el-table :data="project.employees" stripe fit highlight-current-row style="width:100%">
                    <el-table-column label="Aplicant/Partener" width="150">
                        <template slot-scope="scope">
                            <template v-if="scope.row.partner">
                                {{ scope.row.partner.name }}
                            </template>
                            <template v-else>
                                Aplicant
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column label="CNP" prop="cnp" width="140"></el-table-column>
                    <el-table-column label="Pozitie" prop="position" width="300"></el-table-column>
                    <el-table-column label="Nume">
                        <template slot-scope="scope">
                            {{ scope.row.first_name }} {{ scope.row.last_name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Nr. contr." prop="cim" width="80"></el-table-column>
                    <el-table-column label="Data contract" prop="cim_date" width="120">
                        <template slot-scope="scope" v-if="scope.row.cim_date">
                            {{ formatUserDate(scope.row.cim_date) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Data angajare" prop="start_date" width="120">
                        <template slot-scope="scope" v-if="scope.row.start_date">
                            {{ formatUserDate(scope.row.start_date) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Data finalizare" prop="end_date" width="120" align="right">
                        <template slot-scope="scope" v-if="scope.row.end_date">
                            {{ formatUserDate(scope.row.end_date) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Numar ore" prop="hours" width="100" align="right"></el-table-column>
                    <el-table-column label="Cost ora proiect" prop="project" width="100" align="right"></el-table-column>
                    <el-table-column label="Actiuni" width="130" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="secondary" icon="el-icon-edit"
                                       @click="handleSelectRow(scope.row)"></el-button>
                            <el-button size="mini" type="danger" icon="el-icon-delete"
                                       @click="handleDeleteRow(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-form :model="form" id="employeeForm" :rules="rules" ref="employeeForm" label-position="top" :status-icon="true" :inline-message="true">
            <div class="row">
                <div class="col-sm-12">
                    <h4 class="mb-4 mt-5">Adauga/modifica angajat</h4>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Pozitia din proiect" prop="position">
                            <el-input v-model="form.position" placeholder="Introduceti functia" clearable type="text" maxlength="120" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Daca pozitia este de ordinul voluntarilor, bifeaza optiunea de mai jos">
                            <el-checkbox v-model="form.is_volunteer" border >Voluntar</el-checkbox>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Partener, daca se aloca pe un partener, altfel se aloca la Aplicant" prop="partner">
                            <el-select v-model="form.partner" clearable placeholder="Selecteaza" style="width:100%" value-key="id">
<!--                                <el-option label="Aplicant" :value="null"></el-option>-->
                                <el-option v-for="item in project.partners"
                                           :key="item.id" :label="item.name"  :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Prenumele">
                        <el-input v-model="form.first_name" placeholder="Introduceti prenumele (ex: Mihai)" type="text" clearable maxlength="30" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Numele">
                        <el-input v-model="form.last_name" placeholder="Introduceti numele de familie (ex: Ionescu)" type="text" maxlength="30" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item>
                        <template slot="label">CNP
                            <el-tooltip effect="light" postion="top">
                                <div slot="content">Daca nu este alocata o persoana inca,<br />pozitiei i se va aloca un numar unic pentru identificare,<br />de tipul 9999xxxxxxxxx</div>
                                <i class="el-icon-info"></i>
                            </el-tooltip>
                        </template>
                        <el-input v-model="form.cnp" placeholder="Introduceti Codul Numeric Personal" type="text" maxlength="13" show-word-limit></el-input>
                    </el-form-item>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-3 form-group">
                    <el-form-item label="Numar CIM">
                        <el-input v-model="form.cim" placeholder="Introduceti numarul CIM" type="text" maxlength="10" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3">
                    <el-form-item label="Data CIM" class="form-group">
                        <el-date-picker v-model="form.cim_date" format="dd.MM.yyyy" value-format="yyyy-MM-dd"
                                        type="date" placeholder="Selecteaza data"></el-date-picker>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Data angajare">
                        <el-date-picker v-model="form.start_date" format="dd.MM.yyyy" value-format="yyyy-MM-dd" type="date" placeholder="Selecteaza data" @change="updateStartPeriod"></el-date-picker>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Data finalizare">
                        <el-date-picker v-model="form.end_date" format="dd.MM.yyyy" value-format="yyyy-MM-dd" type="date" placeholder="Selecteaza data" :picker-options="periodEndOptions"></el-date-picker>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Tip norma" prop="type">
                        <el-select v-model="form.workload" placeholder="Select" style="width:100%" value-key="id">
                            <el-option v-for="item in workloads.values"
                                       :key="item.id" :label="item.value"  :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-3">
                    <el-form-item label="Nr. ore norma" class="form-group">
                        <el-input v-model="form.workload_hours" placeholder="Introdu valoarea" @focus="$event.target.select()" clearable type="number" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3">
                    <el-form-item label="Salariu brut" class="form-group">
                        <el-input v-model="form.salary" placeholder="Valodare bruta salariu lunar" @focus="$event.target.select()" clearable type="number" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3">
                    <el-form-item label="Ore bugetate" class="form-group">
                        <el-input v-model="form.hours" placeholder="Numar ore bugetate" @focus="$event.target.select()" clearable type="number" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </div>

                <div class="col-sm-3">
                    <el-form-item label="Cost orar proiect in euro" class="form-group">
                        <el-input v-model="form.project" placeholder="Introdu valoare in euro" @focus="$event.target.select()" clearable type="number" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </div>
<!--                <div class="col-sm-12">-->
<!--                    <el-form-item label="Implicare in proiect" class="form-group">-->
<!--                        <el-input v-model="form.involvement" placeholder="Descrieti implicarea in activitatiile proiectului" clearable type="textarea" rows="4" show-word-limit></el-input>-->
<!--                    </el-form-item>-->
<!--                </div>-->
                <div class="col-sm-12 text-right mb-5">
                    <el-button @click.prevent="clear">Goleste formularul</el-button>
                    <el-button @click.prevent="addRecord" type="secondary">Aplica modificarile</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from '../../mixins/common';
    import moment from 'moment';
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "project-team-form",
        mixins: [mixin],
        data(){
            return {
                form:{
                },
                rules: {
                    position: [
                        {required: true, message: 'Numele pozitiei din proiect este obligatoriu', trigger: 'blur'},
                        { min: 3, max: 90, message: 'Lungimea trebuie sa fie intre 3 si 90 caractere', trigger: 'blur' }
                    ],
                    cnp: [
                        { min: 13, max: 13, message: 'Lungimea trebuie sa fie de 13 caractere', trigger: 'blur'},
                    ],
                },
                minDate: new Date(),
                periodEndOptions: {
                    disabledDate: this.disabledEndDate
                },
            }
        },
        computed:{
            ...mapGetters({
                workloads: 'project/workloads',
                project: 'project/project',
                validateTeam: 'project/validateTeam',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validateTeam: function(){
                if(this.validateTeam) {
                    this.submitForm();
                }
            },
        },
        mounted(){
            this.getWorkloads();
            this.clear();
        },
        methods:{
            ...mapActions({
                getWorkloads: 'project/getWorkloads',
                saveProjectEmployee: 'project/saveProjectEmployee',
                removeEmployeeLine: 'project/removeEmployeeLine',
            }),
            ...mapMutations({
                setValidateTeam: 'project/setValidateTeam',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            disabledEndDate(date) {
                return date < this.minDate
            },
            updateStartPeriod: function(){
                this.minDate = moment(this.form.start_date)
            },
            addRecord(){
                this.$refs['employeeForm'].validate((valid) => {
                    if (valid) {
                        this.form.project_id = this.project.id;
                        this.form.promoter_id = this.project.promoter_id;
                        if(this.project.employees && this.form.index > this.project.employees.length) {
                            if(!this.form.cnp) this.form.cnp = Math.floor(Math.random() * (999999999 - 1 + 1)) + 9999000000000;
                            this.form.start_date = this.form.start_date ? this.formatMysqlDate(this.form.start_date) : null;
                            this.form.end_date = this.form.end_date ? this.formatMysqlDate(this.form.end_date) : null;
                            this.form.cim_date = this.form.cim_date ? this.formatMysqlDate(this.form.cim_date) : null;
                            this.project.employees.push(this.form);
                        }else{
                            this.project.employees[this.form.index - 1] = this.form
                        }

                        this.saveProjectEmployee(this.form).then((result) => {
                            if(result.valid === false){
                                this.$notify({ type:'error', title: 'Eroare', message: result.message });
                                //reject(false);
                            }
                        });

                        this.clear();
                        this.setValidateTeam(false);
                        VueScrollTo.scrollTo('#employees');
                        return true;
                    } else {
                        VueScrollTo.scrollTo('#employeeForm');
                        return false;
                    }
                });
            },
            handleSelectRow(row){
                this.clear();
                this.form = row;
                this.form.is_volunteer = row.is_volunteer === 1 ? true : false;
                VueScrollTo.scrollTo('#employeeForm');
            },
            clear(){
                this.form =  {
                    index: ( this.project.employees ? this.project.employees.length : 0) + 1,
                    partner: {},
                    is_volunteer: false,
                    hours: 0,
                    workload_hours: 0,
                    project: 0,
                    salary: 0
                };
            },
            handleDeleteRow(index, row){
                this.$confirm('Ești sigur(ă) că vrei să ștergi această înregistrare? Acest proces este ireversibil!')
                    .then(_ => {
                        if (index !== -1) {
                            if(row.id > 0) {
                                this.removeEmployeeLine({
                                    id: row.id,
                                    project_id: row.project_id,
                                    promoter_id: row.promoter_id,
                                });
                                this.project.employees.splice(index, 1);
                            }else {
                                this.project.employees.splice(index, 1);
                            }
                            this.$notify({ type: 'success', title: 'Echipa', message: 'Înregistrarea a fost ștearsă cu succes!'});
                            this.clear();
                        }
                    }).catch(err => {
                        console.log(err)
                    });
            },
            submitForm() {
                if (this.project.employees.length > 0) {
                    this.setValidateTeam(false);
                    this.setValidationErrorsExists(false);
                    return true;
                } else {
                    this.setValidationErrorsExists(true);
                    this.$notify({ type: 'warning', title: 'Echipa', message: 'Trebuie sa adaugati cel putin un membru!'});
                    return false;
                }
            },
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
