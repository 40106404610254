import Vue from "vue";

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');

import Vuex from 'vuex';

Vue.use(Vuex);

import Notifications from 'vue-notification';
Vue.use(Notifications);


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));



/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import '../scss/element.scss';
import ElementUI from "element-ui";
Vue.use(ElementUI);

import store from './store';
import components from './components'


// debug
// import HoneybadgerVue from '@honeybadger-io/vue'
// Vue.use(HoneybadgerVue, {
//     apiKey: process.env.MIX_HONEYBADGER_API_KEY,
//     environment: process.env.MIX_HONEYBADGER_ENVIRONMENT,
//     revision: 'master'
// });


const app = new Vue({
    el: '#app',
    store,
    components
});
