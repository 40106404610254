import { render, staticRenderFns } from "./form-indicators.vue?vue&type=template&id=4f7f9317&scoped=true"
import script from "./form-indicators.vue?vue&type=script&lang=js"
export * from "./form-indicators.vue?vue&type=script&lang=js"
import style0 from "./form-indicators.vue?vue&type=style&index=0&id=4f7f9317&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f7f9317",
  null
  
)

export default component.exports