<template>
    <el-form :model="project" :rules="rules" ref="details" label-position="top" :status-icon="true" :inline-message="true">
            <h3 class="mb-4">Date identificare
            </h3>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <el-form-item label="Titlul proiectului in limba romana" prop="name">
                            <el-input v-model="project.name" placeholder="Introduceti titlul proiectului in limba romana" clearable
                                      type="text" maxlength="250" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="form-group">
                        <el-form-item label="Titlul proiectului in limba engleza" prop="name_en">
                            <el-input v-model="project.name_en" placeholder="Introduceti titlul proiectului in limba engleza" clearable
                                      type="text" maxlength="250" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Promotor" prop="promoter_id">
                            <el-select value="" v-model="project.promoter_id" filterable remote placeholder="Selecteaza un promotor" style="width:100%">
                                <el-option v-for="item in promoters"
                                           :key="item.id"
                                           :label="item.name + ' - CIF:' + item.cif"
                                           :value="item.id">
                                    <span style="float: left">{{ item.name }} - CIF:{{ item.cif}}</span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <el-form-item label="Numar GRACE" prop="grace_number">
                            <el-input v-model="project.grace_number" placeholder="Introduceti numarul" clearable
                                      type="text" maxlength="30" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-3"></div>
            </div>
            <div class="row">
                <div class="col-sm-3">
                    <div class="form-group">
                        <el-form-item label="Numar apel" prop="appeal">
                            <el-select v-model="project.appeal" filterable placeholder="Select" value-key="id" style="width:100%">
                                <el-option v-for="item in appeals.values"
                                           :key="item.id" :label="item.value" :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <el-form-item label="Runda" prop="round">
                            <el-select v-model="project.round" filterable placeholder="Select" value-key="id" style="width:100%">
                                <el-option v-for="item in rounds.values"
                                           :key="item.id" :label="item.value"  :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Tip grant" prop="grant">
                            <el-select v-model="project.grant" filterable placeholder="Select" style="width:100%" value-key="id">
                                <el-option v-for="item in grants.values"
                                           :key="item.id" :label="item.value"  :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <h5 class="text-muted mt-3">Date proiect</h5>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <el-form-item label="Numar contract finantare" prop="funding_number">
                            <el-input v-model="project.funding_number" placeholder="Introduceti numarul" clearable
                                      type="text" maxlength="30" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <el-form-item label="Data semnarii contractului" prop="contract_date">
                            <el-date-picker v-model="project.contract_date" placeholder="Selectati data" type="date" value-format="yyyy-MM-dd"
                                            format="dd.MM.yyyy" style="width:100%" @change="updateContractDate"></el-date-picker>
                            <!-- :picker-options="datePickerOptions" -->
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <el-form-item label="Perioada de implementare - data start:" prop="project_start_date">
                            <el-date-picker v-model="project.project_start_date" placeholder="De la" type="date" value-format="yyyy-MM-dd"
                                            format="dd.MM.yyyy" style="width:100%" :picker-options="startPeriodOptions" @change="updateStartPeriod"></el-date-picker>
<!--                            <el-date-picker format="dd.MM.yyyy"-->
<!--                                    v-model="project_date"  :picker-options="periodPickerOptions"-->
<!--                                    type="daterange" unlink-panels-->
<!--                                    range-separator="-"-->
<!--                                    start-placeholder="De la"-->
<!--                                    end-placeholder="pana la">-->
<!--                            </el-date-picker>-->
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <el-form-item :label="' ' + datesDifference(this.project.project_start_date, this.project.project_end_date) + '- data sfarsit'"  prop="project_end_date">
                            <el-date-picker v-model="project.project_end_date" placeholder="De la" type="date" value-format="yyyy-MM-dd"
                                            format="dd.MM.yyyy" style="width:100%"  :picker-options="endPeriodOptions"></el-date-picker>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <h5 class="text-muted mt-3">Buget proiect</h5>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <el-form-item label="Total buget" prop="total_budget">
                            <el-input v-model="total_budget" type="number" disabled="" maxlength="20">
                                <template slot="append">€</template>
                            </el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <el-form-item :label="'Finantare ' + calcFunding()" prop="funding_budget">
                            <el-input v-model="project.funding_budget" type="number" maxlength="20">
                                <template slot="append">€</template>
                            </el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <el-form-item :label="'Co-finantare: ' + calcCoFunding()" prop="cofunding_budget">
                            <el-input v-model="project.cofunding_budget" type="number" maxlength="20">
                                <template slot="append">€</template>
                            </el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <el-form-item label="Indirecte" prop="indirect_budget">
                            <el-input v-model="project.indirect_budget" type="number" maxlength="20">
                                <template slot="append">%</template>
                            </el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>
        </el-form>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import mixin from './../../mixins/common';
    import moment from 'moment';
    import numeral from 'numeral';
    export default {
        name: "project-details-form",
        mixins:[mixin],
        props:{
            data:{
                required: true,
                type: Object,
            },
        },
        data(){
            return {
                // project_date: [moment.utc().startOf('month'), moment.utc().endOf('month')],
                // implementation_date: [moment.utc().startOf('month'), moment.utc().endOf('month')],
                rules: {
                    name: [
                        { required: true, message: 'Completati titlul proiectului in limba romana', trigger: 'blur' },
                        { min: 3, max: 150, message: 'Lungimea trebuie sa fie intre 3 si 150 caractere', trigger: 'blur' }
                    ],
                    name_en: [
                        { required: true, message: 'Completati denumirea promotorului in engleza', trigger: 'blur' },
                        { min: 3, max: 150, message: 'Lungimea trebuie sa fie intre 3 si 150 caractere', trigger: 'blur' }
                    ],
                    promoter_id: [
                        { required: true, message: 'Promotorul este obligatoriu', trigger: 'change' },
                    ],
                    funding_number: [
                        { required: true, message: 'Introduceti numarul proiectului', trigger: 'blur' }
                    ],
                    appeal: [
                        { required: true, message: 'Selectati apelul', trigger: 'change' }
                    ],
                    round: [
                        { required: true, message: 'Selectati runda', trigger: 'change' }
                    ],
                    grant_type: [
                        { required: true, message: 'Selectati grantul', trigger: 'change' }
                    ],
                    // total_budget: [
                    //     { required: true, message: 'Introduceti valoarea bugetului', trigger: 'blur' },
                    // ],
                    funding_budget: [
                        { required: true, message: 'Introduceti valoarea finantata', trigger: 'blur' },
                    ],
                    cofunding_budget: [
                        { required: true, message: 'Introduceti valoarea co-finantata', trigger: 'blur' },
                    ],
                    indirect_budget: [
                        { required: true, message: 'Introduceti procentul indirectelor', trigger: 'blur' },
                    ],
                    contract_date: [
                        { required: true, message: 'Selectati data contractului', trigger: 'blur' },
                    ],
                    project_start_date: [
                        { required: true, message: 'Selectati data de incepere', trigger: 'blur' },
                    ],
                    project_end_date: [
                        { required: true, message: 'Selectati data de finalizare', trigger: 'blur' },
                    ],
                },
                contractMinDate: new Date(),
                periodStartDate: new Date(),
                // datePickerOptions: {
                //     disabledDate (date) {
                //         return date <= new Date()
                //     }
                // },
                startPeriodOptions: {
                    disabledDate: this.disabledContractDate
                },
                endPeriodOptions: {
                    disabledDate: this.disabledPeriodStartDate
                },
            }
        },
        computed:{
            ...mapGetters({
                appeals: 'project/appeals',
                rounds: 'project/rounds',
                grants: 'project/grants',
                promoters: 'promoter/promoters',
                project: 'project/project',
                validateDetails: 'project/validateDetails',
                validationErrorsExists: 'validationErrorsExists',
            }),
            project_period: function() {
                return this.getMonthDifference(moment.utc(this.project.project_start_date),moment.utc(this.project.project_end_date))
            },
            total_budget: function(){
                return numeral(parseFloat(this.project.funding_budget) + parseFloat(this.project.cofunding_budget)).format('0.00');
            },
            // implementation_period: function(){
            //     return this.getMonthDifference(moment.utc(this.implementation_date[0]),moment.utc(this.implementation_date[1]))
            // },
        },
        watch:{
            validateDetails: function(){
                if(this.validateDetails) {
                    this.setValidateDetails(false);
                    this.submitForm();
                }
            },
        },
        mounted(){
            this.getAppeals();
            this.getRounds();
            this.getGrants();
            this.getPromoters([1,999]);
            this.minDate = new Date();
            this.minStartDate = new Date();
            this.updateContractDate();
            this.updateStartPeriod();
        },
        methods:{
            ...mapMutations({
                setValidateDetails: 'project/setValidateDetails',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            ...mapActions({
                getAppeals: 'project/getAppeals',
                getRounds: 'project/getRounds',
                getGrants: 'project/getGrants',
                getPromoters: 'promoter/getPromotersList',
                saveProject: 'project/saveProject',
            }),
            updateForm() {
                this.saveProject(this.project).then((result) => {
                    if(result.valid === false){
                        this.$notify({ type:'error', title: 'Eroare', message: result.message });
                        // reject(false);
                    }
                });
            },
            disabledContractDate(date) {
                return date < this.contractMinDate
            },
            disabledPeriodStartDate(date) {
                return date < this.periodStartDate
            },
            updateContractDate: function(){
                this.contractMinDate = moment(this.project.contract_date);
            },
            updateStartPeriod: function(){
                this.periodStartDate = moment(this.project.project_start_date);
            },
            calcFunding: function(){
                return (this.total_budget > 0 && this.project.funding_budget > 0) ? numeral(parseFloat(this.project.funding_budget)/parseFloat(this.total_budget)).format('0.00%') : numeral(0).format('0.00%');
            },
            calcCoFunding: function(){
                return (this.total_budget > 0 && this.project.cofunding_budget > 0) ? numeral(parseFloat(this.project.cofunding_budget)/parseFloat(this.total_budget)).format('0.00%') : numeral(0).format('0.00%');
            },
            getMonthDifference: function(startDate, endDate){
                let monthDifference = this.getAbsoluteMonths(moment.utc(endDate)) - this.getAbsoluteMonths(moment.utc(startDate));
                return monthDifference + (monthDifference === 1 ? ' luna' : ' luni');
            },
            getAbsoluteMonths: function(momentDate) {
                let months = Number(momentDate.format("MM"));
                let years = Number(momentDate.format("YYYY"));
                return months + (years * 12);
            },
            submitForm() {
                this.project.contract_date = this.momentDate(this.project.contract_date);
                this.project.project_start_date = this.momentDate(this.project.project_start_date);
                this.project.project_end_date = this.momentDate(this.project.project_end_date);
                // this.project.implementation_start_date = this.momentDate(this.implementation_date[0]);
                // this.project.implementation_end_date = this.momentDate(this.implementation_date[1]);
                this.$refs['details'].validate((valid) => {
                    if (valid) {
                        this.setValidationErrorsExists(false)
                        return true;
                    } else {
                        this.setValidationErrorsExists(true);
                        return false;
                    }
                })
            },
            momentDate(date = null){
                return moment.utc(date).format('YYYY-MM-DD');
            }
        }
    }
</script>

<style scoped>

</style>
