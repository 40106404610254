<template>
    <div v-if="project" id="indirect">
        <h3 class="mb-4 mt-3">Buget indirecte
            <small class="text-muted">(valoare maxima procentuala {{ this.formatNumber(this.project.indirect_budget,2) }} %
                din total buget {{ this.formatPrice(this.project.total_budget,2) }}) </small>
        </h3>

        <div class="row">
            <div class="col-sm-12">
                <table class="table table-responsive">
                    <thead>
                        <tr>
                            <th style="width:60px">Cod</th>
                            <th>Denumire aplicant/partener</th>
                            <th>Cost in €</th>
                            <th>Cost comp. dezv. org.</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="row in indirects">
                            <td>
                                {{ row.type }}
                            </td>
                            <td>
                               {{ row.name }}
                            </td>
                            <td>
                                <el-input v-model="row.price" type="number" placeholder="0 €" @focus="$event.target.select()"></el-input>
                            </td>
                            <td>
                                <el-input v-model="row.dezv" type="number" placeholder="0 €" @focus="$event.target.select()"></el-input>
                            </td>

                        </tr>
                    </tbody>
                </table>
                <div class="alert alert-danger fade show" v-if="!okIndirects()">
                    <i class="icofont-warning"></i> Bugetul indirect introdus depaseste valorea proiectului! Modifica valorile introduse.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations} from 'vuex'
    import mixin from './../../mixins/common';
    export default {
        name: "project-indirect-form",
        mixins:[mixin],
        data(){
            return {
                showPosition: false,
                form:{},
                indirects: [],
                totalIrredeemable: 0,
                totalCoFunding: 0,
                totalCash: 0,
                totalNature: 0,
                totalEligible: 0,
                totalPartner: 0,
                totalPromoter: 0
            }
        },
        computed:{
            ...mapGetters({
                project: 'project/project',
                validateIndirects: 'project/validateIndirects',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validateIndirects: function(){
                if(this.validateIndirects) {
                    this.submitForm();
                }
            },
        },
        mounted(){
            if(this.project.indirects.length === 0) {
                this.indirects.push({
                    type: 'A',
                    partner_id: this.project.promoter.id,
                    name: this.project.promoter.name,
                    price: 0,
                    dezv: 0,
                    percentFormula: 0,
                });
                this.project.partners.forEach((value, index) => {
                    this.indirects.push({
                        type: 'P' + value.id,
                        partner_id: value.id,
                        name: value.name,
                        price: 0,
                        dezv: 0,
                    })
                });
            }else if(this.project.indirects.length !== this.project.partners.length + 1){
                this.project.indirects.forEach((value, index) => {
                    this.indirects.push({
                        type: value.partner.id !== this.project.promoter.id ? 'P' + value.partner.id : 'A',
                        partner_id: value.partner_id,
                        name: value.partner ? value.partner.name : this.project.promoter.name,
                        price: value.price,
                        dezv: value.dezv,
                        percentFormula: value.percentFormula,
                    });
                });

                this.project.partners.forEach((value, index) => {
                    if(this.project.indirects.filter(item => {
                        return item.partner_id === value.id;
                    }).length === 0){
                        this.indirects.push({
                            type: 'P' + value.id,
                            partner_id: value.id,
                            name: value.name,
                            price: 0,
                            dezv: 0,
                        });
                    }
                });
            }else{
                this.project.indirects.forEach((value, index) => {
                    this.indirects.push({
                        type: value.partner.id !== this.project.promoter.id ? 'P' + value.partner.id : 'A',
                        partner_id: value.partner_id,
                        name: value.partner ? value.partner.name : this.project.promoter.name,
                        price: value.price,
                        dezv: value.dezv,
                        percentFormula: value.percentFormula,
                    })
                });
            }
        },
        methods:{
            ...mapActions({
                saveProjectFinancing: 'project/saveProjectFinancing',
                saveProjectIndirect: 'project/saveProjectIndirect'
            }),
            ...mapMutations({
                setValidateIndirects: 'project/setValidateIndirects',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            okIndirects(){
                let total = parseFloat(this.project.total_budget); //this.project.totalProject;
                let s = 0.00;
                let ca = 0;
                this.indirects.forEach(function(i){
                    if (i.price > 0) {
                        ca = i.price / total * 100;
                        s += parseFloat(ca);
                    }
                    i.percentFormula = s;
                });
                return parseFloat(this.project.indirect_budget) > parseFloat(s);
            },
            okIndirects2(){
                let tc = 0;
                let tn = 0;
                let te = 0;
                let ti = 0;
                this.project.financing.forEach(function(i){
                    tc += parseFloat(i.totalCash);
                    tn += parseFloat(i.totalNature);
                    te += parseFloat(i.totalBudget) + parseFloat(i.totalIndirect);
                });
                // this.project.indirects.forEach(function(j){
                //     ti += parseFloat(j.price);
                // });
                this.totalCash = tc;
                this.totalNature = tn;
                this.totalEligible = te + ti;
                this.totalCoFunding = this.totalCash + this.totalNature;
                this.totalIrredeemable = this.totalEligible - this.totalCoFunding;
                return parseFloat(this.project.funding_budget) >= this.totalCash;
            },
            submitForm() {
                this.project.indirects = this.indirects;
                if (this.indirects.length > 0 && this.okIndirects2()) {
                    this.saveProjectIndirect({
                        project_id: this.project.id,
                        indirects: this.project.indirects,
                    }).then((result) => {
                        if (result.valid === false) {
                            this.$notify({type: 'error', title: 'Eroare', message: result.message});
                            this.setValidationErrorsExists(true);
                            return false;
                        } else {
                            if(this.project.financing.length === 0){
                                this.project.financing.push({
                                    type: 'A',
                                    partner_id: this.project.promoter_id,
                                    partner:{
                                        id: (this.project.promoter ? this.project.promoter.id : null),
                                        name: (this.project.promoter ? this.project.promoter.name : null),
                                    },
                                    totalCash: 0,
                                    totalNature: 0,
                                    totalIndirect: 0,
                                    totalBudget: 0,
                                });
                                this.project.partners.forEach((value, index) => {
                                    this.project.financing.push({
                                        type: 'P' + value.id,
                                        partner_id: value.id,
                                        partner:{
                                            id: value.id,
                                            name: value.name,
                                        },
                                        totalCash: 0,
                                        totalNature: 0,
                                        totalIndirect: 0,
                                        totalBudget: 0,
                                    })
                                });
                            }
                            this.saveProjectFinancing({
                                project_id: this.project.id,
                                financing: this.project.financing,
                                totalIrredeemable: this.totalIrredeemable,
                                totalCoFunding: this.totalCoFunding,
                                totalCash: this.totalCash,
                                totalNature: this.totalNature,
                                totalEligible: this.totalEligible,
                            }).then((result) => {
                                this.project.financing = result.financing;
                                this.project.totalEligible = result.budget.totalEligible;
                                this.project.totalIrredeemable = result.budget.totalIrredeemable;
                                this.project.totalCoFunding = result.budget.totalCoFunding;
                                this.project.totalNature = result.budget.totalNature;
                                this.project.totalCash = result.budget.totalCash;

                                this.setValidateIndirects(false);
                                this.setValidationErrorsExists(false);
                                return true;
                            });
                        }
                    });
                } else {
                    this.setValidationErrorsExists(true);
                    this.$notify({ type: 'warning', title: 'Buget indirecte', message: 'Valorile introduse nu sunt permise! Incercati din nou.'});
                    return false;
                }
            },
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
