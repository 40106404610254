<template>
    <div>
        <el-drawer title="Vizualizare fișiere" :visible.sync="showUploadDrawer.visible" direction="rtl"
                   :wrapperClosable="false" :before-close="handleCloseDrawer" :destroy-on-close="true">
            <div class="px-4">
                <el-form :model="form" v-if="typeof this.showUploadDrawer.showDate !== 'undefined'"
                         label-position="top" :status-icon="true" :inline-message="false">

                    <el-form-item label="Cheltuieli finantare" prop="fa">
                        <el-input disabled v-model="form.amount_financing" placeholder="0" type="float" size="small" show-word-limit
                                  style="text-align:right" @focus="$event.target.select()">
                            <template slot="append">{{form.currency}}</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="Cheltuieli cofinantare" prop="ca">
                        <el-input disabled v-model="form.amount_cofinancing" placeholder="0" type="float" size="small" show-word-limit
                                  style="text-align:right" @focus="$event.target.select()">
                            <template slot="append">{{form.currency}}</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="Cheltuieli dezvoltare" prop="deza">
                        <el-input disabled v-model="form.dezv" placeholder="0" type="float" size="small" show-word-limit
                                  style="text-align:right" @focus="$event.target.select()">
                            <template slot="append">{{form.currency}}</template>
                        </el-input>
                    </el-form-item>

                    <el-form-item label="Nr ore efectiv lucrate acceptate" v-if="this.budget_id === 107 || this.budget_id === 108" prop="worked_hours">
                        <el-input disabled v-model="form.worked_hours"  size="small"
                                type="number" placeholder="0" ></el-input>
                    </el-form-item>

                    <el-form-item label="Motivarea neeligibilitatii costului" prop="motivate_cost">
                        <el-input disabled v-model="form.motivate_cost"  placeholder="Adaugati motivarea neeligibilitatii costului" type="textarea"></el-input>
                    </el-form-item>
                </el-form>

                <!--<p class="alert alert-info">{{ $t(infoMessage) }}</p>-->
                <h5>{{ showUploadDrawer.title ? showUploadDrawer.title : title }}</h5>
                <el-upload ref="upload" action="/api/upload" :data="showUploadDrawer"
                           :with-credentials="true" :auto-upload="true" :disabled="showUploadDrawer.readOnly"
                           :headers="{ 'X-CSRF-TOKEN': csrf}"
                           :multiple="showUploadDrawer.multiple"
                           :accept="showUploadDrawer.acceptFiles ? showUploadDrawer.acceptFiles : acceptFiles"
                           :on-remove="handleRemove" :on-preview="handlePreview"
                           :on-error="handleUploadError" :on-exceed="handleOnExceed"
                           :limit="showUploadDrawer.multiple === false ? 1 : 10"
                           :file-list="showUploadDrawer.files">
                </el-upload>
                <hr />
                <div v-if="typeof this.showUploadDrawer.showComments !== 'undefined'" class="form-group">
                    <h5>Comentarii:</h5>
                    <el-timeline reverse class="pl-0">
                        <el-timeline-item v-for="(row, index) in comments" v-if="!(row.user_id !== user_data && row.reply_to_id === -1)" :key="index"
                            :timestamp="formatUserDate(row.created_at)">
                            <div class="row">
                                <div class="col-12">
                                    <i class="icofont-girl" style="font-size:100%"></i>
                                    <small v-if="row.user && typeof row.user.promoter[0] !== 'undefined'" class=" font-weight-bold">Promotor</small>
                                    <small v-else class=" font-weight-bold">Operator de Fond</small>
                                    <br>
                                    {{ row.comment }}
                                </div>
                                <div class="col-12 text-right" v-if="row.files && row.files.length > 0">
                                    <small class="font-weight-bold">Fisiere incarcate</small><br />
                                    <template v-for="doc in row.files">
                                        <small><a :href="'/getFile/' + doc.id" target="_blank">{{ doc.file_name }}</a></small><br />
                                    </template>
                                </div>
                            </div>
                        </el-timeline-item>
                    </el-timeline>
                </div>

                <div class="form-group" v-if="form.documents && form.documents.length > 0">
                    <h5>Acte justificative</h5>
                    <ul class="list-unstyled">
                        <li v-for="document in form.documents">
                            <el-link v-if="document.file" @click="loadDoc(document)" class="mr-4">
                                <small>{{ document.file_name }}</small>
                            </el-link>
                        </li>
                    </ul>
                </div>
            </div>
        </el-drawer>
    </div>
</template>

<script>
    import {mapGetters,mapMutations,mapActions} from "vuex";
    import mixin from './../../mixins/common';
    export default {
        name: "upload-drawer",
        mixins:[mixin],
        data() {
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                title: 'Import fișier',
                infoMessage: 'Nu aveti permisiunea sa stergeti fisiere din acest drawer!',
                acceptFiles: '.pdf',
                model: '',
                model_id: 0,
                budget_id: 0,
                maxSize: '2MB',
                fileList: [],
                showComments: true,
                showDrawer: false,
                comments: [],
                form: { },
            }
        },
        props:{
            user_data: {
                required: false,
            },
            roles:{
                type: Array,
                required: false
            }
        },
        computed:{
            ...mapGetters({
                // reporting: 'reporting/reporting',
                showUploadDrawer: 'showUploadDrawer',
            }),
            translate(text){
                return this.$t(text);
            }
        },
        watch:{
            showUploadDrawer:function(){
                if(typeof this.showUploadDrawer.showComments !== 'undefined'){
                    this.placeData(this.showUploadDrawer.object);
                }
                else if(typeof this.showUploadDrawer.showDate !== 'undefined'){
                    this.form = this.showUploadDrawer.object;
                }
            },
        },
        methods:{
            ...mapActions({
                removeUploadedFile: 'removeUploadedFile',
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
                // setTerms: 'reporting/setTerms',
            }),
            handleRemove(file, fileList) {
                if(this.roles.length > 0 && this.roles.includes('super_admin')){
                    this.removeUploadedFile(file.id);
                }else{
                    this.handleCloseDrawer();
                    this.$notify({
                        type: 'warning',
                        title: 'Atentie',
                        message: "Nu ai permisiunea sa stergi acest fisier!"
                    });
                }
            },
            handlePreview(file, fileList) {
                window.open('/getFile/'+(typeof file.response !== "undefined" ? (file.response.file_id) : (file.id)), '_blank');
            },
            handleUploadError(file){
                console.log(file);
            },
            handleOnExceed(){
                this.$notify({ type: 'warning', title: 'Import', message: 'Este permisa incarcarea unui singur fisier!'});
            },
            handleCloseDrawer(done){
                this.setShowUploadDrawer({
                    visible: false
                });
            },
            submitUpload(){
                this.$refs.upload.submit();
            },
            placeData: function(e) {
                this.budget_id = e.budget_id;
                this.form.documents = [];
                if (e.comments && typeof e.comments !== 'undefined') this.comments = e.comments;
                if (typeof e !== 'undefined' && (e !== null || e !== undefined) && typeof this.showUploadDrawer.auxDocuments === 'undefined') {
                    if (typeof e.validation !== 'undefined' && e.validation !== null) {
                        this.form.status = e.validation.status;
                        this.form.date = e.validation.answer_until_date;
                        this.form.amount_cofinancing = e.validation.amount_cofinancing;
                        this.form.amount_financing = e.validation.amount_financing;
                        this.form.dezv = e.validation.dezv;
                        this.form.worked_hours = e.validation.worked_hours;
                        this.comments = e.validation.comments;
                        this.form.currency = e.currency;
                        this.form.motivate_cost = e.validation.motivate_cost;
                        //this.form.documents = e.validation.documents;
                        this.form.documents = e.documents;
                    } else if ((e.comments !== null || e.comments !== undefined) && typeof this.showUploadDrawer.auxDocuments !== 'undefined') {
                            this.comments = e.comments;
                    } else {
                        this.form.amount_financing = e.amount_financing;
                        this.form.amount_cofinancing = e.amount_cofinancing;
                        this.form.dezv = e.dezv;
                        this.form.worked_hours = e.worked_hours;
                        this.form.documents = e.documents;
                    }
                }
                this.formchanged = true;
            },
            loadDoc(file) {
                window.open('/getFile/'+file.id, '_blank');
            },
            /*placeData: function(e) {
                if (e.comments && typeof e.comments !== 'undefined') this.comments = e.comments;
                if (e.validation && typeof e.validation.comments !== 'undefined' && (e.validation.comments !== null || true)) this.comments = e.validation.comments;
            },*/
            $t (str) {
                var reg = /\{\{(.*?)\}\}/g
                // var res
                var found
                while (found = reg.exec(str)) {
                    if (this.showUploadDrawer[found[1]]) {
                        str = str.replace(found[0], this.showUploadDrawer[found[1]])
                    }else if(this.$data[found[1]]) {
                        str = str.replace(found[0], this.$data[found[1]])
                    } else {
                        str = str.replace(found[0], '--')
                    }
                }
                return str
            },
        }
    }
</script>

<style scoped>

</style>
