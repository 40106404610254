<template>
    <div>
        <h3 class="mb-4 mt-3">Documente</h3>
        <div class="row">
            <div class="col-sm-12">
                <el-table :data="project.documents" ref="documentTable" id="documentTable" stripe fit highlight-current-row style="width:100%">
                    <el-table-column type="index"></el-table-column>
                    <el-table-column label="Denumire" prop="name"></el-table-column>
                    <el-table-column label="Categorie" prop="category.value"></el-table-column>
                    <el-table-column label="Fisier" prop="file">
                        <template slot-scope="scope" v-if="scope.row.file">
                            {{ scope.row.file.response }}
                            <el-link icon="el-icon-view" v-if="scope.row.file.status === 'success'" :href="'/getFile/'+scope.row.id" target="_blank">Vezi fisierul</el-link>
<!--                            <el-link icon="el-icon-view" v-if="scope.row.file !== ''" :href="scope.row.file" target="_blank">Vezi fisierul</el-link>-->
                            <el-link icon="el-icon-view" v-if="!scope.row.file.response && scope.row.file" :href="'/getFile/'+scope.row.id" target="_blank">Vezi fisierul</el-link>
<!--                            <span v-if="scope.row.file && !scope.row.file.response" class="alert-danger"><i class="el-icon-warning-outline"></i> </span>-->
                        </template>
                    </el-table-column>
                    <el-table-column label="Actiuni">
                        <template slot-scope="scope">
                            <el-button size="mini" type="secondary" icon="el-icon-paperclip"
                                       @click="uploadFile(scope.row)" v-if="!scope.row.file">Incarcă</el-button>
                            <el-button size="mini" type="secondary" icon="el-icon-edit"
                                       @click="handleRowSelect(scope.row)"></el-button>
                            <el-button size="mini" type="danger" icon="el-icon-delete"
                                       @click="handleRowDelete(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-form :model="form" id="documentForm" :rules="rules" ref="documents" label-position="top" :status-icon="true" :inline-message="true">
            <div class="row">
                <div class="col-sm-12">
                    <h4 class="mb-4 mt-5">Adauga/modifica document</h4>
                </div>
                <div class="col-sm-3">
                    <div class="form-group">
                        <el-form-item label="Denumire" prop="name">
                            <el-input v-model="form.name" placeholder="Nume document" clearable
                                      type="text" maxlength="60" show-word-limit>
                            </el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Categoria" prop="category">
                        <el-select v-model="form.category" placeholder="Select" style="width:100%"  value-key="id">
                            <el-option v-for="item in documentCategories.values"
                                       :key="item.id" :label="item.value"  :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-6 form-group">
                    <el-form-item label="Fisier" prop="file">
                        <span v-if="!form.name || !form.category"><i class="el-icon-warning-outline"></i> Introduceti numele si selectati categoria pentru a incarca documentul</span>
                        <el-button v-if="form.name && form.category" @click.prevent="uploadFile(form)"><span v-if="form.file">Modificare fisier</span><span v-else>Incarcă fisierul</span></el-button>
                    </el-form-item>
                </div>
                <div class="col-sm-12 text-right mb-5" v-if="form.file">
                    <el-button @click.prevent="clear">Goleste formularul</el-button>
                    <el-button @click.prevent="addDocument" type="secondary">Aplica modificarile</el-button>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "project-documents-form",
        data(){
            return {
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                form:{},
                rules: {
                    name: [
                        {required: true, message: 'Denumirea este obligatorie', trigger: 'blur'},
                        { min: 3, max: 60, message: 'Lungimea trebuie sa fie intre 3 si 60 caractere', trigger: 'blur' }
                    ],
                    category: [
                        {required: true, message: 'Categoria este obligatorie', trigger: 'blur'},
                    ],
                },
            }
        },
        computed:{
            ...mapGetters({
                project: 'project/project',
                documentCategories: 'project/documentCategories',
                validateDocuments: 'project/validateDocuments',
                validationErrorsExists: 'validationErrorsExists',
                showUploadDrawer: 'showUploadDrawer',
            }),
        },
        watch:{
            validateDocuments: function(){
                if(this.validateDocuments) {
                    this.submitForm();
                }
            },
            showUploadDrawer:function(){
                this.loadProject(this.project.id);
                if(this.showUploadDrawer.visible === false){
                    this.clear();
                }
                return this.showUploadDrawer;
            },
        },
        mounted(){
            this.getDocumentCategories();
            this.clear();
        },
        methods:{
            ...mapMutations({
                setValidateDocuments: 'project/setValidateDocuments',
                setValidationErrorsExists: 'setValidationErrorsExists',
                setShowUploadDrawer: 'setShowUploadDrawer',
            }),
            ...mapActions({
                loadProject: 'project/loadProject',
                getDocumentCategories: 'project/getDocumentCategories',
                removeDocument: 'removeUploadedFile',
                saveProject: 'project/saveProject',
            }),
            uploadFile(document){
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Project',
                    model_id: this.project.id,
                    category_id: document.category.id,
                    acceptFiles: '.pdf', //.docx,.doc,
                    name: document.name,
                    multiple: false,
                    title: 'Incarca document',
                    files: document.file ? [document] : [],
                });
            },
            addDocument(){
                this.$refs.documents.validate((valid) => {
                    if (valid) {
                        if(this.form.index > this.project.documents.length) {
                            this.project.documents.push(this.form);
                        }else{
                            this.project.documents[this.form.index - 1] = this.form;
                        }
                        this.saveDocument();
                        this.loadProject(this.project.id);
                        this.clear();
                        this.setValidateDocuments(false);
                        return true;
                    } else {
                        return false;
                    }
                });
                VueScrollTo.scrollTo('#documentTable');
            },
            saveDocument(){
                this.saveProject(this.project).then((result) => {
                    if(result.valid === false){
                        this.$notify({ type:'error', title: 'Eroare', message: result.message });
                        reject(false);
                    }
                });
            },
            handleUploadRemove(file, fileList){
                this.form.file = '';
                this.$refs.documents.validateField('file')
            },
            handleUploadSuccess (res, file, fileList) {
                if(file.status === 'success'){
                    file.response.success = file.response.success.replace("public","storage");
                }
                this.form.file = file;
                this.$refs.documents.validateField('file')
            },
            handleUploadChange(file, fileList) {
                this.fileList = fileList.slice(-3);
            },
            handleRowSelect(row){
                this.form = row;
                VueScrollTo.scrollTo('#documentForm');
            },
            handleRowDelete(index, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi aceasta inregistrare?')
                    .then(_ => {
                        if (index !== -1) {
                            if(row.id > 0) {
                                // console.log(row);
                                this.removeDocument(row.id);
                                this.project.documents.splice(index, 1);
                            }else {
                                this.project.documents.splice(index, 1);
                            }
                            this.$notify({ type: 'success', title: 'Documente', message: 'Inregistrarea a fost stearsa cu succes!'});
                            this.clear();
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    });
            },
            clear(){
                // this.$refs.upload.clearFiles();
                this.form = {
                    index: (this.project.documents ? this.project.documents.length : 0) + 1,
                    name: null,
                    category: null,
                    file: null
                };
            },

            submitForm() {
                // if (this.project.documents.length > 0) {
                    this.setValidateDocuments(false);
                    this.setValidationErrorsExists(false);
                    return true;
                // }
                // else {
                    // this.setValidationErrorsExists(true);
                    // this.$notify({ type: 'warning', title: 'Documente proiect', message: 'Trebuie sa adaugati cel putin un document!'});
                    // return false;
                // }
            },
        }
    }
</script>

<style scoped>
    .el-upload__tip{
        display: inline-block; margin: 0 10px;
    }
</style>
