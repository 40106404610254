<template>
    <div>
        <el-form :model="form" :rules="rules" ref="account" label-position="top" :status-icon="true" :inline-message="true">
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Subiect" prop="subject" :error="validationErrors.get('subject')">
                            <el-input v-model="form.subject" placeholder="Completeaza subiectul mailului" clearable
                                  type="text" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                    <div class="form-group">
                        <el-form-item label="Numele intern" prop="name" :error="validationErrors.get('name')">
                            <el-input v-model="form.name" placeholder="Numele intern al sablonului" clearable
                                      type="text" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div id="app">
                <vue-editor useCustomImageHandler @image-added="handleImageAdded" v-model="form.html_template"></vue-editor>
            </div>
        </el-form>
        <div class="row mt-4">
            <div class="col-lg-6">
                <button class="btn btn-primary" @click="send()" :disabled="isDisabled">Finalizeaza</button>
            </div>
        </div>
        <hr>
        <small>
            <b>INFO!</b><br />
            {{ project }} - Se inlocuieste automat cu numele proiectului pentru care se trimite notificarea.<br />
            {{ names }} - Se inlocuieste automat cu numele fiecarui utilizator ce primeste mesajul.<br />
            {{ email }} - Se inlocuieste automat cu mail-ul fiecarui utilizator ce primeste mesajul.<br />
            {{ phone }} - Se inlocuieste automat cu numarul de telefon al fiecarui utilizator ce primeste mesajul.<br />
            EX: "Buna ziua dl. {{ names }}, cum va simtiti astazi?" -> REZULTAT: "Buna ziua dl. Stere Cristian, cum va simtiti astazi?"
        </small>
    </div>
</template>

<script>
    import { VueEditor } from "vue2-editor";
    import { mapGetters, mapMutations, mapActions } from 'vuex'
    import mixin from './../../mixins/common';
    export default {
        name: "account-form",
        mixins:[mixin],
        props:{
            template_id:{
                required: true,
            }
        },
        components: {
            VueEditor
        },
        data(){
            return {
                form: {},
                isDisabled: false,
                project: '{{ project }}',
                names: '{{ name }}',
                email: '{{ email }}',
                phone: '{{ phone }}',
                notification_icon: 'icofont-ui-password',
                rules: {
                    subject: [
                        { required: true, message: 'Completati subiectul mailului', trigger: 'blur' },
                        { min: 3, message: 'Lungimea trebuie sa fie de cel putin 3 caractere', trigger: 'blur' }
                    ]
                },
            }
        },
        created() { this.form = this.template_id; },
        computed:{
            ...mapGetters({
                validationErrors: 'validationErrors',
                validationErrorsExists: 'validationErrorsExists'
            }),
        },
        methods: {
            ...mapMutations({
                setValidationErrors: 'setValidationErrors',
            }),
            handleImageAdded(file, Editor, cursorLocation) {
              const CLIENT_ID = '9209232f242adf2'
              var formData = new FormData();
              formData.append('image', file)
              axios({
                url: 'https://api.imgur.com/3/image',
                method: 'POST',
                headers:{
                  'Authorization': 'Client-ID ' + CLIENT_ID
                },
                data: formData
              })
              .then((result) => {
                console.log(result);
                let url = result.data.data.link
                Editor.insertEmbed(cursorLocation, 'image', url);
              })
              .catch((err) => {
                console.log(err);
              })
            },
            send()
            {
                if(!this.form.subject || this.form.subject.length < 3) return this.$notify({type: 'error', title: 'Mail', message: 'Subiectul template-ului este mai mic de 3 caractere!'});
                if(!this.form.html_template || this.form.html_template.length < 3) return this.$notify({type: 'error', title: 'Mail', message: 'Mesajul template-ului este mai mic de 3 caractere!'});
                this.isDisabled = true;
                axios.post('/edit-template', {
                    id: this.template_id.id,
                    name: this.form.name,
                    subject: this.form.subject,
                    html_template: this.form.html_template
                }).then((result) => {
                    this.$notify({
                        type: 'success',
                        title: 'Editare',
                        message: 'Template-ul '+this.template_id.id+' a fost editat cu succes!'
                    });
                    window.location.href = '/templates';
                });
            }
        }
    }
</script>

<style scoped>

</style>
