<template>
    <div>
        <h5 class="text-muted">Modificare lista</h5>
        <h2>{{ catalog.name }}</h2>
        <p>Modifica cu atentie toate campurile de mai jos. Daca e nevoie poti trage de randuri pentru a le schimba pozitia.</p>
        <div class="row">
            <div class="col-lg-6">
                <div class="form-label-group">
                    <input id="name" name="name" type="text" class="form-control" v-model="catalog.name"
                        placeholder="Introdu numele listei" required autofocus />
                    <label for="name">Name</label>
                    <span class="invalid-feedback" role="alert">
                        <strong>error.message</strong>
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <div class="form-label-group">
                    <input id="slug" name="name" type="text" class="form-control" v-model="catalog.slug" disabled />
                    <label for="slug">Identificator unic</label>
                </div>
            </div>
        </div>
        <hr />
        <h5>Valori</h5>
        <draggable v-model="catalog.values" class="draggable" ghost-class="ghost">
                <div class="row" v-for="(value, index) in catalog.values" v-if="catalog.values.length > 0" :key="value.sort">
                    <div class="col-sm-1">
                        <div class="form-label-group">
                            <label class="text-right"><i class="icofont-cursor-drag"></i></label>
                        </div>
                    </div>
                    <div class="col-sm-10">
                        <div class="form-label-group" v-if="value.parent">
                            <select class="form-control" v-model="value.parent.id" placeholder="Selecteaza parintele">
                                <option :value="child.id" v-for="child in children">
                                    {{ child.value }}
                                </option>
                            </select>
                            <label :for="'parent' + index">Selecteaza valoarea parinte (utilizeaza doar daca stii ce faci)</label>
                        </div>
                        <div class="form-label-group">
                            <input :id="'name' + index" name="item['value'][]" type="text" class="form-control" v-model="value.value" placeholder="Introdu numele valorii" />
                            <label :for="'name' + index">Introdu numele valorii</label>
                        </div>
                    </div>
                    <div class="form-label-group col-sm-1 text-right">
                        <a class="btn btn-default" @click="remove(index)"><i class="icofont-ui-delete"></i></a>
                    </div>
                </div>
        </draggable>
        <div class="row mt-4">
            <div class="col-lg-6">
                <button class="btn btn-default" @click="addItem()">Adauga valoare</button>
                <button class="btn btn-primary" @click="save()">Salveaza</button>
            </div>
        </div>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    export default {
        name: "catalog-edit",
        props:{
            catalog_id:{
                required: true,
            }
        },
        components: {
            draggable,
        },
        data(){
            return {
                catalog:{
                    name:null,
                    slug:null,
                    values:null,
                },
                children: [],
                name: '',
                slug: '',
            }
        },
        created(){
            this.getCatalog();
            this.getChildren();
        },
        methods:{
            save(){
                return new Promise((resolve, reject) => {
                    axios.post('/api/catalog/set', this.catalog).then((result) => {
                        this.catalog = result.data;
                        this.$notify({
                            type: 'success',
                            title: 'Modificare lista',
                            message: 'Lista a fost actualizata cu success'
                        });
                        resolve(result.data);
                    }).catch((error) => {
                        reject(error);
                    });
                });
            },
            addItem(){
                this.catalog.values.push({
                    id: '',
                    value: '',
                    sort: this.catalog.values.length + 1
                })
            },
            remove(index){
                this.catalog.values.splice(index,1);
                this.$notify({
                    type: 'warning',
                    title: 'Modificare lista',
                    text: 'Inregistrarea a fost stearsa. Salveaza lista pentru a aplica schimbarea.'
                });
            },
            getCatalog(){
                return new Promise((resolve, reject) => {
                    axios.post('/api/catalog/get', {'catalog_id' : this.catalog_id}).then((result) => {
                        this.catalog = result.data.catalog;
                        // resolve(result.data);
                    }).catch((error) => {
                        reject(error);
                    });
                });
            },
            getChildren() {
                return new Promise((resolve, reject) => {
                    axios.post('/api/catalog/getChildren', {'catalog_id': this.catalog_id}).then((result) => {
                        this.children = result.data.children;
                        // resolve(result.data);
                    }).catch((error) => {
                        reject(error);
                    });
                });
            },
        }

    }
</script>

<style scoped>
.btn{
    cursor: pointer;
}
.draggable, i{
    cursor:move;
}
.draggable .form-label-group{
    margin-top:0.5rem;
    margin-bottom:0.5rem;
}
.ghost{
    opacity: 0.5;
    background: #c8ebfb;
}
</style>
