
import axios from 'axios'

/**
 * Validate Construction Works
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validateConstructionWorks = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validationNotification/constructionWorks', payload)
        .then((response) => {
            commit('setConstructionWorks',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

/**
 * Validate Indirect Costs
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validateIndirectCosts = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validationNotification/indirectCosts', payload)
        .then((response) => {
            commit('setIndirectCosts',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

/**
 * Validate Organizational Development
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validateOrganizationalDevelopment = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validationNotification/organizationalDevelopment', payload)
        .then((response) => {
            commit('setOrganizationalDevelopment',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};


/**
 * Validate Correct Appeal Number
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validateMaxGrantPromoter = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validationNotification/maxGrantPromoter', payload)
        .then((response) => {
            commit('setBudgetMaxGrantPromoter',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};



/**
 * Validate Correct Appeal Number
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validateMaxGrantPartner = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validationNotification/maxGrantPartner', payload)
        .then((response) => {
            commit('setBudgetMaxGrantPartner',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

/**
 * Validate nerambursabil budget
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validatekeepNerambursabil = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validationNotification/keepNerambursabil', payload)
        .then((response) => {
            commit('setkeepNerambursabil',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

/**
 * Validate maintain Budget
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validatemaintainBudget = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validationNotification/maintainBudget', payload)
        .then((response) => {
            commit('setmaintainBudget',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};

/**
 * Validate realocation percent
 * @param commit
 * @param dispatch
 * @param payload
 * @returns {Promise<T>}
 */
export const validaterealocation = ({ commit, dispatch }, payload) => {
    return axios.post('/api/validationNotification/realocation', payload)
        .then((response) => {
            commit('setrealocation',response.data);
            return Promise.resolve(response.data)
        })
        .catch((error) => {
            return Promise.reject(error);
        })
};
