<template>
    <div class="top">
        <el-dropdown v-if="checkPermission(['project-technical-write']) || checkPermission(['project-financial-write'])"
                     @command="handleCommand" trigger="click" style="float: right;top: -82px;">
            <span class="el-dropdown-link">
                Editare <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
                <template v-if="project.status_financial !== 9 || project.status_technical !== 9">
                    <el-dropdown-item :command="[project.id,0]" v-if="checkPermission(['project-technical-write'])">Date identificare</el-dropdown-item>
                    <el-dropdown-item :command="[project.id,1]" v-if="project.status_technical >= 1 && checkPermission(['project-technical-write'])">Descrieri</el-dropdown-item>
                    <el-dropdown-item :command="[project.id,2]" v-if="project.status_technical >= 2 && checkPermission(['project-technical-write'])">Activitati</el-dropdown-item>
                    <el-dropdown-item :command="[project.id,3]" v-if="project.status_technical >= 3 && checkPermission(['project-technical-write'])">Documente</el-dropdown-item>
                    <el-dropdown-item :command="[project.id,4]" v-if="project.status_technical >= 4 && checkPermission(['project-technical-write'])">Parteneri</el-dropdown-item>
                    <el-dropdown-item :command="[project.id,5]" v-if="project.status_technical >= 5 && checkPermission(['project-technical-write'])">Obiective</el-dropdown-item>
                    <el-dropdown-item :command="[project.id,6]" v-if="project.status_technical >= 6 && checkPermission(['project-technical-write'])">Indicatori</el-dropdown-item>
                    <el-dropdown-item :command="[project.id,7]" v-if="project.status_technical >= 7 && checkPermission(['project-technical-write'])">Calendar activitati</el-dropdown-item>
                    <el-dropdown-item :command="[project.id,10]" v-if="(project.status_financial >= 1 || project.status_technical >= 3) && checkPermission(['project-financial-write'])">Echipa</el-dropdown-item>
                    <el-dropdown-item :command="[project.id,11]" v-if="project.status_financial >= 2 && checkPermission(['project-financial-write'])">Bugete</el-dropdown-item>
                    <el-dropdown-item :command="[project.id,40]" v-if="project.status_financial >= 3 && checkPermission(['project-financial-write'])">Indirecte</el-dropdown-item>
                    <el-dropdown-item :command="[project.id,41]" v-if="project.status_financial >= 4 && checkPermission(['project-financial-write'])">Surse finantare</el-dropdown-item>
    <!--                                    <el-dropdown-item disabled :command="[project.id,43]" v-if="project.status_financial >= 5">Plati</el-dropdown-item>-->
                    <el-dropdown-item :command="[project.id, 'delete']" v-if="project.status_technical <= 3 && checkPermission(['project-technical-write'])" divided>Sterge</el-dropdown-item>
                </template>
                <el-dropdown-item :command="[project.id, 'assign']" v-if="checkPermission(['project-user-write'])" divided>Aloca utilizator</el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <el-tabs v-model="infoTabs">
            <el-tab-pane label="Date identificare" name="details">
                <project-details-pane :project="project"></project-details-pane>
            </el-tab-pane>
            <el-tab-pane label="Promotor" name="promoter">
                <el-tabs class="p-4" v-model="promoterTabs">
                    <el-tab-pane label="Detalii promotor" name="details">
                        <span slot="label">Detalii promotor
                            <a href="#" @click.prevent="openUrl('/promoters/show/' + project.promoter_id, true)"><i class="el-icon-news" style="font-size:80%"></i></a>
                        </span>
                        <promoter-details-pane :promoter="project.promoter"></promoter-details-pane>
                    </el-tab-pane>
                    <el-tab-pane label="Reprezentanti legali" name="employees">
                        <promoter-employees-pane :promoter="project.promoter"></promoter-employees-pane>
                    </el-tab-pane>
                    <el-tab-pane label="Notificari e-mail" name="notifications">
                        <promoter-notifications-pane :notifications="project.user_notifications"></promoter-notifications-pane>
                    </el-tab-pane>
                </el-tabs>
            </el-tab-pane>
            <el-tab-pane label="Note" name="comments">
                <ul class="list-group">
                    <li class="list-group-item p-1" v-for="comment in project.comments">
                        {{ comment.comment }}
                        <small class="float-right text-muted">{{ formatUserDateTime(comment.created_at) }}</small>
                    </li>
                </ul>
            </el-tab-pane>
            <el-tab-pane label="Istoric modificari contractuale" name="history">
                <el-table :data="project.notifications.filter(p => p.status === 3 && p.response === 2)" size="small">
                    <el-table-column prop="id" label="#" width="50">
                        <template slot-scope="scope">
                            <span v-if="scope.row.number === 0">-</span>
                            <span v-else>{{ scope.row.number }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="id" label="Tip">
                        <template slot-scope="scope">
                            <span v-if="scope.row.type === 1" >Act adițional</span>
                            <span v-else>Notificare</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="changes" width="500" label="Modificari">
                        <template slot-scope="scope">
                            <small v-for="type in scope.row.changes">{{ type.value }}<br/></small>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" label="Stare" width="140" header-align="center" align="center">
                        <template slot-scope="scope">
                            <span class="badge text-white" style="background-color:#3BB273;">Aprobata</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="updated_at" header-align="center" align="right" label="Data" width="150">
                        <template slot-scope="scope">
                            {{ formatUserDateTime(scope.row.updated_at) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Acțiuni" width="100" header-align="center" align="center">
                        <template slot-scope="scope">
                            <el-button v-if="(scope.row.response === 1 || scope.row.response === 3) && checkPermission(['notification-technical-write','notification-financial-write'])" size="mini" type="primary" icon="el-icon-edit"
                                       @click="openUrl('/notifications/'+scope.row.project_id+'/'+scope.row.id, true)"></el-button>
                            <el-button size="mini" v-if="checkPermission(['notification-read','notification-technical-write','notification-financial-write'])"
                                       type="secondary" icon="el-icon-view" @click="openUrl('/notifications/show/'+scope.row.project_id+'/'+scope.row.id, true)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <h3 class="mt-4 mb-3">Resurse</h3>
        <el-tabs v-model="resourcesTabs">
            <el-tab-pane label="Activitati" name="activities">
                <el-table :data="project.activities" size="small">
                    <el-table-column label="Activitate" width="80" align="center">
                        <template slot-scope="scope">{{ 'A'+scope.row.activity }}</template>
                    </el-table-column>
                    <el-table-column label="Denumire" prop="name"></el-table-column>
                    <el-table-column label="Descriere" prop="description"></el-table-column>
                    <el-table-column label="Rezultate" prop="results"></el-table-column>
                    <el-table-column label="Resurse" prop="resources"></el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="Documente" name="documents">
                <el-table :data="project.documents" size="small">
                    <el-table-column label="Categoria" prop="category.value" width="200"></el-table-column>
                    <el-table-column label="Denumire" prop="name"></el-table-column>
                    <el-table-column label="Fisier" prop="file" width="200">
                        <template slot-scope="scope">
                            <el-link icon="el-icon-view" v-if="scope.row.file" :href="'/getFile/'+scope.row.id" target="_blank">Vezi fisierul</el-link>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="Parteneri" name="partners">
                <el-table :data="project.partners" size="small">
                    <el-table-column label="Cod" width="70">
                        <template slot-scope="scope">
                            P{{ scope.row.id }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Denumire" prop="name"></el-table-column>
                    <el-table-column label="Adresa">
                        <template slot-scope="scope">
                            <span v-if="scope.row.address">{{ scope.row.address }}</span>
                            <span v-if="scope.row.city_id">{{ scope.row.city.name }}</span>
                            <span v-if="scope.row.city_id">, {{ scope.row.city.county.name }}</span>
                            <span v-if="scope.row.country">, {{ scope.row.country.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="CIF" width="140">
                        <template slot-scope="scope">
                            {{ scope.row.fiscal_attribute }} {{ scope.row.cif }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Nr. inreg" prop="register_no" width="140"></el-table-column>
                    <el-table-column label="Forma juridica" prop="type.value" width="140"></el-table-column>
                    <el-table-column label="Contact" prop="phone" width="180">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="light" :content="scope.row.phone" placement="top-start" v-if="scope.row.phone">
                                <a :href="urlPhone(scope.row.phone, true)"><i class="el-icon-phone-outline"></i> {{ scope.row.phone }}</a>
                            </el-tooltip>
                            <el-tooltip class="item" effect="light" :content="scope.row.email" placement="top-start" v-if="scope.row.email">
                                <a :href="urlEmail(scope.row.email, true)"><br /><i class="el-icon-message"></i> {{ scope.row.email | truncate(10, '...') }}</a>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column label="Website" prop="website" width="200">
                        <template slot-scope="scope">
                            <a href="#" v-if="scope.row.website" @click.prevent="openUrl(scope.row.website, true)">{{ scope.row.website.replace('https://','') | truncate(15, '...') }}</a>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane name="team">
                <span slot="label">Echipa
                    <a href="#" @click.prevent="openUrl('/projects/export-team/'+project.id, true)" title="Export in Excel">
                        <i class="icofont-file-excel"></i>
                    </a>
                </span>
                <el-table :data="project.employees" size="small">
                    <el-table-column label="Aplicant / Partener">
                        <template slot-scope="scope">
                            <template v-if="scope.row.partner && scope.row.partner.id !== scope.row.promoter_id">
                                {{ scope.row.partner.name }}
                            </template>
                            <template v-else>
                                Aplicant
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column label="CNP" prop="cnp"></el-table-column>
                    <el-table-column label="Pozitie" prop="position"></el-table-column>
                    <el-table-column label="Nume">
                        <template slot-scope="scope">
                            {{ scope.row.first_name }} {{ scope.row.last_name }}
                        </template>
                    </el-table-column>
                    <el-table-column label="CIM" prop="cim"></el-table-column>
                    <el-table-column label="Data CIM" prop="cim">
                        <template slot-scope="scope">
                            <span v-if="scope.row.cim_date">{{ formatUserDate(scope.row.cim_date) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Perioada">
                        <template slot-scope="scope">
                            <span v-if="scope.row.start_date">{{ formatUserDate(scope.row.start_date) }}<br />{{ formatUserDate(scope.row.end_date) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Norma" prop="workload">
                        <template slot-scope="scope">
                            <span v-if="scope.row.workload_hours > 0">{{ scope.row.workload_hours }}
                                <span v-if="scope.row.workload">{{ scope.row.workload.value }}</span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Salariu brut" prop="salary" align="right">
                        <template slot-scope="scope">{{ formatPrice(scope.row.salary, 2, ' lei') }}</template>
                    </el-table-column>
                    <el-table-column label="Ore bugetate" prop="hours" align="right">
                        <template slot-scope="scope">{{ scope.row.hours }}</template>
                    </el-table-column>
                    <el-table-column label="Cost orar proiect" align="right">
                        <template slot-scope="scope">{{ formatPrice(scope.row.project) }}</template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>

        </el-tabs>
        <h3 class="mt-4 mb-3">Plan</h3>
        <el-tabs v-model="planTabs">
            <el-tab-pane label="Obiective" name="objectives">
                <el-table :data="project.objectives" size="small">
<!--                    <el-table-column label="ID" prop="objective" width="60"></el-table-column>-->
<!--                    <el-table-column label="Denumire" prop="name"></el-table-column>-->
                    <el-table-column label="Denumire" prop="description"></el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="Indicatori" name="indicators">
                <el-table :data="project.indicators" size="small">
                    <el-table-column label="ID" prop="indicator" width="60"></el-table-column>
                    <el-table-column label="Outcome" prop="outcome.value"></el-table-column>
                    <el-table-column label="Output" prop="output.value"></el-table-column>
                    <el-table-column label="Indicator">
                        <template slot-scope="scope">
                            <span v-if="scope.row.outcome_indicators">{{scope.row.outcome_indicators.value}}</span>
                            <span v-if="scope.row.output_indicators">{{scope.row.output_indicators.value}}</span>
                            <span v-if="scope.row.customIndicator">{{scope.row.customIndicator}}</span>
                            <span v-if="!scope.row.customIndicator && scope.row.indicator_id === 0">{{scope.row.name}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Target" width="100px">
                        <template slot-scope="scope">{{ formatNumber(scope.row.target,0) }} {{ scope.row.target_unit }}</template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="Calendar activitati" name="calendar">
                <pane-actions-calendar :project="project"></pane-actions-calendar>
<!--                <table border="1">-->
<!--                    <tr>-->
<!--                        <td v-for="month in monthDiff()">{{ currentMonthName(month) }}</td>-->
<!--                    </tr>-->
<!--                    <tr v-for="calendar in project.calendars">-->
<!--                        <td v-for="month in monthDiff()">-->
<!--                            <span class="bg-success" v-if="calendarActivity(month, calendar.start_date) === 1">{{ calendarActivity(month, calendar.start_date) }}</span>-->
<!--                            <span class="bg-success" v-if="calendarActivity(month, calendar.end_date) === 2">{{ calendarActivity(month, calendar.end_date) }}</span>-->
<!--                        </td>-->
<!--                    </tr>-->
<!--                </table>-->


                <el-table :data="project.calendars" size="small">
                    <el-table-column label="Denumire" prop="activity.name">
                        <template slot-scope="scope">
                            A{{scope.row.activity.activity}} {{scope.row.activity.name}}
                        </template>
                    </el-table-column>
                    <el-table-column label="Data inceput" prop="start_date" width="120">
                        <template slot-scope="scope">
                            {{ formatUserDate(scope.row.start_date) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Data sfarsit" prop="end_date" width="120">
                        <template slot-scope="scope">
                            {{ formatUserDate(scope.row.end_date) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Perioada" width="200">
                        <template slot-scope="scope">
                            {{ datesDifference(scope.row.start_date,scope.row.end_date) }}
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="Grafic de raportare" name="graph">
                <el-table :data="project.rgraph" stripe fit highlight-current-row size="small">
                    <el-table-column prop="reporting_id" label="Nr. raportare" width="120" align="center"></el-table-column>
                    <el-table-column label="Luna depunerii raportului"  align="center">
                        <template slot-scope="scope">
                            {{ formatUserDateNoDay(scope.row.date) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Suma Estimata" align="right">
                        <template slot-scope="scope">
                            {{ formatPrice(scope.row.estimated_amount) }}
                        </template>
                    </el-table-column>
<!--                    <el-table-column label="Raportare financiară" width="160" align="center">-->
<!--                        <template slot-scope="scope">-->
<!--                            <span v-if="scope.row.financial === 1" class="badge badge-success text-white">Depusă</span>-->
<!--                            <span v-else class="badge badge-info text-white">Disponibilă</span>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
<!--                    <el-table-column label="Raportare tehnică" width="160" align="center">-->
<!--                        <template slot-scope="scope">-->
<!--                            <span v-if="scope.row.technical === 1" class="badge badge-success text-white">Depusă</span>-->
<!--                            <span v-else class="badge badge-info text-white">Disponibilă</span>-->
<!--                        </template>-->
<!--                    </el-table-column>-->
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <h3 class="mt-4 mb-3">
            Buget
            <a href="#" @click.prevent="commitExportBudget" title="Export in Excel" style="margin-left:30px;">
                <i class="icofont-file-excel"></i>
            </a>
        </h3>
        <el-tabs v-model="budgetTabs" @tab-click="handleClick">
            <el-tab-pane :label="'A'+activity.activity" :name="'A'+activity.activity" v-for="activity in project.activities" :key="activity.id">
                <h4>
                    {{ 'A'+activity.activity }} {{ activity.name }}
                </h4>
                <el-table :data="activity.budget" :summary-method="getSummaries" show-summary size="small">
                    <el-table-column label="Capitol buget" prop="category.value"></el-table-column>
                    <el-table-column label="Aplicant/Partener" prop="partner">
                        <template slot-scope="scope" v-if="scope.row.partner">
                            {{ scope.row.partner.id !== project.promoter.id ? 'P' + scope.row.partner.id : 'Aplicant' }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Linie buget" prop="budget.value"></el-table-column>
                    <el-table-column label="Descriere" prop="description"></el-table-column>
                    <el-table-column label="Pozitie" prop="position.position" width="200"></el-table-column>
                    <el-table-column label="Cant." prop="quantity" align="right" width="80"></el-table-column>
                    <el-table-column label="UM" prop="unit.value" align="center" width="100"></el-table-column>
                    <el-table-column label="Cost unitar" prop="price" width="120" align="right" header-align="center">
                        <template slot-scope="scope">{{ formatPrice(scope.row.price) }}</template>
                    </el-table-column>
                    <el-table-column label="Total" prop="total" width="140" align="right" header-align="center">
                        <template slot-scope="scope">{{ formatPrice(scope.row.total) }}</template>
                    </el-table-column>
                    <el-table-column label="Din care comp. dezv. org." prop="dezv" width="200" align="right" header-align="center">
                        <template slot-scope="scope">{{ formatPrice(scope.row.dezv) }}</template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="Indirecte" name="indirects">

                <div class="row">
                    <div class="col-sm-12">
                        <table class="table table-responsive">
                            <thead>
                            <tr>
                                <th style="width:60px">Cod</th>
                                <th>Denumire aplicant/partener</th>
                                <th>Cost in €</th>
                                <th>Cost comp. dezv. org.</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="row in indirects">
                                <td>
                                    {{ row.partner_id === project.promoter.id ? 'A' : 'P' + row.partner.id }}
                                </td>
                                <td>
                                    {{ row.partner.name }}
                                </td>
                                <td>
                                    <el-input disabled v-model="row.price" type="number" placeholder="0 €" @focus="$event.target.select()"></el-input>
                                </td>
                                <td>
                                    <el-input disabled v-model="row.dezv" type="number" placeholder="0 €" @focus="$event.target.select()"></el-input>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        Valoarea procentului este de: {{ indirectPercent }}%
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Surse finantare" name="financing">
                <small class="text-muted">(valoare maxima {{ maxValue }})</small>
                <div class="row">
                    <div class="col-sm-12">
                        <h4>Detaliere surse pe aplicant si parteneri</h4>
                        <table class="table table-responsive">
                            <thead>
                            <tr>
                                <th style="width:60px">Cod</th>
                                <th>Denumire aplicant/partener</th>
                                <th>Contributie in bani</th>
                                <th>Contributie in natura</th>
                                <th>Finantare</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="row in financing">
                                <td>
                                    <span v-if="row.partner_id !== project.promoter.id && row.partner">P{{ row.partner.id }}</span>
                                    <span v-else>A</span>
                                </td>
                                <td>
                                    <span v-if="row.partner_id !== project.promoter.id && row.partner">{{ row.partner.name }}</span>
                                    <span v-else>{{ project.promoter.name }}</span>
                                </td>
                                <td class="text-right">
                                    <el-input-number disabled v-model="row.totalCash" controls-position="right" :min="0" :step="1" size="medium" @focus="$event.target.select()">
                                        <template slot="append">{{form.currency}}</template>
                                    </el-input-number>
                                </td>
                                <td class="text-right">
                                    {{ formatPrice(row.totalNature) }}
                                </td>
                                <td class="text-right">
                                    {{ formatPrice(row.totalBudget) }}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </el-tab-pane>
            <el-tab-pane label="Totaluri" name="totals">
                <table class="table table-responsive">
                    <thead>
                    <tr>
                        <th style="width:500px">Surse</th>
                        <th>Total</th>
                        <th>Procent din total</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            1. Finantare nerambursabila solicitata
                        </td>
                        <td class="text-right">
                            {{ formatPrice(totalIrredeemable) }}
                        </td>
                        <td class="text-right">
                            {{ formatPercent(totalIrredeemable/totalEligible) }}
                        </td>
                    </tr>
                    <tr>
                        <td>2. Cofinantare Aplicant si Parteneri</td>
                        <td class="text-right">
                            {{ formatPrice(totalCoFunding) }}
                        </td>
                        <td class="text-right">
                            {{ formatPercent(totalCoFunding/totalEligible) }}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-muted">2.1 Contributie in bani</td>
                        <td class="text-right text-muted">
                            {{ formatPrice(totalCash) }}
                        </td>
                        <td class="text-right text-muted">
                            {{ formatPercent(totalCash/totalCoFunding) }}
                        </td>
                    </tr>
                    <tr>
                        <td class="text-muted">2.2 Contributie in natura</td>
                        <td class="text-right text-muted">
                            {{ formatPrice(totalNature) }}
                        </td>
                        <td class="text-right text-muted">
                            {{ formatPercent(totalNature/totalCoFunding) }}
                        </td>
                    </tr>
                    <tr>
                        <td>TOTAL COSTURI ELIGIBILE ALE PROIECTULUI</td>
                        <td class="text-right">
                            {{ formatPrice(totalEligible) }}
                        </td>
                        <td class="text-right">
                            {{ formatPercent(totalEligible/totalEligible) }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </el-tab-pane>
            <el-tab-pane label="Total pe parteneri" name="total_partners">
                <el-table :data="partnerFinancing" fit size="small">
                    <el-table-column label="Cod" width="100">
                        <template slot-scope="scope">
                            {{ scope.row.partner && parseInt(scope.row.partner_id) !== parseInt(project.promoter.id) ? 'P' + scope.row.partner_id : 'Aplicant'}}
                        </template>
                    </el-table-column>
                    <el-table-column label="Denumire aplicant/partener">
                        <template slot-scope="scope">
                            {{ scope.row.partner && parseInt(scope.row.partner_id) !== parseInt(project.promoter.id) ? scope.row.partner.name : project.promoter.name}}
                        </template>
                    </el-table-column>
                    <el-table-column label="Cost total" width="140" align="right" header-align="center">
                        <template slot-scope="scope">{{ formatPrice(parseFloat(scope.row.totalBudget)  + parseFloat(scope.row.totalCash) + parseFloat(scope.row.totalNature)) }}</template>
                    </el-table-column>
                    <el-table-column label="Din care comp. dezv. org." width="200" align="right" header-align="center">
                        <template slot-scope="scope">{{ scope.row.totalDezv > 0 ? formatPrice(scope.row.totalDezv) : formatPrice(0,2) }}</template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
            <el-tab-pane label="Buget sintetic" name="synthetic">
                <el-table :data="synthetic" size="small">
                    <el-table-column label="Linia de buget" width="300">
                        <template slot-scope="scope">{{ scope.row.budget }}</template>
                    </el-table-column>
                    <template v-for="i in 14">
                        <el-table-column :label="'A' + i + ' total'" width="100" header-align="center" align="right">
                            <template slot-scope="scope">
                                <span v-if="parseInt(scope.row['totalA'+i]) > 0">{{ formatPrice(scope.row['totalA'+i],2) }}</span>
                                <span v-else>{{ formatPrice(0,2) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column :label="'A' + i + ' dezv'" width="100" header-align="center" align="right">
                            <template slot-scope="scope">
                                <span v-if="parseInt(scope.row['dezvA'+i]) > 0">{{ formatPrice(scope.row['dezvA'+i],2) }}</span>
                                <span v-else>{{ formatPrice(0,2) }}</span>
                            </template>
                        </el-table-column>
                    </template>
                </el-table>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex'
    import mixin from './../../mixins/common';
    import projectDetailsPane from './pane-details';
    import promoterDetailsPane from './../promoter/pane-details';
    import promoterEmployeesPane from './../promoter/pane-employees';
    import promoterNotificationsPane from './../promoter/pane-notifications';
    import PaneActionsCalendar from "./pane-actions-calendar";
    export default {
        name: "project-page-view",
        mixins:[mixin],
        components:{
            PaneActionsCalendar,
            projectDetailsPane,
            promoterDetailsPane,
            promoterEmployeesPane,
            promoterNotificationsPane,
            // projectSummaryPane
        },
        props:{
            permissions:{
                required: true,
            },
            project: {
                required: true,
                type: Object
            }
        },
        data() {
            return {
                loading: false,
                search: '',
                infoTabs: 'details',
                promoterTabs: 'details',
                resourcesTabs: '',//'activities',
                planTabs:'', //'objectives',
                budgetTabs:'', //'A1',
                 projectsTabs: '', //this.promoter.projects.length > 0 ? this.promoter.projects[0].name : ''
                indirects: [],
                synthetic: [],
                financing: [],
                partnerFinancing: [],
                totalIndirects: 0,
                totalIndirectsInitial: 0,
                indirectPercent: 0,
                totalEligible: 0,
                totalIrredeemable: 0,
                totalCoFunding: 0,
                totalNature: 0,
                totalCash: 0,
                maxValue: 0,
                form: {

                },
            }
        },
        computed:{
            ...mapGetters({
                // promoters: 'promoter/promoters',
            }),
        },
        methods:{
            ...mapActions({
                exportBudget: 'project/exportBudget',
                getIndirectsBudget: 'project/getIndirectsBudget',
                getIndirectPercent: 'project/getIndirectPercent',
                getFinancingBudget: 'project/getFinancingBudget',
                getTotalBudget: 'project/getTotalBudget',
                getTotalPartner: 'project/getTotalPartner',
                getSynthetic: 'project/getSynthetic',
            }),
            handleClick(tab, event) {
                if (tab.name === 'indirects') {
                    // go and bring the indirects
                    this.getIndirectsBudget({
                        project_id: this.project.id,
                    }).then((response) => {
                        this.indirects = response.data;
                        this.loading = false;
                    });
                    this.getIndirectPercent({
                        project_id: this.project.id,
                    }).then((response) => {
                        this.indirectPercent = response.data;
                        this.loading = false;
                    });
                }
                if (tab.name === 'financing') {
                    this.loading = true;
                    // go and bring the financing
                    this.getFinancingBudget({
                        project_id: this.project.id,
                    }).then((response) => {
                        this.financing = response.data;
                        this.maxValue = this.formatPrice(this.project.funding_budget);
                        this.loading = false;
                    });
                }
                if (tab.name === 'totals') {
                    // go and bring the totals
                    this.getTotalBudget({
                        project_id: this.project.id,
                    }).then((response) => {
                        this.totalEligible = response.data.totalEligible;
                        this.totalIrredeemable = response.data.totalIrredeemable;
                        this.totalCoFunding = response.data.totalCoFunding;
                        this.totalNature = response.data.totalNature;
                        this.totalCash = response.data.totalCash;
                        this.loading = false;
                    });
                }
                if (tab.name === 'total_partners') {
                    // go and bring the totals for partners
                    this.getTotalPartner({
                        project_id: this.project.id,
                    }).then((response) => {
                        this.partnerFinancing = response.data;
                        this.loading = false;
                    });
                }
                if (tab.name === 'synthetic') {
                    // go and bring the synthetic
                    this.getSynthetic({
                        project_id: this.project.id
                    }).then((response) => {
                        this.synthetic = response.data;
                        this.loading = false;
                    });
                }
            },
            commitExportBudget(){
                this.openUrl('/projects/export-budget/'+this.project.id, true);
            },
            handleCommand(command){
                if(command[1] === 'assign'){
                    this.openUrl('/projects/account/' + command[0]);
                }else {
                    this.openUrl('/projects/edit/' + command[0] + '/' + command[1]);
                }
            },
            getSummaries(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = 'Totaluri';
                        return;
                    }
                    if(index === 1 || index === 2 || index === 3 || index === 4 || index === 5 || index === 6 || index ===7){
                        sums[index] = '';
                        return;
                    }
                    const values = data.map(item => Number(item[column.property]));
                    if (!values.every(value => isNaN(value))) {
                        sums[index] = this.formatPrice(values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0),2);
                    } else {
                        sums[index] = ' - ';
                    }
                    if(index !== 4){
                        sums[index] = sums[index];
                    }
                });
                return sums;
            },
            checkPermission(permission){
                let perm = this.permissions;
                return _.filter(permission, function (item){
                    return _.includes(perm, item);
                }).length;
            }
        },
        mounted(){
            if(this.project.financing.length === 0){
                this.project.financing.push({
                    type: 'A',
                    partner_id: 0,
                    partner:{
                        id: 0,
                        name: this.project.promoter.name
                    },
                    price: 0,
                    dezv: 0,
                    totalNature: 0,
                    totalIndirect: 0,
                    totalBudget: 0,
                });
                this.project.partners.forEach((value, index) => {
                    this.project.financing.push({
                        type: 'P' + value.id,
                        partner_id: value.id,
                        partner:{
                            id: value.id,
                            name: value.name,
                        },
                        price: 0,
                        dezv: 0,
                        totalNature: 0,
                        totalIndirect: 0,
                        totalBudget: 0,
                    })
                });
                // this.project.financing = this.financing;
            }
        }
    }
</script>

<style scoped>
    .el-dropdown-link{
        cursor:pointer;
    }
</style>
