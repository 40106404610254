<template>
    <div v-if="project">
        <h3 class="mb-4 mt-3">Parteneri</h3>
        <div class="row">
            <div class="col-sm-12">
                <el-table :data="notification.partners" id="partners" stripe fit highlight-current-row style="width:100%">
                    <el-table-column label="Denumire" prop="name"></el-table-column>
                    <el-table-column label="Tip" prop="type.value" width="170"></el-table-column>
                    <el-table-column label="CIF" prop="cif" width="120">
                        <template slot-scope="scope">
                            {{ scope.row.fiscal_attribute }}{{ scope.row.cif }}
                        </template>
                    </el-table-column>
                    <el-table-column label="Nr. inreg." prop="register_no" width="140"></el-table-column>
                    <el-table-column label="Localitate" width="250">
                        <template slot-scope="scope">
                            <span v-if="scope.row.country_id.id === 1">
                                {{ scope.row.city.name }} - {{ scope.row.city.county.name }}
                            </span>
                            <span v-else="scope.row.country_id.id !== 1">
                                {{ scope.row.address }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Actiuni" width="130px" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="secondary" icon="el-icon-edit"
                                       @click="handleSelect(scope.row)"></el-button>
                            <el-button size="mini" type="danger" icon="el-icon-delete"
                                       @click="handleDelete(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <el-form :model="form" id="partnerForm" :rules="rules" ref="partnerForm" label-position="top" :status-icon="true" :inline-message="true">
            <!--            <div class="row mt-5">-->
            <!--                <div class="col-sm-6">-->
            <!--                    <el-form-item label="Cauta un partener existent dupa nume sau cod fiscal">-->
            <!--                        <el-select v-model="form.company" placeholder="Introduceti numele sau CIF" filterable remote @change="updateCompany"-->
            <!--                                   :remoteMethod="checkUnique" style="width:100%" value-key="id">-->
            <!--                            <el-option v-for="item in companies" :key="item.id" :label="item.name + '       CIF: ' + item.cif" :value="item">-->
            <!--                            </el-option>-->
            <!--                        </el-select>-->
            <!--                    </el-form-item>-->
            <!--                </div>-->
            <!--            </div>-->
            <div class="row">
                <div class="col-sm-12">
                    <hr />
                    <h4 class="mb-4 mt-5">Adauga/modifica partener</h4>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Denumirea partenerului (pentru adaugare introduceti si selectati numele)" prop="name">
                            <el-select v-model="form.name" placeholder="Introduceti numele" filterable remote allow-create @change="updateCompany"
                                       :remoteMethod="checkUnique" style="width:100%" value-key="id">
                                <el-option v-for="item in companies" :key="item.id" :label="item.name + '       CIF: ' + item.cif" :value="item"></el-option>
                            </el-select>
                            <!--                            <el-input v-model="form.name" placeholder="Denumirea partenerului in limba romana" clearable type="text" maxlength="90" show-word-limit></el-input>-->
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <el-form-item label="Denumirea partenerului in limba engleza" prop="name">
                            <el-input v-model="form.name_en" placeholder="Denumirea partenerului in limba engleza" clearable type="text" maxlength="90" show-word-limit></el-input>
                        </el-form-item>
                    </div>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="CIF" prop="cif">
                        <el-select v-model="form.cif" placeholder="Introduceti CIF" filterable allow-create remote @change="updateCompany"
                                   :remoteMethod="checkUnique" style="width:100%" value-key="id">
                            <el-option v-for="item in companies" :key="item.id" :label="item.name + '       CIF: ' + item.cif" :value="item"></el-option>
                        </el-select>
                        <!--                        <el-input v-model="form.cif" placeholder="Introduceti CIF-ul" type="number" maxlength="10" show-word-limit></el-input>-->
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Nr. inregistrare" prop="register_no">
                        <el-input v-model="form.register_no" placeholder="Nr. de inregistrare" type="text" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Forma juridica" prop="type">
                        <el-select v-model="form.type" placeholder="Select" style="width:100%" value-key="id">
                            <el-option v-for="item in companyTypes.values"
                                       :key="item.id" :label="item.value"  :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-3 form-group">
                    <el-form-item label="Atribut fiscal" prop="fiscal_attribute">
                        <el-checkbox v-model="form.fiscal_attribute" border true-label="RO">RO</el-checkbox>
                    </el-form-item>
                </div>
                <div class="col-sm-6" v-if="!form.country || form.country_id.id === 1" >
                    <el-form-item label="Locatie sediu social" prop="city" class="form-group" :rules="{required: true, message: 'Introduceti si selectati localitatea', trigger: 'blur'}">
                        <el-select v-model="form.city" filterable clearable remote
                                   placeholder="Introduceti numele localitatii" :remote-method="remoteMethod" style="width:100%" value-key="id">
                            <el-option v-for="item in cities"
                                       :key="item.id"
                                       :label="item.name + ' - ' + (item.county.name !== 'Bucuresti' ? 'Judetul ' : ' ') + item.county.name"
                                       :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-6" v-if="form.country && form.country_id.id !== 1">
                    <el-form-item label="Orasul" prop="address" class="form-group" :rules="{required: true, message: 'Introduceti numele localitatii', trigger: 'change'}">
                        <el-input v-model="form.address" placeholder="Introduceti numele localitatii" type="text" maxlength="60" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-6">
                    <el-form-item label="Tara" prop="country" class="form-group">
                        <el-select v-model="form.country" placeholder="Selecteaza tara" style="width:100%" value-key="id" disabled>
                            <el-option v-for="item in countries"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </div>
                <div class="col-sm-3">
                    <el-form-item label="Telefon" prop="phone" class="form-group">
                        <el-input v-model="form.phone" placeholder="Numarul de telefon" clearable type="tel" maxlength="20" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-3">
                    <el-form-item label="Email" prop="email" class="form-group">
                        <el-input v-model="form.email" placeholder="Adresa de e-mail" clearable type="email" maxlength="200" show-word-limit></el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-6">
                    <el-form-item label="Website" prop="website" class="form-group">
                        <el-input v-model="form.website" placeholder="Adresa URL" clearable type="text" maxlength="60" show-word-limit>
                            <!--                            <template slot="prepend">https://</template>-->
                        </el-input>
                    </el-form-item>
                </div>
                <div class="col-sm-12 text-right mb-5">
                    <el-button @click.prevent="clear">Goleste formularul</el-button>
                    <el-button @click.prevent="addRecord" type="secondary">Aplica modificarile</el-button>
                </div>
            </div>
            <div class="input-group">
                <textarea placeholder="Adauga un comentariu" v-on:change="saveComment" v-model="form.comment_sec2" class="form-control custom-control" rows="3" style="resize:none"></textarea>     
                <el-button class="input-group-addon btn btn-primary" icon="el-icon-edit" @click="uploadFilesComment(notification)"></br>Vezi/modifică</br>fișierele</br>comentariului</el-button>
            </div>
            <hr />
            <div class="form-group">
                <h5>Lista comentarii:</h5>
                <span style="white-space: pre-line;">{{ form.comment_sec2 }}</span>
            </div>
        </el-form>
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "notification-partners-form",
        data(){
            return {
                form:{},
                partners: [],
                rules: {
                    name: [
                        {required: true, message: 'Numele firmei este obligatoriu', trigger: 'blur'},
                        { min: 3, max: 90, message: 'Lungimea trebuie sa fie intre 3 si 90 caractere', trigger: 'blur' }
                    ],
                    name_en: [
                        {required: true, message: 'Numele firmei este obligatoriu', trigger: 'blur'},
                        { min: 3, max: 90, message: 'Lungimea trebuie sa fie intre 3 si 90 caractere', trigger: 'blur' }
                    ],
                    type: [
                        {required: true, message: 'Selectati forma juridica', trigger: 'blur'},
                    ],
                    cif: [
                        {required: true, message: 'Descrierea activitatii este obligatorie', trigger: 'blur'},
                        { min: 3, max: 10, message: 'Lungimea trebuie sa fie intre 3 si 10 caractere', trigger: '' }
                    ],
                    // city: [
                    //     {required: true, message: '', trigger: 'blur'},
                    // ],
                    country: [
                        {required: true, message: 'Selectati tara', trigger: 'blur'},
                    ],
                    phone: [
                        { required: true, message: 'Telefonul este obligatoriu', trigger: 'blur' },
                        { min: 10, max: 20, message: 'Lungimea trebuie sa fie intre 10 si 20 caractere', trigger: 'blur' }
                    ],
                    email: [
                        { type: 'email', required: true, message: 'Completati adresa de email', trigger: 'blur' }
                    ],
                },
            }
        },
        computed:{
            ...mapGetters({
                cities: 'cities',
                countries: 'countries',
                companies: 'companies',
                project: 'project/project',
                notification: 'notification/notification',
                validateNotification: 'notification/validateNotification',
                companyTypes: 'project/companyTypes',
                validatePartners: 'project/validatePartners',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        watch:{
            validatePartners: function(){
                if(this.validatePartners) {
                    this.submitForm();
                }
            },
            validateNotification: function(){
                if(this.validateNotification) {
                    this.setValidateNotification(false);
                    this.submitForm();
                }
            },
        },
        mounted(){
            this.getCompanyTypes();
            this.getCountries();
            this.clear();
        },
        methods:{
            ...mapActions({
                searchCity: 'searchCity',
                searchCompany: 'searchCompany',
                searchCif: 'searchCif',
                getCountries: 'getCountries',
                getCompanyTypes: 'project/getCompanyTypes',
                saveNotificationComment: 'notification/saveNotificationComment'
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
                setValidatePartners: 'project/setValidatePartners',
                setValidateNotification: 'notification/setValidateNotification',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            uploadFilesComment(form)
            {
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Notification',
                    model_id: form.id,
                    category_id: 3,
                    acceptFiles: '.pdf,.docx,.doc,.xlsx,.xls',
                    multiple: true,
                    title: 'Incarcă documente',
                    files: form.commentfiles.filter(p => p.category_id == 3),
                });
            },
            saveComment()
            {
                this.saveNotificationComment({
                    id: this.notification.id,
                    project_id: this.project.id,
                    section_id: 2,
                    comment: this.form.comment_sec2,
                });
            },
            updateCompany(data){
                if(data.cif) {
                    this.form = data;
                    if(this.form.city) this.searchCity(this.form.city.name);
                    this.form.index = this.notification.partners.length + 1;
                }
            },
            checkUnique(query) {
                if (query !== '') {
                    setTimeout(() => {
                        this.searchCompany(query);
                    }, 200);
                }
            },
            remoteMethod(query) {
                if (query !== '') {
                    setTimeout(() => {
                        this.searchCity(query);
                    }, 200);
                }
            },
            isExist : function(){
                if(this.form.id > 0) return false;
                for(var i=0; i < this.notification.partners.length; i++){
                    if( this.notification.partners[i].cif == this.form.cif){
                        return true
                    }
                }
                return false
            },
            addRecord(){
                this.$refs['partnerForm'].validate((valid) => {
                    if (valid) {
                        if(!this.isExist()) {
                            this.form.type_id = this.form.type.id;
                            if (this.form.index > this.notification.partners.length) {
                                this.notification.partners.push(this.form);
                            } else {
                                this.notification.partners[this.form.index - 1] = this.form
                            }
                            this.savePartner();
                            this.clear();
                            this.setValidatePartners(false);
                            VueScrollTo.scrollTo('#partners');
                            return true;
                        }else{
                            this.$notify({ type: 'warning', title: 'Parteneri', message: 'Acest partener a fost deja adaugat, alege altul!'});
                        }
                    } else {
                        return false;
                    }
                });
            },
            savePartner()
            {
                axios.post('/notifications/notificationPartner', {project_id: this.project.id, id:this.notification.id, partners: this.notification.partners}).then((result) => { this.$notify({ type: 'success', title: 'Parteneri', message: 'Partenerul a fost adaugat cu succes!'}); });
            },
            handleSelect(row){
                this.form = row;
                if(this.form.country.id === 1) {
                    this.remoteMethod(this.form.city.name);
                }
                VueScrollTo.scrollTo('#partnerForm');
            },
            clear(){
                if(this.notification.partners){
                    this.notification.partners = this.notification.partners;
                }
                this.form =  {
                    index: this.notification.partners ? this.notification.partners.length + 1 : 1
                };
            },
            handleDelete(index, row){
                this.$confirm('Esti sigur(a) ca vrei sa stergi aceasta inregistrare?')
                    .then(_ => {
                        if (index !== -1) {
                            if(row.id > 0)
                            {
                                axios.post('/notifications/notificationRemovePartner', {project_id: this.project.id, id: this.notification.id, cif: row.cif});
                                this.notification.partners.splice(index, 1);
                            }else {
                                this.notification.partners.splice(index, 1);
                            }
                            this.$notify({ type: 'success', title: 'Parteneri', message: 'Inregistrarea a fost stearsa cu succes!'});
                            this.clear();
                        }
                    }).catch(err => {
                    console.log(err)
                });
            },
            submitForm() {
                if (this.notification.partners.length >= 0) {
                    this.setValidatePartners(false);
                    this.setValidationErrorsExists(false);
                    return true;
                } else {
                    this.setValidationErrorsExists(true);
                    this.$notify({ type: 'warning', title: 'Parteneri', message: 'Trebuie sa adaugati cel putin un partener!'});
                    return false;
                }
            },
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
