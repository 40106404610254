<template>
    <div v-if="project">
        <h3 class="mb-4 mt-3">Data de implementare</h3>
        <div class="col-sm-3">
            <label>Perioada de implementare: {{ project.implementation_start_date }} - {{ project.implementation_end_date }}</label>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <el-date-picker v-model="form.end_date" placeholder="Data perioadei de implementare" :picker-options="datePickerOptions" type="date" value-format="yyyy-MM-dd"
                        format="yyyy-MM-dd" @change="UPDB" style="width:100%"></el-date-picker>
                </div>
            </div>
        </div>
        <div class="input-group">
            <textarea placeholder="Adauga un comentariu" v-on:change="saveComment" v-model="form.comment_sec1" class="form-control custom-control" rows="3" style="resize:none"></textarea>     
            <el-button class="input-group-addon btn btn-primary" icon="el-icon-edit" @click="uploadFilesComment(notification)"></br>Vezi/modifică</br>fișierele</br>comentariului</br></el-button>
        </div>
        <hr />
        <div class="form-group">
            <h5>Lista comentarii:</h5>
            <span style="white-space: pre-line;">{{ form.comment_sec1 }}</span>
        </div>
        
    </div>
</template>

<script>
    import { mapGetters, mapActions, mapMutations } from 'vuex'
    import VueScrollTo from 'vue-scrollto';
    export default {
        name: "notification-date-form",
        data(){
            return {
                form:{},
                datePickerOptions: {
                disabledDate (date) {
                    return date < new Date() }
                },
            }
        },
        computed:{
            ...mapGetters({
                project: 'project/project',
                notification: 'notification/notification',
                validationErrorsExists: 'validationErrorsExists',
            }),
        },
        methods:{
            ...mapActions({
                saveNotificationComment: 'notification/saveNotificationComment'
            }),
            ...mapMutations({
                setShowUploadDrawer: 'setShowUploadDrawer',
                setValidationErrorsExists: 'setValidationErrorsExists'
            }),
            uploadFilesComment(form)
            {
                this.setShowUploadDrawer({
                    visible: true,
                    model: 'App\\Models\\Notification',
                    model_id: form.id,
                    category_id: 2,
                    acceptFiles: '.pdf,.docx,.doc,.xlsx,.xls',
                    multiple: true,
                    title: 'Incarcă documente',
                    files: form.commentfiles.filter(p => p.category_id == 2),
                });
            },
            saveComment()
            {
                this.saveNotificationComment({
                    id: this.project.id,
                    project_id: this.notification.id,
                    section_id: 1,
                    comment: this.form.comment_sec1,
                });
            },
            UPDB()
            {
                axios.post('/notifications/notificationDate', {project_id: this.project.id, id:this.notification.id, date: this.form.end_date}).then((result) => {
                        this.$notify({
                        type: 'success',
                        title: 'Data finala a perioadei de implementare',
                        message: 'Data a fost modificata cu succes!'
                    });
                });
            }
        }
    }
</script>

<style scoped>
    .el-popover__reference{
        cursor: help;
    }
</style>
